import { createSlice } from "@reduxjs/toolkit";

export const companySettingsSlice = createSlice({
  name: "company-settings",
  initialState: {
    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    industryClassification: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    orgRep: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    group: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    customField: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      isSingleFetched: false,
      singleCustomField: undefined
    }
  },
  reducers: {
    onCallCompanySettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].isLoading = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessCompanySettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;
      //     .map(i => ({
      //     company_status_id: i.company_status_id,
      //     isDefault: i.Default,
      //     statusName: i.Status
      // }));
      state[payload.key].columnNames = payload.data.columnNames;
    },
    onFailedCompanySettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },

    switchCompanySettingLoading: (state, { payload }) => {
      state[payload.key].isLoading = payload.status !== undefined ? payload.status : !state[payload.key].isLoading;
    },

    onSuccessCompanySettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default && payload.key !== 'group') {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessCompanySettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          if (payload.key === 'group') {
            state[payload.key].list = state[payload.key].list.map((item) =>
              item[payload.idKey] === payload.data[payload.idKey]
                ? payload.data
                : item
            );
          } else {
            state[payload.key].list = state[payload.key].list.map((item) =>
              item[payload.idKey] === payload.data[payload.idKey]
                ? payload.data
                : {
                  ...item,
                  Default: false
                }
            );
          }
        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessCompanySettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
      }
    },

    onCallCompanyCustomFields: (state) => {
      state.customField.isFetched = false;
      state.customField.isLoading = false;
      state.customField.list = [];
      state.customField.columnNames = [];
    },
    onSuccessCompanyCustomFields: (state, { payload }) => {
      state.customField.isFetched = true;
      state.customField.list = payload.tableData;
      state.customField.columnNames = payload.columnNames;
    },
    onFailedCompanyCustomFields: (state) => {
      state.customField.isFetched = true;
      state.customField.list = [];
      state.customField.columnNames = [];
    },
    onFailedReorderCustomFieldRowsSetting: (state, { payload }) => {
      state[payload.key].list = payload.data;
    },

    switchCompanyCustomFieldLoading: (state, { payload }) => {
      state.customField.isLoading = payload !== undefined ? payload : !state.customField.isLoading;
    },

    onSuccessCompanyCustomField: (state, { payload }) => {
      state.customField.isSingleFetched = true;
      state.customField.singleCustomField = payload;
    },
    onFailedCompanyCustomField: (state) => {
      state.customField.isSingleFetched = true;
      state.customField.singleCustomField = undefined;
    },
    resetCompanyCustomField: (state) => {
      state.customField.isSingleFetched = false;
      state.customField.singleCustomField = undefined;
    },

    onSuccessCompanyCustomFieldAdd: (state, { payload }) => {
      if (payload) {
        state.customField.list = [...state.customField.list, payload];
      }
    },
    onSuccessCompanyCustomFieldEdit: (state, { payload }) => {
      if (payload) {
        state.customField.list = state.customField.list.map((cF) =>
          cF.custom_field_definition_id === payload.custom_field_definition_id ? payload : cF
        );
      }
    },
    onSuccessCompanyCustomFieldDelete: (state, { payload }) => {
      if (payload !== undefined) {
        const deletedItemIndex = state.customField.list.findIndex((cf) => cf.custom_field_definition_id === payload);
        state.customField.list = state.customField.list.reduce((acc, item, index) => {
          if (index >= deletedItemIndex) {
            item.Position -= 1;
          }
          if (item.custom_field_definition_id !== payload) {
            acc.push(item);
          }
          return acc;
        }, []);
        // TO DO => DELETE AFTER SUCCESSFULL TESTING // state.customField.list = state.customField.list.filter(cF => cF.custom_field_definition_id !== payload);
      }
    },
    onReorderCompanySettings: (state, { payload }) => {
      state[payload.key].list = payload.data;
    }
  }
});

export const {
  onCallCompanySettings,
  onSuccessCompanySettings,
  onFailedCompanySettings,
  switchCompanySettingLoading,
  onSuccessCompanySettingAdd,
  onSuccessCompanySettingEdit,
  onSuccessCompanySettingDelete,
  onCallCompanyCustomFields,
  onSuccessCompanyCustomFields,
  onFailedCompanyCustomFields,
  onFailedReorderCustomFieldRowsSetting,
  switchCompanyCustomFieldLoading,
  onSuccessCompanyCustomField,
  onFailedCompanyCustomField,
  resetCompanyCustomField,
  onSuccessCompanyCustomFieldAdd,
  onSuccessCompanyCustomFieldEdit,
  onSuccessCompanyCustomFieldDelete,
  onReorderCompanySettings
} = companySettingsSlice.actions;

export default companySettingsSlice.reducer;
