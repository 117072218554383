import React, { memo } from "react";
import { Switch, Route } from "react-router-dom";
import { thirdPartAuthRouteNames } from "constant/routes";
import ThirdPartyAuth from "pages/third-party";
import UserUnsubscription from "pages/third-party/user-unsubscription";

const ThirdPartyLayout = memo(() => (
  <div>
    <Switch>
      <Route path={thirdPartAuthRouteNames.AUTHENTICATION} component={ThirdPartyAuth} />
      <Route path={thirdPartAuthRouteNames.UNSUBSCRIPTION} component={UserUnsubscription} />
      {/* <ConditionRoute*/}
      {/*    path={thirdPartAuthRouteNames.GOOGLE}*/}
      {/*    redirectLink={routeNames.APP}*/}
      {/*    condition={true}*/}
      {/*    component={GoogleAuth}*/}
      {/* />*/}
    </Switch>
  </div>
));

export default ThirdPartyLayout;
