import { createSlice } from "@reduxjs/toolkit";

export const timezonesSlice = createSlice({
  name: "timezones",
  initialState: {
    isFetched: false,
    timezones: []
  },
  reducers: {
    onCallTimezones: (state) => {
      state.isFetched = false;
      state.timezones = [];
    },
    onSuccessTimezones: (state, { payload }) => {
      state.isFetched = true;
      if (payload?.timezones && Array.isArray(payload.timezones)) {
        state.timezones = payload?.timezones.map((t) => ({
          // ...t,
          name: t.timezone,
          code: t.timezone_id
        }));
      }
    },
    onFailedTimezones: (state) => {
      state.isFetched = true;
      state.timezones = [];
    }
  }
});

export const { onCallTimezones, onSuccessTimezones, onFailedTimezones } = timezonesSlice.actions;

export default timezonesSlice.reducer;
