import { call, put, takeEvery } from "redux-saga/effects";
import { setError } from "store/error/slice";
import axiosInstance from "clients/api";
import { EXPORT_CALL } from "./sagas-actions";
import { onLoadingExport, onDownloadedExport } from "./slice";

function* exportCallGenerator(action) {
  try {
    yield put(onLoadingExport(true));
    const {
      payload: { exportData, moduleName, fileName, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.post, `/tenantUser/${moduleName}/sheet/export`, exportData, {
      responseType: "blob"
    });

    const blobType =
      exportData.file_type === "xlsx"
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "application/vnd.ms-excel";
    const blob = new Blob([data], { type: blobType });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.${exportData.file_type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    yield messageFunction && messageFunction();
    yield put(onDownloadedExport(true));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(onLoadingExport(false));
}

export default function* exportSaga() {
  yield takeEvery(EXPORT_CALL, exportCallGenerator);
}
