export const SECURITY_ROLES_CALL = "SECURITY_ROLES_CALL";
export const SECURITY_ROLES_EDIT_CALL = "SECURITY_ROLES_EDIT_CALL";
export const SECURITY_ROLE_ADD_CALL = "SECURITY_ROLE_ADD_CALL";
export const SECURITY_ROLE_DELETE_CALL = "SECURITY_ROLE_DELETE_CALL";

export function callSecurityRoles() {
  return { type: SECURITY_ROLES_CALL };
}

export function callEditSecurityRoles(securityRolesData) {
  return { type: SECURITY_ROLES_EDIT_CALL, payload: { securityRolesData } };
}

export function callAddSecurityRole(roleData) {
  return { type: SECURITY_ROLE_ADD_CALL, payload: { roleData } };
}

export function callDeleteSecurityRole(securityRoleId) {
  return { type: SECURITY_ROLE_DELETE_CALL, payload: { securityRoleId } };
}
