import React from "react";
import Loader from "components/common/loader";

const ThirdPartyAuth = () => (
  <div>
    <Loader />
  </div>
);

export default ThirdPartyAuth;
