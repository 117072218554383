import { createSlice } from "@reduxjs/toolkit";

export const deletedItemsSlice = createSlice({
  name: "deleted-items",
  initialState: {
    isFetched: false,
    isLoading: false,
    deletedItems: [],
    columnNames: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
  },
  reducers: {
    onCallDeletedItems: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.deletedItems = [];
    },
    onSuccessDeletedItems: (state, { payload }) => {
      state.isFetched = true;
      state.count = parseInt(payload?.total_count, 10) || 0;
      state.deletedItems = payload.tableData;
      state.columnNames = payload.columnNames;
    },
    onFailedDeletedItems: (state) => {
      state.isFetched = true;
      state.deletedItems = [];
      state.columnNames = [];
      state.count = 0;
    },
    switchDeletedItemsLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    onSuccessRestoreItems: (state, { payload }) => {
      state.deletedItems = state.deletedItems.filter(item => !payload.includes(item.id));
      state.count -= payload.length;
    }
  }
});

export const {
  onCallDeletedItems,
  onSuccessDeletedItems,
  onFailedDeletedItems,
  switchDeletedItemsLoading,
  onSuccessRestoreItems
} = deletedItemsSlice.actions;

export default deletedItemsSlice.reducer;