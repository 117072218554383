import React, { memo, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import axiosInstance from "clients/api";
import useDidUpdate from "hooks/common/useDidUpdate";
import Field from "../field-generic";
import FieldEndAdornment from "../field-end-adornment";

const FieldAxiosCheck = memo(
  ({
     control,
     watch,
     trigger,
     error,
     setError,
     clearErrors,
     isDirty,
     name,
     defaultValue,
     statusBarText,
     fetchUrl,
     setPromoInfo,
     ...inputProps
   }) => {
    const inputValue = watch(name);

    const checkTimer = useRef(null);
    const axiosSourceRef = useRef(null);
    const [fetchIsLoading, setFetchIsLoading] = useState(false);

    const checkByApi = useCallback(async (loadingHandler, key, value, url) => {
      if (axiosSourceRef.current) {
        axiosSourceRef.current.cancel();
      }

      axiosSourceRef.current = axios.CancelToken.source();

      try {
        if (value) {
          const { data } = await axiosInstance.get(url, {
            cancelToken: axiosSourceRef.current.token,
            params: {
              ...(key === "subdomain" && { subdomain: value }),
              ...(key.includes("email") && { email: value }),
              ...(key === "promoCode" && { promoCode: value })
            }
          });

          if (key === "promoCode") {
            setPromoInfo(data.promoCodeInfo[0].coupon);
          }
        }
      } catch (err) {
        if (err?.response?.data?.status === 422 || err?.response?.data?.status === 409) {
          setError(key, {
            type: `${key}.used`,
            message: key === "promoCode" ? "This promo code is invalid" : `This ${key} is already in use`
          });
        } else if (key !== "promoCode") {
          setError(key, {
            type: `${key}.failed`,
            message: `Failed to check ${key}`
          });
        }
      }
      loadingHandler(false);
    }, []);

    // onChange listener
    useDidUpdate(() => {
      setFetchIsLoading(true);

      if (checkTimer.current) {
        clearTimeout(checkTimer.current);
      }

      checkTimer.current = setTimeout(async () => {
        const isValidSubdomain = await trigger(name);

        if (isValidSubdomain) {
          checkByApi(setFetchIsLoading, name, inputValue, fetchUrl);
        } else {
          setFetchIsLoading(false);
        }
      }, 400);

      return () => {
        checkTimer.current && clearTimeout(checkTimer.current);
        axiosSourceRef.current && axiosSourceRef.current.cancel();
      };
    }, [inputValue]);

    // useDidUpdate(() => {
    //     if (fetchIsLoading) {
    //         setError(name, {
    //             type: `${name}.loading`,
    //             message: 'loading'
    //         });
    //     } else {
    //         clearErrors(name);
    //     }
    // }, [fetchIsLoading]);

    return (
      <Field
        control={control}
        name={name}
        defaultValue={defaultValue}
        error={error}
        InputProps={{
          endAdornment: (
            <FieldEndAdornment text={statusBarText} isDirty={isDirty} isError={!!error} isLoading={fetchIsLoading} />
          )
        }}
        {...inputProps}
      />
    );
  }
);

FieldAxiosCheck.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  error: PropTypes.string
};

FieldAxiosCheck.defaultProps = {
  defaultValue: "",
  error: undefined
};

export default FieldAxiosCheck;
