import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { implementPromiseAction, rejectPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import {
  callTenantIntegrationAddPromise,
  callTenantIntegrationDeletePromise,
  callTenantIntegrationEditPromise,
  DISCONNECT_INTEGRATION,
  INTEGRATION_STATUS_CHANGE,
  INTEGRATIONS_CALL,
  SYNC_INTEGRATION,
  TENANT_INTEGRATIONS_CALL,
  USER_TENANT_INTEGRATIONS_CALL
} from "./sagas-actions";
import {
  onCallIntegrations,
  onCallTenantIntegrations,
  onCallUserTenantIntegrations,
  onFailedIntegrations,
  onFailedTenantIntegrations,
  onFailedUserTenantIntegrations,
  onSuccessDisconnectIntegration,
  onSuccessIntegrations,
  onSuccessTenantIntegrationAdd,
  onSuccessTenantIntegrationDelete,
  onSuccessTenantIntegrationEdit,
  onSuccessTenantIntegrations,
  onSuccessUserTenantIntegrations,
  switchTenantIntegrationLoading
} from "./slice";
import { changeUserEmail } from "../user/slice";

const prefix = "/integration";

function* integrationsCallGenerator(action) {
  yield put(onCallIntegrations());
  try {
    const { data } = yield call(axiosSagaRequest, "get", prefix);
    yield put(onSuccessIntegrations(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedIntegrations());
  }
}

function* tenantIntegrationsCallGenerator(action) {
  if (!action?.payload?.withoutLoading) {
    yield put(onCallTenantIntegrations());
  }
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/tenant`);
    yield put(onSuccessTenantIntegrations(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTenantIntegrations());
  }
}

function* userTenantIntegrationsCallGenerator(action) {
  yield put(onCallUserTenantIntegrations());
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/tenant_user`);
    yield put(onSuccessUserTenantIntegrations(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedUserTenantIntegrations());
  }
}

function* tenantIntegrationAddPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* tenantIntegrationAddWorker() {
    const {
      payload: { integrationData, userTenantIntegration, messageFunction, errorMessageText }
    } = action;
    yield put(switchTenantIntegrationLoading(true));
    try {
      const url = userTenantIntegration ? "tenant_user" : "tenant";
      const { data } = yield call(axiosSagaRequest, "post", `${prefix}/${url}`, integrationData);
      yield put(onSuccessTenantIntegrationAdd({ data, userTenantIntegration }));
      yield messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield messageFunction && messageFunction(errorMessageText, "error");
      // yield call(rejectPromiseAction, action, error);
    }
    yield put(switchTenantIntegrationLoading(false));
  });
}

function* tenantIntegrationEditPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* tenantIntegrationEditWorker() {
    const {
      payload: { tenantIntegrationId, integrationData, userTenantIntegration, messageFunction, errorMessageText }
    } = action;
    yield put(switchTenantIntegrationLoading(true));
    try {
      const url = userTenantIntegration ? "tenant_user" : "tenant";
      const { data } = yield call(axiosSagaRequest, "put", `${prefix}/${url}/${tenantIntegrationId}`, integrationData);
      yield put(onSuccessTenantIntegrationEdit({ data, userTenantIntegration }));
      yield messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield messageFunction && messageFunction(errorMessageText, "error");
      yield call(rejectPromiseAction, action, error);
    }
    yield put(switchTenantIntegrationLoading(false));
  });
}

function* tenantIntegrationDeletePromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* tenantIntegrationDeleteWorker() {
    const {
      payload: { tenantIntegrationIds, userTenantIntegration, messageText, messageFunction, errorMessageText }
    } = action;
    yield put(switchTenantIntegrationLoading(true));
    try {
      const link = userTenantIntegration ? "tenant_user" : "tenant";
      const key = userTenantIntegration ? "tenant_user_integration_ids" : "tenant_integration_ids";
      const { data } = yield call(axiosSagaRequest, "post", `${prefix}/${link}/delete_multiple`, {
        [key]: tenantIntegrationIds
      });
      yield put(onSuccessTenantIntegrationDelete({ tenantIntegrationIds, userTenantIntegration }));
      yield messageFunction && messageFunction(messageText);
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield messageFunction && messageFunction(errorMessageText, "error");
      yield call(rejectPromiseAction, action, error);
    }
    yield put(switchTenantIntegrationLoading(false));
  });
}

function* integrationStatusChangeCallGenerator(action) {
  try {
    const {
      payload: { integrationId, status_id, userTenantIntegration, messageFunction }
    } = action;

    const link = userTenantIntegration ? "tenant_user" : "tenant";

    const { data } = yield call(axiosSagaRequest, "put", `${prefix}/${link}/status/${integrationId}`, {
      status_id
    });
    yield put(onSuccessTenantIntegrationEdit({ data, userTenantIntegration }));
    yield messageFunction && messageFunction("Status changed");
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* disconnectIntegrationGenerator(action) {
  try {
    const {
      payload: { integrationId, messageFunction }
    } = action;
    yield call(axiosSagaRequest, "get", `integration/tenant/disconnect/${integrationId}`);
    yield messageFunction && messageFunction();
    yield put(onSuccessDisconnectIntegration(integrationId));
  } catch (e) {
    console.log(e, "this is error");
  }
}

function* syncIntegrationGenerator(action) {
  try {
    const { payload } = action;
    yield call(axiosSagaRequest, "put", `integration/email_integration_sync`, payload.data);
    yield put(changeUserEmail(payload.data));
  } catch (e) {
    console.log(e, "this is error");
  }
}

export default function* integrationsWatcherSaga() {
  yield takeLatest(INTEGRATIONS_CALL, integrationsCallGenerator);
  yield takeLatest(TENANT_INTEGRATIONS_CALL, tenantIntegrationsCallGenerator);
  yield takeLatest(USER_TENANT_INTEGRATIONS_CALL, userTenantIntegrationsCallGenerator);
  yield takeEvery(callTenantIntegrationAddPromise, tenantIntegrationAddPromiseCallGenerator);
  yield takeEvery(callTenantIntegrationEditPromise, tenantIntegrationEditPromiseCallGenerator);
  yield takeEvery(callTenantIntegrationDeletePromise, tenantIntegrationDeletePromiseCallGenerator);
  yield takeEvery(INTEGRATION_STATUS_CHANGE, integrationStatusChangeCallGenerator);
  yield takeEvery(DISCONNECT_INTEGRATION, disconnectIntegrationGenerator);
  yield takeEvery(SYNC_INTEGRATION, syncIntegrationGenerator);
}
