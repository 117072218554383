import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const DragItem = ({ itemObject, index }) => (
  <Draggable key={itemObject.columnName} draggableId={itemObject.columnName} index={index}>
    {(provided) => (
      <ListItem
        sx={{ pl: 0, pr: 0 }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <ListItemText
          primaryTypographyProps={{ style: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
          primary={itemObject.columnName}
        />
      </ListItem>
    )}
  </Draggable>
);

export default DragItem;
