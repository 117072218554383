import { createPromiseAction } from "@adobe/redux-saga-promise";

export const TEMPLATES_CALL = "TEMPLATES_CALL";
export const TEMPLATES_CALL_PROMISE = "TEMPLATES_CALL_PROMISE";
export const TEMPLATE_CALL = "TEMPLATE_CALL";
export const TEMPLATE_ADD_CALL = "TEMPLATE_ADD_CALL";
export const TEMPLATE_ADD_CALL_PROMISE = "TEMPLATE_ADD_CALL_PROMISE";
export const TEMPLATE_IMPORT_CALL = "TEMPLATE_IMPORT_CALL";
export const TEMPLATE_EDIT_CALL = "TEMPLATE_EDIT_CALL";
export const TEMPLATES_DELETE_CALL = "TEMPLATES_DELETE_CALL";
export const TEMPLATES_RESTORE_CALL = "TEMPLATES_RESTORE_CALL";
export const TEMPLATE_MERGE_FIELDS_CALL = "TEMPLATE_MERGE_FIELDS_CALL";
export const TEMPLATE_MERGE_FIELDS_DATA_CALL = "TEMPLATE_MERGE_FIELDS_DATA_CALL";
export const TEMPLATE_TEST_CALL = "TEMPLATE_TEST_CALL";
export const TEMPLATE_CC_BCC_CALL = "TEMPLATE_CC_BCC_CALL";
export const TEMPLATES_CONTACTS_CALL = "TEMPLATES_CONTACTS_CALL";
export const EMAILS_SEND_TO_CONTACTS_CALL = "EMAILS_SEND_TO_CONTACTS_CALL";
export const SMS_SEND_TO_CONTACTS_CALL = "SMS_SEND_TO_CONTACTS_CALL";
export const DOCUMENT_SEND_EMAIL = "DOCUMENT_SEND_EMAIL";
export const GET_TEMPLATES_COUNT = "GET_TEMPLATES_COUNT";
export const GET_TEMPLATES_BY_TYPE = "GET_TEMPLATES_BY_TYPE";
export const GET_TEMPLATES_GROUPS = "GET_TEMPLATES_GROUPS";
export const GET_FILTERED_TEMPLATES = "GET_FILTERED_TEMPLATES";
// export const TEMPLATE_ATTACHMENTS_ADD_CALL = 'TEMPLATE_ATTACHMENTS_ADD_CALL';
// export const TEMPLATE_ATTACHMENTS_DELETE_CALL = 'TEMPLATE_ATTACHMENTS_DELETE_CALL';
export const callTemplateAddPromise = createPromiseAction(TEMPLATE_ADD_CALL_PROMISE);
export const callTemplatesPromise = createPromiseAction(TEMPLATES_CALL_PROMISE);

export function callTemplates(paginationData) {
  return { type: TEMPLATES_CALL, payload: { paginationData } };
}

export function getTemplatesGroups() {
  return { type: GET_TEMPLATES_GROUPS };
}

export function getFilteredTemplates(typeIds, groupIds) {
  return {
    type: GET_FILTERED_TEMPLATES,
    payload: { typeIds, groupIds }
  };
}

export function getTemplatesByType(typeId, typeName) {
  return { type: GET_TEMPLATES_BY_TYPE, payload: { typeId, typeName } };
}

export function getTemplatesCount() {
  return { type: GET_TEMPLATES_COUNT };
}

export function callTemplatesCcBcc(id, module) {
  return { type: TEMPLATE_CC_BCC_CALL, payload: { id, module } };
}

export function callTemplate(templateId) {
  return { type: TEMPLATE_CALL, payload: { templateId } };
}

export function callMergeFields() {
  return { type: TEMPLATE_MERGE_FIELDS_CALL };
}

export function callTemplatesMergeFieldsData(mergeFields, ids) {
  return { type: TEMPLATE_MERGE_FIELDS_DATA_CALL, payload: { mergeFields, ids } };
}

export function addTemplate(templateData, messageFunction) {
  return { type: TEMPLATE_ADD_CALL, payload: { templateData, messageFunction } };
}

export function callAddTemplate(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function editTemplate(templateData, messageFunction) {
  return { type: TEMPLATE_EDIT_CALL, payload: { templateData, messageFunction } };
}

export function deleteTemplates(templateIds, messageFunction) {
  return { type: TEMPLATES_DELETE_CALL, payload: { templateIds, messageFunction } };
}

export function testTemplate(email, templateId, messageFunction) {
  return { type: TEMPLATE_TEST_CALL, payload: { email, templateId, messageFunction } };
}

// export function addEmailTemplateAttachments(templateId, attachments) {
//     return { type: TEMPLATE_ATTACHMENTS_ADD_CALL, payload: { templateId, attachments } };
// }
//
// export function deleteEmailTemplateAttachments(attachmentId) {
//     return { type: TEMPLATE_ATTACHMENTS_DELETE_CALL, payload: { attachmentId } };
// }

export function callTemplatesForContact() {
  return { type: TEMPLATES_CONTACTS_CALL, payload: { typeIds: [2, 3] } }; // Email, SMS
}

export function callEmailSendToContacts(
  contactIds,
  templateTypeId,
  templateId,
  from,
  cc,
  bcc,
  templateData,
  attachmentsToUpload,
  templateAttachments,
  messageFunction
) {
  return {
    type: EMAILS_SEND_TO_CONTACTS_CALL,
    payload: {
      contactIds,
      templateId,
      templateTypeId,
      from,
      cc,
      bcc,
      templateData,
      attachmentsToUpload,
      templateAttachments,
      messageFunction
    }
  };
}

export function callDocumentEmailSend(
  contactId,
  documentId,
  templateTypeId,
  templateId,
  from,
  cc,
  bcc,
  templateData,
  attachmentsToUpload,
  templateAttachments
) {
  return {
    type: DOCUMENT_SEND_EMAIL,
    payload: {
      contactId,
      documentId,
      templateTypeId,
      templateId,
      from,
      cc,
      bcc,
      templateData,
      attachmentsToUpload,
      templateAttachments
    }
  };
}

export function callSmsSendToContacts(contactIds, templateTypeId, templateId, templateData, messageFunction) {
  return {
    type: SMS_SEND_TO_CONTACTS_CALL,
    payload: { contactIds, templateId, templateTypeId, templateData, messageFunction }
  };
}

export function callImportTemplate(data, messageFunction) {
  return {
    type: TEMPLATE_IMPORT_CALL,
    payload: { data, messageFunction }
  };
}
