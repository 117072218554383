import { createSlice } from "@reduxjs/toolkit";

export const importSlice = createSlice({
  name: "import",
  initialState: {
    importData: [],
    isLoading: false
  },
  reducers: {
    onSuccessImportAdd: (state, { payload }) => {
      state.importData = payload.importData;
    },
    onImportLoading: (state) => {
      state.isLoading = !state.isLoading;
    }
  }
});

export const { onSuccessImportAdd, onImportLoading } = importSlice.actions;

export default importSlice.reducer;
