import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { promiseMiddleware } from "@adobe/redux-saga-promise";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import { TEMPLATE_ADD_CALL, TEMPLATE_EDIT_CALL, EMAILS_SEND_TO_CONTACTS_CALL } from "./templates/sagas-actions";
import { isDevelopment, isProduction } from "../constant/env-variables";

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    // serializableCheck: {
    // ignoredActions: ['errors/setError', 'user/userGetLoadingSwitch', 'user/onUserTokenFail']
    // Ignore these field paths in all actions
    // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
    // Ignore these paths in the state
    // ignoredPaths: ['items.dates']
    // }
    immutableCheck: {
      ignoredPaths: [
        `errorData.errorList.${TEMPLATE_EDIT_CALL}`,
        `errorData.errorList.${TEMPLATE_ADD_CALL}`,
        `errorData.errorList.${EMAILS_SEND_TO_CONTACTS_CALL}`
      ]
    }
  }),
  promiseMiddleware,
  sagaMiddleware
];

const store = configureStore({
  reducer: rootReducer,
  devTools: !(isDevelopment || isProduction),
  middleware
});

sagaMiddleware.run(rootSaga);

export default store;
