import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    isFetched: false,
    isLoading: false,
    page: 1,
    notifications: [],
    total_count: 0,
    total_unread_count: 0
  },
  reducers: {
    onCallNotifications: (state) => {
      if (state.page === 1) {
        state.isFetched = false;
        state.notifications = [];
        state.total_count = 0;
        state.total_unread_count = 0;
      } else {
        state.isLoading = true;
      }
    },
    onSuccessNotifications: (state, { payload }) => {
      if (state.page === 1) {
        state.isFetched = true;
        state.total_count = parseInt(payload.total_count, 10);
        state.total_unread_count = parseInt(payload.total_unread_count, 10);
      } else {
        state.isLoading = false;
      }

      state.page += 1;
      state.notifications.push(...payload.tableData);
    },
    onFailedNotifications: (state) => {
      state.isFetched = true;
      state.isLoading = true;
      state.notifications = [];
      state.total_count = 0;
      state.total_unread_count = 0;
    },

    switchNotificationsLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },

    onSuccessNotificationSeen: (state, { payload }) => {
      if (payload) {
        const { notificationId, notificationReadStatus } = payload;
        if (notificationId !== undefined) {
          const unreadNotifications = state.notifications.filter(
            (n) => n.notification_id !== notificationId && !n.read
          );
          const currentNotif = state.notifications.find((n) => n.notification_id === notificationId);

          state.total_unread_count = unreadNotifications.length || 0 + !currentNotif.read ? -1 : +1;

          if (state.total_unread_count < 0) {
            state.total_unread_count = 0;
          }

          state.notifications = state.notifications.map((n) =>
            n.notification_id === notificationId
              ? {
                  ...n,
                  read: !n.read
                }
              : n
          );
        }
      }
    },
    onSuccessNotificationDelete: (state, { payload }) => {
      if (payload) {
        const { notificationId, notificationReadStatus } = payload;
        if (notificationId !== undefined) {
          const filteredNotifications = state.notifications.filter((n) => n.notification_id !== notificationId);
          state.notifications = filteredNotifications;
          state.total_count = filteredNotifications.length;

          if (!notificationReadStatus) {
            state.total_unread_count -= 1;
          }

          if (state.total_unread_count < 0) {
            state.total_unread_count = 0;
          }
        }
      }
    },

    onSuccessNotificationsAllSeen: (state) => {
      state.notifications = state.notifications.map((n) => ({
        ...n,
        read: true
      }));
      state.total_unread_count = 0;
    },
    onSuccessNotificationsAllDelete: (state) => {
      state.notifications = [];
      state.total_count = 0;
      state.total_unread_count = 0;
    },

    addNotification: (state, { payload }) => {
      return {
        ...state,
        notifications: [...state.notifications, payload],
        total_count: state.total_count + 1,
        total_unread_count: state.total_unread_count + 1
      };
    }
  }
});

export const {
  onCallNotifications,
  onSuccessNotifications,
  onFailedNotifications,
  switchNotificationsLoading,
  onSuccessNotificationSeen,
  onSuccessNotificationDelete,
  onSuccessNotificationsAllSeen,
  onSuccessNotificationsAllDelete,
  addNotification
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
