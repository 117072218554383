import { createSlice } from "@reduxjs/toolkit";

export const documentWizardsSlice = createSlice({
  name: "documentWizardDefinitions",
  initialState: {
    isFetched: false,
    documentWizardDefinitions: [],

    // single Document Wizard
    documentWizardDefinition: {
      isFetched: false,
      isLoading: false,
      isLoadingDelete: false,
      data: undefined
    },

    documentWizardDefinitionCondition: {
      isFetched: false,
      data: undefined
    },

    documentWizards: {
      data: [],
      isFetched: false,
      isLoading: false,
      isLoadingDelete: false,
    },
    documentWizard: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
  },
  reducers: {
    // all Document Wizard Definitions
    onCallDocumentWizardDefinitions: (state) => {
      state.isFetched = false;
      state.documentWizardDefinitions = [];
    },
    onSuccessDocumentWizardDefinitions: (state, { payload }) => {
      state.isFetched = true;
      state.documentWizardDefinitions = payload;
    },
    onFailedDocumentWizardDefinitions: (state) => {
      state.isFetched = true;
      state.documentWizardDefinitions = [];
    },

    // single Document Wizard Definition
    switchDocumentWizardDefinitionLoading: (state, { payload }) => {
      state.documentWizardDefinition.isLoading = payload !== undefined ? payload : !state.documentWizardDefinition.isLoading;
    },
    switchDocumentWizardDefinitionDeleteLoading: (state, { payload }) => {
      state.documentWizardDefinition.isLoadingDelete = payload !== undefined ? payload : !state.documentWizardDefinition.isLoadingDelete;
    },

    onSuccessDocumentWizardDefinition: (state, { payload }) => {
      state.documentWizardDefinition.isFetched = true;
      state.documentWizardDefinition.data = payload;
    },
    onFailedDocumentWizardDefinition: (state) => {
      state.documentWizardDefinition.isFetched = true;
      state.documentWizardDefinition.data = undefined;
    },
    resetDocumentWizardDefinition: (state) => {
      state.documentWizardDefinition.isFetched = false;
      state.documentWizardDefinition.data = undefined;
    },

    onSuccessDocumentWizardDefinitionAdd: (state, { payload }) => {
      if (payload) {
        const wizardData = {
          ...payload, 
          status: payload.status_id === 1 ? 'Active' : 'Inactive'
        };
        state.documentWizardDefinitions = [...state.documentWizardDefinitions, wizardData];
        state.documentWizardDefinition.data = wizardData;
        state.documentWizardDefinition.isFetched = true;
      }
    },
    onSuccessDocumentWizardDefinitionEdit: (state, { payload }) => {
      if (payload) {
        const wizardData = {
          ...payload, 
          status: payload.status_id === 1 ? 'Active' : 'Inactive'
         };
        state.documentWizardDefinitions = state.documentWizardDefinitions.map((documentWizard) => {
         if (documentWizard.document_wizard_definition_id === payload.document_wizard_definition_id) { 
         
         state.documentWizardDefinition.data = wizardData;
         return wizardData;
        }
         return documentWizard;
        });
        state.documentWizardDefinition.data = wizardData;
        state.documentWizardDefinition.isFetched = true;
      }
    },
    onSuccessDocumentWizardDefinitionDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.documentWizardDefinitions = state.documentWizardDefinitions.filter((documentWizard) => documentWizard.document_wizard_definition_id !== payload);
        state.documentWizardDefinition.data = [];
      }
    },

    // documentWizardDefinitionconditions
    onCallDocumentWizardDefinitionConditions: (state) => {
      state.documentWizardDefinitionCondition.isFetched = false;
      state.documentWizardDefinitionCondition.data = [];
    },
    onSuccessDocumentWizardDefinitionConditions: (state, { payload }) => {
      const cleanList = payload.conditionTokens.filter((cT) => cT.data_type !== "unused");
      const parsedList = cleanList.map((item) => ({
        ...item
      }));

      const groups = parsedList.reduce((r, a) => {
        r[a.group_name.toLowerCase()] = [...(r[a.group_name.toLowerCase()] || []), a];
        return r;
      }, {});

      Object.keys(groups).forEach((group) => {
        groups[group] = groups[group] // .filter(item => item.token)
          .map((item) => ({
            code: item.token,
            name: item.token,
            type: item.data_type
          }));
      });

      state.documentWizardDefinitionCondition.isFetched = true;
      state.documentWizardDefinitionCondition.data = groups;
    },
    onFailedDocumentWizardDefinitionConditions: (state) => {
      state.documentWizardDefinitionCondition.isFetched = true;
      state.documentWizardDefinitionCondition.data = [];
    },

    // Document Wizards
    onCallDocumentWizards: (state) => {
      state.documentWizards.isFetched = false;
      state.documentWizards.data = [];
    },
    onSuccessDocumentWizards: (state, { payload }) => {
      state.documentWizards.isFetched = true;
      state.documentWizards.data = payload;
    },
    onFailedDocumentWizards: (state) => {
      state.documentWizards.isFetched = true;
      state.documentWizards.data = [];
    },

    switchDocumentWizardLoading: (state, { payload }) => {
      state.documentWizard.isLoading = payload !== undefined ? payload : !state.documentWizard.isLoading;
    },
    onSuccessDocumentWizardAdd: (state, { payload }) => {
      if (payload) {
        state.documentWizards.data.push(payload);
      }
    },

    // Single Document Wizard
    onSuccessDocumentWizard: (state, { payload }) => {
      state.documentWizard.isFetched = true;
      state.documentWizard.data = payload;
    },
    onFailedDocumentWizard: (state) => {
      state.documentWizard.isFetched = true;
      state.documentWizard.data = undefined;
    },
    resetDocumentWizard: (state) => {
      state.documentWizard.isFetched = false;
      state.documentWizard.data = undefined;
    },
    onSuccessDocumentWizardDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.documentWizards.data = state.documentWizards.data.filter((documentWizard) => documentWizard.document_wizard_id !== payload);
      }
    },
  }
});

export const {
  onCallDocumentWizardDefinitions,
  onSuccessDocumentWizardDefinitions,
  onFailedDocumentWizardDefinitions,

  switchDocumentWizardDefinitionLoading,
  switchDocumentWizardDefinitionDeleteLoading,

  onSuccessDocumentWizardDefinition,
  onFailedDocumentWizardDefinition,
  resetDocumentWizardDefinition,

  onSuccessDocumentWizardDefinitionAdd,
  onSuccessDocumentWizardDefinitionEdit,
  onSuccessDocumentWizardDefinitionDelete,

  onCallDocumentWizardDefinitionConditions,
  onSuccessDocumentWizardDefinitionConditions,
  onFailedDocumentWizardDefinitionConditions,

  onCallDocumentWizards,
  onSuccessDocumentWizards,
  onFailedDocumentWizards,

  onSuccessDocumentWizard,
  onFailedDocumentWizard,
  resetDocumentWizard,

  switchDocumentWizardLoading,
  onSuccessDocumentWizardAdd,

  onSuccessDocumentWizardDelete
} = documentWizardsSlice.actions;

export default documentWizardsSlice.reducer;
