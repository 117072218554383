import { takeEvery, put, call } from "redux-saga/effects";
import { setError } from "../error/slice";
import axiosInstance from "../../clients/api";
import { PLANS_CALL } from "./sagas-actions";
import { onCallPlans, onSuccessPlans, onFailedPlans } from "./slice";

function* plansCallGenerator(action) {
  yield put(onCallPlans());
  try {
    const { data } = yield call(axiosInstance.get, "/base/getPlans");
    yield put(onSuccessPlans(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedPlans());
  }
}

export default function* plansSaga() {
  yield takeEvery(PLANS_CALL, plansCallGenerator);
}
