import { createSlice } from "@reduxjs/toolkit";

export const subuserDomainsSlice = createSlice({
  name: "subuserDomains",
  initialState: {
    isLoading: false,
    dnsRecords: null,
    isValid: null,
    domain_id: null,
    domainName: null
  },
  reducers: {
    switchSubuserDomainLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    onSuccessValidateDomain: (state, { payload }) => {
      state.dnsRecords = payload.valid
        ? null
        : state.dnsRecords.map((record) => ({
            ...record,
            valid: payload.dns[record.name].valid
          }));
      state.isValid = payload.valid;
      if (payload.domain) {
        state.domainName = payload.domain;
      }
    },
    onSuccessSubuserDomain: (state, { payload }) => {
      state.isValid = payload.valid;
      state.domainName = payload.domain;
      state.domain_id = payload.id;
      state.dnsRecords =
        payload.valid === true
          ? null
          : Object.keys(payload.dns).map((recordName) => ({
              name: recordName,
              data: payload.dns[recordName].data,
              host: payload.dns[recordName].host,
              valid: payload.dns[recordName].valid
            }));
    },
    onSuccessDeleteSubuserDomain: (state) => {
      state.isLoading = false;
      state.dnsRecords = null;
      state.isValid = null;
      state.domainName = null;
      state.domain_id = null;
    }
  }
});
export const {
  switchSubuserDomainLoading,
  onSuccessDeleteSubuserDomain,
  onSuccessSubuserDomain,
  onSuccessValidateDomain
} = subuserDomainsSlice.actions;

export default subuserDomainsSlice.reducer;
