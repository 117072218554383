import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { implementPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import { paramParserForPagination } from "utils/helpers";
import appModules from "constant/data/app-modules";
import { setError } from "../error/slice";
import qs from "qs";
import {
  CONTACTS_CALL,
  CONTACT_CALL,
  CONTACT_EDIT_CALL,
  CONTACT_DELETE_CALL,
  CONTACTS_CALL_CUSTOM_FIELDS,
  CONTACTS_CALL_SOCIAL_MEDIAS,
  CONTACTS_CALL_STATUSES,
  CONTACTS_CALL_TYPES,
  CONTACTS_CALL_GROUPS,
  CONTACTS_CHANGE_STATUSES,
  CONTACTS_CHANGE_TYPES,
  CONTACTS_CHANGE_GROUPS,
  callContactsPromise,
  callContactAddPromise,
  CONTACT_NOTES_CALL,
  CONTACTS_CHANGE_SUBSCRIPTION,
  CONTACTS_CHANGE_SEQUENCES,
  CONTACTS_CALL_SEQUENCES,
  CONTACT_CALL_SEQUENCES,
  callSingleContactAddPromise,
  callContactEditPromise,
  CONTACT_TASKS_CALL,
  CONTACT_CALL_APOLLO_DATA,
  CONTACTS_CALL_ORGREPS,
  CONTACTS_CHANGE_ORG_REP,
  CONTACT_RESTORE_CALL,
  CONTACTS_CHECK_EMAIL_VALIDATION,
  CONTACT_CALL_UPLOAD_OCR_IMAGE
} from "./sagas-actions";
import {
  onCallContacts,
  onSuccessContacts,
  onFailedContacts,
  switchContactLoading,
  onSuccessContact,
  onFailedContact,
  onSuccessContactAdd,
  onSuccessContactEdit,
  onSuccessContactsDelete,
  onCallContactAttributes,
  onSuccessContactAttributes,
  onFailedContactAttributes,
  onCallContactSocialMedias,
  switchContactsAttributeChangeLoading,
  onSuccessContactAttributeChange,
  onSuccessContactSocialMedias,
  onFailedContactSocialMedias,
  onCallContactCustomFields,
  onSuccessContactCustomFields,
  onFailedContactCustomFields,
  onCallContactNotes,
  onSuccessContactNotes,
  onFailedContactNotes,
  onCallContactSequences,
  onFailedContactSequences,
  onSuccessContactSequences,
  onCallContactSequencesList,
  onSuccessContactSequencesList,
  onFailedContactSequencesList,
  onCallContactTasks,
  onSuccessContactTasks,
  onFailedContactTasks,
  onCallContactApolloData,
  onSuccessContactApolloData,
  onFailedContactApolloData,
  onChangeContactSequencesList,
  onSuccessContactEmailValidation,
  onFailedContactEmailValidation,
  onCallContactOCRImageData,
  onSuccessContactOCRImageData,
  onFailedContactOCRImageData
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { listViewTypes } from "../../constant/data/list-view-types";

export const prefix = "/tenantUser/contacts";
const appModuleId = appModules.find((t) => t.name === "Contact").code;

// Initial call
function* contactsCallGenerator(action) {
  try {
    const {
      payload: { paginationData, tenantUserId, withoutLoading }
    } = action;
    if (!withoutLoading) {
      yield put(onCallContacts());
    }
    const params = paramParserForPagination(paginationData);
    if (tenantUserId) {
      params.filter_fields.push({
        name: "tenant_user_id",
        operator: "eq",
        value: tenantUserId
      });
    }

    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);
    data.uiViewType = listViewTypes[paginationData.uiViewTypeId];
    data.shouldClear = params.current_page === 1;

    yield put(onSuccessContacts({...data, paginationData}));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContacts());
  }
}

// Pagination call
function* contactsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* contactsWorker() {
    yield put(onCallContacts(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

      return yield put(onSuccessContacts(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      // yield call(resolvePromiseAction, action, value)
      // yield put(onFailedContacts());
    }
  });
}

function* contactCallGenerator(action) {
  try {
    const {
      payload: { contactId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${contactId}`);

    yield put(onSuccessContact(data?.contact?.[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContact());
  }
}

function* contactApolloDataCallGenerator(action) {
  yield put(onCallContactApolloData());
  try {
    const {
      payload: { contactEmail }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/apollo?email=${contactEmail}`);
    // const data = {
    //   data: {
    //     "contactData": {
    //       "id": "5ff89d59ca7c6a0001e46568",
    //       "first_name": "Dr Name1",
    //       "last_name": "abd",
    //       "name": "Jessie Devine",
    //       "linkedin_url": "http://www.linkedin.com/in/jcappiello",
    //       "title": "VP, Channel Development",
    //       "email_status": null,
    //       "photo_url": "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6532726f228cb10001ada47b/picture",
    //       "twitter_url": "https://twitter.com/jessiecap",
    //       "github_url": null,
    //       "facebook_url": "https://media.licdn.com/dms/image/C5603AQHS6ml6",
    //       "extrapolated_email_confidence": null,
    //       "headline": "VP, Channel Development",
    //       "email": "jdevine@ascii.com",
    //       "organization_id": "57c4d175a6da986ad696ac25",
    //       "employment_history": [
    //         {
    //           "_id": "65000163af83be00014804e8",
    //           "created_at": null,
    //           "current": true,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": null,
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "57c4d175a6da986ad696ac25",
    //           "organization_name": "The ASCII Group",
    //           "raw_address": null,
    //           "start_date": "2022-10-01",
    //           "title": "VP, Channel Development",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804e8",
    //           "key": "65000163af83be00014804e8"
    //         },
    //         {
    //           "_id": "65000163af83be00014804c6",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2022-11-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "57c4d175a6da986ad696ac25",
    //           "organization_name": "The ASCII Group",
    //           "raw_address": null,
    //           "start_date": "2020-10-01",
    //           "title": "Director, Channel Development",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804c6",
    //           "key": "65000163af83be00014804c6"
    //         },
    //         {
    //           "_id": "65000163af83be00014804c7",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2020-09-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "54a19fc374686942d3efc902",
    //           "organization_name": "QuoteWerks",
    //           "raw_address": null,
    //           "start_date": "2017-01-01",
    //           "title": "Community Engagement Manager",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804c7",
    //           "key": "65000163af83be00014804c7"
    //         },
    //         {
    //           "_id": "65000163af83be00014804c8",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2017-01-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "54a19fc374686942d3efc902",
    //           "organization_name": "QuoteWerks",
    //           "raw_address": null,
    //           "start_date": "2014-06-01",
    //           "title": "Community Coordinator",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804c8",
    //           "key": "65000163af83be00014804c8"
    //         },
    //         {
    //           "_id": "65000163af83be00014804c9",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2014-06-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "54a1296f69702d95482c8e01",
    //           "organization_name": "Center for Independent Living in Central Florida",
    //           "raw_address": null,
    //           "start_date": "2013-09-01",
    //           "title": "Business Development Specialist",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804c9",
    //           "key": "65000163af83be00014804c9"
    //         },
    //         {
    //           "_id": "65000163af83be00014804ca",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2013-09-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": "54a1296f69702d95482c8e01",
    //           "organization_name": "Center for Independent Living in Central Florida",
    //           "raw_address": null,
    //           "start_date": "2012-09-01",
    //           "title": "Special Events Coordinator",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804ca",
    //           "key": "65000163af83be00014804ca"
    //         },
    //         {
    //           "_id": "65000163af83be00014804cb",
    //           "created_at": null,
    //           "current": false,
    //           "degree": null,
    //           "description": null,
    //           "emails": null,
    //           "end_date": "2012-08-01",
    //           "grade_level": null,
    //           "kind": null,
    //           "major": null,
    //           "organization_id": null,
    //           "organization_name": "CRG, Inc",
    //           "raw_address": null,
    //           "start_date": "2011-06-01",
    //           "title": "Account Executive",
    //           "updated_at": null,
    //           "id": "65000163af83be00014804cb",
    //           "key": "65000163af83be00014804cb"
    //         }
    //       ],
    //       "state": "Florida",
    //       "city": "Orlando",
    //       "country": "United States",
    //       "organization": {
    //         "id": "57c4d175a6da986ad696ac25",
    //         "name": "The ASCII Group",
    //         "website_url": "http://www.ascii.com",
    //         "blog_url": null,
    //         "angellist_url": null,
    //         "linkedin_url": "http://www.linkedin.com/company/ascii",
    //         "twitter_url": "https://twitter.com/asciigroup",
    //         "facebook_url": "https://www.facebook.com/TheASCIIGroup",
    //         "primary_phone": {
    //           "number": "+1 301-718-2600",
    //           "source": "Account"
    //         },
    //         "languages": [
    //           "English"
    //         ],
    //         "alexa_ranking": null,
    //         "phone": "+1 301-718-2600",
    //         "linkedin_uid": "61829",
    //         "founded_year": 1984,
    //         "publicly_traded_symbol": null,
    //         "publicly_traded_exchange": null,
    //         "logo_url": "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6532726f228cb10001ada47b/picture",
    //         "crunchbase_url": null,
    //         "primary_domain": "ascii.com",
    //         "sanitized_phone": "+13017182600",
    //         "industry": "information technology & services",
    //         "keywords": [
    //           "var",
    //           "solution provider",
    //           "reseller",
    //           "managed services",
    //           "managed services provider",
    //           "it practice",
    //           "it community",
    //           "msp",
    //           "mssp"
    //         ],
    //         "estimated_num_employees": 50,
    //         "industries": [
    //           "information technology & services"
    //         ],
    //         "secondary_industries": [],
    //         "snippets_loaded": true,
    //         "industry_tag_id": "5567cd4773696439b10b0000",
    //         "industry_tag_hash": {
    //           "information technology & services": "5567cd4773696439b10b0000"
    //         },
    //         "retail_location_count": 0,
    //         "raw_address": "The ASCII Group Inc 7101 Wisconsin Avenue Suite 1350 , Bethesda, Maryland, USA, 20814",
    //         "street_address": "7101 Wisconsin Ave",
    //         "city": "Bethesda",
    //         "state": "Maryland",
    //         "postal_code": "20814-4805",
    //         "country": "United States",
    //         "owned_by_organization_id": null,
    //         "suborganizations": [],
    //         "num_suborganizations": 0,
    //         "seo_description": "The ASCII Group is the oldest & largest independent IT community in the world, with over 1300+ MSP members across North America. Learn more.",
    //         "short_description": "The ASCII Group is the premier community of North American MSPs, MSSPs and Solution Providers. The Group has members located throughout the U.S. and Canada, and membership encompasses everyone from credentialed MSPs serving the SMB community to multi-location solution providers with a national and international reach. Founded in 1984, ASCII provides services to members including leveraged purchasing programs, education and training, marketing assistance, extensive peer interaction and more. ASCII works with a vibrant ecosystem of leading and major technology vendors that complement the ASCII community and support the mission of helping MSPs to grow their businesses. For more information, please visit www.ascii.com.",
    //         "annual_revenue_printed": "47.5M",
    //         "annual_revenue": 47500000,
    //         "total_funding": null,
    //         "total_funding_printed": null,
    //         "latest_funding_round_date": null,
    //         "latest_funding_stage": null,
    //         "funding_events": [],
    //         "technology_names": [
    //           "Amazon AWS",
    //           "Bootstrap Framework",
    //           "CloudFlare",
    //           "Cloudflare DNS",
    //           "Google Cloud Hosting",
    //           "Google Font API",
    //           "Google Tag Manager",
    //           "Microsoft Office 365",
    //           "Mobile Friendly",
    //           "Nginx",
    //           "Ontraport",
    //           "Sage Intacct",
    //           "Sendgrid",
    //           "WP Engine",
    //           "Woo Commerce",
    //           "WordPress.org",
    //           "reCAPTCHA"
    //         ],
    //         "current_technologies": [
    //           {
    //             "uid": "amazon_aws",
    //             "name": "Amazon AWS",
    //             "category": "Hosting"
    //           },
    //           {
    //             "uid": "bootstrap_framework",
    //             "name": "Bootstrap Framework",
    //             "category": "CSS and JavaScript Libraries"
    //           },
    //           {
    //             "uid": "cloudflare",
    //             "name": "CloudFlare",
    //             "category": "Web Accelerators"
    //           },
    //           {
    //             "uid": "cloudflare_dns",
    //             "name": "Cloudflare DNS",
    //             "category": "Domain Name Services"
    //           },
    //           {
    //             "uid": "google_cloud_hosting",
    //             "name": "Google Cloud Hosting",
    //             "category": "Hosting"
    //           },
    //           {
    //             "uid": "google_font_api",
    //             "name": "Google Font API",
    //             "category": "Fonts"
    //           },
    //           {
    //             "uid": "google_tag_manager",
    //             "name": "Google Tag Manager",
    //             "category": "Tag Management"
    //           },
    //           {
    //             "uid": "office_365",
    //             "name": "Microsoft Office 365",
    //             "category": "Other"
    //           },
    //           {
    //             "uid": "mobile_friendly",
    //             "name": "Mobile Friendly",
    //             "category": "Other"
    //           },
    //           {
    //             "uid": "nginx",
    //             "name": "Nginx",
    //             "category": "Load Balancers"
    //           },
    //           {
    //             "uid": "ontraport",
    //             "name": "Ontraport",
    //             "category": "Customer Relationship Management"
    //           },
    //           {
    //             "uid": "sage-intacct",
    //             "name": "Sage Intacct",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "sendgrid",
    //             "name": "Sendgrid",
    //             "category": "Email Delivery"
    //           },
    //           {
    //             "uid": "wp_engine",
    //             "name": "WP Engine",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "woo_commerce",
    //             "name": "Woo Commerce",
    //             "category": "E-commerce Platforms"
    //           },
    //           {
    //             "uid": "wordpress_org",
    //             "name": "WordPress.org",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "recaptcha",
    //             "name": "reCAPTCHA",
    //             "category": "Captcha"
    //           }
    //         ],
    //         "org_chart_root_people_ids": [
    //           "6252fa757f4de900011c599b"
    //         ]
    //       },
    //       "phone_numbers": [
    //         {
    //           "raw_number": "+1 301-718-2600",
    //           "sanitized_number": "+13017182600",
    //           "type": "work_hq",
    //           "position": 0,
    //           "status": "no_status",
    //           "dnc_status": null,
    //           "dnc_other_info": null
    //         }
    //       ],
    //       "intent_strength": null,
    //       "show_intent": false,
    //       "revealed_for_current_team": true,
    //       "departments": [
    //         "master_sales"
    //       ],
    //       "subdepartments": [
    //         "channel_sales"
    //       ],
    //       "functions": [],
    //       "seniority": "vp",
    //       "bizzity_company_id": "",
    //       "organizationData": {
    //         "id": "57c4d175a6da986ad696ac25",
    //         "name": "Comp-App 2",
    //         "website_url": "http://www.ascii.com",
    //         "blog_url": null,
    //         "angellist_url": null,
    //         "linkedin_url": "http://www.linkedin.com/company/ascii",
    //         "twitter_url": "https://twitter.com/asciigroup",
    //         "facebook_url": "https://www.facebook.com/TheASCIIGroup",
    //         "primary_phone": {
    //           "number": "+1 301-718-2600",
    //           "source": "Account"
    //         },
    //         "languages": [
    //           "English"
    //         ],
    //         "alexa_ranking": null,
    //         "phone": "+1 301-718-2600",
    //         "linkedin_uid": "61829",
    //         "founded_year": 1984,
    //         "publicly_traded_symbol": null,
    //         "publicly_traded_exchange": null,
    //         "logo_url": "https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6532726f228cb10001ada47b/picture",
    //         "crunchbase_url": null,
    //         "primary_domain": "ascii.com",
    //         "sanitized_phone": "+13017182600",
    //         "industry": "information technology & services",
    //         "keywords": [
    //           "var",
    //           "solution provider",
    //           "reseller",
    //           "managed services",
    //           "managed services provider",
    //           "it practice",
    //           "it community",
    //           "msp",
    //           "mssp"
    //         ],
    //         "estimated_num_employees": 50,
    //         "industries": [
    //           "information technology & services"
    //         ],
    //         "secondary_industries": [],
    //         "snippets_loaded": true,
    //         "industry_tag_id": "5567cd4773696439b10b0000",
    //         "industry_tag_hash": {
    //           "information technology & services": "5567cd4773696439b10b0000"
    //         },
    //         "retail_location_count": 0,
    //         "raw_address": "The BBC Group Inc 7101 Wisconsin Avenue Suite 1350 , Bethesda, Maryland, USA, 20814",
    //         "street_address": "7101 Wisconsin Ave",
    //         "city": "Bethesda",
    //         "state": "Maryland",
    //         "postal_code": "20814-4805",
    //         "country": "United States",
    //         "owned_by_organization_id": null,
    //         "suborganizations": [],
    //         "num_suborganizations": 0,
    //         "seo_description": "The ASCII Group is the oldest & largest independent IT community in the world, with over 1300+ MSP members across North America. Learn more.",
    //         "short_description": "The ASCII Group is the premier community of North American MSPs, MSSPs and Solution Providers. The Group has members located throughout the U.S. and Canada, and membership encompasses everyone from credentialed MSPs serving the SMB community to multi-location solution providers with a national and international reach. Founded in 1984, ASCII provides services to members including leveraged purchasing programs, education and training, marketing assistance, extensive peer interaction and more. ASCII works with a vibrant ecosystem of leading and major technology vendors that complement the ASCII community and support the mission of helping MSPs to grow their businesses. For more information, please visit www.ascii.com.",
    //         "annual_revenue_printed": "47.5M",
    //         "annual_revenue": 47500000,
    //         "total_funding": null,
    //         "total_funding_printed": null,
    //         "latest_funding_round_date": null,
    //         "latest_funding_stage": null,
    //         "funding_events": [],
    //         "technology_names": [
    //           "Amazon AWS",
    //           "Bootstrap Framework",
    //           "CloudFlare",
    //           "Cloudflare DNS",
    //           "Google Cloud Hosting",
    //           "Google Font API",
    //           "Google Tag Manager",
    //           "Microsoft Office 365",
    //           "Mobile Friendly",
    //           "Nginx",
    //           "Ontraport",
    //           "Sage Intacct",
    //           "Sendgrid",
    //           "WP Engine",
    //           "Woo Commerce",
    //           "WordPress.org",
    //           "reCAPTCHA"
    //         ],
    //         "current_technologies": [
    //           {
    //             "uid": "amazon_aws",
    //             "name": "Amazon AWS",
    //             "category": "Hosting"
    //           },
    //           {
    //             "uid": "bootstrap_framework",
    //             "name": "Bootstrap Framework",
    //             "category": "CSS and JavaScript Libraries"
    //           },
    //           {
    //             "uid": "cloudflare",
    //             "name": "CloudFlare",
    //             "category": "Web Accelerators"
    //           },
    //           {
    //             "uid": "cloudflare_dns",
    //             "name": "Cloudflare DNS",
    //             "category": "Domain Name Services"
    //           },
    //           {
    //             "uid": "google_cloud_hosting",
    //             "name": "Google Cloud Hosting",
    //             "category": "Hosting"
    //           },
    //           {
    //             "uid": "google_font_api",
    //             "name": "Google Font API",
    //             "category": "Fonts"
    //           },
    //           {
    //             "uid": "google_tag_manager",
    //             "name": "Google Tag Manager",
    //             "category": "Tag Management"
    //           },
    //           {
    //             "uid": "office_365",
    //             "name": "Microsoft Office 365",
    //             "category": "Other"
    //           },
    //           {
    //             "uid": "mobile_friendly",
    //             "name": "Mobile Friendly",
    //             "category": "Other"
    //           },
    //           {
    //             "uid": "nginx",
    //             "name": "Nginx",
    //             "category": "Load Balancers"
    //           },
    //           {
    //             "uid": "ontraport",
    //             "name": "Ontraport",
    //             "category": "Customer Relationship Management"
    //           },
    //           {
    //             "uid": "sage-intacct",
    //             "name": "Sage Intacct",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "sendgrid",
    //             "name": "Sendgrid",
    //             "category": "Email Delivery"
    //           },
    //           {
    //             "uid": "wp_engine",
    //             "name": "WP Engine",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "woo_commerce",
    //             "name": "Woo Commerce",
    //             "category": "E-commerce Platforms"
    //           },
    //           {
    //             "uid": "wordpress_org",
    //             "name": "WordPress.org",
    //             "category": "CMS"
    //           },
    //           {
    //             "uid": "recaptcha",
    //             "name": "reCAPTCHA",
    //             "category": "Captcha"
    //           }
    //         ],
    //         "org_chart_root_people_ids": [
    //           "6252fa757f4de900011c599b"
    //         ],
    //         "departmental_head_count": {
    //           "consulting": 1,
    //           "arts_and_design": 1,
    //           "operations": 3,
    //           "marketing": 3,
    //           "human_resources": 1,
    //           "business_development": 1,
    //           "engineering": 2,
    //           "administrative": 1,
    //           "accounting": 0,
    //           "sales": 0,
    //           "finance": 0,
    //           "information_technology": 0,
    //           "legal": 0,
    //           "product_management": 0,
    //           "education": 0,
    //           "media_and_commmunication": 0,
    //           "entrepreneurship": 0,
    //           "support": 0,
    //           "data_science": 0
    //         }
    //       }
    //     }
    //   }
    // }
    yield put(onSuccessContactApolloData(data?.data?.contactData));
  } catch (error) {
    yield put(onFailedContactApolloData(error?.response?.data?.msg));
  }
}

function* contactOCRImageUploadCallGenerator(action) {
  yield put(onCallContactOCRImageData());
  try {
    const {
      payload: { imageData }
    } = action;

    const formData = new FormData();
    formData.append("image", imageData, "photo.png");

    const { data } = yield call(axiosInstance.post, `/TenantUser/image_ocr`, formData);
    console.log(data);
    yield put(onSuccessContactOCRImageData(data));
  } catch (error) {
    yield put(onFailedContactOCRImageData(error?.response?.data?.msg));
  }
}

function* contactNotesCallGenerator(action) {
  try {
    const {
      payload: { entityId, module, isSocketUpdate }
    } = action;
    yield put(onCallContactNotes(isSocketUpdate));
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/note/generic/${module}/?entity_id=${entityId}`);
    yield put(onSuccessContactNotes(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactNotes());
  }
}

function* contactTasksCallGenerator(action) {
  const {
    payload: { contactId, isSocketUpdate }
  } = action;
  yield put(onCallContactTasks(isSocketUpdate));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/task/table_data?contact_id=${contactId}`);
    yield put(onSuccessContactTasks(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactTasks());
  }
}

function* contactSequencesListCallGenerator(action) {
  yield put(onCallContactSequencesList());
  try {
    const {
      payload: { contactId }
    } = action;
    const { data } = yield call(
      axiosSagaRequest,
      "get",
      `/tenantUser/sequence_automations/table_data?contact_id=${contactId}`
    );
    yield put(onSuccessContactSequencesList(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactSequencesList());
  }
}

function* uploadContactPhotoGenerator(file, fileName, contactId) {
  const formData = new FormData();
  formData.append("photo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, `${prefix}/photo/${contactId}`, formData);

  return data?.url;
}

function* contactAddCallGenerator(action) {
  yield put(switchContactLoading(true));
  yield call(implementPromiseAction, action, function* contactAddWithImageWorker() {
    try {
      const {
        payload: { contactData, newPhotoFile, messageFunction }
      } = action;

      const { data } = yield call(axiosInstance.post, prefix, contactData);

      let imageNewUrl;
      if (newPhotoFile?.file) {
        imageNewUrl = yield uploadContactPhotoGenerator(
          newPhotoFile?.file,
          newPhotoFile?.name,
          data.createdContact.contact_id
        );
      }

      yield put(
        onSuccessContactAdd({
          contactData: {
            ...data.createdContact,
            ...(newPhotoFile?.file && { photo: imageNewUrl })
          }
        })
      );
      yield data && messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });

  yield put(switchContactLoading(false));
}

function* contactAddCallPromiseGenerator(action) {
  yield put(switchContactLoading(true));

  yield call(implementPromiseAction, action, function* contactAddWorker() {
    try {
      const {
        payload: { contactData, newPhotoFile, messageFunction }
      } = action;
      const { data } = yield call(axiosInstance.post, prefix, { contactData, newPhotoFile });

      yield put(
        onSuccessContactAdd({
          contactData: { ...data.createdContact }
        })
      );

      yield data && messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });

  yield put(switchContactLoading(false));
}

function* contactEditCallGenerator(action) {
  yield put(switchContactLoading(true));
  try {
    const {
      payload: { contactData, contactId, newPhotoFile, messageFunction }
    } = action;

    // change logo
    if (newPhotoFile?.file) {
      yield uploadContactPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, contactId);
    }

    // update contact data
    const { data } = yield call(axiosInstance.put, `${prefix}/${contactId}`, contactData);

    yield put(onSuccessContactEdit(data?.updatedContact));

    yield data && messageFunction && messageFunction();
    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactLoading(false));
}

function* contactEditPromiseCallGenerator(action) {
  yield put(switchContactLoading(true));
  yield call(implementPromiseAction, action, function* contactEditWorker() {
    try {
      const {
        payload: { contactData, contactId, newPhotoFile, messageFunction }
      } = action;

      // change logo
      if (newPhotoFile?.file) {
        yield uploadContactPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, contactId);
      }

      // update contact data
      const { data } = yield call(axiosInstance.put, `${prefix}/${contactId}`, contactData);

      yield put(onSuccessContactEdit(data?.updatedContact));
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });

  yield put(switchContactLoading(false));
}

function* contactDeleteCallGenerator(action) {
  yield put(switchContactLoading(true));
  try {
    const {
      payload: { contactIds }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      contact_ids: contactIds
    });
    yield put(onSuccessContactsDelete(contactIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactLoading(false));
}

function* contactRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      contact_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

const contactAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallContactAttributes({ key }));
    try {
      const link = url ? `${prefix}-settings/list/${url}` : `${prefix}-settings/list/contact_${key}`;
      const { data } = yield call(axiosInstance.get, link);

      yield put(
        onSuccessContactAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedContactAttributes({ key }));
    }
  };

function* contactSequencesCallGenerator(action) {
  yield put(onCallContactSequences());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/sequence_automations");

    yield put(onSuccessContactSequences(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactSequences());
  }
}

function* contactSocialMediasCallGenerator(action) {
  yield put(onCallContactSocialMedias());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/social_media");
    yield put(onSuccessContactSocialMedias(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactSocialMedias());
  }
}

function* contactCustomFieldsCallGenerator(action) {
  yield put(onCallContactCustomFields());
  try {
    const { data } = yield call(axiosInstance.get, `/tenantUser/custom-fields/list/${appModuleId}`);

    yield put(onSuccessContactCustomFields(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactCustomFields());
  }
}

const contactsAttributeChangeCallGenerator = (key, fieldToChange) =>
  function* gen(action) {
    yield put(
      switchContactsAttributeChangeLoading({
        key,
        status: true
      })
    );

    try {
      const {
        payload: { data }
      } = action;

      if (key === "group") {
        yield call(axiosInstance.patch, "/tenantUser/contact/change_groups", data);
      } else {
        const { data: resData } = yield call(
          axiosInstance.patch,
          `/tenantUser/contact/change_setting/${fieldToChange}`,
          data
        );
        yield put(
          onSuccessContactAttributeChange({
            data: resData?.updatedRows
          })
        );
      }
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }

    yield put(
      switchContactsAttributeChangeLoading({
        key,
        status: false
      })
    );
  };

function* contactsSequenceChangeCallGenerator(action) {
  try {
    const {
      payload: { data, messageFunction }
    } = action;
    const res = yield call(axiosInstance.put, "/tenantUser/sequences/attach_detach_contacts", data);
    yield put(onChangeContactSequencesList(data));
    yield data && messageFunction && messageFunction(res.data.message, res.data.messageType || "warning");
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* contactsSubscriptionChangeCallGenerator(action) {
  yield put(switchContactLoading(true));
  try {
    const {
      payload: { contactIds, type, unsubscribe }
    } = action;
    const subscriptionData = {
      contact_ids: contactIds
    };

    if (type === "email") {
      subscriptionData.unsubscribe = unsubscribe;
    } else {
      subscriptionData.unsubscribe_sms = unsubscribe;
    }

    yield call(axiosInstance.put, "/tenantUser/contacts/subscription", subscriptionData);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactLoading(false));
}

function* contactsEmailValidationCheckCallGenerator(action) {
  try {
    const {
      payload: { email }
    } = action;
    yield call(axiosInstance.post, `${prefix}/validate_email`, {
      email
    });
    yield put(onSuccessContactEmailValidation("Email is valid and good to use."));
  } catch (error) {
    yield put(onFailedContactEmailValidation("Oops! It looks like there is a typo in the email."));
  }
}

export default function* contactsSaga() {
  yield takeEvery(CONTACTS_CALL, contactsCallGenerator);
  yield takeEvery(callContactsPromise, contactsCallPromiseGenerator);
  yield takeLatest(CONTACT_CALL, contactCallGenerator);
  yield takeLatest(CONTACT_CALL_APOLLO_DATA, contactApolloDataCallGenerator);
  yield takeLatest(CONTACT_CALL_UPLOAD_OCR_IMAGE, contactOCRImageUploadCallGenerator);
  yield takeEvery(callSingleContactAddPromise, contactAddCallGenerator);
  yield takeEvery(callContactAddPromise, contactAddCallPromiseGenerator);
  yield takeEvery(CONTACT_EDIT_CALL, contactEditCallGenerator);
  yield takeEvery(callContactEditPromise, contactEditPromiseCallGenerator);

  yield takeEvery(CONTACT_DELETE_CALL, contactDeleteCallGenerator);
  yield takeEvery(CONTACT_RESTORE_CALL, contactRestoreCallGenerator);

  yield takeLatest(CONTACT_NOTES_CALL, contactNotesCallGenerator);

  yield takeLatest(CONTACT_TASKS_CALL, contactTasksCallGenerator);
  yield takeLatest(CONTACT_CALL_SEQUENCES, contactSequencesListCallGenerator);

  yield takeEvery(CONTACTS_CALL_STATUSES, contactAttributesCallGenerator("status"));
  yield takeEvery(CONTACTS_CALL_TYPES, contactAttributesCallGenerator("type"));
  yield takeEvery(CONTACTS_CALL_GROUPS, contactAttributesCallGenerator("group"));
  yield takeEvery(CONTACTS_CALL_ORGREPS, contactAttributesCallGenerator("orgRep", "tenant_user_list"));
  yield takeEvery(CONTACTS_CALL_SEQUENCES, contactSequencesCallGenerator);
  yield takeEvery(CONTACTS_CALL_CUSTOM_FIELDS, contactCustomFieldsCallGenerator);
  yield takeEvery(CONTACTS_CALL_SOCIAL_MEDIAS, contactSocialMediasCallGenerator);

  yield takeEvery(CONTACTS_CHANGE_STATUSES, contactsAttributeChangeCallGenerator("status", "status_id"));
  yield takeEvery(CONTACTS_CHANGE_TYPES, contactsAttributeChangeCallGenerator("type", "type_id"));
  yield takeEvery(CONTACTS_CHANGE_GROUPS, contactsAttributeChangeCallGenerator("group"));
  yield takeEvery(CONTACTS_CHANGE_ORG_REP, contactsAttributeChangeCallGenerator("orgRep", "tenant_user_id"));
  yield takeEvery(CONTACTS_CHANGE_SEQUENCES, contactsSequenceChangeCallGenerator);
  yield takeEvery(CONTACTS_CHANGE_SUBSCRIPTION, contactsSubscriptionChangeCallGenerator);

  yield takeEvery(CONTACTS_CHECK_EMAIL_VALIDATION, contactsEmailValidationCheckCallGenerator);
}
