import { createSlice } from "@reduxjs/toolkit";

export const workflowsSlice = createSlice({
  name: "workflows",
  initialState: {
    isFetched: false,
    workflows: [],

    // single workflow
    workflow: {
      isFetched: false,
      isLoading: false,
      isLoadingDelete: false,
      data: undefined
    },

    workflowCondition: {
      isFetched: false,
      data: undefined
    }
  },
  reducers: {
    // all workflows
    onCallWorkflows: (state) => {
      state.isFetched = false;
      state.workflows = [];
    },
    onSuccessWorkflows: (state, { payload }) => {
      state.isFetched = true;
      state.workflows = payload.workflows;
    },
    onFailedWorkflows: (state) => {
      state.isFetched = true;
      state.workflows = [];
    },

    // single workflow
    switchWorkflowLoading: (state, { payload }) => {
      state.workflow.isLoading = payload !== undefined ? payload : !state.workflow.isLoading;
    },
    switchWorkflowDeleteLoading: (state, { payload }) => {
      state.workflow.isLoadingDelete = payload !== undefined ? payload : !state.workflow.isLoadingDelete;
    },

    onSuccessWorkflow: (state, { payload }) => {
      state.workflow.isFetched = true;
      state.workflow.data = payload;
    },
    onFailedWorkflow: (state) => {
      state.workflow.isFetched = true;
      state.workflow.data = undefined;
    },
    resetWorkflow: (state) => {
      state.workflow.isFetched = false;
      state.workflow.data = undefined;
    },

    onSuccessWorkflowAdd: (state, { payload }) => {
      if (payload) {
        const workflowData = {
          ...payload,
          status_id: payload.status === 'Active' ? 1 : 2
         };
        state.workflows = [...state.workflows, workflowData];
        state.workflow.data = workflowData;
        state.workflow.isFetched = true;
      }
    },
    onSuccessWorkflowEdit: (state, { payload }) => {
      if (payload) {
        const workflowData = {
          ...payload,
          status_id: payload.status === 'Active' ? 1 : 2
         };
        state.workflows = state.workflows.map((workflow) =>
          workflow.workflow_id === payload.workflow_id ? workflowData : workflow
        );
        state.workflow.data = workflowData;
        state.workflow.isFetched = true;
      }
    },
    onSuccessWorkflowsDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.workflows = state.workflows.filter((workflow) => !payload.includes(workflow.workflow_id));
      }
    },

    // workflow conditions
    onCallWorkflowConditions: (state) => {
      state.workflowCondition.isFetched = false;
      state.workflowCondition.data = [];
    },
    onSuccessWorkflowConditions: (state, { payload }) => {
      const cleanList = payload.conditionTokens.filter((cT) => cT.data_type !== "unused");
      const parsedList = cleanList.map((item) => ({
        ...item
      }));

      const groups = parsedList.reduce((r, a) => {
        r[a.group_name?.toLowerCase()] = [...(r[a.group_name?.toLowerCase()] || []), a];
        return r;
      }, {});

      Object.keys(groups).forEach((group) => {
        groups[group] = groups[group] // .filter(item => item.token)
          .map((item) => ({
            code: item.token,
            name: item.token,
            type: item.data_type
            // ex.
            // column_name: "name"
            // data_length: "50"
            // data_type: "text"
            // group_name: "Company"
            // token: "Company Name"
            // token_id: 17
          }));
      });

      state.workflowCondition.isFetched = true;
      state.workflowCondition.data = groups;
    },
    onFailedWorkflowConditions: (state) => {
      state.workflowCondition.isFetched = true;
      state.workflowCondition.data = [];
    }
  }
});

export const {
  onCallWorkflows,
  onSuccessWorkflows,
  onFailedWorkflows,

  switchWorkflowLoading,
  switchWorkflowDeleteLoading,

  onSuccessWorkflow,
  onFailedWorkflow,
  resetWorkflow,

  onSuccessWorkflowAdd,
  onSuccessWorkflowEdit,
  onSuccessWorkflowsDelete,

  onCallWorkflowConditions,
  onSuccessWorkflowConditions,
  onFailedWorkflowConditions
} = workflowsSlice.actions;

export default workflowsSlice.reducer;
