import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  SALES_TAX_CALL,
  SALE_TAX_CALL,
  SALE_TAX_ADD_CALL,
  SALE_TAX_EDIT_CALL,
  SALE_TAX_DELETE_CALL
} from "./sagas-actions";
import {
  onCallSalesTax,
  onSuccessSalesTax,
  onFailedSalesTax,
  switchSalesTaxLoading,
  resetSaleTax,
  onSuccessSaleTax,
  onFailedSaleTax,
  onSuccessSaleTaxAdd,
  onSuccessSaleTaxEdit,
  onSuccessSalesTaxDelete
} from "./slice";

function* salesTaxCallGenerator(action) {
  yield put(onCallSalesTax());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/salesTax");

    yield put(onSuccessSalesTax(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSalesTax());
  }
}

function* saleTaxCallGenerator(action) {
  yield put(resetSaleTax());
  try {
    const {
      payload: { saleTaxId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/salesTax/${saleTaxId}`);

    yield put(onSuccessSaleTax(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSaleTax());
  }
}

function* saleTaxAddCallGenerator(action) {
  yield put(switchSalesTaxLoading(true));
  try {
    const {
      payload: { saleTaxData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, "/tenantUser/salesTax", saleTaxData);
    const createdSalesTax = data.salesTax[0];

    yield messageFunction && messageFunction();
    yield put(onSuccessSaleTaxAdd(createdSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSalesTaxLoading(false));
}

function* saleTaxEditCallGenerator(action) {
  yield put(switchSalesTaxLoading(true));
  try {
    const {
      payload: { saleTaxId, saleTaxData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.put, `/tenantUser/salesTax/${saleTaxId}`, saleTaxData);
    const editedSalesTax = data.salesTax[0];

    yield messageFunction && messageFunction();
    yield put(onSuccessSaleTaxEdit(editedSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSalesTaxLoading(false));
}

function* saleTaxDeleteCallGenerator(action) {
  yield put(switchSalesTaxLoading(true));
  try {
    const {
      payload: { saleTaxId }
    } = action;
    yield call(axiosInstance.delete, `/tenantUser/salesTax/${saleTaxId}`);
    yield put(onSuccessSalesTaxDelete(saleTaxId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSalesTaxLoading(false));
}

export default function* salesTaxSaga() {
  yield takeEvery(SALES_TAX_CALL, salesTaxCallGenerator);
  yield takeEvery(SALE_TAX_CALL, saleTaxCallGenerator);
  yield takeEvery(SALE_TAX_ADD_CALL, saleTaxAddCallGenerator);
  yield takeEvery(SALE_TAX_EDIT_CALL, saleTaxEditCallGenerator);
  yield takeEvery(SALE_TAX_DELETE_CALL, saleTaxDeleteCallGenerator);
}
