import { useEffect, useContext } from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { userDataSelector } from "store/user/selectors";
import socketInstance from "clients/socket";
import { SocketContext } from "../../context/socketProvider";

/** This hook handles socket connection */

const useSocket = (tenantId, tenantUserId) => {
  const { reset, setReset } = useContext(SocketContext);
  const { user } = useSelector(userDataSelector, isEqual);
  const tenant_id = tenantId || user?.tenant_id;
  const tenant_user_id = tenantUserId || user?.tenant_user_id;
  const tab_id = sessionStorage.getItem("tabId");


  useEffect(() => {

    // Authenticate socket connection
    const authenticateRes = socketInstance.emit("authentication", {
      tenant_id,
      tenant_user_id,
      tab_id
    });
    // console.log(authenticateRes, 'Socket Auth Result');
    //

    socketInstance.on("connect", () => {
      // setReset(!reset);
      // socketInstance.emit("authentication", {
      //   tenant_id,
      //   tenant_user_id,
      //   tab_id
      // });
    });
    socketInstance.on("connect_error", (error) => {
      console.log("Socket Connect Error: ", error.toString());
    });

    socketInstance.on("disconnect", (reason) => {
      console.log(reason, 'disconnect Reason');
      // if (reason === "io server disconnect") {
        setTimeout(() => {
          socketInstance.connect();
        }, 5000);
      // }
    });

    // return () => {
    //     socketInstance.disconnect();
    // };
  }, [reset]);
};

export default useSocket;
