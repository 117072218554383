import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "store/error/slice";
import appModules from "constant/data/app-modules";
import {
  CONTACT_STATUSES_CALL,
  CONTACT_STATUS_ADD_CALL,
  CONTACT_STATUS_EDIT_CALL,
  CONTACT_STATUS_DELETE_CALL,
  CONTACT_TYPES_CALL,
  CONTACT_TYPE_ADD_CALL,
  CONTACT_TYPE_EDIT_CALL,
  CONTACT_TYPE_DELETE_CALL,
  CONTACT_GROUPS_CALL,
  CONTACT_GROUP_ADD_CALL,
  CONTACT_GROUP_EDIT_CALL,
  CONTACT_GROUP_DELETE_CALL,
  CONTACT_CUSTOM_FIELDS_CALL,
  CONTACT_CUSTOM_FIELD_CALL,
  CONTACT_CUSTOM_FIELD_ADD_CALL,
  CONTACT_CUSTOM_FIELD_EDIT_CALL,
  CONTACT_CUSTOM_FIELD_DELETE_CALL,
  CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE
} from "./sagas-actions";
import {
  onCallContactSettings,
  onSuccessContactSettings,
  onFailedContactSettings,
  switchContactSettingLoading,
  onSuccessContactSettingAdd,
  onSuccessContactSettingEdit,
  onSuccessContactSettingDelete,
  onCallContactCustomFields,
  onSuccessContactCustomFields,
  onFailedContactCustomFields,
  switchContactCustomFieldLoading,
  onSuccessContactCustomField,
  onFailedContactCustomField,
  resetContactCustomField,
  onSuccessContactCustomFieldAdd,
  onSuccessContactCustomFieldEdit,
  onSuccessContactCustomFieldDelete,
  onFailedReorderCustomFieldRowsSetting
} from "./slice";
import { onSuccessContactAttributeAdd, onSuccessContactAttributes } from "../../contacts/slice";

const contactSettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallContactSettings({
        key
      })
    );
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/contacts-settings/${url}`);

      yield put(
        onSuccessContactSettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedContactSettings({
          key
        })
      );
    }
  };

const contactSettingAddCallGenerator = (key, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchContactSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemData }
      } = action;

      const { data } = yield call(axiosInstance.post, `/tenantUser/contacts-settings/${url}`, itemData);

      yield put(
        onSuccessContactSettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessContactAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchContactSettingLoading({
        key,
        status: false
      })
    );
  };

const contactSettingEditCallGenerator = (key, idKey, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchContactSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `/tenantUser/contacts-settings/${url}/${itemId}`, itemData);

      yield put(
        onSuccessContactSettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );

      yield put(onSuccessContactAttributes({
        idKey,
        key,
        data: data?.[apiReturnKey],
        type: 'edit'
      }));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchContactSettingLoading({
        key,
        status: false
      })
    );
  };

const contactSettingDeleteCallGenerator = (key, idKey, url) =>
  function* gen(action) {
    yield put(
      switchContactSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `/tenantUser/contacts-settings/generic_delete/${itemId}/${url}`);
      yield put(
        onSuccessContactSettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchContactSettingLoading({
        key,
        status: false
      })
    );
  };

function* contactCustomFieldsCallGenerator(action) {
  yield put(onCallContactCustomFields());
  try {
    const { data } = yield call(
      axiosInstance.get,
      `/tenantUser/custom-fields/view/${appModules.find((t) => t.name === "Contact").code}`
    );

    yield put(onSuccessContactCustomFields(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactCustomFields());
  }
}

function* contactCustomFieldCallGenerator(action) {
  yield put(resetContactCustomField());
  try {
    const {
      payload: { itemId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/custom-fields/single/${itemId}`);

    yield put(onSuccessContactCustomField(data?.custom_field_definition));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactCustomField());
  }
}

function* contactCustomFieldAddCallGenerator(action) {
  yield put(switchContactCustomFieldLoading(true));
  try {
    const {
      payload: { itemData }
    } = action;
    const { data } = yield call(axiosInstance.post, "/tenantUser/custom-fields", {
      ...itemData,
      app_module_id: appModules.find((aM) => aM.name === "Contact").code
    });
    const createdSalesTax = data.custom_field_definition[0];

    yield put(onSuccessContactCustomFieldAdd(createdSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactCustomFieldLoading(false));
}

function* contactCustomFieldEditCallGenerator(action) {
  yield put(switchContactCustomFieldLoading(true));
  try {
    const {
      payload: { itemId, itemData }
    } = action;
    const { data } = yield call(axiosInstance.put, `/tenantUser/custom-fields/${itemId}`, {
      ...itemData,
      app_module_id: appModules.find((aM) => aM.name === "Contact").code
    });
    const editedSalesTax = data.custom_field_definition[0];

    yield put(onSuccessContactCustomFieldEdit(editedSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactCustomFieldLoading(false));
}

function* contactCustomFieldDeleteCallGenerator(action) {
  yield put(switchContactCustomFieldLoading(true));
  try {
    const {
      payload: { itemId, moduleId }
    } = action;
    yield call(axiosInstance.delete, `/tenantUser/custom-fields/${itemId}?moduleId=${moduleId}`);
    yield put(onSuccessContactCustomFieldDelete(itemId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactCustomFieldLoading(false));
}

function* updateCustomFieldRowsPositionGenerator(action) {
  const { positions, oldPositions } = action.payload;
  try {
    yield call(axiosInstance.put, "/tenantUser/changePosition/custom_field_definition", positions);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedReorderCustomFieldRowsSetting({ key: "customField", data: oldPositions }));
  }
}

export default function* contactSettingsSaga() {
  yield takeEvery(CONTACT_STATUSES_CALL, contactSettingsCallGenerator("status", "contact_status_list"));
  yield takeEvery(
    CONTACT_STATUS_ADD_CALL,
    contactSettingAddCallGenerator("status", "contact_status", "createdContactStatus")
  );
  yield takeEvery(
    CONTACT_STATUS_EDIT_CALL,
    contactSettingEditCallGenerator("status", "contact_status_id", "contact_status", "updatedContactStatus")
  );
  yield takeEvery(
    CONTACT_STATUS_DELETE_CALL,
    contactSettingDeleteCallGenerator("status", "contact_status_id", "contact_status")
  );

  yield takeEvery(CONTACT_TYPES_CALL, contactSettingsCallGenerator("type", "contact_type_list"));
  yield takeEvery(CONTACT_TYPE_ADD_CALL, contactSettingAddCallGenerator("type", "contact_type", "createdContactType"));
  yield takeEvery(
    CONTACT_TYPE_EDIT_CALL,
    contactSettingEditCallGenerator("type", "contact_type_id", "contact_type", "updatedContactType")
  );
  yield takeEvery(
    CONTACT_TYPE_DELETE_CALL,
    contactSettingDeleteCallGenerator("type", "contact_type_id", "contact_type")
  );

  yield takeEvery(CONTACT_GROUPS_CALL, contactSettingsCallGenerator("group", "contact_group_list"));
  yield takeEvery(
    CONTACT_GROUP_ADD_CALL,
    contactSettingAddCallGenerator("group", "contact_group", "createdContactGroup")
  );
  yield takeEvery(
    CONTACT_GROUP_EDIT_CALL,
    contactSettingEditCallGenerator("group", "contact_group_id", "contact_group", "updatedContactGroup")
  );
  yield takeEvery(
    CONTACT_GROUP_DELETE_CALL,
    contactSettingDeleteCallGenerator("group", "contact_group_id", "contact_group")
  );

  yield takeEvery(CONTACT_CUSTOM_FIELDS_CALL, contactCustomFieldsCallGenerator);
  yield takeEvery(CONTACT_CUSTOM_FIELD_CALL, contactCustomFieldCallGenerator);
  yield takeEvery(CONTACT_CUSTOM_FIELD_ADD_CALL, contactCustomFieldAddCallGenerator);
  yield takeEvery(CONTACT_CUSTOM_FIELD_EDIT_CALL, contactCustomFieldEditCallGenerator);
  yield takeEvery(CONTACT_CUSTOM_FIELD_DELETE_CALL, contactCustomFieldDeleteCallGenerator);
  yield takeEvery(CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE, updateCustomFieldRowsPositionGenerator);
}
