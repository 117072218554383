import { createSlice } from "@reduxjs/toolkit";

export const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    isFetched: false,
    isLoading: false,
    messages: []
  },
  reducers: {
    onCallMessages: (state) => {
      state.isFetched = false;
      state.isLoading = true;
    },
    onCallMessagesSuccess: (state, { payload }) => {
      const sortedMessages = [...payload.messages].sort((a, b) => new Date(a.created_date) - new Date(b.created_date));
      state.messages = sortedMessages;
      state.isFetched = true;
      state.isLoading = false;
    },
    onCallMessagesFail: (state) => {
      state.messages = [];
      state.isFetched = false;
      state.isLoading = false;
    }
  }
});

export const { onCallMessages, onCallMessagesFail, onCallMessagesSuccess } = messagesSlice.actions;

export default messagesSlice.reducer;
