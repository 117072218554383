import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import ErrorPopup from "components/complex/dialogs/error-popup";
import { useDidUpdate, useForm } from "hooks";
import pluralize from "pluralize";
import { PropTypes } from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import validationSchema from "./validationSchema";
import ExportStepper from "./export-stepper";
import { useDispatch, useSelector } from "react-redux";
import { callExport } from "store/export/sagas-actions";
import exportDataSelector from "store/export/selectors";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import LoaderLinear  from "components/common/loader-liner";
import { onDownloadedExport } from "store/export/slice";

const Export = ({ moduleValue, selectedIds, closePopup, isOpen, columns }) => {
  const selectedModule = useMemo(
    () => ({
      company: {
        name: "company"
      },
      contact: {
        name: "contact"
      },
      product_service: {
        name: "product service"
      },
      opportunity: {
        name: "opportunity"
      }
    }),
    []
  );
  const [error, setError] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const { isLoading, isDownloaded } = useSelector(exportDataSelector, isEqual);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const steps = ["Select Columns", "Confirmation"];

  const defaultValues = useMemo(
    () => ({
      filename: "",
      fileType: "",
      headers: []
    }),
    []
  );

  const { FormField, handleSubmit } = useForm({
    defaultValues,
    validationSchema
  });

  const formSubmitHandler = (data) => {
    const exportData = {
      file_type: data.fileType,
      module: moduleValue,
      ids: selectedIds,
      selectedHeaders: data.headers.map((column) => column.name)
    };
    dispatch(callExport(exportData, moduleValue, data.fileName, message));
    closePopup();
    dispatch(onDownloadedExport(false));
    setActiveStep(0);
  };

  function message() {
    enqueueSnackbar("File has exported successfully", {
      variant: "success"
    });
  }

  useDidUpdate(() => {
    if (!isLoading) {
      if (isDownloaded) {
        closePopup();
        dispatch(onDownloadedExport(false));
        setActiveStep(0);
      }
    }
  }, [isLoading]);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return (
    <>
      <Dialog open={isOpen} onClose={closePopup} fullWidth maxWidth="sm">
        <DialogTitle>
          <ExportStepper steps={steps} activeStep={activeStep} />
        </DialogTitle>
        <DialogContent>
          <form id="export-data-form">
            <Grid display={activeStep !== 0 ? "none" : "flex"} item xs={12}>
              {FormField({
                name: "headers",
                label: "Columns",
                type: "select-multiple",
                options: columns?.map((c) => ({ name: c, code: c }))
              })}
            </Grid>
            {activeStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  {FormField({
                    name: "fileName",
                    label: "Filename"
                  })}
                </Grid>
                <Grid item xs={4}>
                  {FormField({
                    name: "fileType",
                    label: "Select file type",
                    type: "select",
                    options: [
                      {
                        name: "CSV",
                        code: "csv"
                      },
                      {
                        name: "XLSX",
                        code: "xlsx"
                      }
                    ]
                  })}
                </Grid>
              </Grid>
            )}
          </form>
          <DialogContentText style={{ whiteSpace: "pre" }}>
            {`You are about to export ${selectedIds?.length} ${
              selectedIds?.length === 1
                ? selectedModule[moduleValue].name
                : pluralize(selectedModule[moduleValue].name, "1")
            }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {activeStep !== 0 && <Button onClick={(e) => handleBack(e)}>Back</Button>}
          {activeStep === 0 && <Button onClick={(e) => handleNext(e)}>NEXT</Button>}
          {activeStep === 1 && (
            <Button type="submit" autoFocus onClick={handleSubmit(formSubmitHandler)}>
              EXPORT
            </Button>
          )}
          <Button onClick={closePopup} type="button">
            CANCEL
          </Button>
        </DialogActions>
        {isLoading && <LoaderLinear />}
      </Dialog>
      <ErrorPopup message={error} isOpen={error !== undefined} closePopup={() => setError(undefined)} />
    </>
  );
};

Export.propTypes = {
  moduleValue: PropTypes.string.isRequired
};

export default Export;
