export const APP_IMPERSONATION_CALL = "APP_IMPERSONATION_CALL";
export const APP_STOP_IMPERSONATION_CALL = "APP_IMPERSONATION_CALL";

export function callImpersonate(tenantId) {
  return { type: APP_IMPERSONATION_CALL, payload: { tenantId } };
}

export function callStopImpersonation() {
  return { type: APP_STOP_IMPERSONATION_CALL };
}
