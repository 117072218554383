import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";
import _ from "lodash";

export const productsServiceSlice = createSlice({
  name: "products-service",
  initialState: {
    isFetched: false,
    isLoading: false,
    columnNames: [],
    productsServices: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
    cardLabels: {},
    cardStructure: {},

    // single document
    productsService: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },

    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    category: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    sub_category: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    manufacturer: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    uom: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    recurrence: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    }
  },
  reducers: {
    // all Products Services
    onCallProductsServices: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.isLoading = true;
      state.productsServices = [];
    },
    onSuccessProductsServices: (state, { payload }) => {
      state.isFetched = true;
      state.isLoading = false;
      state.count = parseInt(payload?.total_count, 10);
      if (payload.uiViewType === "card") {
        state.productsServices = payload.shouldClear
          ? payload.tableData
          : state.productsServices.concat(payload.tableData);
        state.pagination.skip = payload.shouldClear
          ? payload.paginationData.limit
          : payload.paginationData.skip + payload.paginationData.limit;
        state.cardLabels = payload.structure.card_label;
        state.cardStructure = payload.structure.card_structure;
      } else {
        state.productsServices = payload.tableData;
        state.columnNames = payload.columnNames;
      }
    },
    onFailedProductsServices: (state) => {
      state.isFetched = true;
      state.isLoading = false;
      state.productsServices = [];
      state.columnNames = [];
      state.count = 0;
    },

    switchProductsServicesLoading: (state, { payload }) => {
      state.productsService.isLoading = payload !== undefined ? payload : !state.productsService.isLoading;
    },

    onSuccessProductsService: (state, { payload }) => {
      state.productsService.isFetched = true;
      state.productsService.data = {
        ...payload,
        ...(payload?.photo && {
          photo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/productServicePhoto/${
            payload.product_service_id
          }/${payload.photo}`
        })
      };
    },
    onFailedProductsService: (state) => {
      state.productsService.isFetched = true;
      state.productsService.data = undefined;
    },
    resetProductsService: (state) => {
      state.productsService.isFetched = false;
      state.productsService.data = undefined;
    },

    onSuccessProductsServiceAdd: (state, { payload }) => {
      if (payload) {
        state.productsServices.push(payload);
        state.count += 1;
      }
    },
    onSuccessProductsServiceEdit: (state, { payload }) => {
      if (payload) {
        state.productsServices = state.productsServices.map((ps) =>
          ps.product_service_id === payload.product_service_id ? payload : ps
        );
      }
    },
    onSuccessProductsServicesDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.productsServices = state.productsServices.filter((ps) => !payload.includes(ps.product_service_id));
      }
    },

    onCallProductsServiceSettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessProductsServiceSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;

      if (payload.key === "sub_category") {
        state[payload.key].columnNames = payload.data.columnNames.filter((cN) => cN.column_name !== "category_id");
      } else {
        state[payload.key].columnNames = payload.data.columnNames;
      }
    },
    onFailedProductsServiceSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },

    switchProductsServiceSettingLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },

    onSuccessProductsServiceSettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.key === "sub_category") {
          if (payload.data.Default) {
            state[payload.key].list = [
              ...state[payload.key].list.map((item) => ({
                ...item,
                Default: false
              })),
              payload.data[0]
            ];
          } else {
            state[payload.key].list = [...state[payload.key].list, payload.data[0]];
          }
        } else if (payload.data.Default) {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessProductsServiceSettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey]
              ? payload.data
              : {
                  ...item,
                  Default: false
                }
          );
        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessProductsServiceSettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
      }
    },

    // ATTRIBUTES

    onCallProductsServiceAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = false;
      state[payload.key].attributeData.isLoading = false;
      state[payload.key].attributeData.list = [];
    },
    onSuccessProductsServiceAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;
      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );
        const tableData = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.default_value,
          ...(item.recurrence_id !== undefined && { recurrence_id: item.recurrence_id }),
          ...(item.position !== undefined && { position: item.position })
        }));
        state[payload.key].attributeData.list = _.orderBy(tableData, [(list) => list.name.toLowerCase()], ["asc"]);
      }
    },
    onFailedProductsServiceAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;
      state[payload.key].attributeData.list = [];
    },
    onSuccessProductsServiceAttributeAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.key === "sub_category") {
          const codeFieldName = Object.keys(payload.data[0]).find((k) => k.includes("_id"));
          let nameFieldName = "";
          if (Object.keys(payload.data[0]).find((k) => k.includes("Sub-Category"))) {
            nameFieldName = Object.keys(payload.data[0]).find((k) => k.includes("Sub-Category"));
          } else {
            nameFieldName = Object.keys(payload.data[0]).find((k) => !k.includes("Default") && !k.includes("_id"));
          }
          state[payload.key].attributeData.list = [
            ...state[payload.key].attributeData.list,
            {
              code: payload.data[0][codeFieldName],
              name: payload.data[0][nameFieldName]
            }
          ];
        } else {
          const codeFieldName = Object.keys(payload.data).find((k) => k.includes("_id"));
          const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));

          state[payload.key].attributeData.list = [
            ...state[payload.key].attributeData.list,
            {
              code: payload.data[codeFieldName],
              name: payload.data[nameFieldName]
            }
          ];
        }
      }
    },
    switchProductsServicesAttributeChangeLoading: (state, { payload }) => {
      state[payload.key].attributeData.isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].attributeData.isLoading;
    },
    onSuccessProductsServicesAttributeChange: (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        state.productsServices = state.productsServices.map(
          (d) => payload.data.find((newD) => newD.product_service_id === d.product_service_id) || d
        );
      }
    }
  }
});

export const {
  onCallProductsServices,
  onSuccessProductsServices,
  onFailedProductsServices,
  switchProductsServicesLoading,

  onSuccessProductsService,
  onFailedProductsService,
  resetProductsService,

  onSuccessProductsServiceAdd,
  onSuccessProductsServiceEdit,
  onSuccessProductsServicesDelete,

  onCallProductsServiceSettings,
  onSuccessProductsServiceSettings,
  onFailedProductsServiceSettings,
  switchProductsServiceSettingLoading,
  onSuccessProductsServiceSettingAdd,
  onSuccessProductsServiceSettingEdit,
  onSuccessProductsServiceSettingDelete,

  onCallProductsServiceAttributes,
  onSuccessProductsServiceAttributes,
  onFailedProductsServiceAttributes,
  onSuccessProductsServiceAttributeAdd,
  switchProductsServicesAttributeChangeLoading,
  onSuccessProductsServicesAttributeChange,

  filterProductsServices
} = productsServiceSlice.actions;

export default productsServiceSlice.reducer;
