import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useRef } from "react";
import { IMPORT_TYPES } from "store/import/sagas-actions";

const ImportUploader = ({ setImportType, importType, file, onFileUpload, allowedFileTypes, isFileLoading }) => {
  const hiddenFileInputRef = useRef(null);

  const fileChangeHandler = async (e) => {
    onFileUpload(e.target.files[0]);
  };

  const handleClick = () => {
    hiddenFileInputRef.current.click();
  };

  const importTypeHandler = (e) => {
    setImportType(e.target.value);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
      <Typography component="p">Please select function:</Typography>
      <RadioGroup
        sx={{ mb: "20px" }}
        row
        aria-labelledby="import-type-controlled-group"
        value={importType}
        onClick={(e) => importTypeHandler(e)}
      >
        <FormControlLabel value={IMPORT_TYPES.INSERT} control={<Radio />} label="Add" />
        <FormControlLabel value={IMPORT_TYPES.UPDATE} control={<Radio />} label="Update" />
        <FormControlLabel value={IMPORT_TYPES.INSERT_AND_UPDATE} control={<Radio />} label="Add and Update" />
      </RadioGroup>
      <Box display="flex" sx={{ textAlign: "center" }} flexDirection="column">
        <Button onClick={handleClick}>Click here to select file</Button>
        <input
          ref={hiddenFileInputRef}
          style={{ display: "none" }}
          type="file"
          accept={allowedFileTypes}
          onChange={fileChangeHandler}
        />
        {isFileLoading ? (
          <Typography component="span">File is loading...</Typography>
        ) : (
          <Typography component="p">{file?.name}</Typography>
        )}
      </Box>
    </div>
  );
};

export default ImportUploader;
