import { createPromiseAction } from "@adobe/redux-saga-promise";

export const DELETED_ITEMS_CALL = "DELETED_ITEMS_CALL";
export const DELETED_ITEMS_CALL_PROMISE = "DELETED_ITEMS_CALL_PROMISE";

export const callDeletedItemsPromise = createPromiseAction(DELETED_ITEMS_CALL_PROMISE);

export function callDeletedItems(paginationData) {
  return { type: DELETED_ITEMS_CALL, payload: { paginationData } };
}
