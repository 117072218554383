const customFieldTypes = [
  {
    name: "Text",
    code: "1",
    type: "text"
  },
  {
    name: "Number",
    code: "2",
    type: "number"
  },
  {
    name: "Percent",
    code: "3",
    type: "number"
  },
  {
    name: "Date",
    code: "4",
    type: "date"
  },
  {
    name: "Checkbox",
    code: "5",
    type: "checkbox"
  },
  {
    name: "Hyperlink",
    code: "6",
    type: "text"
  },
  {
    name: "Note",
    code: "7",
    type: "textarea"
  }
];

export const CHECKBOX_TYPE_ID = 5;

export default customFieldTypes;
