export const CONTACT_STATUSES_CALL = "CONTACT_STATUSES_CALL";
export const CONTACT_STATUS_ADD_CALL = "CONTACT_STATUS_ADD_CALL";
export const CONTACT_STATUS_EDIT_CALL = "CONTACT_STATUS_EDIT_CALL";
export const CONTACT_STATUS_DELETE_CALL = "CONTACT_STATUS_DELETE_CALL";

export const CONTACT_TYPES_CALL = "CONTACT_TYPES_CALL";
export const CONTACT_TYPE_ADD_CALL = "CONTACT_TYPE_ADD_CALL";
export const CONTACT_TYPE_EDIT_CALL = "CONTACT_TYPE_EDIT_CALL";
export const CONTACT_TYPE_DELETE_CALL = "CONTACT_TYPE_DELETE_CALL";

export const CONTACT_GROUPS_CALL = "CONTACT_GROUPS_CALL";
export const CONTACT_GROUP_ADD_CALL = "CONTACT_GROUP_ADD_CALL";
export const CONTACT_ORG_REP_ADD_CALL = "CONTACT_ORG_REP_ADD_CALL";
export const CONTACT_GROUP_EDIT_CALL = "CONTACT_GROUP_EDIT_CALL";
export const CONTACT_GROUP_DELETE_CALL = "CONTACT_GROUP_DELETE_CALL";

export const CONTACT_CUSTOM_FIELDS_CALL = "CONTACT_CUSTOM_FIELDS_CALL";
export const CONTACT_CUSTOM_FIELD_CALL = "CONTACT_CUSTOM_FIELD_CALL";
export const CONTACT_CUSTOM_FIELD_ADD_CALL = "CONTACT_CUSTOM_FIELD_ADD_CALL";
export const CONTACT_CUSTOM_FIELD_EDIT_CALL = "CONTACT_CUSTOM_FIELD_EDIT_CALL";
export const CONTACT_CUSTOM_FIELD_DELETE_CALL = "CONTACT_CUSTOM_FIELD_DELETE_CALL";
export const CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE = "CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE";

export const callContactSettings = (actionName) => () => ({ type: actionName });

export function callAddContactSetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditContactSetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteContactSetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

// custom fields

export function callContactCustomFields() {
  return { type: CONTACT_CUSTOM_FIELDS_CALL };
}

export function callContactCustomField(itemId) {
  return { type: CONTACT_CUSTOM_FIELD_CALL, payload: { itemId } };
}

export function addContactCustomField(itemData) {
  return { type: CONTACT_CUSTOM_FIELD_ADD_CALL, payload: { itemData } };
}

export function editContactCustomField(itemId, itemData) {
  return { type: CONTACT_CUSTOM_FIELD_EDIT_CALL, payload: { itemId, itemData } };
}

export function deleteContactCustomField(itemId, moduleId) {
  return { type: CONTACT_CUSTOM_FIELD_DELETE_CALL, payload: { itemId, moduleId } };
}

export function callUpdateContactCustomFieldRowsPosition(data) {
  return { type: CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE, payload: { ...data } };
}
