import { createSlice } from "@reduxjs/toolkit";

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    isFetched: false,
    isLoading: false,
    columnNames: [],
    tasks: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
    cardLabels: {},
    cardStructure: {},

    // single task
    task: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
    priority: {
      isFetched: false,
      list: []
    },
    type: {
      isFetched: false,
      list: []
    },
    tag: {
      isFetched: false,
      list: []
    },
    status: {
      isFetched: false,
      list: []
    }
  },
  reducers: {
    // all tasks
    onCallTasks: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.isLoading = true;
      state.tasks = [];
    },
    onSuccessTasks: (state, { payload }) => {
      state.isFetched = true;
      state.isLoading = false;
      state.count = parseInt(payload?.total_count, 10);

      if (payload.uiViewType === "card") {
        state.tasks = payload.shouldClear ? payload.tableData : state.tasks.concat(payload.tableData);
        state.pagination.skip = payload.shouldClear
          ? payload.paginationData.limit
          : payload.paginationData.skip + payload.paginationData.limit;
        state.cardLabels = payload.structure.card_label;
        state.cardStructure = payload.structure.card_structure;
      } else {
        state.tasks = payload.tableData;
        state.columnNames = payload.columnNames;
      }
    },
    onFailedTasks: (state) => {
      state.isFetched = true;
      state.isLoading = false;
      state.tasks = [];
      state.columnNames = [];
      state.count = 0;
    },

    onSuccessTask: (state, { payload }) => {
      state.task.isFetched = true;
      state.task.data = payload;
    },
    onFailedTask: (state) => {
      state.task.isFetched = true;
      state.task.data = undefined;
    },
    switchTaskLoading: (state, { payload }) => {
      state.task.isLoading = payload !== undefined ? payload : !state.task.isLoading;
    },
    onSuccessTaskAdd: (state, { payload }) => {
      if (payload?.taskData) {
        state.tasks.push(payload.taskData);
        state.count += 1;
      }
    },
    onSuccessTaskEdit: (state, { payload }) => {
      if (payload) {
        state.tasks = state.tasks.map((task) => (task.task_id === payload.task_id ? payload : task));
      }
    },
    onSuccessTasksEdit: (state, { payload }) => {
      if (payload) {
        state.tasks = state.tasks.reduce((allTasks, currentTask) => {
          const updatedTask = payload.find((task) => task.value.task_id === currentTask.task_id);
          if (updatedTask) {
            allTasks.push(updatedTask.value);
            return allTasks;
          }
          allTasks.push(currentTask);
          return allTasks;
        }, []);
      }
    },
    onSuccessTasksDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.tasks = state.tasks.filter((task) => !payload.includes(task.task_id));
        state.count -= 1;
      }
    },
    resetTask: (state) => {
      state.task.isFetched = false;
      state.task.data = undefined;
    },
    onSuccessTaskAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;

      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );
        state[payload.key].list = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.Default,
          ...(payload.key === "priority" && { color: item.Color }),
          ...(payload.key === "status" && { outcome: item.Outcome })
        }));
      }
    },
    onCallTaskAttributes: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onFailedTaskAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    }
  }
});

export const {
  onCallTasks,
  onSuccessTasks,
  onFailedTasks,

  switchTaskLoading,

  onSuccessTask,
  onFailedTask,
  resetTask,

  onFailedTaskAttributes,

  onCallTaskAttributes,
  onSuccessTaskAttributes,

  onSuccessTaskAdd,
  onSuccessTaskEdit,
  onSuccessTasksEdit,
  onSuccessTasksDelete
} = tasksSlice.actions;

export default tasksSlice.reducer;
