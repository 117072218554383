import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { setError } from "../error/slice";
import {
  ADD_ITEM_FROM_WIZARD,
  callAddDocumentWizardDefinitionPromise,
  CHANGE_ITEM_FROM_WIZARD,
  CREATE_DOCUMENT_FROM_WIZARD,
  DOCUMENT_WIZARD_ADD_CALL,
  DOCUMENT_WIZARD_CALL,
  DOCUMENT_WIZARD_DEFINITION_CALL,
  DOCUMENT_WIZARD_DEFINITION_DELETE_CALL,
  DOCUMENT_WIZARD_DEFINITION_EDIT_CALL,
  DOCUMENT_WIZARD_DEFINITION_RESTORE_CALL,
  DOCUMENT_WIZARD_DEFINITIONS_CALL,
  DOCUMENT_WIZARD_DEFINITIONS_CALL_CONDITIONS,
  DOCUMENT_WIZARD_DELETE_CALL,
  DOCUMENT_WIZARD_EDIT_CALL,
  DOCUMENT_WIZARDS_CALL
} from "./sagas-actions";
import {
  onCallDocumentWizardDefinitionConditions,
  onCallDocumentWizardDefinitions,
  onCallDocumentWizards,
  onFailedDocumentWizard,
  onFailedDocumentWizardDefinition,
  onFailedDocumentWizardDefinitionConditions,
  onFailedDocumentWizardDefinitions,
  onFailedDocumentWizards,
  onSuccessDocumentWizard,
  onSuccessDocumentWizardAdd,
  onSuccessDocumentWizardDefinition,
  onSuccessDocumentWizardDefinitionAdd,
  onSuccessDocumentWizardDefinitionConditions,
  onSuccessDocumentWizardDefinitionDelete,
  onSuccessDocumentWizardDefinitionEdit,
  onSuccessDocumentWizardDefinitions,
  onSuccessDocumentWizardDelete,
  onSuccessDocumentWizards,
  switchDocumentWizardDefinitionDeleteLoading,
  switchDocumentWizardDefinitionLoading,
  switchDocumentWizardLoading
} from "./slice";
import { v4 as uuidv4 } from "uuid";

import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { onSuccessDocumentItem } from "../documents/slice";

const prefix = "/tenantUser/documents/document_wizard";
const definitionsPrefix = "/tenantUser/documents/document_wizard_definition";

// Initial call
function* documentWizardDefinitionsCallGenerator(action) {
  yield put(onCallDocumentWizardDefinitions());
  try {
    const { data } = yield call(axiosSagaRequest, "get", definitionsPrefix);
    yield put(onSuccessDocumentWizardDefinitions(data.documentWizards));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentWizardDefinitions());
  }
}

// Single Document Wizard Definition
function* documentWizardDefinitionCallGenerator(action) {
  try {
    const {
      payload: { documentWizardDefinitionId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `${definitionsPrefix}/${documentWizardDefinitionId}`);

    yield put(onSuccessDocumentWizardDefinition(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentWizardDefinition());
  }
}

function* documentWizardDefinitionAddCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentWizardDefinitionAddWorker() {
    try {
      yield put(switchDocumentWizardDefinitionLoading(true));
      const { payload } = action;
      const formData = new FormData();

      payload.image && typeof (payload.image) === 'object' && payload.image.src.file && formData.append("image", payload.image.src.file);
      formData.append("data", payload.data);
      formData.append("description", payload.description);
      formData.append("name", payload.name);
      formData.append("status_id", payload.status_id);

      // add document wizard definition
      const { data } = yield call(axiosSagaRequest, "post", definitionsPrefix, formData);

      yield payload.message && payload.message();
      yield put(onSuccessDocumentWizardDefinitionAdd(data));

      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    } finally {
      yield put(switchDocumentWizardDefinitionLoading(false));
    }
  });
}

function* documentWizardDefinitionEditCallGenerator(action) {
  yield put(switchDocumentWizardDefinitionLoading(true));
  try {
    const {
      payload: { documentWizardDefinitionData, messageFunction }
    } = action;
    const formData = new FormData();

    formData.append("data", documentWizardDefinitionData.data);
    formData.append("description", documentWizardDefinitionData.description);
    formData.append("name", documentWizardDefinitionData.name);
    formData.append("status_id", documentWizardDefinitionData.status_id);

    documentWizardDefinitionData.image && typeof (documentWizardDefinitionData.image) === 'object' && formData.append("image", documentWizardDefinitionData.image.src.file);

    // update document wizard definition data
    const { data } = yield call(axiosSagaRequest, "put", `${definitionsPrefix}/${documentWizardDefinitionData.document_wizard_definition_id}`, formData);
    yield messageFunction && messageFunction();
    yield put(onSuccessDocumentWizardDefinitionEdit(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentWizardDefinitionLoading(false));
}

function* documentWizardDefinitionDeleteCallGenerator(action) {
  yield put(switchDocumentWizardDefinitionDeleteLoading(true));
  try {
    const {
      payload: { documentWizardDefinitionId }
    } = action;
    yield call(axiosSagaRequest, "delete", `${definitionsPrefix}/${documentWizardDefinitionId}`);
    yield put(onSuccessDocumentWizardDefinitionDelete(documentWizardDefinitionId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentWizardDefinitionDeleteLoading(false));
}

function* documentWizardDefinitionRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosSagaRequest, "post", `${definitionsPrefix}/undo_deleted`, {
      document_wizard_definition_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function selectDocumentWizardId({ payload }) {
  const { documentWizardId } = payload;
  return documentWizardId;
}

function* documentWizardDefinitionConditionsCallGenerator(action) {
  yield put(onCallDocumentWizardDefinitionConditions());
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/tokens/get_conditions`);
    yield put(onSuccessDocumentWizardDefinitionConditions(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentWizardDefinitionConditions());
  }
}

function* documentWizardsCallGenerator(action) {
  yield put(onCallDocumentWizards());
  try {
    const { data } = yield call(axiosSagaRequest, "get", prefix);
    yield put(onSuccessDocumentWizards(data.documentWizards));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentWizards());
  }
}

// Single Document Wizard
function* documentWizardCallGenerator(action) {
  try {
    const {
      payload: { documentWizardId }
    } = action;

    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/${documentWizardId}`);

    yield put(onSuccessDocumentWizard(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentWizard());
  }
}

function* documentWizardAddCallGenerator(action) {
  yield put(switchDocumentWizardLoading(true));
  try {
    const {
      payload: { wizardData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, prefix, wizardData);
    yield put(
      onSuccessDocumentWizardAdd(data)
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentWizardLoading(false));
}

function* documentWizardEditCallGenerator(action) {
  yield put(switchDocumentWizardLoading(true));
  try {
    const {
      payload: { documentWizardId, documentWizardData, messageFunction }
    } = action;

    // update document wizard data
    yield call(axiosSagaRequest, "put", `${prefix}/${documentWizardId}`, documentWizardData);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentWizardLoading(false));
}

function* documentWizardDeleteCallGenerator(action) {
  try {
    const {
      payload: { documentWizardId }
    } = action;
    yield call(axiosSagaRequest, "delete", `${prefix}/${documentWizardId}`);
    yield put(onSuccessDocumentWizardDelete(documentWizardId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* createDocumentFromWizardGenerator({ payload }) {
  const { data, resolve, reject } = payload;
  try {
    const createDocData = {
      bill_to_contact_id: data.bill_to_contact_id.code,
      ship_to_contact_id: data.ship_to_contact_id.code,
      sold_to_contact_id: data.sold_to_contact_id.code,
      tenant_user_id: data.tenant_user_id
    };
    const contactEnum = {
      0: 'bill_to',
      1: 'sold_to',
      2: 'ship_to',
    };

    const AddressEnum = {
      'bill_to': 'Default Billing',
      'sold_to': 'Default Main',
      'ship_to': 'Default Shipping'
    };

    const contacts = [data.bill_to_contact_id, data.sold_to_contact_id, data.ship_to_contact_id].reduce((acc, current, index) => {
      const foundItem = acc.find(item => item.code === current.code);
      if (!foundItem) {
        acc.push({ ...current, for: [index] });
        return acc;
      }
      if (foundItem) {
        return acc.map(item => {
          if (item.code === current.code) {
            return { ...item, for: [...item.for, index] };
          }
          return item;
        });
      }
      return acc;
    }, []);

    let companyId;

    yield all(contacts.map(async contact => {
      const { data } = await axiosSagaRequest('get', `/tenantUser/contacts/${contact.code}`);
      companyId = data?.contact[0]?.company_id?.company_id;
      if (companyId) {
        contact.for.map(f => {
          createDocData[`${contactEnum[f]}_company_id`] = companyId;
        });
        const { data: companyAddress } = await axiosSagaRequest('get', `/tenantUser/companies/company_address/${companyId}/get_company_addresses`);
        companyAddress.clearedTableData.map(address => {
          contact.for.map(f => {
            if (address[AddressEnum[contactEnum[f]]]) {
              createDocData[`${contactEnum[f]}_company_address_id`] = address.company_address_id || "";
            }
          });
        });
      }
    }));

    const { data: documentTemplateData } = yield axiosSagaRequest('get', `/tenantUser/documents/${data.document_id}`);

    createDocData.adjustments = documentTemplateData.document.adjustments;
    createDocData.tenant_user_id = documentTemplateData.document.tenant_user_id;
    createDocData.cost = documentTemplateData.document.cost;
    createDocData.currency_id = documentTemplateData.document.currency_id;
    createDocData.document_date = documentTemplateData.document.document_date;
    createDocData.expire_date = documentTemplateData.document.expire_date;
    createDocData.payment_term_id = documentTemplateData.document.payment_term_id;
    createDocData.sales_tax_id = documentTemplateData.document.sales_tax_id;
    createDocData.sales_tax_rate = documentTemplateData.document.sales_tax_rate;
    createDocData.status_id = documentTemplateData.document.status_id;
    createDocData.sub_total = documentTemplateData.document.sub_total;
    createDocData.type_id = documentTemplateData.document.type_id;
    createDocData.opportunity_add_edit = documentTemplateData.document.opportunity_add_edit;


    if (documentTemplateData.document.opportunity_add_edit) {

      const { oppStatus, oppBoard, oppType, oppStage } = yield all({
        oppStatus: call(axiosSagaRequest, 'get', 'tenantUser/opportunity-setting/list/opportunity_status'),
        oppBoard: call(axiosSagaRequest, 'get', 'tenantUser/opportunity-setting/list/opportunity_board'),
        oppType: call(axiosSagaRequest, 'get', 'tenantUser/opportunity-setting/list/opportunity_type'),
        oppStage: call(axiosSagaRequest, 'get', 'tenantUser/opportunity-setting/list/opportunity_stage'),
      });

      const defaultStatus = oppStatus.data.tableData.find(item => item.default_value);
      const defaultBoard = oppBoard.data.tableData.find(item => item.default_value);
      const defaultType = oppType.data.tableData.find(item => item.default_value);
      const defaultStage = oppStage.data.tableData.find(item => item.default_value);


      const oppData = {
        board_id: defaultBoard.opportunity_board_id,
        company_id: companyId || null,
        contact_id: data.sold_to_contact_id.code,
        currency_id: documentTemplateData.document.currency_id,
        name: `${documentTemplateData.document.document_name} - ${String(Date.now())}`,
        stage_id: defaultStage.opportunity_stage_id,
        status_id: defaultStatus.opportunity_status_id,
        type_id: defaultType.opportunity_type_id,
        tenant_user_id: data.tenant_user_id,
      };

      const { data: responseOppCreate } = yield axiosSagaRequest('post', '/tenantUser/opportunities', oppData);
      createDocData.opportunity_id = responseOppCreate?.createdOpportunity?.opportunity_id || "";
    }

    createDocData.name = `${documentTemplateData.document.document_name} for ${data.sold_to_contact_id.name}`;

    const { data: documentTemplateItems } = yield axiosSagaRequest('get', `/tenantUser/documents/items/${documentTemplateData.document.document_id}`);

    const res = yield axiosSagaRequest('post', '/tenantUser/documents', createDocData);

    const ps = [];

    const referenceProductServiceId = {};
    const referenceLayoutItemId = {};

    const newLayout = documentTemplateItems.layoutData.layout.map(item => {
      const newItemId = uuidv4();
      if (item.typeId === "product_service") {
        documentTemplateItems.productServices.map(productService => {
          if (productService.document_item_id === item.i) {
            const newProductServiceItemId = uuidv4();
            referenceProductServiceId[productService.document_item_product_service_id] = newProductServiceItemId;
            const newPs = {
              ...productService,
              document_item_id: newItemId,
              document_item_product_service_id: newProductServiceItemId,
            };
            if (!ps.vendor_id) {
              delete ps.vendor_name;
            }
            ps.push(newPs);
          }
        });
      }
      documentTemplateItems.layoutData.items[newItemId] = documentTemplateItems.layoutData.items[item.i];
      referenceLayoutItemId[item.i] = newItemId;
      delete documentTemplateItems.layoutData.items[item.i];
      return {
        ...item,
        i: newItemId
      };
    });

    const updateItems = {
      documentId: res.data.document.document_id,
      docData: {
        sub_total: Number(documentTemplateData.document.sub_total),
        sales_tax_rate: documentTemplateData.document.sales_tax_rate,
        cost: Number(documentTemplateData.document.cost)
      },
      layoutData: JSON.stringify({
        ...documentTemplateItems.layoutData,
        layout: newLayout,
        deletedItemIds: [],
        itemUrls: []
      }),
      productServiceData: JSON.stringify({ delete: [], upsert: ps })
    };


    const createdDocItems = yield axiosSagaRequest('post', '/tenantUser/documents/items', updateItems);
    resolve({ document: res.data.document, items: createdDocItems.data, updateItems, referenceProductServiceId, referenceLayoutItemId });
  } catch (e) {
    console.log(e);
    reject(e);
  }
}


function* changeItemFromWizard({ payload }) {
  const { data, resolve, reject } = payload;
  try {
    let newUpsert = data.documentItems.productServices;
    newUpsert = newUpsert.map(ps => {
      if (data.itemsToChangeQuantity?.[ps.document_item_product_service_id]) {
        return {
          ...ps,
          quantity: data.itemsToChangeQuantity[ps.document_item_product_service_id].quantity
        };
      }
      return ps;
    });


    newUpsert = newUpsert.filter(ps => !data.itemsToDelete?.[ps.document_item_product_service_id]);

    const newDelete = data.documentItems.productServices.filter(ps => data.itemsToDelete?.[ps.document_item_product_service_id])
      .map(item => item.document_item_product_service_id);

    if (data?.itemsToAdd?.length) {
      newUpsert = [...newUpsert, ...data.itemsToAdd];
    }

    newUpsert = newUpsert.map(item => {
      return {
        ...item,
        discount: Number(item.discount),
        sell: Number(item.sell),
        cost: Number(item.cost),
        total: Number(item.total)
      };
    });

    const finalData = {
      ...data.updateItems,
      productServiceData: JSON.stringify({ delete: newDelete || [], upsert: newUpsert || [] })
    };
    const createdDocItems = yield axiosSagaRequest('post', '/tenantUser/documents/items', finalData);
    yield put(onSuccessDocumentItem({
      itemData: createdDocItems.data
    }));
    resolve(createdDocItems);
  } catch (e) {
    reject(e);
    console.log(e, 'this is e');
  }
}

function* AddItemFromWizard({ payload }) {
  const { data, resolve, reject } = payload;
  try {
    const finalData = {
      ...data.updateItems,
      productServiceData: JSON.stringify({ delete: [], upsert: data.productServices || [] })
    };
    const createdDocItems = yield axiosSagaRequest('post', '/tenantUser/documents/items', finalData);
    resolve(createdDocItems);
  } catch (e) {
    reject(e);
    console.log(e, 'this is e');
  }
}


export default function* documentWizardsSaga() {
  yield takeEvery(DOCUMENT_WIZARD_DEFINITIONS_CALL, documentWizardDefinitionsCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_DEFINITION_CALL, documentWizardDefinitionCallGenerator);
  yield takeEvery(callAddDocumentWizardDefinitionPromise, documentWizardDefinitionAddCallPromiseGenerator);
  yield takeEvery(DOCUMENT_WIZARD_DEFINITION_EDIT_CALL, documentWizardDefinitionEditCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_DEFINITION_DELETE_CALL, documentWizardDefinitionDeleteCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_DEFINITION_RESTORE_CALL, documentWizardDefinitionRestoreCallGenerator);

  yield takeEvery(DOCUMENT_WIZARDS_CALL, documentWizardsCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_CALL, documentWizardCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_ADD_CALL, documentWizardAddCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_EDIT_CALL, documentWizardEditCallGenerator);
  yield takeEvery(DOCUMENT_WIZARD_DELETE_CALL, documentWizardDeleteCallGenerator);
  yield takeEvery(CREATE_DOCUMENT_FROM_WIZARD, createDocumentFromWizardGenerator);
  yield takeEvery(CHANGE_ITEM_FROM_WIZARD, changeItemFromWizard);
  yield takeEvery(ADD_ITEM_FROM_WIZARD, AddItemFromWizard);

  // yield takeEvery(DOCUMENT_WIZARD_RESTORE_CALL, documentWizardDeleteCallGenerator);

  yield takeLatest(DOCUMENT_WIZARD_DEFINITIONS_CALL_CONDITIONS, documentWizardDefinitionConditionsCallGenerator);
}
