import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";
import TableViewCreateEdit from "../table-view-create-edit";

function TableViewDialog({ isOpen, closePopup, viewData, isNew, moduleValue }) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="table-view-dialog-title"
      aria-describedby="table-view-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      {isNew ? (
        <TableViewCreateEdit isNew viewData={viewData} closePopup={closePopup} moduleValue={moduleValue} />
      ) : (
        viewData && <TableViewCreateEdit viewData={viewData} closePopup={closePopup} moduleValue={moduleValue} />
      )}
    </Dialog>
  );
}

export default TableViewDialog;

TableViewDialog.propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  viewData: PropTypes.shape({}),
  closePopup: PropTypes.func.isRequired,
  moduleValue: PropTypes.string.isRequired
};

TableViewDialog.defaultProps = {
  isOpen: false,
  isNew: false,
  viewData: undefined
};
