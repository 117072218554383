import Joi from "joi";
import joiDefaultErrorMessages from "../messages";

// US
export const unitedStatesStateSchema = Joi.string().max(50).messages(joiDefaultErrorMessages);

export const unitedStatesZipSchema = Joi.string()
  .regex(/^\d{5}(?:[-\s]\d{4})?$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.base": "Is a required field",
    "string.pattern.base": "Invalid zip code"
  });

// Canada
export const canadaProvinceSchema = Joi.string().max(50).messages(joiDefaultErrorMessages);

export const canadaPostalCodeSchema = Joi.string()
  .regex(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid postal code"
  });

export const stateByCountrySchema = Joi.when("country", {
  is: "US",
  then: unitedStatesStateSchema.empty(""),
  otherwise: Joi.when("country", {
    is: "CA",
    then: canadaProvinceSchema.empty("").allow(null),
    otherwise: Joi.optional().allow("", null)
  })
});

export const postalCodeByCountrySchema = Joi.when("country", {
  is: "US",
  then: unitedStatesZipSchema.empty("").allow(null),
  otherwise: Joi.when("country", {
    is: "CA",
    then: canadaPostalCodeSchema.empty("").allow(null),
    otherwise: Joi.optional().allow("", null)
  })
});
