export const NOTIFICATIONS_CALL = "NOTIFICATIONS_CALL";
export const NOTIFICATIONS_SEEN_CALL = "NOTIFICATIONS_SEEN_CALL";
export const NOTIFICATIONS_DELETE_CALL = "NOTIFICATIONS_DELETE_CALL";
export const NOTIFICATIONS_SEEN_ALL_CALL = "NOTIFICATIONS_SEEN_ALL_CALL";
export const NOTIFICATIONS_DELETE_ALL_CALL = "NOTIFICATIONS_DELETE_ALL_CALL";

export function callNotifications(page) {
  return { type: NOTIFICATIONS_CALL, payload: { page } };
}

export function callSeenNotification(notificationId, notificationReadStatus) {
  return { type: NOTIFICATIONS_SEEN_CALL, payload: { notificationId, notificationReadStatus } };
}

export function callDeleteNotification(notificationId, notificationReadStatus) {
  return { type: NOTIFICATIONS_DELETE_CALL, payload: { notificationId, notificationReadStatus } };
}

export function callSeenAllNotifications() {
  return { type: NOTIFICATIONS_SEEN_ALL_CALL };
}

export function callDeleteAllNotifications() {
  return { type: NOTIFICATIONS_DELETE_ALL_CALL };
}
