import {
  Add,
  DeleteOutline,
  Edit,
  Email,
  RotateLeft,
  Save,
  Search,
  Sms,
  Visibility,
  Print,
  Phone,
  PhoneIphone,
  ViewTimeline,
  List,
  MeetingRoom
} from "@mui/icons-material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { IconButton } from "@mui/material";
import CustomizedTooltip from "components/common/customized-tooltip";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

const HeaderActionButton = memo(({ title, onClick, Icon, disabled, size, ...buttonProps }) => {
  const ThisIcon = useMemo(() => {
    const t = title.toLowerCase();
    switch (true) {
      case t === "flow":
        return ViewTimeline;
      case t.includes("list"):
        return List;
      case t.includes("create"):
      case t.includes("add"): {
        return Add;
      }
      case t.includes("edit"): {
        return Edit;
      }
      case t.includes("save"): {
        return Save;
      }
      case t.includes("reset"): {
        return RotateLeft;
      }
      case t.includes("import"): {
        return UploadIcon;
      }
      case t.includes("export"): {
        return DownloadIcon;
      }
      case t.includes("delete"): {
        return DeleteOutline;
      }
      case t.includes("view"): {
        return Visibility;
      }
      case t.includes("email"): {
        return Email;
      }
      case t.includes("sms"): {
        return Sms;
      }
      case t.includes("search"): {
        return Search;
      }
      case t.includes("copy"):
      case t.includes("duplicate"): {
        return ContentCopyIcon;
      }
      case t.includes("print"): {
        return Print;
      }
      case t.includes("restore"): {
        return RestoreFromTrashIcon;
      }
      case t.includes("call office"): {
        return Phone;
      }
      case t.includes("call cell phone"): {
        return PhoneIphone;
      }
      case t.includes("access tenant"): {
        return MeetingRoom;
      }
      default: {
        return Icon;
      }
    }
  }, [title]);

  return (
    <CustomizedTooltip title={title} placement="left" arrow>
      <IconButton
        size={size}
        color="primary"
        aria-label={title}
        component="span"
        disabled={disabled}
        {...(onClick && { onClick })}
        {...(size && { size })}
        {...buttonProps}>
        {ThisIcon && <ThisIcon {...(size && { fontSize: size })} />}
      </IconButton>
    </CustomizedTooltip>
  );
});

HeaderActionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.shape({})]),
  disabled: PropTypes.bool
};

HeaderActionButton.defaultProps = {
  onClick: undefined,
  Icon: undefined,
  disabled: false,
  size: undefined
};

export default HeaderActionButton;
