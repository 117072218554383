import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "security",
  initialState: {
    isFetched: false,
    securityRoles: [],
    columnNames: [],
    isLoading: false
  },
  reducers: {
    onCallSecurityRoles: (state) => {
      state.isFetched = false;
      state.securityRoles = [];
      state.columnNames = [];
    },
    onSuccessSecurityRoles: (state, { payload }) => {
      state.isFetched = true;
      state.securityRoles = payload.securityRoles;
      // state.columnNames = payload.columnNames;
    },
    onFailedSecurityRoles: (state) => {
      state.isFetched = true;
      state.securityRoles = [];
      state.columnNames = [];
    },
    switchSecurityRoleLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    onSuccessSecurityRoleAdd: (state, { payload }) => {
      if (payload) {
        state.securityRoles = [...state.securityRoles, payload];
      }
    },
    onSuccessSecurityRolesEdit: () => {
      // if (payload) {
      //     state.securityRoles = payload;
      // }
    },
    onSuccessSecurityRoleDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.securityRoles = state.securityRoles.filter((s) => s.security_role_id !== payload);
      }
    }
  }
});

export const {
  onCallSecurityRoles,
  onSuccessSecurityRoles,
  onFailedSecurityRoles,
  switchSecurityRoleLoading,
  onSuccessSecurityRoleAdd,
  onSuccessSecurityRolesEdit,
  onSuccessSecurityRoleDelete
} = usersSlice.actions;

export default usersSlice.reducer;
