import React, { memo, Suspense } from "react";
import PropTypes from "prop-types";
import LoaderLinear from "components/common/loader-liner";

const LazyLoad = memo(({ children }) => <Suspense fallback={<LoaderLinear />}>{children}</Suspense>);

LazyLoad.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default LazyLoad;
