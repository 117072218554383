export const templateTypes = [
  {
    name: "Notification",
    code: 1
  },
  {
    name: "Email",
    code: 2
  },
  {
    name: "SMS",
    code: 3
  },
  {
    name: "Script",
    code: 4
  },
  {
    name: "Objection",
    code: 5
  },
  {
    name: 'Document',
    code: 6
  }
];

export const templateGroups = [
  {
    name: "Marketing",
    code: 2
  },
  {
    name: "Quote/Proposal",
    code: 3
  }
];
