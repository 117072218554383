import { delKeys, toCurrency } from "utils/helpers";
import _ from "lodash";

const calculateTotal = (salesTaxRate, productServiceTotalData, documentTotal) => {
  const taxableSubTotal = documentTotal?.taxableSubTotal || 0;
  const nonTaxableSubTotal = documentTotal?.nonTaxableSubTotal || 0;
  const adjustedTaxableSubTotal = documentTotal?.adjustedTaxableSubTotal || 0;
  const adjustedSubTotal = documentTotal?.adjustedSubTotal || 0;
  const nonAdjustedSubTotal = documentTotal?.nonAdjustedSubTotal || 0;
  const adjustedRecurringSubTotal = documentTotal?.adjustedRecurringSubTotal || 0;
  const adjustedFixedSubTotal = documentTotal?.adjustedFixedSubTotal || 0;
  const adjustedRecurringSubTotalReference = documentTotal?.adjustedRecurringSubTotalReference || 0;
  const adjustedNonTaxableSubTotal = documentTotal?.adjustedNonTaxableSubTotal || 0;

  const shipping = Number(productServiceTotalData?.shipping) || 0;
  const shippingTaxable = productServiceTotalData?.taxable ?? false;

  // Grand Total
  let tax;
  let grandTotal;
  if (adjustedSubTotal) {
    if (shippingTaxable) {
      const taxables = adjustedTaxableSubTotal + shipping;
      tax = (taxables * salesTaxRate || 0) / 100;
      grandTotal = adjustedNonTaxableSubTotal + nonTaxableSubTotal + taxables + shipping + tax; /* - discount(s) */
    } else {
      tax = (adjustedTaxableSubTotal * Number(salesTaxRate || 0)) / 100;
      grandTotal = adjustedSubTotal + nonAdjustedSubTotal + shipping + tax; /* - Discount(s) */
    }
  } else if (shippingTaxable) {
    const taxables = taxableSubTotal + shipping;
    tax = (taxables * Number(salesTaxRate)) / 100;
    grandTotal = nonTaxableSubTotal + taxables + tax; /* - discount(s) */
  } else {
    tax = (nonAdjustedSubTotal * Number(salesTaxRate)) / 100;
    grandTotal = taxableSubTotal + nonTaxableSubTotal + shipping + tax; /* - Discount(s) */
  }
  return {
    sub_total: toCurrency(nonAdjustedSubTotal),
    adjusted_subTotal: toCurrency(adjustedSubTotal),
    shipping: toCurrency(shipping),
    sales_tax: toCurrency(tax),
    adjustment_amount: toCurrency(adjustedSubTotal - nonAdjustedSubTotal),
    adjusted_recurring_subTotal: toCurrency(adjustedRecurringSubTotal),
    adjusted_fixed_subTotal: toCurrency(adjustedFixedSubTotal),
    adjusted_recurring_subTotal_reference: toCurrency(adjustedRecurringSubTotalReference),
    grand_total: toCurrency(grandTotal)
  };
};

export const calculateAdjustments = (productServices, adjustments) => {
  if (adjustments && adjustments.length) {
    let newProductServices = [...productServices];
    adjustments.map((ad) => {
      switch (ad.Source) {
        case "Miscellaneous": {
          newProductServices = newProductServices.map((pc_ref) => {
            const pc = _.cloneDeep(pc_ref);
            if (pc_ref.include_in_adjustment) {
              const key = ad.Destination === "Sell Price" ? "sell" : "cost";
              if (ad.adjustment_value_direction_id === 0) {
                if (pc[key] <= 0) {
                  pc[key] = 0;
                  return pc;
                }
                pc[key] = pc[key]
                  ? ad.Type === "Amount"
                    ? pc[key] - Number(ad.Value)
                    : pc[key] - (pc[key] * Number(ad.Value)) / 100
                  : ad.Type === "Amount"
                    ? pc[key] - Number(ad.Value)
                    : pc[key] - (pc[key] * Number(ad.Value)) / 100;
                return pc;
              }
              if (ad.adjustment_value_direction_id === 1) {
                if (pc[key] <= 0) {
                  pc[key] = 0;
                  return pc;
                }
                pc[key] = pc[key]
                  ? ad.Type === "Amount"
                    ? pc[key] + Number(ad.Value)
                    : pc[key] + (pc[key] * Number(ad.Value)) / 100
                  : ad.Type === "Amount"
                    ? pc[key] + Number(ad.Value)
                    : pc[key] + (pc[key] * Number(ad.Value)) / 100;
                return pc;
              }
            }
            return pc;
          });
          return newProductServices;
        }
        case "Location": {
          newProductServices = newProductServices.map((pc_ref) => {
            const pc = _.cloneDeep(pc_ref);
            if (pc_ref.include_in_adjustment) {
              if (pc.location === ad.Parameter) {
                const key = ad.Destination === "Sell Price" ? "sell" : "cost";
                if (ad.adjustment_value_direction_id === 0) {
                  if (pc[key] <= 0) {
                    pc[key] = 0;
                    return pc;
                  }
                  pc[key] = pc[key]
                    ? ad.Type === "Amount"
                      ? pc[key] - Number(ad.Value)
                      : pc[key] - (pc[key] * Number(ad.Value)) / 100
                    : ad.Type === "Amount"
                      ? pc[key] - Number(ad.Value)
                      : pc[key] - (pc[key] * Number(ad.Value)) / 100;
                  return pc;
                }
                if (ad.adjustment_value_direction_id === 1) {
                  if (pc[key] <= 0) {
                    pc[key] = 0;
                    return pc;
                  }
                  pc[key] = pc[key]
                    ? ad.Type === "Amount"
                      ? pc[key] + Number(ad.Value)
                      : pc[key] + (pc[key] * Number(ad.Value)) / 100
                    : ad.Type === "Amount"
                      ? pc[key] + Number(ad.Value)
                      : pc[key] + (pc[key] * Number(ad.Value)) / 100;
                  return pc;
                }
              }
              return pc;
            }
            return pc;
          });
          return newProductServices;
        }
        case "Manufacturer": {
          newProductServices = newProductServices.map((pc_ref) => {
            const pc = _.cloneDeep(pc_ref);
            if (pc_ref.include_in_adjustment) {
              if (pc.manufacturer_id === ad.parameter_id) {
                const key = ad.Destination === "Sell Price" ? "sell" : "cost";
                if (ad.adjustment_value_direction_id === 0) {
                  if (pc[key] <= 0) {
                    pc[key] = 0;
                    return pc;
                  }
                  pc[key] = pc[key]
                    ? ad.Type === "Amount"
                      ? pc[key] - Number(ad.Value)
                      : pc[key] - (pc[key] * Number(ad.Value)) / 100
                    : ad.Type === "Amount"
                      ? pc[key] - Number(ad.Value)
                      : pc[key] - (pc[key] * Number(ad.Value)) / 100;
                  return pc;
                }
                if (ad.adjustment_value_direction_id === 1) {
                  if (pc[key] <= 0) {
                    pc[key] = 0;
                    return pc;
                  }
                  pc[key] = pc[key]
                    ? ad.Type === "Amount"
                      ? pc[key] + Number(ad.Value)
                      : pc[key] + (pc[key] * Number(ad.Value)) / 100
                    : ad.Type === "Amount"
                      ? pc[key] + Number(ad.Value)
                      : pc[key] + (pc[key] * Number(ad.Value)) / 100;
                  return pc;
                }
              }
              return pc;
            }
            return pc;
          });
          return newProductServices;
        }
        default:
          return productServices;
      }
    });
    return newProductServices;
  }

  return productServices;
};

export const calculateAll = (
  {
    adjustments: { tableData: adjustments = [] } = {},
    cost,
    currency_id = 840,
    sales_tax_rate,
    shipping,
    shipping_tax,
    ...rest
  },
  prodService,
  ignore = []
) => {
  try {
    const ZERO = 0;
    prodService = prodService.map(
      ({
        cost,
        discount,
        sell,
        msrp,
        quantity,
        include_in_adjustment,
        taxable,
        recurrence_include_in_total,
        recurrence_option_id,
        optional,
        optional_is_selected,
        total,
        manufacturer_id
      }) => {
        const nums = [cost, discount, sell, total, quantity, msrp];
        [cost, discount, sell, total, quantity, msrp] = nums.map((num) => (!num ? ZERO : Number(num)));
        return {
          cost,
          discount,
          sell,
          msrp,
          quantity,
          total,
          include_in_adjustment: include_in_adjustment ?? true,
          taxable: taxable ?? true,
          recurrence_include_in_total: recurrence_include_in_total ?? true,
          recurrence_option_id: recurrence_option_id ?? 1,
          optional: optional ?? false,
          optional_is_selected: optional_is_selected ?? true,
          manufacturer_id
        };
      }
    );
    shipping = Number(shipping) ?? ZERO;
    shipping_tax = shipping_tax ?? true;
    const AdjustedProdServ = calculateAdjustments(prodService, adjustments);
    // SubTotal
    // OneTimeSubtotal
    // MonthlySubtotal
    // BiMonthlySubtotal
    // QuarterlySubtotal
    // SemiAnnualSubtotal
    // AnnualSubTotal
    const [
      SubTotal,
      OneTimeSubtotal,
      MonthlySubtotal,
      BiMonthlySubtotal,
      QuarterlySubtotal,
      SemiAnnualSubtotal,
      AnnualSubTotal,
      DocumentCost,
      MSRPTotal
    ] = AdjustedProdServ.reduce(
      (
        [
          SubTotal,
          OneTimeSubtotal,
          MonthlySubtotal,
          BiMonthlySubtotal,
          QuarterlySubtotal,
          SemiAnnualSubtotal,
          AnnualSubTotal,
          DocumentCost,
          MSRPTotal
        ],
        {
          recurrence_include_in_total,
          sell,
          msrp,
          quantity,
          discount,
          recurrence_option_id,
          optional,
          optional_is_selected,
          cost
        }
      ) => {
        const cond = !optional || (optional && optional_is_selected);
        // DocumentCost
        recurrence_include_in_total && cond && (DocumentCost += quantity * cost);
        // SubTotal
        recurrence_include_in_total && cond && (SubTotal += quantity * sell - discount);
        // MSRPTotal
        recurrence_include_in_total && cond && (MSRPTotal += quantity * msrp);
        // OneTimeSubtotal
        recurrence_option_id === 1 && cond && (OneTimeSubtotal += quantity * sell - discount);
        // MonthlySubtotal
        recurrence_option_id === 2 && cond && (MonthlySubtotal += quantity * sell - discount);
        // BiMonthlySubtotal
        recurrence_option_id === 3 && cond && (BiMonthlySubtotal += quantity * sell - discount);
        // QuarterlySubtotal
        recurrence_option_id === 4 && cond && (QuarterlySubtotal += quantity * sell - discount);
        // SemiAnnualSubtotal
        recurrence_option_id === 5 && cond && (SemiAnnualSubtotal += quantity * sell - discount);
        // AnnualSubTotal
        recurrence_option_id === 6 && cond && (AnnualSubTotal += quantity * sell - discount);
        return [
          SubTotal,
          OneTimeSubtotal,
          MonthlySubtotal,
          BiMonthlySubtotal,
          QuarterlySubtotal,
          SemiAnnualSubtotal,
          AnnualSubTotal,
          DocumentCost,
          MSRPTotal
        ];
      },
      [ZERO, ZERO, ZERO, ZERO, ZERO, ZERO, ZERO, ZERO, ZERO]
    );
    // PreAdjustedSubTotal
    const PreAdjustedSubTotal = prodService.reduce(
      (acc, { recurrence_include_in_total, sell, quantity, discount, optional, optional_is_selected }) => {
        recurrence_include_in_total &&
          (!optional || (optional && optional_is_selected)) &&
          (acc += quantity * sell - discount);
        return acc;
      },
      ZERO
    );
    // AdjustmentAmount
    const AdjustmentAmount = Math.abs(PreAdjustedSubTotal - SubTotal);
    // SalesTaxRate
    const SalesTaxRate = parseFloat(sales_tax_rate);
    // SalesTax
    const SalesTax =
      (AdjustedProdServ.reduce(
        (acc, { recurrence_include_in_total, sell, quantity, discount, taxable, optional, optional_is_selected }) => {
          recurrence_include_in_total &&
            (!optional || (optional && optional_is_selected)) &&
            taxable &&
            (acc += quantity * sell - discount);
          return acc;
        },
        ZERO
      ) +
        (shipping_tax ? shipping : 0)) *
      (SalesTaxRate / 100);

    // GrandTotal
    const GrandTotal = SubTotal + shipping + SalesTax;

    // Currency for external documents
    const currency = {};
    if ("$_symbol" in rest) {
      const $ = "$_";
      Object.keys(rest).forEach((key) => {
        key.startsWith($) && (currency[key.replace($, "")] = rest[key]);
      });
    }
    // DO not change return values
    const obj = {
      pre_adjusted_subtotal: PreAdjustedSubTotal,
      sub_total: SubTotal,
      one_time_subtotal: OneTimeSubtotal,
      monthly_subtotal: MonthlySubtotal,
      bi_monthly_subtotal: BiMonthlySubtotal,
      quarterly_subtotal: QuarterlySubtotal,
      semi_annual_subtotal: SemiAnnualSubtotal,
      annual_subtotal: AnnualSubTotal,
      adjustment_amount: AdjustmentAmount,
      sales_tax: SalesTax,
      sales_tax_rate: SalesTaxRate,
      grand_total: GrandTotal,
      document_cost: DocumentCost,
      shipping,
      currency_id,
      currency,
      msrp: MSRPTotal
    };
    delKeys(obj, ignore);
    return obj;
  } catch (e) {
    console.log(`Calculation Issue: ${e.message}`);
  }
};

export default calculateTotal;
