import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useDidUpdate, useForm } from "hooks";
import { errorSelectorById } from "store/error/selector";
import LoaderLinear  from "components/common/loader-liner";
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import {
  callAddCustomTableView,
  callEditCustomTableView,
  CUSTOM_TABLE_VIEW_ADD_CALL,
  CUSTOM_TABLE_VIEW_EDIT_CALL
} from "store/table-view/sagas-actions";
import { customTableViewDataSelector } from "store/table-view/selectors";
import validationSchema from "./validationSchema";

function TableViewCreateEdit({ closePopup, viewData, isNew, moduleValue }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading } = useSelector((store) => customTableViewDataSelector(store, moduleValue), isEqual);
  const resError = useSelector(
    (store) => errorSelectorById(store, isNew ? CUSTOM_TABLE_VIEW_ADD_CALL : CUSTOM_TABLE_VIEW_EDIT_CALL),
    isEqual
  );

  const defaultValues = useMemo(() => {
    let obj;

    if (isNew) {
      obj = {
        name: "",
        shared: false
      };
    } else {
      obj = {
        name: viewData.name,
        shared: viewData.shared
      };
    }

    return obj;
  }, [isNew, viewData]);

  const { FormField, handleSubmit } = useForm({
    validationSchema,
    defaultValues,
    isLoading
  });

  function formSubmitHandler(data) {
    const finalData = {
      ...data,
      data: { ...viewData.data, columns: viewData.data.columns.map((item) => ({ ...item, visible: true })) }
    };

    if (finalData?.children && finalData.children.length > 0) {
      finalData.rate = 0;
    }

    if (isNew) {
      dispatch(callAddCustomTableView(moduleValue, finalData, success));
    } else {
      dispatch(callEditCustomTableView(moduleValue, viewData.table_view_id, finalData, null, success));
    }
    closePopup();
  }

  function success() {
    enqueueSnackbar(isNew ? "View saved." : "View updated.", {
      variant: "success"
    });
  }

  return (
    <>
      <DialogTitle id="table-view-dialog-title">{isNew ? "Create View" : "Edit View"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit((d) => formSubmitHandler(d))}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {FormField({ name: "name" })}
            </Grid>
            <Grid item xs={4}>
              {FormField({
                name: "shared",
                label: "Shared",
                type: "checkbox"
              })}
            </Grid>
          </Grid>

          <DialogActions>
            <Button type="submit" disabled={isLoading} autoFocus>
              Save
            </Button>
            <Button onClick={closePopup} type="button" disabled={isLoading}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      {isLoading && <LoaderLinear />}
    </>
  );
}

export default TableViewCreateEdit;

TableViewCreateEdit.propTypes = {
  isNew: PropTypes.bool,
  viewData: PropTypes.shape({}),
  closePopup: PropTypes.func.isRequired,
  moduleValue: PropTypes.string.isRequired
};

TableViewCreateEdit.defaultProps = {
  isNew: false,
  viewData: undefined
};
