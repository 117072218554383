import React, { createContext, useState } from "react";

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [reset, setReset] = useState(false);
  const [alreadyConnected, setAlreadyConnected] = useState({});
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <SocketContext.Provider value={{ reset, setReset, alreadyConnected, setAlreadyConnected }}>{children}</SocketContext.Provider>;
};
