import { useCallback, useMemo } from "react";
import { customFieldTypes } from "constant";
import { getObjectsDifferences } from "utils/helpers";

const useGridTileSizes = ({ customFieldsList } = {}) => {
  const customRowsHeight = useMemo(() => {
    if (customFieldsList) {
      let rowsHeight = 1;
      customFieldsList.forEach((f) => {
        // eslint-disable-next-line eqeqeq
        if (f.type_id == customFieldTypes.find((cFT) => cFT.name === "Note").code) {
          rowsHeight += 2;
        } else {
          rowsHeight += 1;
        }
      });
      return rowsHeight;
    }
    return null;
  }, [customFieldsList]);

  const mergeViews = useCallback(
    (view, defaults) => {
      // If new user or new item added then reset the view look to defaults
      if (!Array.isArray(view) || defaults.length > view.length) {
        return [...defaults];
      }
      // TODO ??? fix bug about drag in grid
      return defaults.map((tileDefaultProps, index) => {
        if (view && view[index]) {
          const { x: defaultX, y: defaultY, ...restTileDefaultProps } = tileDefaultProps;
          const { x, y, ...restTileProps } = view[index];

          const diffKeys = getObjectsDifferences(restTileProps, restTileDefaultProps);

          const diffProps = diffKeys.reduce(
            (o, keyName) => ({
              ...o,
              [keyName]: tileDefaultProps[keyName]
            }),
            {}
          );

          return {
            ...view[index],
            ...diffProps
          };

          // return { ...tileDefaultProps };
        }
        return { ...tileDefaultProps };
      });
    },
    [customFieldsList]
  );

  return {
    ...(customFieldsList && { customRowsHeight }),
    mergeViews
  };
};

export default useGridTileSizes;
