import { createPromiseAction } from "@adobe/redux-saga-promise";

export const TASKS_CALL = "TASKS_CALL";
export const TASKS_CALL_PROMISE = "TASKS_CALL_PROMISE";
export const TASK_CALL = "TASK_CALL";
export const TASK_ADD_CALL = "TASK_ADD_CALL";
export const TASK_ADD_CALL_PROMISE = "TASK_ADD_CALL_PROMISE";
export const TASK_EDIT_CALL = "TASK_EDIT_CALL";
export const TASKS_EDIT_CALL = "TASKS_EDIT_CALL";
export const TASK_DELETE_CALL = "TASK_DELETE_CALL";
export const TASK_RESTORE_CALL = "TASK_RESTORE_CALL";
export const TASK_CALL_PRIORITIES = "TASK_CALL_PRIORITIES";
export const TASK_CALL_TYPES = "TASK_CALL_TYPES";
export const TASK_CALL_TAGS = "TASK_CALL_TAGS";
export const TASK_CALL_STATUSES = "TASK_CALL_STATUSES";

export const callTasksPromise = createPromiseAction(TASKS_CALL_PROMISE);
export const callTaskAddPromise = createPromiseAction(TASK_ADD_CALL_PROMISE);

export function callTasks(paginationData, tenantUserId, withoutLoading) {
  return { type: TASKS_CALL, payload: { paginationData, tenantUserId, withoutLoading } };
}

export function callTask(taskId) {
  return { type: TASK_CALL, payload: { taskId } };
}

export function callAddTask(taskData, messageFunction) {
  return { type: TASK_ADD_CALL, payload: { taskData, messageFunction } };
}

export function callEditTask(taskId, taskData, messageFunction) {
  return { type: TASK_EDIT_CALL, payload: { taskId, taskData, messageFunction } };
}

export function callEditTasks(taskData) {
  return { type: TASKS_EDIT_CALL, payload: { taskData } };
}

export function callDeleteTasks(taskIds, isSingle) {
  return { type: TASK_DELETE_CALL, payload: { taskIds, isSingle } };
}

export function callTaskAttributes(type) {
  return { type };
}
