import axios, { CancelToken } from "axios";
import { BASE_URL } from "constant/env-variables";
import store from "store";
import { resetUserData } from "store/user/slice";
import { setError } from "store/error/slice";
import { generateSubdomainBaseUrl } from "utils/helpers";
import { CANCEL } from "redux-saga";
import { noSubdomainApis } from "constant";

const axiosInstance = axios.create({
  baseURL: `${generateSubdomainBaseUrl(BASE_URL)}/api`,
  timeout: 50000,
  headers: {
    Authorization: (() => {
      const token = localStorage.getItem("userToken");
      return token ? `Bearer ${token}` : "";
    })()
  }
});

// REQUEST
axiosInstance.interceptors.request.use(
  (request) => {
    // eslint-disable-next-line no-param-reassign
    request.baseURL = `${generateSubdomainBaseUrl(BASE_URL, noSubdomainApis, request.url)}/api`;

    const token = localStorage.getItem("userToken");
    const tabId = sessionStorage.getItem("tabId");

    if (request.method === "put" || request.method === "post" || request.method === "patch") {
      request.headers["x-tab-id"] = tabId;
    }

    if (token) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

// RESPONSE
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error) && error?.response) {
      // Unauthorized api call
      if (error.response.status === 401) {
        store.dispatch(resetUserData());
      }
    }

    // Server is down TODO -> add value to check if request is not redux/saga so no need to add redux errors
    if (!error.response || error?.response?.status === 502) {
      store.dispatch(
        setError({
          error,
          action: {
            type: "SERVER_DOWN"
          }
        })
      );
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

/**
 * @description Axios request generic func
 * @param {string} method
 * @param {string} url
 * @param {any} data
 * @param {any} options
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export function axiosRequest(method, url, data = {}, options = {}) {
  const httpMethod = method.toLowerCase();

  const hasData = ["post", "put", "patch"].indexOf(httpMethod) >= 0;
  const settings = hasData ? options : data;

  const source = CancelToken.source();
  settings.cancelToken = source.token;

  const request = hasData ? axiosInstance[httpMethod](url, data, settings) : axiosInstance[httpMethod](url, settings);

  return request;
}

export function axiosSagaRequest(method, url, data = {}, options = {}) {
  const httpMethod = method.toLowerCase();

  const hasData = ["post", "put", "patch"].indexOf(httpMethod) >= 0;
  const settings = hasData ? options : data;

  const source = CancelToken.source();
  settings.cancelToken = source.token;

  const request = hasData ? axiosInstance[httpMethod](url, data, settings) : axiosInstance[httpMethod](url, settings);

  request[CANCEL] = () => source.cancel();

  return request;
}
