const noSubdomainApis = [
  "/dynamicContent/",
  "/getPlans",
  "/getIndustries",
  "/getCurrencyTypes",
  "/timezone",
  "/mfaCallback",
  // "/getResetPasswordCode",
  "/resetPassword",
  "/emailCheck",
  "/subdomainCheck",
  "/promoCodeCheck"
];

export default noSubdomainApis;
