import { createSelector } from "@reduxjs/toolkit";

export const templatesDataSelector = createSelector(
  (state) => state.templatesData,
  (a) => a
);

export const templatesCountSelector = createSelector(
  (state) => state.templatesData.templatesCount,
  (a) => a
);

export const templateSelector = createSelector(
  (state) => state.templatesData.template,
  (a) => a
);

export const templatesGroupsSelector = createSelector(
  (state) => state.templatesData.templateGroups,
  (a) => a
);

export const filteredTemplatesSelector = createSelector(
  (state) => state.templatesData.filteredTemplates,
  (a) => a
);



export const templateMergeFieldsSelector = createSelector(
  (state) => state.templatesData.mergeFields,
  (a) => a
);

export const templateByTypeSelector = createSelector(
  (state) => state.templatesData.templateByType,
  (a) => a
);

export const templateMergeFieldItemsSelector = createSelector(
  (state) => state.templatesData.mergeFieldItems,
  (a) => a
);

export const templateForContactSelector = createSelector(
  (state) => state.templatesData.templateForContact,
  (a) => a
);

export const ccBcctemplateSelector = createSelector(
  (state) => state.templatesData?.ccBcc,
  (a) => a
);
