import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";
import { cloneDeep } from "lodash";

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    isFetched: false,
    isLoading: false,
    contacts: [],
    columnNames: [],
    allContacts: [],
    fetchedPages: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: true,
      groupBy: [],
      filterValue: []
    },
    cardLabels: {},
    cardStructure: {},

    // single contact
    contact: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },

    contactNotes: {
      isFetched: false,
      data: {},
      columnNames: []
    },

    contactTasks: {
      isLoading: false,
      isFetched: false,
      data: {},
      columnNames: []
    },

    contactSequences: {
      isFetched: false,
      data: [],
      columnNames: []
    },

    contactApolloData: {
      isFetched: false,
      isLoading: false,
      data: [],
      error: undefined
    },

    contactOCRImageData: {
      isFetched: false,
      isLoading: false,
      data: [],
      error: undefined
    },

    orgRep: {
      isFetched: false,
      data: {},
      columnNames: [],
      isLoading: false
    },

    status: {
      isFetched: false,
      list: []
    },
    type: {
      isFetched: false,
      list: []
      // isLoading: false
    },
    group: {
      isFetched: false,
      list: []
    },
    sequence: {
      isFetched: false,
      list: [],
      isLoading: false
    },
    customField: {
      isFetched: false,
      list: []
    },
    socialMedias: {
      isFetched: false,
      list: []
    },
    contactDraft: {},

    emailValidation: {
      isFetched: false,
      message: "",
      isValid: undefined
    },

    contactDocuments: {
      isFetched: false,
      data: {},
      columnNames: []
    },

    contactDuplicates: {
      data: [],
      columnNames: [],
      contactData: {}
    }
  },
  reducers: {
    onCallContacts: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.isLoading = true;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.contacts = [];
    },
    onSuccessContacts: (state, { payload }) => {
      state.isFetched = true;
      state.isLoading = false;
      state.count = parseInt(payload?.total_count, 10) || 0;

      const currentPage = state?.pagination?.skip / state?.pagination.limit + 1;
      const currentPages = cloneDeep(state.fetchedPages);

      state.fetchedPages = [...currentPages, currentPage];
      state.allContacts = state.allContacts.concat(payload.tableData);
      if (payload.uiViewType === "card") {
        state.contacts = payload.shouldClear ? payload.tableData : state.contacts.concat(payload.tableData);
        state.pagination.skip = payload.shouldClear
          ? payload.paginationData.limit
          : payload.paginationData.skip + payload.paginationData.limit;
        state.cardLabels = payload.structure.card_label;
        state.cardStructure = payload.structure.card_structure;
      } else {
        state.columnNames = payload.columnNames;
        state.contacts = payload.tableData;
      }
    },
    onFailedContacts: (state) => {
      state.isFetched = true;
      state.isLoading = false;
      state.contacts = [];
      state.columnNames = [];
      state.count = 0;
    },

    // contact notes
    onCallContactNotes: (state, { payload }) => {
      state.contactNotes.isLoading = true;
      if (!payload) {
        state.contactNotes.isFetched = false;
      }
      state.contactNotes.columnNames = [];
      state.contactNotes.data = [];
    },
    onSuccessContactNotes: (state, { payload }) => {
      state.contactNotes.isFetched = true;
      state.contactNotes.data = payload.tableData;
      state.contactNotes.columnNames = payload.columnNames;
      state.contactNotes.isLoading = false;
    },
    onAddNewNote: (state, { payload }) => {
      const currentNotes = cloneDeep(state.contactNotes.data);
      state.contactNotes.data = [...payload, ...currentNotes];
    },
    onFailedContactNotes: (state) => {
      state.contactNotes.isFetched = true;
      state.contactNotes.data = [];
      state.contactNotes.columnNames = [];
      state.contactNotes.isLoading = false;
    },
    onResetContactNotes: (state) => {
      state.contactNotes.isFetched = false;
      state.contactNotes.data = [];
      state.contactNotes.columnNames = [];
      state.contactNotes.isLoading = false;
    },

    // org reps

    onCallOrgReps: (state, { payload }) => {
      if (!payload) {
        state.orgRep.isFetched = false;
      }
      state.orgRep.columnNames = [];
      state.orgRep.data = [];
      state.orgRep.isLoading = true;
    },
    onSuccessOrgReps: (state, { payload }) => {
      state.orgRep.isFetched = true;
      state.orgRep.data = payload.tableData;
      state.orgRep.columnNames = payload.columnNames;
      state.orgRep.isLoading = false;
    },
    onFailedOrgReps: (state) => {
      state.orgRep.isFetched = true;
      state.orgRep.data = [];
      state.orgRep.columnNames = [];
      state.orgRep.isLoading = false;
    },

    // contact tasks
    onCallContactTasks: (state, payload) => {
      state.contactTasks.isLoading = true;
      if (!payload) {
        state.contactTasks.isFetched = false;
      }
      state.contactTasks.columnNames = [];
      state.contactTasks.data = [];
    },
    onSuccessContactTasks: (state, { payload }) => {
      state.contactTasks.isLoading = false;
      state.contactTasks.isFetched = true;
      state.contactTasks.data = payload.tableData;
      state.contactTasks.columnNames = payload.columnNames;
    },
    onFailedContactTasks: (state) => {
      state.contactTasks.isFetched = true;
      state.contactTasks.isLoading = false;
      state.contactTasks.data = [];
      state.contactTasks.columnNames = [];
    },
    onResetContactTasks: (state) => {
      state.contactTasks.isFetched = false;
      state.contactTasks.isLoading = false;
      state.contactTasks.data = [];
      state.contactTasks.columnNames = [];
    },

    // contact sequences
    onCallContactSequencesList: (state) => {
      state.contactSequences.isFetched = false;
      state.contactSequences.columnNames = [];
      state.contactSequences.data = [];
    },
    onSuccessContactSequencesList: (state, { payload }) => {
      state.contactSequences.isFetched = true;
      state.contactSequences.data = payload.tableData;
      state.contactSequences.columnNames = payload.columnNames;
    },
    onFailedContactSequencesList: (state) => {
      state.contactSequences.isFetched = true;
      state.contactSequences.data = [];
      state.contactSequences.columnNames = [];
    },
    onChangeContactSequencesList: (state, { payload }) => {
      if (payload) {
        if (payload.sequences_to_add.length) {
          const sequencesToAdd = state.sequence.list.reduce((result, seq) => {
            if (payload.sequences_to_add.includes(seq.code)) {
              result.push({
                Name: seq.name,
                sequence_id: seq.code
              });
            }
            return result;
          }, []);
          state.contactSequences.data = state.contactSequences.data.concat(sequencesToAdd);
        }
        if (payload.sequences_to_remove.length) {
          const sequencesToRemoveSet = new Set(payload.sequences_to_remove);
          state.contactSequences.data = state.contactSequences.data.filter(
            (seq) => !sequencesToRemoveSet.has(seq.sequence_id)
          );
        }
      }
    },

    // contact apollo data
    onCallContactApolloData: (state, { payload }) => {
      state.contactApolloData.isLoading = payload !== undefined ? payload : !state.contactApolloData.isLoading;
    },
    onSuccessContactApolloData: (state, { payload }) => {
      state.contactApolloData.isLoading = false;
      state.contactApolloData.isFetched = true;
      state.contactApolloData.data = payload;
      state.contactApolloData.error = undefined;
    },
    resetContactApolloData: (state) => {
      state.contactApolloData.isLoading = false;
      state.contactApolloData.isFetched = false;
      state.contactApolloData.data = [];
      state.contactApolloData.error = undefined;
    },
    onFailedContactApolloData: (state, { payload }) => {
      state.contactApolloData.isFetched = true;
      state.contactApolloData.isLoading = false;
      state.contactApolloData.data = [];
      state.contactApolloData.error = payload || "";
    },

    // ocr image
    onCallContactOCRImageData: (state, { payload }) => {
      state.contactOCRImageData.isLoading = payload !== undefined ? payload : !state.contactOCRImageData.isLoading;
    },
    onSuccessContactOCRImageData: (state, { payload }) => {
      state.contactOCRImageData.isLoading = false;
      state.contactOCRImageData.isFetched = true;
      state.contactOCRImageData.data = payload;
      state.contactOCRImageData.error = undefined;
    },
    resetContactOCRImageData: (state) => {
      state.contactOCRImageData.isLoading = false;
      state.contactOCRImageData.isFetched = false;
      state.contactOCRImageData.data = [];
      state.contactOCRImageData.error = undefined;
    },
    onFailedContactOCRImageData: (state, { payload }) => {
      state.contactOCRImageData.isFetched = true;
      state.contactOCRImageData.isLoading = false;
      state.contactOCRImageData.data = [];
      state.contactOCRImageData.error = payload || "";
    },

    // single contact
    switchContactLoading: (state, { payload }) => {
      state.contact.isLoading = payload !== undefined ? payload : !state.contact.isLoading;
    },

    onSuccessContact: (state, { payload }) => {
      state.contact.isFetched = true;
      state.contact.data = {
        ...payload,
        ...(payload?.photo && {
          photo: payload.photo.includes(AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL)
            ? payload.photo
            : `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/contactPhoto/${
              payload.contact_id
            }/${payload.photo}`
        })
      };
    },
    onFailedContact: (state) => {
      state.contact.isFetched = true;
      state.contact.data = undefined;
    },
    resetContact: (state) => {
      state.contact.isFetched = false;
      state.contact.data = undefined;
    },

    onSuccessContactAdd: (state, { payload }) => {
      if (state.isFetched && payload?.contactData) {
        state.contacts = [payload.contactData, ...state.contacts];
        state.count += 1;
      }
    },
    onSuccessContactEdit: (state, { payload }) => {
      if (payload) {
        state.contacts = state.contacts.map((contact) =>
          contact.contact_id === payload.contact_id ? payload : contact
        );
        state.contact.isFetched = true;
        state.contact.data = {
          ...state.contact.data,
          ...payload,
          ...(payload?.photo && {
            photo: payload.photo.includes(AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL)
              ? payload.photo
              : `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/contactPhoto/${
                payload.contact_id
              }/${payload.photo}`
          })
        };
      }
    },
    onSuccessContactsDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.contacts = state.contacts.filter((contact) => !payload.includes(contact.contact_id));
      }
    },

    onCallContactAttributes: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].isLoading = false;
      state[payload.key].list = [];
    },
    onCallContactSequences: (state) => {
      state.sequence.isFetched = false;
      state.sequence.isLoading = false;
      state.sequence.list = [];
    },
    onSuccessContactSequences: (state, { payload }) => {
      state.sequence.isFetched = true;
      state.sequence.list = payload.sequence_automations.map((item) => ({
        code: item.sequence_id,
        name: item.name,
        status_id: item.status_id
      }));
    },
    onFailedContactSequences: (state) => {
      state.sequence.isFetched = true;
      state.sequence.list = [];
    },
    onSuccessContactAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;

      if (payload.type === "edit") {
        const currentList = cloneDeep(state[payload.key].list);
        if (!currentList.length) {
          state[payload.key].isFetched = false;
        }
        state[payload.key].list = currentList.map((item) => {
          if (payload.data[payload.idKey] === item.code) {
            return {
              code: payload.data[payload.idKey],
              name: payload.data.Group,
              isDefault: payload.data.Default
            };
          }
          return item;
        });
      }
      if (payload.type === "add") {
        const currentList = cloneDeep(state[payload.key].list);
        state[payload.key].list = [
          ...currentList,
          {
            code: payload.data[payload.idKey],
            name: payload.data.Group,
            isDefault: payload.data.Default
          }
        ];
      }

      if (payload.key === "orgRep") {
        state[payload.key].list = payload.data.tableData.map((item) => ({
          code: item.tenant_user_id,
          name: `${item["First Name"]} ${item["Last Name"]}`,
          isDefault: item.default_value ?? false
        }));
        return;
      }

      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );

        state[payload.key].list = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.default_value
        }));
      }
    },
    onFailedContactAttributes: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },
    onSuccessContactSubscriptionChange: (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data.value)) {
        state.contacts = state.contacts.map(
          (c) => payload.data.value.find((newC) => newC.contact_id === c.contact_id) || c
        );
      }
    },

    onSuccessContactAttributeAdd: (state, { payload }) => {
      const codeFieldName = Object.keys(payload.data).find((k) => k.includes("_id"));
      const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));

      const currentList = cloneDeep(state[payload.key].list);
      state[payload.key].list = [
        ...currentList,
        {
          code: payload.data[codeFieldName],
          name: payload.data[nameFieldName],
          isDefault: payload.data.Default
        }
      ];
    },

    // attribute loading
    switchContactsAttributeChangeLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },
    switchContactsSequenceChangeLoading: (state) => {
      state.sequence.isLoading = !state.sequence.isLoading;
    },
    onSuccessContactAttributeChange: (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        const currentContacts = cloneDeep(state.contacts);
        const currentAllContacts = cloneDeep(state.allContacts);

        state.contacts = currentContacts.reduce((acc, current) => {
          const foundItem = payload.data.find((item) => item.contact_id === current.contact_id);
          if (foundItem) {
            acc.push(foundItem);
            return acc;
          }
          acc.push(current);
          return acc;
        }, []);

        state.allContacts = currentAllContacts.reduce((acc, current) => {
          const foundItem = payload.data.find((item) => item.contact_id === current.contact_id);
          if (foundItem) {
            acc.push(foundItem);
            return acc;
          }
          acc.push(current);
          return acc;
        }, []);
      }
    },

    onCallContactSocialMedias: (state) => {
      state.socialMedias.isFetched = false;
      state.socialMedias.list = [];
    },
    onSuccessContactSocialMedias: (state, { payload }) => {
      state.socialMedias.isFetched = true;

      if (payload?.social_media_items) {
        state.socialMedias.list = payload?.social_media_items
          .filter((item) => item.visible)
          .map((item) => ({
            social_media_id: item.social_media_id,
            name: item.social_media
          }));
      }
    },
    onFailedContactSocialMedias: (state) => {
      state.socialMedias.isFetched = true;
      state.socialMedias.list = [];
    },

    onCallContactCustomFields: (state) => {
      state.customField.isFetched = false;
      state.customField.list = [];
    },
    onSuccessContactCustomFields: (state, { payload }) => {
      state.customField.isFetched = true;

      if (payload?.custom_fields) {
        state.customField.list = payload.custom_fields
          .filter((item) => item.visible && !Number.isInteger(item.custom_field_definition_parent_id))
          .map((item) => {
            if (item.list) {
              const children = item.list_items.map((c) => ({
                ...c,
                default_value: payload.custom_fields.find(
                  (i) => i.custom_field_definition_id === c.custom_field_definition_id
                )?.default_value
              }));
              return {
                ...item,
                list_items: children
              };
            }

            return item;
          });
      }
    },
    onReorderMainContactCustomFields: (state, { payload }) => {
      if (state.customField.list.length > 0) {
        state.customField.list = state.customField?.list.map((item) => {
          const findCustomField = payload.data?.find(
            (cf) => cf?.custom_field_definition_id === item?.custom_field_definition_id
          );
          if (findCustomField) {
            return {
              ...item,
              position: findCustomField.Position
            };
          }
          return item;
        });
        state.customField.list = [...state.customField.list].sort((a, b) => (a.position > b.position ? 1 : -1));
      }
    },
    onFailedContactCustomFields: (state) => {
      state.customField.isFetched = true;
      state.customField.list = [];
    },
    onContactSave: (state, { payload }) => {
      state.contactDraft = payload;
    },
    resetContactDraft: (state) => {
      state.contactDraft = {};
    },
    onSuccessContactEmailValidation: (state, { payload }) => {
      state.emailValidation.isFetched = true;
      state.emailValidation.message = payload;
      state.emailValidation.isValid = true;
    },
    onFailedContactEmailValidation: (state, { payload }) => {
      state.emailValidation.isFetched = true;
      state.emailValidation.message = payload;
      state.emailValidation.isValid = false;
    },
    resetContactEmailValidation: (state) => {
      state.emailValidation.isFetched = false;
      state.emailValidation.message = "";
      state.emailValidation.isValid = undefined;
    },

    onCallContactDocuments: (state) => {
      state.contactDocuments.isFetched = false;
      state.contactDocuments.columnNames = [];
      state.contactDocuments.data = [];
    },
    onSuccessContactDocuments: (state, { payload }) => {
      state.contactDocuments.isFetched = true;
      if (payload && payload?.document_list && payload?.columnNames) {
        state.contactDocuments.data = payload.document_list;
        state.contactDocuments.columnNames = payload.columnNames.filter((cN) => cN.column_name !== "document_id");
      }
    },
    onFailedContactDocuments: (state) => {
      state.contactDocuments.isFetched = true;
      state.contactDocuments.data = [];
      state.contactDocuments.columnNames = [];
    },

    onSetContactDuplicates: (state, { payload }) => {
      state.contactDuplicates.data = payload.duplications.tableData;
      state.contactDuplicates.columnNames = payload.duplications.columnNames;
      state.contactDuplicates.contactData = payload.contactData;
    },
    resetContactDuplicates: (state) => {
      state.contactDuplicates.data = [];
      state.contactDuplicates.columnNames = [];
      state.contactDuplicates.contactData = {};
    }
  }
});

export const {
  onCallContacts,
  onSuccessContacts,
  onFailedContacts,
  switchContactLoading,
  onReorderMainContactCustomFields,

  onAddNewNote,

  onSuccessContact,
  onFailedContact,
  resetContact,

  onCallContactNotes,
  onSuccessContactNotes,
  onFailedContactNotes,
  onResetContactNotes,

  onCallContactTasks,
  onSuccessContactTasks,
  onFailedContactTasks,
  onResetContactTasks,

  onCallContactSequences,
  onSuccessContactSequences,
  onFailedContactSequences,
  switchContactsSequenceChangeLoading,

  onCallContactSequencesList,
  onSuccessContactSequencesList,
  onFailedContactSequencesList,
  onChangeContactSequencesList,

  onCallContactApolloData,
  onSuccessContactApolloData,
  resetContactApolloData,
  onFailedContactApolloData,

  onCallContactOCRImageData,
  onSuccessContactOCRImageData,
  onFailedContactOCRImageData,
  resetContactOCRImageData,

  onSuccessContactAdd,
  onSuccessContactEdit,
  onSuccessContactsDelete,

  onCallContactAttributes,
  onSuccessContactAttributes,
  onFailedContactAttributes,
  onSuccessContactAttributeAdd,
  switchContactsAttributeChangeLoading,
  onSuccessContactAttributeChange,
  onSuccessContactSubscriptionChange,

  onCallContactSocialMedias,
  onSuccessContactSocialMedias,
  onFailedContactSocialMedias,
  onCallContactCustomFields,
  onSuccessContactCustomFields,
  onFailedContactCustomFields,

  onContactSave,
  resetContactDraft,

  onSuccessContactEmailValidation,
  onFailedContactEmailValidation,
  resetContactEmailValidation,

  onCallContactDocuments,
  onSuccessContactDocuments,
  onFailedContactDocuments,

  onSetContactDuplicates,
  resetContactDuplicates
} = contactsSlice.actions;

export default contactsSlice.reducer;
