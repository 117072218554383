import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  SECURITY_ROLE_ADD_CALL,
  SECURITY_ROLES_EDIT_CALL,
  SECURITY_ROLES_CALL,
  SECURITY_ROLE_DELETE_CALL
} from "./sagas-actions";
import {
  onCallSecurityRoles,
  onSuccessSecurityRoles,
  onFailedSecurityRoles,
  switchSecurityRoleLoading,
  onSuccessSecurityRoleAdd,
  onSuccessSecurityRolesEdit,
  onSuccessSecurityRoleDelete
} from "./slice";

function* securityRolesCallGenerator(action) {
  yield put(onCallSecurityRoles());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/securityRoles", {
      params: {
        type: "all"
      }
    });

    yield put(onSuccessSecurityRoles(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSecurityRoles());
  }
}

function* securityRolesEditCallGenerator(action) {
  yield put(switchSecurityRoleLoading(true));
  try {
    const {
      payload: { securityRolesData }
    } = action;
    const { data } = yield call(axiosInstance.put, "/tenantUser/securityRole", securityRolesData);

    yield put(onSuccessSecurityRolesEdit(data.updatedSecurityRoles));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSecurityRoleLoading(false));
}

function* securityRoleAddCallGenerator(action) {
  yield put(switchSecurityRoleLoading(true));
  try {
    const {
      payload: { roleData }
    } = action;
    const { data } = yield call(axiosInstance.post, "/tenantUser/securityRole", roleData);

    yield put(onSuccessSecurityRoleAdd(data[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSecurityRoleLoading(false));
}

function* securityRoleDeleteCallGenerator(action) {
  yield put(switchSecurityRoleLoading(true));
  try {
    const {
      payload: { securityRoleId }
    } = action;
    yield call(axiosInstance.delete, `/tenantUser/securityRole/${securityRoleId}`);

    yield put(onSuccessSecurityRoleDelete(securityRoleId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSecurityRoleLoading(false));
}

export default function* securitySaga() {
  yield takeEvery(SECURITY_ROLES_CALL, securityRolesCallGenerator);
  yield takeEvery(SECURITY_ROLES_EDIT_CALL, securityRolesEditCallGenerator);
  yield takeEvery(SECURITY_ROLE_ADD_CALL, securityRoleAddCallGenerator);
  yield takeEvery(SECURITY_ROLE_DELETE_CALL, securityRoleDeleteCallGenerator);
}
