import React from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import useDeviceType from "../../../../hooks/common/useDeviceType";

const useStyles = makeStyles((theme) => ({
  pickerWrapper: {
    "& > .sketch-picker": {
      boxShadow: "none!important"
    }
  }
}));

function ColorPickDialog({ color, closePopup, isOpen, handleChange }) {
  const classes = useStyles();
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      aria-labelledby="color-pick-dialog-title"
      aria-describedby="color-pick-dialog-description"
      maxWidth="sm">
      <DialogTitle id="color-pick-dialog-title">Pick a color</DialogTitle>
      <DialogContent className={classes.pickerWrapper}>
        <SketchPicker
          width={isMobile ? 290 : 320}
          color={color || false}
          value={color}
          presetColors={[]}
          disableAlpha
          onChange={handleChange}
        />
        <Button sx={{ mt: "10px" }} onClick={() => handleChange({ hex: "TRANSPARENT" })}>
          Transparent
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup} type="button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ColorPickDialog;

ColorPickDialog.propTypes = {
  color: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};

ColorPickDialog.defaultProps = {
  isOpen: false,
  color: ""
};
