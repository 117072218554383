import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  MULTI_TENANT_ADMIN_TENANTS_CALL,
  callMultiTenantAdminTenantsPromise,
  MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL,
  MULTI_TENANT_ADMIN_APP_TEMPLATES_ADD_CALL,
  MULTI_TENANT_ADMIN_APP_TEMPLATES_EDIT_CALL,
  MULTI_TENANT_ADMIN_APP_TEMPLATES_DELETE_CALL,
  callMultiTenantAdminAppTemplatesPromise
} from "./sagas-actions";
import {
  onCallMultiTenantAdminTenants,
  onFailedMultiTenantAdminTenants,
  onSuccessMultiTenantAdminTenants,
  onCallMultiTenantAdminAppTemplates,
  onSuccessMultiTenantAdminAppTemplates,
  onFailedMultiTenantAdminAppTemplates,
  onSuccessMultiTenantAdminAppTemplateAdd,
  onSuccessMultiTenantAdminAppTemplateEdit,
  onSuccessMultiTenantAdminAppTemplateDelete,
  switchMultiTenantAdminAppTemplateLoading
} from "./slice";
import { paramParserForPagination } from "../../utils/helpers";
import qs from "qs";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* multiTenantAdminTenantsCallGenerator(action) {
  yield put(onCallMultiTenantAdminTenants(true));
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `/tenantUser/tenant_multi?${queryString}`);
    yield put(onSuccessMultiTenantAdminTenants(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedMultiTenantAdminTenants());
  }
}

function* multiTenantAdminTenantsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* multiTenantAdminTenantsWorker() {
    yield put(onCallMultiTenantAdminTenants(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `/tenantUser/tenant_multi?${queryString}`);

      return yield put(onSuccessMultiTenantAdminTenants(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* multiTenantAdminAppTemplatesCallGenerator(action) {
  yield put(onCallMultiTenantAdminAppTemplates());
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `/tenantUser/app_template?${queryString}`);
    yield put(onSuccessMultiTenantAdminAppTemplates(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedMultiTenantAdminAppTemplates());
  }
  yield put(switchMultiTenantAdminAppTemplateLoading(false));
}

function* multiTenantAdminAppTemplatesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* multiTenantAdminTenantsWorker() {
    yield put(onCallMultiTenantAdminAppTemplates(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `/tenantUser/app_template?${queryString}`);

      yield put(onSuccessMultiTenantAdminAppTemplates(data));
      return yield put(switchMultiTenantAdminAppTemplateLoading(false));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(switchMultiTenantAdminAppTemplateLoading(false));
      return Promise.reject(error);
    }
  });
}

function* uploadMultiTenantAdminAppTemplateImageGenerator(file, fileName, templateId) {
  const formData = new FormData();
  formData.append("image", file, file.name || fileName);
  const { data } = yield call(axiosInstance.post, `/tenantUser/app_template/image/${templateId}`, formData);

  return data?.url;
}

function* multiTenantAdminAppTemplatesAddCallGenerator(action) {
  yield put(switchMultiTenantAdminAppTemplateLoading(true));
  try {
    const {
      payload: { templateData, newImage, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.post, "/tenantUser/app_template", templateData);

    let imageNewUrl;
    if (newImage?.file && data) {
      imageNewUrl = yield uploadMultiTenantAdminAppTemplateImageGenerator(
        newImage?.file,
        newImage?.name,
        data.app_template_id
      );
    }

    yield put(
      onSuccessMultiTenantAdminAppTemplateAdd({
        templateData: {
          ...data,
          ...(newImage?.file && { Image: imageNewUrl })
        }
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchMultiTenantAdminAppTemplateLoading(false));
}

function* multiTenantAdminAppTemplatesEditCallGenerator(action) {
  yield put(switchMultiTenantAdminAppTemplateLoading(true));
  try {
    const {
      payload: { templateId, templateData, newImage, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.put, `/tenantUser/app_template/${templateId}`, templateData);

    let imageNewUrl;
    if (newImage?.file && data) {
      imageNewUrl = yield uploadMultiTenantAdminAppTemplateImageGenerator(
        newImage?.file,
        newImage?.name,
        data?.app_template_id
      );
    }
    yield put(
      onSuccessMultiTenantAdminAppTemplateEdit({
        ...data,
        ...(newImage?.file && { image: imageNewUrl })
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchMultiTenantAdminAppTemplateLoading(false));
}

function* multiTenantAdminAppTemplatesDeleteCallGenerator(action) {
  yield put(switchMultiTenantAdminAppTemplateLoading(true));
  try {
    const {
      payload: { templateId }
    } = action;

    yield call(axiosInstance.delete, `/tenantUser/app_template/${templateId}`);

    yield put(onSuccessMultiTenantAdminAppTemplateDelete(templateId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchMultiTenantAdminAppTemplateLoading(false));
}

export default function* adminSaga() {
  yield takeEvery(MULTI_TENANT_ADMIN_TENANTS_CALL, multiTenantAdminTenantsCallGenerator);
  yield takeEvery(callMultiTenantAdminTenantsPromise, multiTenantAdminTenantsCallPromiseGenerator);
  yield takeEvery(MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL, multiTenantAdminAppTemplatesCallGenerator);
  yield takeEvery(MULTI_TENANT_ADMIN_APP_TEMPLATES_ADD_CALL, multiTenantAdminAppTemplatesAddCallGenerator);
  yield takeEvery(MULTI_TENANT_ADMIN_APP_TEMPLATES_EDIT_CALL, multiTenantAdminAppTemplatesEditCallGenerator);
  yield takeEvery(MULTI_TENANT_ADMIN_APP_TEMPLATES_DELETE_CALL, multiTenantAdminAppTemplatesDeleteCallGenerator);
  yield takeEvery(callMultiTenantAdminAppTemplatesPromise, multiTenantAdminAppTemplatesCallPromiseGenerator);
}
