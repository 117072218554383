import { useCallback, useState } from "react";
import mergeImages from "merge-images";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "clients/api";
import { useSnackbar } from "notistack";
import useDidUpdate from "hooks/common/useDidUpdate";
import play_icon from "../../assets/img/Play.png";
/**
 * This hook helps to upload recoded video
 */

const useUploadRecordedVideo = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const uploadFiles = useCallback(async (video, thumbnail) => {
    if (!isLoading) {
      setIsLoading(true);
      setError(false);

      const formData = new FormData();
      formData.append("record", video, uuidv4());

      try {
        await mergeImages(
          [
            { src: thumbnail, x: 0, y: 0 },
            { src: play_icon, x: 255, y: 136 }
          ],
          {
            width: 600,
            height: 340
          }
        ).then((b64) => {
          formData.append("thumbnail", base64ToBlob(b64), uuidv4());
        });
        const { data } = await axiosInstance.post(`/tenantUser/templates/store_record`, formData);
        setIsLoading(false);
        const videoName = data?.fileName;
        const thumbnailName = data?.thumbnail;

        return { videoName, thumbnailName };
      } catch (err) {
        setError(err.toString());
        console.log(err);
      }
      setIsLoading(false);
    }
    return null;
  }, []);

  // Show error if upload failed
  useDidUpdate(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error"
      });
    }
  }, [error]);

  return {
    isUploadLoading: isLoading,
    uploadError: error,
    uploadFiles
  };
};

export default useUploadRecordedVideo;

function base64ToBlob(base64Data) {
  const byteCharacters = window.atob(base64Data.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/jpeg" }); // Replace 'image/png' with the mime type of your photo
  return blob;
}
