import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, InputAdornment } from "@mui/material";
import { Check, Close } from "@mui/icons-material";

const FieldEndAdornment = memo(({ text, isDirty, isLoading, isError }) => (
  <InputAdornment position="end">
    <Box display="flex" alignItems="center">
      {text && <span>{text}</span>}
      {isDirty && (
        <Box display="flex" pl={0.5}>
          {isLoading ? (
            <CircularProgress size={24} thickness={5} style={{ padding: "2px" }} />
          ) : !isError ? (
            <Check color="primary" />
          ) : (
            <Close color="error" />
          )}
        </Box>
      )}
    </Box>
  </InputAdornment>
));

FieldEndAdornment.propTypes = {
  text: PropTypes.string,
  isDirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
};

export default FieldEndAdornment;
