import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import DragItem from "../drag-item";

const FileListItem = ({ itemObject, index }) => (
  <ListItem key={itemObject.token} sx={{ display: "flex", pr: 0, pl: 0 }} component="div">
    <ListItemText
      sx={{ width: "44%", paddingRight: "1%" }}
      primaryTypographyProps={{ style: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
      primary={itemObject.columnName}
    />
    <Droppable droppableId={itemObject.columnName}>
      {(provided, snapshot) => (
        <div
          style={{
            width: "55%",
            height: "30px",
            backgroundColor: snapshot.isDraggingOver && "skyblue",
            border: "1px solid white"
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <DragItem itemObject={itemObject} index={index} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </ListItem>
);

export default FileListItem;
