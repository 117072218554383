import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { backendDateFormat } from "../../constant/dates";

dayjs.extend(utc);
dayjs.extend(timezone);

const useGetCityTime = (tz) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return new Date(dayjs.tz(currentDate, tz).format(backendDateFormat));
};

export default useGetCityTime;
