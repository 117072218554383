import { createPromiseAction } from "@adobe/redux-saga-promise";

export const MULTI_TENANT_ADMIN_TENANTS_CALL = "MULTI_TENANT_ADMIN_TENANTS_CALL";
export const MULTI_TENANT_ADMIN_TENANTS_CALL_PROMISE = "MULTI_TENANT_ADMIN_TENANTS_CALL_PROMISE";
export const MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL = "MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL";
export const MULTI_TENANT_ADMIN_APP_TEMPLATES_ADD_CALL = "MULTI_TENANT_ADMIN_APP_TEMPLATES_ADD_CALL";
export const MULTI_TENANT_ADMIN_APP_TEMPLATES_EDIT_CALL = "MULTI_TENANT_ADMIN_APP_TEMPLATES_EDIT_CALL";
export const MULTI_TENANT_ADMIN_APP_TEMPLATES_DELETE_CALL = "MULTI_TENANT_ADMIN_APP_TEMPLATES_DELETE_CALL";

export const MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL_PROMISE = "MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL_PROMISE";

export const callMultiTenantAdminTenantsPromise = createPromiseAction(MULTI_TENANT_ADMIN_TENANTS_CALL_PROMISE);
export const callMultiTenantAdminAppTemplatesPromise = createPromiseAction(
  MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL_PROMISE
);

export function callMultiTenantAdminTenants(paginationData) {
  return { type: MULTI_TENANT_ADMIN_TENANTS_CALL, payload: { paginationData } };
}

export function callMultiTenantAdminAppTemplates(paginationData) {
  return { type: MULTI_TENANT_ADMIN_APP_TEMPLATES_CALL, payload: { paginationData } };
}

export function callAddMultiTenantAdminAppTemplate({ templateData, newImage, messageFunction }) {
  return { type: MULTI_TENANT_ADMIN_APP_TEMPLATES_ADD_CALL, payload: { templateData, newImage, messageFunction } };
}

export function callEditMultiTenantAdminAppTemplate({ templateId, templateData, newImage, messageFunction }) {
  return {
    type: MULTI_TENANT_ADMIN_APP_TEMPLATES_EDIT_CALL,
    payload: { templateId, templateData, newImage, messageFunction }
  };
}

export function callDeleteMultiTenantAdminAppTemplate(templateId) {
  return { type: MULTI_TENANT_ADMIN_APP_TEMPLATES_DELETE_CALL, payload: { templateId } };
}
