import { createPromiseAction } from "@adobe/redux-saga-promise";

export const OPPORTUNITIES_CALL = "OPPORTUNITIES_CALL";

export const OPPORTUNITIES_DELETE_CALL = "OPPORTUNITIES_DELETE_CALL";
export const OPPORTUNITIES_RESTORE_CALL = "OPPORTUNITIES_RESTORE_CALL";
export const OPPORTUNITIES_POSITIONS_CHANGE_CALL = "OPPORTUNITIES_POSITIONS_CHANGE_CALL";
export const OPPORTUNITY_CALL = "OPPORTUNITY_CALL";
export const OPPORTUNITY_ADD_CALL = "OPPORTUNITY_ADD_CALL";
export const OPPORTUNITY_EDIT_CALL = "OPPORTUNITY_EDIT_CALL";
export const OPPORTUNITY_STAGE_CHANGE_CALL = "OPPORTUNITY_STAGE_CHANGE_CALL";
export const OPPORTUNITIES_STAGE_CHANGE_CALL = "OPPORTUNITIES_STAGE_CHANGE_CALL";

export const OPPORTUNITY_STATUSES_CALL = "OPPORTUNITY_STATUSES_CALL";
export const OPPORTUNITY_STATUS_ADD_CALL = "OPPORTUNITY_STATUS_ADD_CALL";
export const OPPORTUNITY_STATUS_EDIT_CALL = "OPPORTUNITY_STATUS_EDIT_CALL";
export const OPPORTUNITY_STATUS_DELETE_CALL = "OPPORTUNITY_STATUS_DELETE_CALL";

export const OPPORTUNITY_TYPES_CALL = "OPPORTUNITY_TYPES_CALL";
export const OPPORTUNITY_TYPE_ADD_CALL = "OPPORTUNITY_TYPE_ADD_CALL";
export const OPPORTUNITY_TYPE_EDIT_CALL = "OPPORTUNITY_TYPE_EDIT_CALL";
export const OPPORTUNITY_TYPE_DELETE_CALL = "OPPORTUNITY_TYPE_DELETE_CALL";

export const OPPORTUNITY_STAGES_CALL = "OPPORTUNITY_STAGES_CALL";
export const OPPORTUNITY_STAGES_POSITION_UPDATE = "OPPORTUNITY_STAGES_POSITION_UPDATE";
export const OPPORTUNITY_STAGE_ADD_CALL = "OPPORTUNITY_STAGE_ADD_CALL";
export const OPPORTUNITY_STAGE_EDIT_CALL = "OPPORTUNITY_STAGE_EDIT_CALL";
export const OPPORTUNITY_STAGE_DELETE_CALL = "OPPORTUNITY_STAGE_DELETE_CALL";

export const OPPORTUNITIES_CALL_STATUSES = "OPPORTUNITIES_CALL_STATUSES";
export const OPPORTUNITIES_CALL_TYPES = "OPPORTUNITIES_CALL_TYPES";
export const OPPORTUNITIES_CALL_STAGES = "OPPORTUNITIES_CALL_STAGES";
export const OPPORTUNITIES_CALL_BOARDS = "OPPORTUNITIES_CALL_BOARDS";

export const OPPORTUNITY_BOARDS_CALL = "OPPORTUNITY_BOARDS_CALL";
export const OPPORTUNITY_BOARD_ADD_CALL = "OPPORTUNITY_BOARD_ADD_CALL";
export const OPPORTUNITY_BOARD_EDIT_CALL = "OPPORTUNITY_BOARD_EDIT_CALL";
export const OPPORTUNITY_BOARD_DELETE_CALL = "OPPORTUNITY_BOARD_DELETE_CALL";

export const OPPORTUNITY_BOARD_CHANGE_CALL = "OPPORTUNITY_BOARD_CHANGE_CALL";
export const OPPORTUNITY_EDIT_PROMISE_CALL = "OPPORTUNITY_EDIT_PROMISE_CALL";
export const OPPORTUNITY_ADD_PROMISE_CALL = "OPPORTUNITY_ADD_PROMISE_CALL";
export const opportunityEditPromise = createPromiseAction(OPPORTUNITY_EDIT_PROMISE_CALL);
export const opportunityAddPromise = createPromiseAction(OPPORTUNITY_ADD_PROMISE_CALL);

export function callOpportunities(withoutLoading) {
  return { type: OPPORTUNITIES_CALL, payload: { withoutLoading } };
}

export function callOpportunity(opportunityId) {
  return { type: OPPORTUNITY_CALL, payload: { opportunityId } };
}

export function addOpportunity(opportunityData, isExternal, messageFunction) {
  return { type: OPPORTUNITY_ADD_CALL, payload: { opportunityData, isExternal, messageFunction } };
}

export function editOpportunity(opportunityId, opportunityData, messageFunction) {
  return { type: OPPORTUNITY_EDIT_CALL, payload: { opportunityId, opportunityData, messageFunction } };
}

export function deleteOpportunities(opportunityIds, stageColumns) {
  return { type: OPPORTUNITIES_DELETE_CALL, payload: { opportunityIds, stageColumns } };
}

export function changeOpportunitiesPositions(stageId, opportunityPositions) {
  return { type: OPPORTUNITIES_POSITIONS_CHANGE_CALL, payload: { stageId, opportunityPositions } };
}

export function editOpportunityStage(
  fromStageId,
  toStageId,
  fromStagePositions,
  toStagePositions,
  opportunityId,
  probability
) {
  return {
    type: OPPORTUNITY_STAGE_CHANGE_CALL,
    payload: { fromStageId, toStageId, fromStagePositions, toStagePositions, opportunityId, probability }
  };
}

// SETTINGS

export const callOpportunitySettings = (actionName) => () => ({ type: actionName });

export function callAddOpportunitySetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditOpportunitySetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteOpportunitySetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

export function callUpdateOpportunityStagePositions(data) {
  return { type: OPPORTUNITY_STAGES_POSITION_UPDATE, payload: { ...data } };
}

// ATTRIBUTES

export function callOpportunityAttributes(type) {
  return { type };
}

export function callOpportunitiesChangeAttributes(type, data) {
  return { type, payload: { data } };
}

export function changeBoard(boardId) {
  return { type: OPPORTUNITY_BOARD_CHANGE_CALL, payload: { boardId } };
}
