import { takeEvery, put, call, all } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { documentsPrefix } from "store/documents/sagas";
import { setError } from "../error/slice";
import {
  TEMPLATES_CALL,
  TEMPLATE_CALL,
  TEMPLATE_ADD_CALL,
  TEMPLATE_EDIT_CALL,
  TEMPLATES_DELETE_CALL,
  TEMPLATES_RESTORE_CALL,
  TEMPLATE_MERGE_FIELDS_CALL,
  TEMPLATE_TEST_CALL,
  TEMPLATES_CONTACTS_CALL,
  EMAILS_SEND_TO_CONTACTS_CALL,
  callTemplateAddPromise,
  SMS_SEND_TO_CONTACTS_CALL,
  TEMPLATE_CC_BCC_CALL,
  DOCUMENT_SEND_EMAIL,
  callTemplatesPromise,
  TEMPLATE_MERGE_FIELDS_DATA_CALL,
  GET_TEMPLATES_COUNT,
  GET_TEMPLATES_BY_TYPE,
  GET_TEMPLATES_GROUPS,
  GET_FILTERED_TEMPLATES,
  getFilteredTemplates,
  TEMPLATE_IMPORT_CALL
} from "./sagas-actions";
import {
  onCallTemplates,
  onSuccessTemplates,
  onFailedTemplates,
  switchTemplatesLoading,
  switchTemplateTestLoading,
  // switchTemplatesAttachmentLoading,
  onSuccessTemplate,
  onFailedTemplate,
  resetTemplate,
  onCallMergeFields,
  onSuccessMergeFields,
  onFailedMergeFields,
  onSuccessTemplateAdd,
  onSuccessTemplateEdit,
  onSuccessTemplatesDelete,
  onCallContactTemplates,
  onSuccessContactTemplates,
  onFailedContactTemplates,
  switchContactEmailTemplateSendLoading,
  onCallCcBccTemplate,
  onSuccessCcBccTemplate,
  onFailedCcBccTemplate,
  onSuccessContactTemplateMergeFieldsData,
  onCallContactTemplateMergeFieldsData,
  onFailedContactTemplateMergeFieldsData,
  onCallTemplatesCount,
  onCallTemplatesCountFailed,
  onCallTemplatesCountSuccess,
  onCallTemplateByType,
  onCallTemplateByTypeFailed,
  onCallTemplateByTypeSuccess,
  onCallTemplatesGroups,
  onCallTemplatesGroupsFailed,
  onCallTemplatesGroupsSuccess,
  onCallFilteredTemplates,
  onCallFilteredTemplatesFailed,
  onCallFilteredTemplatesSuccess,
  onSuccessTemplateAddForFiltered
} from "./slice";

import convertBlobToBase64 from "../helper";
import { onSuccessDocumentSend } from "store/documents/slice";
import { paramParserForPagination } from "utils/helpers";
import qs from "qs";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

const prefix = "/tenantUser/templates";

function* templatesCallGenerator(action) {
  yield put(onCallTemplates());
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    let queryString = qs.stringify(params);
    if (!queryString) {
      queryString = "current_page=1&page_size=1000";
    }
    const { data } = yield call(axiosInstance.get, `${prefix}?${queryString}`);
    yield put(onSuccessTemplates(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTemplates());
  }
}

function* templatesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* auditLogsWorker() {
    yield put(onCallTemplates(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `${prefix}?${queryString}`);

      return yield put(onSuccessTemplates(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* templateCallGenerator(action) {
  yield put(resetTemplate());
  try {
    const {
      payload: { templateId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${templateId}`);

    yield put(onSuccessTemplate(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTemplate());
  }
}

function* templateCcBccCallGenerator(action) {
  yield put(onCallCcBccTemplate());
  try {
    const {
      payload: { id, module }
    } = action;
    const { data } = yield call(axiosInstance.get, `tenantUser/contacts/generic${id ? `?${module}=${id}` : ""}`);
    yield put(onSuccessCcBccTemplate(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCcBccTemplate());
  }
}

function* mergeFieldsCallGenerator(action) {
  yield put(onCallMergeFields());
  try {
    const { data } = yield call(axiosInstance.get, `${prefix}/getMergeFields/all`);

    yield put(onSuccessMergeFields(data?.templateTokens));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedMergeFields());
  }
}

function* templateMergeFieldsDataCallGenerator(action) {
  yield put(onCallContactTemplateMergeFieldsData());
  try {
    const {
      payload: { mergeFields, ids }
    } = action;

    const { data } = yield call(axiosInstance.post, `${prefix}/getMergeFields/fillTemplate`, {
      mergeFields,
      tokenIds: ids
    });

    yield put(onSuccessContactTemplateMergeFieldsData(data?.items));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactTemplateMergeFieldsData());
  }
}

// Attachments

export function* uploadEmailTemplateAttachmentsGenerator(templateId, attachments) {
  const formData = new FormData();

  yield all(
    attachments.map(async (attachment) => {
      const blob = await fetch(attachment.url).then((r) => r.blob());
      const fileWithNewName = await new File([blob], attachment.file.name, { type: blob.type });
      formData.append("attachments", fileWithNewName);
    })
  );

  yield call(axiosInstance.post, `${prefix}/addAttachments/${templateId}`, formData);
}

export function* deleteEmailTemplateAttachmentsGenerator(attachmentIds) {
  yield call(axiosInstance.post, `${prefix}/attachments/delete_multiple`, {
    template_attachment_ids: attachmentIds
  });
}

// function* emailTemplateAttachmentsAddCallGenerator(action) {
//     yield put(switchEmailTemplatesAttachmentLoading(true));
//     try {
//         const { payload: { templateId, attachments } } = action;
//         const { data } = yield call(axiosInstance.post, `${prefix}/addTemplateAttachment/${templateId}`, {
//             Attachments: attachments
//         });
//
//         // yield put(onSuccessEmailTemplateAdd(data?.createdTemplate[0]));
//     } catch (error) {
//         yield put(setError({
//             error,
//             action
//         }));
//     }
//     yield put(switchEmailTemplatesAttachmentLoading(false));
// }
//
// function* emailTemplateAttachmentsDeleteCallGenerator(action) {
//     yield put(switchEmailTemplatesAttachmentLoading(true));
//     try {
//         const { payload: { attachmentId } } = action;
//         yield call(axiosInstance.delete, `${prefix}/deleteAttachment/${attachmentId}`);
//
//         // yield put(onSuccessSecurityRoleDelete(attachmentId));
//     } catch (error) {
//         yield put(setError({
//             error,
//             action
//         }));
//     }
//     yield put(switchEmailTemplatesAttachmentLoading(false));
// }

function* templateAddCallGenerator(action) {
  yield put(switchTemplatesLoading(true));
  try {
    const {
      payload: { templateData, attachmentsToUpload, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, prefix, templateData);
    const createdTemplate = data.createdTemplate[0];

    if (attachmentsToUpload && attachmentsToUpload.length > 0) {
      yield uploadEmailTemplateAttachmentsGenerator(createdTemplate.template_id, attachmentsToUpload);
    }
    yield messageFunction && messageFunction();
    yield put(onSuccessTemplateAdd(createdTemplate));
    yield put(onSuccessTemplateAddForFiltered(createdTemplate));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTemplatesLoading(false));
}

function* templateAddCallPromiseGenerator(action) {
  yield put(switchTemplatesLoading(true));

  yield call(implementPromiseAction, action, function* templateAddWorker() {
    try {
      const {
        payload: { templateData, attachmentsToUpload, message }
      } = action;
      const { data } = yield call(axiosInstance.post, prefix, templateData);
      const createdTemplate = data.createdTemplate[0];

      if (attachmentsToUpload && attachmentsToUpload.length > 0) {
        yield uploadEmailTemplateAttachmentsGenerator(createdTemplate.template_id, attachmentsToUpload);
      }

      yield put(onSuccessTemplateAdd(createdTemplate));
      yield put(onSuccessTemplateAddForFiltered(createdTemplate));
      yield message && message();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });

  yield put(switchTemplatesLoading(false));
}

function* templateEditCallGenerator(action) {
  yield put(switchTemplatesLoading(true));
  try {
    const {
      payload: { templateData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.put, `${prefix}/${templateData.template_id}`, templateData);

    if (templateData.attachmentsToDelete && templateData.attachmentsToDelete.length > 0) {
      yield deleteEmailTemplateAttachmentsGenerator(templateData.attachmentsToDelete);
    }

    if (templateData.attachmentsToUpload && templateData.attachmentsToUpload.length > 0) {
      yield uploadEmailTemplateAttachmentsGenerator(templateData.template_id, templateData.attachmentsToUpload);
    }
    yield messageFunction && messageFunction();
    yield put(
      onSuccessTemplateEdit({
        ...data?.updatedTemplate[0],
        active: data?.updatedTemplate[0]?.Active
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTemplatesLoading(false));
}

function* templatesDeleteCallGenerator(action) {
  yield put(switchTemplatesLoading(true));
  try {
    const {
      payload: { templateIds, messageFunction }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete`, {
      templateIds
    });
    yield put(onSuccessTemplatesDelete(templateIds));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTemplatesLoading(false));
}

function* templatesRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      templateIds: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function* templateTestCallGenerator(action) {
  yield put(switchTemplateTestLoading(true));
  try {
    const {
      payload: { email, templateId, messageFunction }
    } = action;
    yield call(axiosInstance.post, `${prefix}/test`, {
      email,
      templateId
    });
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTemplateTestLoading(false));
}

function* templateImportCallGenerator(action) {
  yield put(switchTemplatesLoading(true));
  try {
    const {
      payload: { data, messageFunction }
    } = action;

    const {
      data: { template }
    } = yield call(axiosInstance.post, `${prefix}/import`, data);

    yield put(onSuccessTemplateAdd(template));
    yield put(onSuccessTemplateAddForFiltered(template));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTemplatesLoading(false));
}

// Templates for contacts
function* templatesForContactsCallGenerator(action) {
  yield put(onCallContactTemplates());
  try {
    const {
      payload: { typeIds }
    } = action;
    const { data } = yield call(axiosInstance.post, `${prefix}/get_list_by_types`, {
      type_ids: typeIds
    });

    yield put(onSuccessContactTemplates(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContactTemplates());
  }
}

function* emailSendToContactsCallGenerator(action) {
  yield put(switchContactEmailTemplateSendLoading(true));

  try {
    const {
      payload: {
        contactIds,
        templateId,
        from,
        cc,
        bcc,
        templateTypeId,
        templateData,
        attachmentsToUpload,
        templateAttachments,
        messageFunction
      }
    } = action;
    const formData = new FormData();
    formData.append("type_id", templateTypeId);
    if (templateId) {
      formData.append("template_id", templateId);
    }
    formData.append("from", from);
    formData.append("cc", JSON.stringify(cc));
    formData.append("bcc", JSON.stringify(bcc));
    contactIds.forEach((contactId) => {
      formData.append("contact_ids", contactId);
    });
    formData.append("subject", templateData.subject);
    formData.append("body", templateData.body);

    const attachments = yield attachmentsToUpload &&
    Promise.all(
      attachmentsToUpload.map(async (item) => {
        const blob = new Blob([item?.file], {
          type: item?.file?.type
        });
        const file = await convertBlobToBase64(blob);
        return {
          filename: item?.file?.name,
          type: item?.file?.type,
          disposition: "attachment",
          content: file
        };
      })
    );
    const attachmentsToSend = JSON.stringify(attachments);
    formData.append("attachments", attachmentsToSend);
    formData.append("template_attachments", JSON.stringify(templateAttachments));

    yield call(axiosInstance.post, `${prefix}/send_email_by_contact_ids`, formData);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactEmailTemplateSendLoading(false));
}

function* documentSendEmailCallGenerator(action) {
  yield put(switchContactEmailTemplateSendLoading(true));
  try {
    const {
      payload: {
        contactId,
        templateTypeId,
        documentId,
        from,
        cc,
        bcc,
        templateData,
        templateId,
        attachmentsToUpload,
        templateAttachments
      }
    } = action;
    const formData = new FormData();
    formData.append("type_id", templateTypeId);
    if (templateId) {
      formData.append("template_id", templateId);
    }
    formData.append("from", from);
    formData.append("cc", JSON.stringify(cc));
    formData.append("bcc", JSON.stringify(bcc));
    formData.append("contact_id", contactId);
    formData.append("subject", templateData.subject);
    formData.append("body", templateData.body);
    formData.append("document_id", documentId);

    yield all(
      attachmentsToUpload.map(async (attachment) => {
        const blob = await fetch(attachment.url).then((r) => r.blob());
        const fileWithNewName = await new File([blob], attachment.file.name, { type: blob.type });
        formData.append("attachments", fileWithNewName);
      })
    );
    formData.append("initial_attachments", JSON.stringify(templateAttachments));

    yield call(axiosInstance.post, `${documentsPrefix}/${documentId}/send_document_link`, formData);
    yield put(onSuccessDocumentSend(documentId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactEmailTemplateSendLoading(false));
}

function* smsSendToContactsCallGenerator(action) {
  yield put(switchContactEmailTemplateSendLoading(true));

  try {
    const {
      payload: { contactIds, templateId, templateTypeId, templateData, messageFunction }
    } = action;

    console.log(templateData, 'tem data');

    // const formData = new FormData();
    // formData.append("type_id", templateTypeId);
    // if (templateId) {
    //   formData.append("template_id", templateId);
    // }
    // contactIds.forEach((contactId) => {
    //   formData.append("contact_ids", contactId);
    //   formData.append(`${contactId}_body`, templateData[`${contactId}_body`]);
    // });

    const data = contactIds.reduce((acc, contactId) => {
      acc.push({
        contact_id: contactId,
        [`${contactId}_body`]: templateData[`${contactId}_body`]
      });
      return acc;
    }, []);


    yield call(axiosInstance.post, `${prefix}/send_sms_by_contact_ids`, data);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchContactEmailTemplateSendLoading(false));
}

function* getTemplatesCountGenerator(action) {
  yield put(onCallTemplatesCount());
  try {
    const { data } = yield call(axiosInstance.get, `${prefix}/count`);
    yield put(onCallTemplatesCountSuccess(data.tableData));
  } catch (error) {
    setError({
      error,
      action
    });
    yield put(onCallTemplatesCountFailed(error));
  }
}

function* getTemplateByTypeGenerator(action) {
  yield put(onCallTemplateByType(action.payload));
  try {
    const { data } = yield call(axiosInstance.post, `${prefix}/get_list_by_types`, {
      type_ids: [action.payload.typeId]
    });

    yield put(onCallTemplateByTypeSuccess({ typeName: action.payload.typeName, data: data.templates }));
  } catch (error) {
    setError({
      error,
      action
    });
    yield put(onCallTemplateByTypeFailed({ typeName: action.payload.typeName, data: error }));
  }
}

function* getTemplatesGroupsGenerator(action) {
  yield put(onCallTemplatesGroups());
  try {
    const { data } = yield call(axiosInstance.get, `${prefix}/list/getGroupsList`);
    yield put(onCallTemplatesGroupsSuccess(data.templateGroups));
  } catch (error) {
    setError({
      error,
      action
    });
    yield put(onCallTemplatesGroupsFailed(error));
  }
}

function* getFilteredTemplatesGenerator(action) {
  yield put(onCallFilteredTemplates());
  try {
    const {
      payload: { typeIds, groupIds }
    } = action;
    const { data } = yield call(axiosInstance.post, `${prefix}/get_list_by_types`, {
      type_ids: typeIds,
      group_ids: groupIds
    });
    yield put(onCallFilteredTemplatesSuccess(data.templates));
  } catch (error) {
    setError({
      error,
      action
    });
    yield put(onCallFilteredTemplatesFailed(error));
  }
}

export default function* templatesWatcherSaga() {
  yield takeEvery(TEMPLATES_CALL, templatesCallGenerator);
  yield takeEvery(callTemplatesPromise, templatesCallPromiseGenerator);

  yield takeEvery(TEMPLATE_CALL, templateCallGenerator);
  yield takeEvery(TEMPLATE_CC_BCC_CALL, templateCcBccCallGenerator);
  yield takeEvery(TEMPLATE_ADD_CALL, templateAddCallGenerator);
  yield takeEvery(callTemplateAddPromise, templateAddCallPromiseGenerator);
  yield takeEvery(TEMPLATE_EDIT_CALL, templateEditCallGenerator);
  yield takeEvery(TEMPLATES_DELETE_CALL, templatesDeleteCallGenerator);
  yield takeEvery(TEMPLATES_RESTORE_CALL, templatesRestoreCallGenerator);
  yield takeEvery(TEMPLATE_MERGE_FIELDS_CALL, mergeFieldsCallGenerator);
  yield takeEvery(TEMPLATE_MERGE_FIELDS_DATA_CALL, templateMergeFieldsDataCallGenerator);
  yield takeEvery(TEMPLATE_TEST_CALL, templateTestCallGenerator);
  yield takeEvery(TEMPLATE_IMPORT_CALL, templateImportCallGenerator);

  yield takeEvery(DOCUMENT_SEND_EMAIL, documentSendEmailCallGenerator);
  // yield takeEvery(TEMPLATE_ATTACHMENTS_ADD_CALL, templateAttachmentsAddCallGenerator);
  // yield takeEvery(TEMPLATE_ATTACHMENTS_DELETE_CALL, templateAttachmentsDeleteCallGenerator);
  yield takeEvery(TEMPLATES_CONTACTS_CALL, templatesForContactsCallGenerator);
  yield takeEvery(EMAILS_SEND_TO_CONTACTS_CALL, emailSendToContactsCallGenerator);
  yield takeEvery(SMS_SEND_TO_CONTACTS_CALL, smsSendToContactsCallGenerator);
  yield takeEvery(GET_TEMPLATES_COUNT, getTemplatesCountGenerator);
  yield takeEvery(GET_TEMPLATES_BY_TYPE, getTemplateByTypeGenerator);
  yield takeEvery(GET_TEMPLATES_GROUPS, getTemplatesGroupsGenerator);
  yield takeEvery(GET_FILTERED_TEMPLATES, getFilteredTemplatesGenerator);
}
