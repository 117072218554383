import { Step, StepLabel, Box, Stepper } from "@mui/material";
import React from "react";

const ImportStepper = ({ activeStep, steps }) => (
  <Box sx={{ padding: "10px" }}>
    <Stepper activeStep={activeStep}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
export default ImportStepper;
