import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useQuery } from "hooks";
import { AZURE_STORAGE_PREFIX } from "constant/env-variables";
import { useBrowser } from "hooks/custom/useBrowser";
import './styles.scss';
import { Box, Container, Divider, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { callVideoTemplateData } from "store/video/sagas-actions";
import { isEqual } from "lodash";
import videoTemplateDataSelector from "store/video/selectors";
import Loader from "components/common/loader";


export default function VideoPlayer() {
  const { video, thumbnail, tenant_id } = useQuery();
  const ref = useRef(null);

  const dispatch = useDispatch();

  const videoUrl = `${AZURE_STORAGE_PREFIX}/${tenant_id}/recordings/${video}`;
  const thumbnailUrl = `${AZURE_STORAGE_PREFIX}/${tenant_id}/recordings/${thumbnail}`;
  const { data: user, isFetched } = useSelector(videoTemplateDataSelector, isEqual);

  const organizationLogo = `${AZURE_STORAGE_PREFIX}/${tenant_id}/companyLogo/${user.logo}`;

  const browser = useBrowser();
  const isSafari = browser === 'Safari';

  useEffect(() => {
    dispatch(callVideoTemplateData(tenant_id));
  }, []);

  const handleClick = () => {
    const videoElement = document.getElementsByTagName('video');
    const imageElement = document.getElementById('overlapImage');
    const videoPlayer = document.getElementById('videoPlayer');
    if (videoElement && videoElement[0]) {
      if (imageElement && imageElement.style) {
        imageElement.style.display = 'none';
      }
      if (videoPlayer && videoPlayer.style) {
        videoPlayer.style.display = 'flex';

      }
      videoElement[0]?.play();
    }
  };

  return (
    <Container maxWidth="md">
      {!isFetched ? <Loader />
        : <Paper sx={{ marginTop: "50px" }}>
          <Box className="orgLogo">
            <img alt="organization logo" src={organizationLogo}/>
          </Box>
          <Divider />
          <Typography component={'p'} variant={'body1'} textAlign={'center'} pt={2}>
            This video was sent by {user.name}
          </Typography>
          {
            isSafari ? (<div ref={ref} className="wrapper">
                <div className="container">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    onClick={handleClick}
                    id="overlapImage"
                    className="overlapImage"
                    src={thumbnailUrl}
                    alt="thumbnail"
                  />
                  {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
                  <ReactPlayer id="videoPlayer" autoplay playsInline playsinline url={`${videoUrl}?t=${Date.now()}`} controls />
                </div>
              </div>) :
              (<Box ref={ref} onClick={handleClick} className="wrapper">
                <div className="container">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    onClick={handleClick}
                    id="overlapImage"
                    className="overlapImage"
                    src={thumbnailUrl}
                    alt="thumbnail"
                  />
                  {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
                  <ReactPlayer id="videoPlayer" autoplay playsInline playsInline url={videoUrl} controls />
                </div>
              </Box>)
          }
        </Paper>
      }
    </Container>
  );
}
