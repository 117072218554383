// PRIOIRTY
export const TASK_PRIORITIES_CALL = "TASK_PRIORITIES_CALL";
export const TASK_PRIORITY_ADD_CALL = "TASK_PRIORITY_ADD_CALL";
export const TASK_PRIORITY_EDIT_CALL = "TASK_PRIORITY_EDIT_CALL";
export const TASK_PRIORITY_DELETE_CALL = "TASK_PRIORITY_DELETE_CALL";
export const TASK_PRIORITY_ROWS_POSITION_UPDATE = "TASK_PRIORITY_ROWS_POSITION_UPDATE";

// TAGS
export const TASK_TAGS_CALL = "TASK_TAGS_CALL";
export const TASK_TAG_ADD_CALL = "TASK_TAG_ADD_CALL";
export const TASK_TAG_EDIT_CALL = "TASK_TAG_EDIT_CALL";
export const TASK_TAG_DELETE_CALL = "TASK_TAG_DELETE_CALL";


// TYPES
export const TASK_TYPES_CALL = "TASK_TYPES_CALL";
export const TASK_TYPE_ADD_CALL = "TASK_TYPE_ADD_CALL";
export const TASK_TYPE_EDIT_CALL = "TASK_TYPE_EDIT_CALL";
export const TASK_TYPE_DELETE_CALL = "TASK_TYPE_DELETE_CALL";

// STATUSES
export const TASK_STATUSES_CALL = "TASK_STATUSES_CALL";
export const TASK_STATUS_ADD_CALL = "TASK_STATUS_ADD_CALL";
export const TASK_STATUS_EDIT_CALL = "TASK_STATUS_EDIT_CALL";
export const TASK_STATUS_DELETE_CALL = "TASK_STATUS_DELETE_CALL";

export const callTaskSettings = (actionName) => () => ({ type: actionName });

export function callAddTaskSetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditTaskSetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteTaskSetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

export function callTaskAttributes(type) {
  return { type };
}

export function callUpdateTaskPriorityRowsPositions(data) {
  return { type: TASK_PRIORITY_ROWS_POSITION_UPDATE, payload: { ...data } };
}
