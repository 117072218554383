import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { axiosSagaRequest } from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { setError } from "../error/slice";
import {
  WORKFLOWS_CALL,
  WORKFLOW_CALL,
  WORKFLOW_EDIT_CALL,
  WORKFLOW_DELETE_CALL,
  WORKFLOW_RESTORE_CALL,
  callAddWorkflowPromise,
  WORKFLOWS_CALL_CONDITIONS,
  callEditWorkflowPromise
} from "./sagas-actions";
import {
  onCallWorkflows,
  onSuccessWorkflows,
  onFailedWorkflows,
  switchWorkflowLoading,
  switchWorkflowDeleteLoading,
  onSuccessWorkflow,
  onFailedWorkflow,
  onSuccessWorkflowAdd,
  onSuccessWorkflowEdit,
  onSuccessWorkflowsDelete,
  onCallWorkflowConditions,
  onSuccessWorkflowConditions,
  onFailedWorkflowConditions
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

const prefix = "/tenantUser/workflows";

// Initial call
function* workflowsCallGenerator(action) {
  yield put(onCallWorkflows());
  try {
    const { data } = yield call(axiosSagaRequest, "get", prefix);
    yield put(onSuccessWorkflows(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedWorkflows());
  }
}

// Single Workflow
function* workflowCallGenerator(action) {
  yield put(switchWorkflowLoading(true));
  try {
    const {
      payload: { workflowId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/${workflowId}`);

    yield put(onSuccessWorkflow(data?.workflow));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedWorkflow());
  }
  yield put(switchWorkflowLoading(false));
}

function* workflowAddCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* workflowAddWorker() {
    try {
      yield put(switchWorkflowLoading(true));
      const { payload: { formData, message } } = action;

      // Workflow add
      const { data } = yield call(axiosSagaRequest, "post", prefix, formData);
      yield put(
        onSuccessWorkflowAdd({
          ...data.data,
          data: JSON.parse(formData.data),
          app_module_id: formData.app_module_id
        })
      );
      yield message && message();
      return Promise.resolve(data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    } finally {
      yield put(switchWorkflowLoading(false));
    }
  });
}

function* workflowEditCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* workflowEditWorker() {

    yield put(switchWorkflowLoading(true));
    try {
      const {
        payload: { workflowData, message }
      } = action;

      // update workflow data
      const { data } = yield call(axiosSagaRequest, "put", `${prefix}/${workflowData.workflow_id}`, workflowData);

      yield put(onSuccessWorkflowEdit({
        ...data?.data?.[0],
        data: JSON.parse(workflowData.data),
        app_module_id: workflowData.app_module_id
      }));
      yield message && message();
      return Promise.resolve(data?.data?.[0]);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    finally {
      yield put(switchWorkflowLoading(false));
    }
  });
}

function* workflowDeleteCallGenerator(action) {
  yield put(switchWorkflowDeleteLoading(true));
  try {
    const {
      payload: { workflowIds }
    } = action;
    yield call(axiosSagaRequest, "post", `${prefix}/delete_multiple`, {
      workflow_ids: workflowIds
    });
    yield put(onSuccessWorkflowsDelete(workflowIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchWorkflowDeleteLoading(false));
}

function* workflowRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosSagaRequest, "post", `${prefix}/undo_deleted`, {
      workflow_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function* workflowConditionsCallGenerator(action) {
  yield put(onCallWorkflowConditions());
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/tokens/get_conditions`);
    yield put(onSuccessWorkflowConditions(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedWorkflowConditions());
  }
}

export default function* workflowsSaga() {
  yield takeLatest(WORKFLOWS_CALL, workflowsCallGenerator);
  yield takeEvery(WORKFLOW_CALL, workflowCallGenerator);
  yield takeEvery(callAddWorkflowPromise, workflowAddCallPromiseGenerator);
  yield takeEvery(callEditWorkflowPromise, workflowEditCallPromiseGenerator);
  yield takeEvery(WORKFLOW_DELETE_CALL, workflowDeleteCallGenerator);
  yield takeEvery(WORKFLOW_RESTORE_CALL, workflowRestoreCallGenerator);
  yield takeLatest(WORKFLOWS_CALL_CONDITIONS, workflowConditionsCallGenerator);
}
