import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import { MESSAGES_CALL, MESSAGE_ADD_CALL } from "./sagas-actions";
import { onCallMessages, onCallMessagesFail, onCallMessagesSuccess } from "./slice";

const prefix = "/tenantUser/app-log";

function* messageCallGenerator(action) {
  yield put(onCallMessages());
  try {
    const { data } = yield call(axiosInstance.post, `${prefix}/messages`, action.payload);
    yield put(onCallMessagesSuccess(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onCallMessagesFail());
  }
}

function* addMessageCallGenerator(action) {
  try {
    yield call(axiosInstance.post, `${prefix}/message`, action.payload);
    yield call(messageCallGenerator, { type: MESSAGES_CALL, payload: { key: action.payload.key } });
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

export default function* messagesSaga() {
  yield takeEvery(MESSAGES_CALL, messageCallGenerator);
  yield takeEvery(MESSAGE_ADD_CALL, addMessageCallGenerator);
}
