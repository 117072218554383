import { createSlice } from "@reduxjs/toolkit";

export const termsSlice = createSlice({
  name: "terms",
  initialState: {
    isFetched: false,
    terms: {}
  },
  reducers: {
    onCallTerms: (state) => {
      state.isFetched = false;
      state.terms = {};
    },
    onSuccessTerms: (state, action) => {
      state.isFetched = true;
      state.terms = { ...action.payload };
    },
    onFailedTerms: (state) => {
      state.isFetched = true;
      state.terms = {};
    }
  }
});

export const { onCallTerms, onSuccessTerms, onFailedTerms } = termsSlice.actions;

export default termsSlice.reducer;
