import { takeLatest, put, call } from "redux-saga/effects";
import { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import { APP_MESSAGE_CALL } from "./sagas-actions";
import { onCallAppMessage, onSuccessAppMessage, onFailedAppMessage } from "./slice";

function* appMessageCallGenerator(action) {
  yield put(onCallAppMessage());
  try {
    const { data } = yield call(axiosSagaRequest, "get", "/base/dynamicContent/2");
    yield put(onSuccessAppMessage(data.content[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAppMessage());
  }
}

export default function* appMessageSaga() {
  yield takeLatest(APP_MESSAGE_CALL, appMessageCallGenerator);
}
