import React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import FileListItem from "../file-list-item";

const FileColumn = ({ column }) => (
  <div
    style={{
      backgroundColor: "gray",
      padding: 20,
      marginRight: "10px",
      color: "white",
      width: "60%"
    }}
  >
    <Typography fontSize="19px!important" variant="h5">
      {column.name}
    </Typography>
    <List>
      {column.list.map((itemObject, index) => (
        <FileListItem key={itemObject.columnName} index={index} itemObject={itemObject} />
      ))}
    </List>
  </div>
);

export default FileColumn;
