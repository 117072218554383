import { createPromiseAction } from "@adobe/redux-saga-promise";

export const COMPANY_STATUSES_CALL = "COMPANY_STATUSES_CALL";
export const COMPANY_STATUS_ADD_CALL = "COMPANY_STATUS_ADD_CALL";
export const COMPANY_STATUS_EDIT_CALL = "COMPANY_STATUS_EDIT_CALL";
export const COMPANY_STATUS_DELETE_CALL = "COMPANY_STATUS_DELETE_CALL";

export const COMPANY_TYPES_CALL = "COMPANY_TYPES_CALL";
export const COMPANY_TYPE_ADD_CALL = "COMPANY_TYPE_ADD_CALL";
export const COMPANY_TYPE_EDIT_CALL = "COMPANY_TYPE_EDIT_CALL";
export const COMPANY_TYPE_DELETE_CALL = "COMPANY_TYPE_DELETE_CALL";

export const COMPANY_INDUSTRY_CLASSIFICATIONS_CALL = "COMPANY_INDUSTRY_CLASSIFICATIONS_CALL";
export const COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL = "COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL";
export const COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL_PROMISE = "COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL_PROMISE";
export const COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL = "COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL";
export const COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL = "COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL";


export const COMPANY_ORG_REPS_CALL = "COMPANY_ORG_REPS_CALL";
export const COMPANY_ORG_REP_ADD_CALL = "COMPANY_ORG_REP_ADD_CALL";
export const COMPANY_ORG_REP_EDIT_CALL = "COMPANY_ORG_REP_EDIT_CALL";
export const COMPANY_ORG_REP_DELETE_CALL = "COMPANY_ORG_REP_DELETE_CALL";

export const COMPANY_GROUPS_CALL = "COMPANY_GROUPS_CALL";
export const COMPANY_GROUP_ADD_CALL = "COMPANY_GROUP_ADD_CALL";
export const COMPANY_GROUP_EDIT_CALL = "COMPANY_GROUP_EDIT_CALL";
export const COMPANY_GROUP_DELETE_CALL = "COMPANY_GROUP_DELETE_CALL";

export const COMPANY_CUSTOM_FIELDS_CALL = "COMPANY_CUSTOM_FIELDS_CALL";
export const COMPANY_CUSTOM_FIELD_CALL = "COMPANY_CUSTOM_FIELD_CALL";
export const COMPANY_CUSTOM_FIELD_ADD_CALL = "COMPANY_CUSTOM_FIELD_ADD_CALL";
export const COMPANY_CUSTOM_FIELD_EDIT_CALL = "COMPANY_CUSTOM_FIELD_EDIT_CALL";
export const COMPANY_CUSTOM_FIELD_DELETE_CALL = "COMPANY_CUSTOM_FIELD_DELETE_CALL";
export const COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE = "COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE";


export const callCompanyIndustryClassificationAddPromise = createPromiseAction(COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL_PROMISE);

export const callCompanySettings = (actionName) => () => ({ type: actionName });

export function callAddCompanySetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditCompanySetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteCompanySetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

// custom fields

export function callCompanyCustomFields() {
  return { type: COMPANY_CUSTOM_FIELDS_CALL };
}

export function callCompanyCustomField(itemId) {
  return { type: COMPANY_CUSTOM_FIELD_CALL, payload: { itemId } };
}

export function addCompanyCustomField(itemData) {
  return { type: COMPANY_CUSTOM_FIELD_ADD_CALL, payload: { itemData } };
}

export function editCompanyCustomField(itemId, itemData) {
  return { type: COMPANY_CUSTOM_FIELD_EDIT_CALL, payload: { itemId, itemData } };
}

export function deleteCompanyCustomField(itemId, moduleId) {
  return { type: COMPANY_CUSTOM_FIELD_DELETE_CALL, payload: { itemId, moduleId } };
}

export function callUpdateCompanyCustomFieldRowsPosition(data) {
  return { type: COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE, payload: { ...data } };
}
