import React, { useMemo, useState } from "react";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { Button, Checkbox, checkboxClasses, List, ListItem, ListItemText } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { axiosRequest } from "clients/api";
import Loader from "components/common/loader";
import ErrorPopup from "components/complex/dialogs/error-popup";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";
import Message from "./message";
import { JSONParse, getQueryParams } from "utils/helpers";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      boxShadow: "inset 0 0 100px 15px #00000036",
      minHeight: "100vh"
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: "100%",
      minHeight: "100vh"
    },
    info: {
      width: "40%",
      margin: "0 auto",
      textAlign: "center",
      marginBottom: theme.spacing(4)
    },
    unsubscription: {
      padding: "20px",
      maxWidth: "500px",
      width: "500px",
      borderRadius: "5px",
      backgroundColor: theme.palette.grey[300]
    },
    logo: {
      width: "200px",
      marginBottom: theme.spacing(1),
      marginRight: "auto",
      marginLeft: "auto",
      color: theme.palette.primary.main
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      "& > .MuiButton-root": {
        marginRight: "10px",
        backgroundColor: theme.palette.grey[400],
        color: "black"
      }
    },
    checked: {
      color: theme.palette.grey[400]
    }
  })
);

const UserUnsubscription = () => {
  const query = getQueryParams();

  const contactData = useMemo(() => {
    const defaultContactData = {
      tenant_name: "",
      tenant_logo: "",
      contact_id: "",
      tenant_id: "",
      first_name: "",
      last_name: "",
      unsubscribe: false,
      sub_seq: []
    };
    const values = JSONParse(atob(query.data));

    return isEmpty(values) ? defaultContactData : values;
  }, [query]);

  const sequencesSubscriptionsOptions = useMemo(
    () => contactData?.sub_seq.map((item) => ({ ...item, checked: true })),
    []
  );
  const [sequencesSubscriptions, setSequencesSubscriptions] = useState(sequencesSubscriptionsOptions || []);
  const [unsubscribeFromAllSequences, setUnsubscribeFromAllSequences] = useState(contactData.unsubscribe);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [isUnsubscriptionSucceed, setIsUnsubscriptionSucceed] = useState(false);
  const theme = useTheme();

  const classes = useStyles();

  const onSubmit = () => {
    const data = {
      contact_id: contactData.contact_id,
      tenant_id: contactData.tenant_id,
      contact_first_name: contactData.first_name,
      contact_last_name: contactData.last_name,
      tenant_logo: contactData.tenant_logo,
      sub_seq: sequencesSubscriptions.filter((item) => !item.checked).map((item) => item.sequence_id),
      unsubscribe: contactData.unsubscribe === unsubscribeFromAllSequences ? null : unsubscribeFromAllSequences
    };
    setIsLoading(true);
    axiosRequest("put", `/base/subscription_management/${contactData.contact_id}`, data)
      .then(() => {
        setIsUnsubscriptionSucceed(true);
      })
      .catch((e) => {
        setError("");
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeHandler = (e, id) => {
    setSequencesSubscriptions((prev) =>
      prev.map((item) => (item.sequence_id === id ? { ...item, checked: e.target.checked } : item))
    );
  };

  const unsubscribeFromAllHandler = (e) => {
    setUnsubscribeFromAllSequences(e.target.checked);
    setSequencesSubscriptions((prev) => prev.map((item) => ({ ...item, checked: e.target.checked })));
  };

  return (
    <div className={classes.layout}>
      {isLoading && <Loader />}
      <div className={classes.wrapper}>
        <div className={classes.info}>
          <div className={classes.logo}>
            <img
              style={{ maxWidth: "100%" }}
              src={`${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${contactData.tenant_id}/companyLogo/${
                contactData?.tenant_logo
              }`}
              alt="Organization Logo"
            />
          </div>
          <h2>{contactData?.tenant_name}</h2>
          <span>Subscription Management for: {`${contactData.first_name} ${contactData.last_name}`}</span>
        </div>
        {isUnsubscriptionSucceed ? (
          <Message firstName={`${contactData.first_name}`} />
        ) : (
          <div className={classes.unsubscription}>
            <p>{`${contactData?.first_name}`}, please check/uncheck your subscription preferences</p>
            <PerfectScrollbar style={{ height: "200px", marginBottom: "25px" }}>
              {sequencesSubscriptionsOptions.length > 0 && (
                <List>
                  <ListItem sx={{ mb: "15px" }}>
                    <Checkbox
                      sx={{
                        [`&.${checkboxClasses.checked}`]: {
                          color: theme.palette.grey[400]
                        }
                      }}
                      onClick={unsubscribeFromAllHandler}
                      checked={unsubscribeFromAllSequences}
                      disableRipple
                    />
                    <ListItemText
                      primary={`Unsubscribe All-Removes you from all ${contactData?.tenant_name} Marketing `}
                    />
                  </ListItem>
                  {sequencesSubscriptions.map((item) => (
                    <ListItem key={item.sequence_id}>
                      <Checkbox
                        sx={{
                          [`&.${checkboxClasses.checked}`]: {
                            color: theme.palette.grey[400]
                          }
                        }}
                        onChange={(e) => onChangeHandler(e, item.sequence_id)}
                        checked={item.checked}
                        disableRipple
                      />
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            </PerfectScrollbar>
            <div className={classes.actions}>
              <Button variant="contained" onClick={onSubmit}>
                Submit
              </Button>
              {/* <Button variant="contained">Cancel</Button> */}
            </div>
          </div>
        )}
      </div>
      <ErrorPopup
        submitFunction={onSubmit}
        submitButton="Retry"
        isOpen={error !== undefined}
        onClose={() => setError(undefined)}
        message={error}
      />
    </div>
  );
};

export default UserUnsubscription;
