import { createSlice, current } from "@reduxjs/toolkit";
import {
  COMPANY_SETTING_TILE,
  COMPANY_TILE,
  CONTACT_SETTING_TILE,
  CONTACT_TILE,
  DOCUMENT_TILE,
  TEMPLATE_TABLE_VIEW,
  PRODUCT_SERVICE_TILE,
  USER_TABLE_VIEW
} from "constant/env-variables";
import _ from "lodash";

export const tableViewsSlice = createSlice({
  name: "table-views",
  initialState: {
    [TEMPLATE_TABLE_VIEW]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [USER_TABLE_VIEW]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [COMPANY_SETTING_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [CONTACT_SETTING_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [COMPANY_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [CONTACT_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [DOCUMENT_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },
    [PRODUCT_SERVICE_TILE]: {
      isFetched: false,
      isLoading: false,
      data: []
    },

    // Custom views
    company: {
      isFetched: false,
      isLoading: false,
      selectedTableView: undefined,
      list: [],
      isFilteredByTenantUser: false,
    },
    contact: {
      isFetched: false,
      isLoading: false,
      selectedTableView: undefined,
      list: [],
      isFilteredByTenantUser: false,
    },
    task: {
      isFetched: false,
      isLoading: false,
      selectedTableView: undefined,
      list: [],
      isFilteredByTenantUser: false,
    },
    document: {
      isFetched: false,
      isLoading: false,
      selectedTableView: undefined,
      list: [],
      isFilteredByTenantUser: false,
    },
    product_service: {
      isFetched: false,
      isLoading: false,
      selectedTableView: undefined,
      list: []
    }
  },
  reducers: {
    onTableViewChange: (state) => {
      const currentState = _.cloneDeep(state);
    },
    onKeepTableView: (state, { payload }) => {
      state[payload.key].selectedTableView = payload.view;
      state[payload.key].isFilteredByTenantUser = payload.isFilteredByTenantUser;
    },
    resetSelectedTableView: (state) => {
      state.selectedTableView = undefined;
    },

    onSuccessMeViews: (state, { payload }) => {
      const meDataKeys = Object.keys(payload);
      Object.keys(state).forEach((tableName) => {
        if (meDataKeys.includes(tableName)) {
          state[tableName].isFetched = true;
          state[tableName].data = payload[tableName];
        }
      });
    },

    onSuccessTableView: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].data = payload.data;
      state[payload.key].isFilteredByTenantUser = payload.isFilteredByTenantUser;
    },

    switchTableViewLoading: (state, { payload }) => {
      state[payload.key].isLoading = payload.status !== undefined ? payload.status : !state[payload.key].isLoading;
    },

    onCallCustomTableView: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onSuccessCustomTableView: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.table_views;
    },
    onFailedCustomTableView: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },

    onSuccessCustomTableViewAdd: (state, { payload }) => {
      const { data, name, shared, table_view_id } = payload.data.table_view;

      const tableViews = _.orderBy(
        [
          ...current(state[payload.key].list),
          {
            data,
            name,
            shared,
            table_view_id
          }
        ],
        [(list) => list.name.toLowerCase()],
        ["asc"]
      );
      state[payload.key].selectedTableView = payload.data.table_view;

      state[payload.key].list = tableViews;
    },
    onSuccessCustomTableViewEdit: (state, { payload }) => {
      const { data, name, shared, table_view_id } = payload.data.table_view;

      state[payload.key].list = state[payload.key].list.map((vT) =>
        +vT.table_view_id === +payload.data.table_view.table_view_id
          ? {
              data,
              name,
              shared,
              table_view_id
            }
          : vT
      );
    },
    onSuccessCustomTableViewDelete: (state, { payload }) => {
      state[payload.key].selectedTableView = undefined;
      state[payload.key].list = state[payload.key].list.filter((vT) => vT.table_view_id !== payload.viewId);
    }
  }
});

export const {
  onKeepTableView,
  resetSelectedTableView,
  onSuccessMeViews,
  onSuccessTableView,
  switchTableViewLoading,
  onCallCustomTableView,
  onSuccessCustomTableView,
  onFailedCustomTableView,
  onSuccessCustomTableViewAdd,
  onSuccessCustomTableViewEdit,
  onSuccessCustomTableViewDelete,
} = tableViewsSlice.actions;

export default tableViewsSlice.reducer;
