import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  onCallOrganization,
  onSuccessOrganizationGet,
  onFailedOrganization,
  onSuccessOrganizationEdit,
  organizationLoadingSwitch,
  onSuccessEmailReputation,
  onFailedEmailReputation,
  onCallEmailReputation
} from "./slice";
import { ORGANIZATION_DATA_CALL, ORGANIZATION_DATA_EDIT_CALL, SUBUSER_EMAIL_REPUTATION } from "./sagas-actions";

function* getOrganizationGenerator(action) {
  yield put(onCallOrganization());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/organization");
    yield put(onSuccessOrganizationGet(data[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedOrganization());
  }
}

function* getSubuserEmailReputation(action) {
  yield put(onCallEmailReputation());
  try {
    const { data } = yield call(axiosInstance.get, "/integration/sendgrid/subuser/reputation");
    yield put(onSuccessEmailReputation(data.result.reputation));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedEmailReputation());
  }
}

function* uploadOrganizationLogoGenerator(file, fileName) {
  const formData = new FormData();
  formData.append("logo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, "/tenantUser/organization/logo", formData);

  return data?.url;
}

function* editOrganizationGenerator(action) {
  yield put(organizationLoadingSwitch(true));
  try {
    const {
      payload: { organizationData, newLogoFile, messageFunction }
    } = action;

    if (newLogoFile?.file) {
      yield uploadOrganizationLogoGenerator(newLogoFile?.file, newLogoFile?.name);
    }

    const { data } = yield call(axiosInstance.put, "/tenantUser/organization", organizationData);

    yield data && messageFunction && messageFunction();
    yield put(onSuccessOrganizationEdit(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(organizationLoadingSwitch(false));
}

export default function* organizationSaga() {
  yield takeEvery(ORGANIZATION_DATA_CALL, getOrganizationGenerator);
  yield takeEvery(SUBUSER_EMAIL_REPUTATION, getSubuserEmailReputation);
  yield takeEvery(ORGANIZATION_DATA_EDIT_CALL, editOrganizationGenerator);
}
