import { createPromiseAction } from "@adobe/redux-saga-promise";

export const APP_CONTENTS_CALL = "APP_CONTENTS_CALL";
export const APP_CONTENTS_EDIT_CALL = "APP_CONTENTS_EDIT_CALL";
export const RESET_MFA_CALL = "RESET_MFA_CALL";
export const ADMIN_LOGS_CALL = "ADMIN_LOGS_CALL";
export const ADMIN_LOGS_CALL_PROMISE = "ADMIN_LOGS_CALL_PROMISE";
export const GET_APP_VERSION = "GET_APP_VERSION";

export const callAdminLogsPromise = createPromiseAction(ADMIN_LOGS_CALL_PROMISE);

export function callAppContents() {
  return { type: APP_CONTENTS_CALL };
}

export function getAppVersion() {
  return { type: GET_APP_VERSION };
}

export function callEditContent(contentId, contentData, messageFunction) {
  return { type: APP_CONTENTS_EDIT_CALL, payload: { contentId, contentData, messageFunction } };
}

export function callResetMfa(data, messageFunction) {
  return { type: RESET_MFA_CALL, payload: { mfaData: data, messageFunction } };
}

export function callAdminLogs(paginationData) {
  return { type: ADMIN_LOGS_CALL, payload: { paginationData } };
}
