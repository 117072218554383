import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { axiosSagaRequest } from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { setError } from "../error/slice";
import {
  SEQUENCES_CALL,
  SEQUENCES_CALL_CONDITIONS,
  SEQUENCE_CALL,
  SEQUENCE_DELETE_CALL,
  SEQUENCE_RESTORE_CALL,
  callAddSequencePromise,
  SEQUENCE_EDIT_CALL,
  SEQUENCES_CALL_ANALYTICS,
  callEditSequencePromise, GET_HOLIDAYS
} from "./sagas-actions";
import {
  onCallSequences,
  onSuccessSequences,
  onFailedSequences,
  onCallSequenceConditions,
  onSuccessSequenceConditions,
  onFailedSequenceConditions,
  onSuccessSequence,
  onFailedSequence,
  switchSequenceDeleteLoading,
  onSuccessSequenceDelete,
  switchSequenceLoading,
  onSuccessSequenceAdd,
  onSuccessSequenceEdit,
  onCallSequenceAnalytics,
  onSuccessSequenceAnalytics,
  onFailedSequenceAnalytics, onCallHolidays, onCallHolidaysSuccess, onCallHolidaysFailure
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

const prefix = "/tenantUser/sequences";

// Initial call
function* sequencesCallGenerator(action) {
  yield put(onCallSequences());
  try {
    const { data } = yield call(axiosSagaRequest, "get", prefix);
    yield put(onSuccessSequences(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSequences());
  }
}

// Single Sequence
function* sequenceCallGenerator(action) {
  try {
    const {
      payload: { sequenceId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/${sequenceId}`);

    yield put(onSuccessSequence(data?.sequence));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSequence());
  }
}

function* sequenceAddCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* f() {
    try {
      yield put(switchSequenceLoading(true));
      const { payload: { formData, message } } = action;

      // Sequence add
      const { data } = yield call(axiosSagaRequest, "post", prefix, formData);

      yield put(onSuccessSequenceAdd({
          ...data.data,
          data: JSON.parse(formData.data),
          app_module_id: formData.app_module_id
        })
      );

      yield message && message();
      return Promise.resolve(data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    } finally {
      yield put(switchSequenceLoading(false));
    }
  });
}

function* sequenceEditCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* f() {
    try {
      yield put(switchSequenceLoading(true));
      const { payload: { sequenceData, message } } = action;

      const { data } = yield call(axiosSagaRequest, "put", `${prefix}/${sequenceData.sequence_id}`, sequenceData);

      yield put(onSuccessSequenceEdit({
          ...data?.data,
          data: JSON.parse(sequenceData.data),
          app_module_id: sequenceData.app_module_id
        })
      );

      yield message && message();
      return Promise.resolve(data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    } finally {
      yield put(switchSequenceLoading(false));
    }
  });
}

function* sequenceDeleteCallGenerator(action) {
  yield put(switchSequenceDeleteLoading(true));
  try {
    const {
      payload: { sequenceIds }
    } = action;
    yield call(axiosSagaRequest, "post", `${prefix}/delete_multiple`, {
      sequence_ids: sequenceIds
    });
    yield put(onSuccessSequenceDelete(sequenceIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSequenceDeleteLoading(false));
}

function* sequenceRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosSagaRequest, "post", `${prefix}/undo_deleted`, {
      sequence_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function* sequenceConditionsCallGenerator(action) {
  yield put(onCallSequenceConditions());
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/tokens/get_conditions`);
    yield put(onSuccessSequenceConditions(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSequenceConditions());
  }
}

function* sequenceAnalyticsCallGenerator(action) {
  yield put(onCallSequenceAnalytics());
  try {
    const {
      payload: { sequenceId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/${sequenceId}/analytics`);

    yield put(onSuccessSequenceAnalytics(data.data[0] || {}));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedSequenceAnalytics());
  }
}

function* getHolidaysGenerator(action) {
  yield put(onCallHolidays());
  try {
    const {
      payload: { countryCode, year }
    } = action;
    const { data } = yield call(axiosSagaRequest, 'get', `${prefix}/getHolidays?countryCode=${countryCode}&year=${year}`);
    yield put(onCallHolidaysSuccess({countryCode, list: data.holidays}));
  } catch (error) {
    yield put(onCallHolidaysFailure(error));
  }
}

export default function* sequencesSaga() {
  yield takeLatest(SEQUENCES_CALL, sequencesCallGenerator);
  yield takeEvery(SEQUENCE_CALL, sequenceCallGenerator);
  yield takeEvery(callAddSequencePromise, sequenceAddCallPromiseGenerator);
  yield takeEvery(callEditSequencePromise, sequenceEditCallPromiseGenerator);
  yield takeEvery(SEQUENCE_DELETE_CALL, sequenceDeleteCallGenerator);
  yield takeEvery(SEQUENCE_RESTORE_CALL, sequenceRestoreCallGenerator);
  yield takeLatest(SEQUENCES_CALL_CONDITIONS, sequenceConditionsCallGenerator);
  yield takeEvery(SEQUENCES_CALL_ANALYTICS, sequenceAnalyticsCallGenerator);
  yield takeEvery(GET_HOLIDAYS, getHolidaysGenerator);
}
