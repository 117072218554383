import { takeEvery, put, call } from "redux-saga/effects";
import { onSuccessMeViews } from "store/table-view/slice";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { v4 as uuidv4 } from "uuid";
import { setError } from "../error/slice";
import axiosInstance, { axiosSagaRequest } from "../../clients/api";
import {
  setUserSignFactor,
  setUserToken,
  setUserLocal,
  userGetLoadingSwitch,
  userLoadingSwitch,
  resetUserData,
  onUserError,
  setUserResetPasswordStatus,
  setUserFactorStatus,
  setUserEmail,
  onCallUserEULA,
  onSuccessUserEULA,
  onFailedUserEULA,
  setUserTabId,
  onSuccessMeUpdate,
  switchUserEmailLoading,
  setUserEmailError,
  onCallGetHistoryFailure,
  onCallGetHistorySuccess,
  onCallGetHistory,
  removeUserEmail
} from "./slice";
import {
  USER_SIGN_IN_CALL,
  USER_MFA_CALLBACK,
  USER_GET,
  RESET_PASSWORD_CALL,
  FORGOT_PASSWORD_CALL,
  USER_REFRESH_TOKEN,
  USER_EMAIL_GET,
  getUserByToken,
  USER_CHANGE_STATUS_CALL,
  USER_EULA_GET,
  editUserEULAPromise,
  GENERATE_TAB_ID,
  LOGOUT_EMAIL,
  USER_SSO_CALL,
  UPDATE_HISTORY,
  GET_HISTORY,
  USER_CHANGE_PASSWORD
} from "./sagas-actions";
import { getPermissions } from "../settings/permissions/sagas-actions";
import { authRouteNames } from "../../constant/routes";

function* signInGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { signInData }
    } = action;
    const { data } = yield call(axiosInstance.post, "/auth/signIn", signInData);
    yield put(
      setUserSignFactor({
        authType: signInData.authType,
        ...data
      })
    );
  } catch (error) {
    yield put(onUserError(error?.response?.data));
  }
  yield put(userLoadingSwitch(false));
}

function* userSSOGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { provider }
    } = action;
    yield call(axiosInstance.get, `/auth/${provider}/signin`);
  } catch (error) {
    yield put(onUserError(error?.response?.data));
  }
  yield put(userLoadingSwitch(false));
}

function* setTabId() {
  try {
    const accessToken = localStorage.getItem("userToken");
    const tab_id = uuidv4();
    yield put(setUserTabId(tab_id));

    if (accessToken) {
      yield call(axiosInstance.post, "/auth/tab", { accessToken, tab_id: "" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* mfaCallbackGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { authData }
    } = action;
    const { data } = yield call(axiosInstance.post, "/auth/mfaCallback", authData);

    const { token } = data;
    if (token) {
      yield put(setUserToken(token));
      yield put(getUserByToken(token));
      yield put(getPermissions());
      // yield put(getUserEmail(token));
    } else {
      yield put(setUserFactorStatus(data));
    }
  } catch (error) {
    yield put(onUserError(error?.response?.data));
  }
  yield put(userLoadingSwitch(false));
}

function* getUserByTokenGenerator(action) {
  yield put(userGetLoadingSwitch(true));
  try {
    const {
      payload: { token }
    } = action;
    localStorage.setItem("userToken", token);

    const { data } = yield call(axiosInstance.get, "/auth/me", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    yield put(setUserLocal(data));
    yield put(onSuccessMeViews(data));
  } catch (error) {
    yield put(resetUserData());
  }
  yield put(userGetLoadingSwitch(false));
}

function* getUserEmailByTokenGenerator(action) {
  // yield put(userGetLoadingSwitch(true));
  try {
    const {
      payload: { token }
    } = action;
    const { data } = yield call(axiosInstance.get, "/auth/email/me", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    yield put(setUserEmail(data));
  } catch (error) {
    // yield put(resetUserData());
  }
  // yield put(userGetLoadingSwitch(false));
}

function* getUserEmailGenerator() {
  yield put(switchUserEmailLoading(true));
  try {
    const { data } = yield call(axiosInstance.get, "auth/email/me");
    if (typeof data?.type === "number") {
      yield put(setUserEmail(data));
    }
  } catch (error) {
    yield put(setUserEmailError(error.response?.data?.message));
  }
  yield put(switchUserEmailLoading(false));
}

function* logoutEmailGenerator(action) {
  const {
    payload: { messageFunction }
  } = action;
  try {
    yield call(axiosSagaRequest, "get", "auth/email/logout");
    yield put(removeUserEmail());
    yield messageFunction && messageFunction("success", "Sign out was successful.");
  } catch (e) {
    console.log(e, "this is error");
    yield messageFunction && messageFunction("error", e.msg || e.message || "An error occurred while signing out.");
  }
}

// forgot/reset password

function* forgotPasswordGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { email }
    } = action;
    yield call(axiosInstance.post, "/auth/getResetPasswordCode", {
      email
    });
    yield put(setUserResetPasswordStatus("RESET_CODE_SENT"));
  } catch (error) {
    // Check if it work fine in sign up
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onUserError(error?.response?.data));
  }
  // yield put(setUserResetPasswordStatus('RESET_CODE_SENT'));
  yield put(userLoadingSwitch(false));
}

function* resetPasswordGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { email, newPassword, resetCode }
    } = action;
    yield call(axiosInstance.post, "/auth/resetPassword", {
      email,
      new_password: newPassword,
      reset_code: resetCode
    });
    yield put(setUserResetPasswordStatus("RESET_SUCCESS"));
  } catch (error) {
    yield put(onUserError(error?.response?.data));
  }
  // yield put(setUserResetPasswordStatus('RESET_SUCCESS'));
  yield put(userLoadingSwitch(false));
}

function* refreshTokenGenerator() {
  const savedToken = localStorage.getItem("userToken");
  try {
    const { data } = yield call(axiosInstance.post, "/auth/accessToken", { accessToken: savedToken });
    if (data && savedToken !== data?.token) {
      yield put(setUserToken(data?.token));
    }
  } catch (error) {
    localStorage.clear();
    console.log(error);
  }
}

// function* refreshTokenGenerator() {
//     try {
//         const { data } = yield call(axiosInstance.get, '/auth/prolongSession');
//         yield put(setUserToken(data?.token));
//     } catch (error) {
//     }
// }
function* editUserEULAPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* editUserEULAWorker() {
    try {
      const { data } = yield call(axiosInstance.put, "/tenantUser/editEULA");
      yield put(onSuccessUserEULA(data));
    } catch (error) {
      yield put(onUserError(error?.response?.data));
    }
  });
}

function* getUserEULAGenerator(action) {
  yield put(onCallUserEULA());
  try {
    const {
      payload: { token }
    } = action;
    const { data } = yield call(axiosInstance.get, "/tenantUser/eulaAcceptance", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    yield put(onSuccessUserEULA(data));
  } catch (error) {
    yield put(onFailedUserEULA());
  }
}

function* userChangeStatusCallGenerator(action) {
  yield put(userLoadingSwitch(true));
  try {
    const {
      payload: { userId, status }
    } = action;

    // update user status
    yield call(axiosInstance.put, `/tenantUser/changeStatus/${userId}`, { status });
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(userLoadingSwitch(false));
}

function* userChangePasswordGenerator(action) {
  const { payload } = action;
  try {
    yield call(axiosInstance.post, `/tenantUser/changePassword/`, { ...payload.data });
    payload?.message && payload.message("Password changed successfully!", "success");
  } catch (e) {
    payload?.message &&
      payload.message(e?.response?.data?.errors || "Unknown error occurred. Please contact support.", "error");
  }
}

function* updateHistoryGenerator(action) {
  const { payload } = action;
  try {
    yield call(axiosInstance.post, "/tenantUser/history", { data: payload.data });
  } catch (e) {
    console.log(e, "this is error");
  }
}

function* getHistoryGenerator() {
  yield put(onCallGetHistory());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/history");
    yield put(onCallGetHistorySuccess(data));
  } catch (e) {
    yield put(onCallGetHistoryFailure(e));
  }
}

export default function* userSaga() {
  yield takeEvery(USER_SIGN_IN_CALL, signInGenerator);
  yield takeEvery(USER_MFA_CALLBACK, mfaCallbackGenerator);
  yield takeEvery(USER_GET, getUserByTokenGenerator);
  yield takeEvery(USER_EMAIL_GET, getUserEmailGenerator);
  yield takeEvery(LOGOUT_EMAIL, logoutEmailGenerator);
  yield takeEvery(FORGOT_PASSWORD_CALL, forgotPasswordGenerator);
  yield takeEvery(RESET_PASSWORD_CALL, resetPasswordGenerator);
  yield takeEvery(USER_CHANGE_PASSWORD, userChangePasswordGenerator);
  yield takeEvery(USER_REFRESH_TOKEN, refreshTokenGenerator);
  yield takeEvery(editUserEULAPromise, editUserEULAPromiseGenerator);
  yield takeEvery(USER_EULA_GET, getUserEULAGenerator);
  yield takeEvery(GENERATE_TAB_ID, setTabId);
  yield takeEvery(USER_CHANGE_STATUS_CALL, userChangeStatusCallGenerator);
  yield takeEvery(USER_SSO_CALL, userSSOGenerator);
  yield takeEvery(UPDATE_HISTORY, updateHistoryGenerator);
  yield takeEvery(GET_HISTORY, getHistoryGenerator);
}
