import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import { onCallPerson, onFailedPerson, onSuccessPerson } from "./slice";
import { APP_IMPERSONATION_CALL } from "./sagas-actions";

export const prefix = "/tenantUser/impersonateTenantUserId";

function* impersonationCallGenerator(action) {
  try {
    yield put(onCallPerson());
    const {
      payload: { tenantId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${tenantId}`);

    const { host } = window.location;

    let currentSubDomain;
    const dotSplintedHost = host.split(".");

    if (host.includes("localhost")) {
      // eslint-disable-next-line prefer-destructuring
      currentSubDomain = dotSplintedHost[0];
    } else if (dotSplintedHost.length <= 2) {
      currentSubDomain = "";
    } else {
      // eslint-disable-next-line prefer-destructuring
      currentSubDomain = dotSplintedHost[0];
    }

    if (currentSubDomain) {
      window.open(
        `${window.location.protocol}//${window.location.host.replace(currentSubDomain, data.subdomain)}/app/dashboard?impToken=${data.token}`
      );
    } else {
      window.open(
        `${window.location.protocol}//${data.subdomain}.${window.location.host}/app/dashboard?impToken=${data.token}`
      );
    }

    yield put(onSuccessPerson(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedPerson());
  }
}

export default function* impersonateSaga() {
  yield takeEvery(APP_IMPERSONATION_CALL, impersonationCallGenerator);
}
