import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useDidUpdate } from "hooks";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import BackEndColumn from "./backend-column/column";
import FileColumn from "./front-column/column";

const MappingList = ({
  setFileRowsAmount,
  fileColumnNames,
  setIsFirstRowColumnated,
  isFirstRowColumnated,
  setColumnsToSend,
  backendColumnNames,
  fileDataAmount,
  columnsToSend
}) => {
  const columnNamesForFile = useMemo(
    () => fileColumnNames?.map((fileColumnName) => ({ columnName: fileColumnName, token: null })),
    [fileColumnNames]
  );

  const getSpreadSheetColumnName = (column) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let num = column;
    num -= 1;
    let result = "";
    while (num >= 0) {
      result = letters[num % 26] + result;
      num = Math.floor(num / 26) - 1;
    }
    return result;
  };
  const getSpreadSheetColumnNamesForFile = (fieldsArray) =>
    fieldsArray.map((_, index) => ({
      columnName: getSpreadSheetColumnName(index + 1),
      token: null
    }));
  const spreadSheetColumnNames = useMemo(() => getSpreadSheetColumnNamesForFile(fileColumnNames), [fileColumnNames]);

  const columnNamesForBackend = useMemo(() => backendColumnNames?.map((item) => ({ columnName: item })), []);

  const initialColumns = useMemo(
    () => ({
      FILE: {
        id: "FILE",
        name: "Imported File",
        list: columnNamesForFile
      },
      BACKEND: {
        id: "BACKEND",
        name: "Available Columns",
        list: columnNamesForBackend
      }
    }),
    []
  );

  const [columns, setColumns] = useState(!isEmpty(columnsToSend) ? columnsToSend : initialColumns);

  useDidUpdate(() => {
    setColumnsToSend(columns);
  }, [columns]);

  const onDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return null;

    if (source.droppableId === destination.droppableId && destination.index === source.index) {
      return null;
    }

    const start = source.droppableId;
    const end = destination.droppableId;
    if (start === end) {
      return null;
    }

    if (start !== "BACKEND" && end !== "BACKEND") {
      const newList = [...columns.FILE.list];
      const updatedList = newList.map((item, index) => {
        if (index === source.index) {
          item = { ...item, token: columns.FILE.list[destination.index].token };
        }
        if (index === destination.index) {
          item = { ...item, token: columns.FILE.list[source.index].token };
        }
        return item;
      });
      const newCol = {
        ...columns.FILE,
        id: "FILE",
        list: updatedList
      };
      setColumns((state) => ({
        ...state,
        [newCol.id]: newCol
      }));
    }
    if (start === "BACKEND") {
      const endList = columns.FILE.list.find((item) => item.columnName === end).token;
      const startList = columns.BACKEND.list;

      if (endList) {
        return null;
      }

      const newStartList = startList.filter((_, idx) => idx !== source.index);

      const newStartCol = {
        ...columns.BACKEND,
        id: start,
        list: newStartList
      };
      const newEndList = columns.FILE.list.map((column) => {
        if (column.columnName === end) {
          return {
            ...column,
            token: startList[source.index].columnName
          };
        }
        return column;
      });

      const newEndCol = {
        ...columns.FILE,
        id: "FILE",
        list: newEndList
      };

      setColumns((state) => ({
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }));
    } else if (destination.droppableId === "BACKEND") {
      const startList = columns.FILE.list;
      const newStartList = startList.map((column) => {
        if (column.columnName === start) {
          return {
            ...column,
            token: null
          };
        }
        return column;
      });

      const newStartCol = {
        ...columns.FILE,
        id: "FILE",
        list: newStartList
      };
      const endList = [...columns.BACKEND.list];
      endList.splice(destination.index, 0, { columnName: startList[source.index].token });
      const newEndCol = {
        ...columns.BACKEND,
        id: end,
        list: endList
      };
      setColumns((state) => ({
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }));
    }
  };

  const updateFileColumnNames = (e) => {
    const newColumns = { ...columns };
    columns.FILE.list = e.target.checked ? columnNamesForFile : spreadSheetColumnNames;
    columns.BACKEND.list = columnNamesForBackend;
    if (fileDataAmount > 0 && !e.target.checked) {
      setFileRowsAmount((prev) => prev + 1);
    }
    if (fileDataAmount > 0 && e.target.checked) {
      setFileRowsAmount((prev) => prev - 1);
    }
    setIsFirstRowColumnated((prev) => !prev);
    setColumns(newColumns);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <FormControlLabel
          label="First Row Contains Column Names"
          control={<Checkbox checked={isFirstRowColumnated} onChange={(e) => updateFileColumnNames(e)} />}
        />
        <Box display="flex">
          <FileColumn column={columns.FILE} />
          <BackEndColumn column={columns.BACKEND} />
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default MappingList;
