export const GET_NOTE_MESSAGES_TABLE_DATA = "GET_NOTE_MESSAGES_TABLE_DATA";
export const CREATE_NEW_NOTE_MESSAGE = "CREATE_NEW_NOTE_MESSAGE";
export const UPLOAD_PDF_TO_BACKEND = "UPLOAD_PDF_TO_BACKEND";
export const DELETE_NOTE_MESSAGE = "DELETE_NOTE_MESSAGE";
export const CREATE_NEW_NOTE_MESSAGE_INTERNAL = "CREATE_NEW_NOTE_MESSAGE_INTERNAL";
export const GET_INTERNAL_NOTE_MESSAGES_TABLE_DATA = "GET_INTERNAL_NOTE_MESSAGES_TABLE_DATA";


export function getNoteMessagesTableData({ page, pageSize, encryptedDocumentNumber, encryptedTenantId }) {
  return {
    type: GET_NOTE_MESSAGES_TABLE_DATA,
    page,
    pageSize,
    encryptedDocumentNumber,
    encryptedTenantId
  };
}


export function getInternalNoteMessagesTableData({ page, pageSize, documentNumber }) {
  return {
    type: GET_INTERNAL_NOTE_MESSAGES_TABLE_DATA,
    documentNumber,
    page,
    pageSize
  };
}

export function createNewNoteMessage(payload) {
  return {
    type: CREATE_NEW_NOTE_MESSAGE,
    payload
  };
}

export function uploadPdfToBackend(payload) {
  return {
    type: UPLOAD_PDF_TO_BACKEND,
    payload
  };
}

export function deleteNoteMessage() {
  return {
    type: DELETE_NOTE_MESSAGE
  };
}

export function createNewNoteMessageInternal(payload) {
  return {
    type: CREATE_NEW_NOTE_MESSAGE_INTERNAL,
    payload
  };
}
