import { takeLatest, put, call } from "redux-saga/effects";
import { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import { TIMEZONES_CALL } from "./sagas-actions";
import { onCallTimezones, onSuccessTimezones, onFailedTimezones } from "./slice";

function* timezonesCallGenerator(action) {
  yield put(onCallTimezones());
  try {
    const { data } = yield call(axiosSagaRequest, "get", "/base/timezone");
    yield put(onSuccessTimezones(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTimezones());
  }
}

export default function* currenciesWatcherSaga() {
  yield takeLatest(TIMEZONES_CALL, timezonesCallGenerator);
}
