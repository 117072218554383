import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import useDidUpdate from "hooks/common/useDidUpdate";
import { useSnackbar } from "notistack";
import { errorSelectorByIds } from "store/error/selector";
import NetworkError from "models/network-error";
import LoaderLinear from "components/common/loader-liner";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import useDeviceType from "../../../../hooks/common/useDeviceType";

function ActionDialog({
  title,
  description,
  buttonText,
  cancelButtonText,
  actionButtonDisabled = false,
  closePopup,
  isOpen,
  isLoading,
  errorId,
  actionFunction,
  noButtons,
  children,
  onSuccess,
  disableWhiteSpace = false,
  successSnackMessage
}) {
  const { enqueueSnackbar } = useSnackbar();
  const resError = useSelector((store) => errorSelectorByIds(store, [errorId]), isEqual);

  const { isMobile } = useDeviceType();

  // On error
  useDidUpdate(() => {
    if (resError) {
      const processedError = new NetworkError(resError);
      if (processedError.closeOnFail) {
        closePopup();
      }
    }
  }, [resError]);

  // On success
  useDidUpdate(() => {
    function success() {
      successSnackMessage &&
        enqueueSnackbar(successSnackMessage, {
          variant: "success"
        });
      onSuccess && onSuccess();
      closePopup();
    }

    if (!isLoading) {
      if (resError) {
        if (!resError.isVisible) {
          success();
        }
      } else {
        success();
      }
    }
  }, [isLoading]);

  const handleActionFunction = useCallback(() => {
    actionFunction();
    if (isLoading === undefined) {
      closePopup();
    }
  }, [actionFunction, closePopup]);

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      aria-labelledby="action-dialog-title"
      aria-describedby="action-dialog-description"
      fullWidth
      maxWidth="sm">
      <DialogTitle id="action-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ ...(!disableWhiteSpace && { whiteSpace: isMobile ? "normal" : "pre" }) }}
          id="action-dialog-description">
          {description}
        </DialogContentText>
        {children && children}
      </DialogContent>
      {!noButtons && (
        <DialogActions>
          {actionFunction && (
            <Button onClick={handleActionFunction} type="button" disabled={isLoading || actionButtonDisabled} autoFocus>
              {buttonText}
            </Button>
          )}
          <Button onClick={closePopup} type="button" disabled={isLoading}>
            {cancelButtonText}
          </Button>
        </DialogActions>
      )}
      {isLoading && <LoaderLinear />}
    </Dialog>
  );
}

export default ActionDialog;

ActionDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  errorId: PropTypes.string,
  actionFunction: PropTypes.func,
  noButtons: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  onSuccess: PropTypes.func,
  successSnackMessage: PropTypes.string,
  disableWhiteSpace: PropTypes.bool
};

ActionDialog.defaultProps = {
  title: "",
  description: "",
  buttonText: "Yes",
  cancelButtonText: "Cancel",
  isOpen: false,
  isLoading: undefined,
  errorId: "",
  noButtons: false,
  actionFunction: undefined,
  children: undefined,
  onSuccess: undefined,
  successSnackMessage: undefined,
  disableWhiteSpace: false
};
