import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const ErrorPopup = ({ isOpen, title, onClose, message, submitButton, submitFunction }) => {
  const onSubmit = () => {
    submitFunction();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert severity="error" color="error">
          {message || "There is a server issue. Please contact support"}
        </Alert>
      </DialogContent>
      <DialogActions>
        {submitButton && <Button onClick={onSubmit}>{submitButton}</Button>}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorPopup;
