import { createPromiseAction } from "@adobe/redux-saga-promise";

export const INTEGRATION_PAYMENT_TYPE_ID = 1;
export const INTEGRATION_DISTRIBUTOR_TYPE_ID = 2;
export const INTEGRATION_ACCOUNTING_TYPE_ID = 3;
export const INTEGRATION_MISCELIANEOUS_TYPE_ID = 4;
export const INTEGRATION_PSA_TYPE_ID = 5;
export const INTEGRATION_USER_TENANT = 1001;

export const INTEGRATIONS_CALL = "INTEGRATIONS_CALL";

export const INTEGRATION_STATUS_CHANGE = "INTEGRATION_STATUS_CHANGE";
export const DISCONNECT_INTEGRATION = "DISCONNECT_INTEGRATION";
export const SYNC_INTEGRATION = "SYNC_INTEGRATION";
export const LOGOUT_EMAIL_INTEGRATION = "LOGOUT_EMAIL_INTEGRATION";

export const TENANT_INTEGRATIONS_CALL = "TENANT_INTEGRATIONS_CALL";
export const TENANT_INTEGRATION_ADD_CALL_PROMISE = "TENANT_INTEGRATION_ADD_CALL_PROMISE";
export const TENANT_INTEGRATION_EDIT_CALL_PROMISE = "TENANT_INTEGRATION_EDIT_CALL_PROMISE";
export const TENANT_INTEGRATION_DELETE_CALL_PROMISE = "TENANT_INTEGRATION_DELETE_CALL_PROMISE";
export const TENANT_INTEGRATION_ALLOW_START_PROCESS = "TENANT_INTEGRATION_ALLOW_START_PROCESS";

export const USER_TENANT_INTEGRATIONS_CALL = "TENANT_INTEGRATIONS_CALL";

export const callTenantIntegrationAddPromise = createPromiseAction(TENANT_INTEGRATION_ADD_CALL_PROMISE);
export const callTenantIntegrationEditPromise = createPromiseAction(TENANT_INTEGRATION_EDIT_CALL_PROMISE);
export const callTenantIntegrationDeletePromise = createPromiseAction(TENANT_INTEGRATION_DELETE_CALL_PROMISE);

export function callIntegrations() {
  return { type: INTEGRATIONS_CALL };
}

export function callTenantIntegrations(withoutLoading = false) {
  return { type: TENANT_INTEGRATIONS_CALL, payload: { withoutLoading } };
}

export function callUserTenantIntegrations() {
  return { type: USER_TENANT_INTEGRATIONS_CALL };
}

export function callIntegrationChangeStatus(integrationId, status_id, userTenantIntegration, messageFunction) {
  return {
    type: INTEGRATION_STATUS_CHANGE,
    payload: { integrationId, status_id, userTenantIntegration, messageFunction }
  };
}

export function disconnectIntegration(integrationId, messageFunction) {
  return { type: DISCONNECT_INTEGRATION, payload: { integrationId, messageFunction } };
}

export function callIntegrationSync(data) {
  return { type: SYNC_INTEGRATION, payload: { data } };
}
