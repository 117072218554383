import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "store/error/slice";
import { onSuccessCompanyAttributeAdd, onSuccessCompanyAttributes } from "store/companies/slice";
import appModules from "constant/data/app-modules";
import {
  COMPANY_STATUSES_CALL,
  COMPANY_STATUS_ADD_CALL,
  COMPANY_STATUS_EDIT_CALL,
  COMPANY_STATUS_DELETE_CALL,
  COMPANY_TYPES_CALL,
  COMPANY_TYPE_ADD_CALL,
  COMPANY_TYPE_EDIT_CALL,
  COMPANY_TYPE_DELETE_CALL,
  COMPANY_INDUSTRY_CLASSIFICATIONS_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL,
  COMPANY_ORG_REPS_CALL,
  COMPANY_ORG_REP_ADD_CALL,
  COMPANY_ORG_REP_EDIT_CALL,
  COMPANY_ORG_REP_DELETE_CALL,
  COMPANY_GROUPS_CALL,
  COMPANY_GROUP_ADD_CALL,
  COMPANY_GROUP_EDIT_CALL,
  COMPANY_GROUP_DELETE_CALL,
  COMPANY_CUSTOM_FIELDS_CALL,
  COMPANY_CUSTOM_FIELD_CALL,
  COMPANY_CUSTOM_FIELD_ADD_CALL,
  COMPANY_CUSTOM_FIELD_EDIT_CALL,
  COMPANY_CUSTOM_FIELD_DELETE_CALL,
  COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE,
  callCompanyIndustryClassificationAddPromise
} from "./sagas-actions";
import {
  onCallCompanySettings,
  onSuccessCompanySettings,
  onFailedCompanySettings,
  switchCompanySettingLoading,
  onSuccessCompanySettingAdd,
  onSuccessCompanySettingEdit,
  onSuccessCompanySettingDelete,
  onCallCompanyCustomFields,
  onSuccessCompanyCustomFields,
  onFailedCompanyCustomFields,
  switchCompanyCustomFieldLoading,
  onSuccessCompanyCustomField,
  onFailedCompanyCustomField,
  resetCompanyCustomField,
  onSuccessCompanyCustomFieldAdd,
  onSuccessCompanyCustomFieldEdit,
  onSuccessCompanyCustomFieldDelete,
  onFailedReorderCustomFieldRowsSetting
} from "./slice";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

const companySettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallCompanySettings({
        key
      })
    );
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/company-settings/${url}`);
      yield put(
        onSuccessCompanySettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedCompanySettings({
          key
        })
      );
    }
  };

const companySettingAddCallGenerator = (key, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchCompanySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemData }
      } = action;


      const { data } = yield call(axiosInstance.post, `/tenantUser/company-settings/${url}`, itemData);
      yield put(
        onSuccessCompanySettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessCompanyAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchCompanySettingLoading({
        key,
        status: false
      })
    );
  };

const companySettingEditCallGenerator = (key, idKey, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchCompanySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `/tenantUser/company-settings/${url}/${itemId}`, itemData);
      yield put(
        onSuccessCompanySettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(onSuccessCompanyAttributes({
        key,
        data: data?.[apiReturnKey],
        type: 'edit',
        idKey
      }));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchCompanySettingLoading({
        key,
        status: false
      })
    );
  };

function* companyIndustryClassificationAddCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* noteAddWorker() {
    yield put(
      switchCompanySettingLoading({
        key: 'industryClassification',
        status: true
      })
    );
    try {
      const { payload } = action;
      const { data } = yield call(axiosInstance.post, "/tenantUser/company-settings/company_industry_classification", payload);
      yield put(
        onSuccessCompanySettingAdd({
          key: "industryClassification",
          data: data?.createdCompanyIndustryClassification
        })
      );
      yield put(
        onSuccessCompanyAttributeAdd({
          key: "industryClassification",
          data: data?.createdCompanyIndustryClassification
        })
      );
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchCompanySettingLoading({
        key: 'industryClassification',
        status: false
      })
    );
  });
}

const companySettingDeleteCallGenerator = (key, idKey, url) =>
  function* gen(action) {
    yield put(
      switchCompanySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `/tenantUser/company-settings/generic_delete/${itemId}/${url}`);
      yield put(
        onSuccessCompanySettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchCompanySettingLoading({
        key,
        status: false
      })
    );
  };

function* companyCustomFieldsCallGenerator(action) {
  yield put(onCallCompanyCustomFields());
  try {
    const { data } = yield call(
      axiosInstance.get,
      `/tenantUser/custom-fields/view/${appModules.find((t) => t.name === "Company").code}`
    );

    yield put(onSuccessCompanyCustomFields(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyCustomFields());
  }
}

function* companyCustomFieldCallGenerator(action) {
  yield put(resetCompanyCustomField());
  try {
    const {
      payload: { itemId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/custom-fields/single/${itemId}`);

    yield put(onSuccessCompanyCustomField(data?.custom_field_definition));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyCustomField());
  }
}

function* companyCustomFieldAddCallGenerator(action) {
  yield put(switchCompanyCustomFieldLoading(true));
  try {
    const {
      payload: { itemData }
    } = action;
    const { data } = yield call(axiosInstance.post, "/tenantUser/custom-fields", {
      ...itemData,
      app_module_id: appModules.find((aM) => aM.name === "Company").code
    });
    const createdSalesTax = data.custom_field_definition[0];

    yield put(onSuccessCompanyCustomFieldAdd(createdSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyCustomFieldLoading(false));
}

function* companyCustomFieldEditCallGenerator(action) {
  yield put(switchCompanyCustomFieldLoading(true));
  try {
    const {
      payload: { itemId, itemData }
    } = action;
    const { data } = yield call(axiosInstance.put, `/tenantUser/custom-fields/${itemId}`, {
      ...itemData,
      app_module_id: appModules.find((aM) => aM.name === "Company").code
    });
    const editedSalesTax = data.custom_field_definition[0];

    yield put(onSuccessCompanyCustomFieldEdit(editedSalesTax));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyCustomFieldLoading(false));
}

function* companyCustomFieldDeleteCallGenerator(action) {
  yield put(switchCompanyCustomFieldLoading(true));
  try {
    const {
      payload: { itemId, moduleId }
    } = action;
    yield call(axiosInstance.delete, `/tenantUser/custom-fields/${itemId}?moduleId=${moduleId}`);
    yield put(onSuccessCompanyCustomFieldDelete(itemId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyCustomFieldLoading(false));
}

function* updateCustomFieldRowsPositionGenerator(action) {
  const { positions, oldPositions } = action.payload;
  try {
    yield call(axiosInstance.put, "/tenantUser/changePosition/custom_field_definition", positions);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedReorderCustomFieldRowsSetting({ key: "customField", data: oldPositions }));
  }
}

export default function* companySettingsSaga() {
  yield takeEvery(COMPANY_STATUSES_CALL, companySettingsCallGenerator("status", "company_status_list"));
  yield takeEvery(
    COMPANY_STATUS_ADD_CALL,
    companySettingAddCallGenerator("status", "company_status", "createdCompanyStatus")
  );
  yield takeEvery(
    COMPANY_STATUS_EDIT_CALL,
    companySettingEditCallGenerator("status", "company_status_id", "company_status", "updatedCompanyStatus")
  );
  yield takeEvery(
    COMPANY_STATUS_DELETE_CALL,
    companySettingDeleteCallGenerator("status", "company_status_id", "company_status")
  );

  yield takeEvery(COMPANY_TYPES_CALL, companySettingsCallGenerator("type", "company_type_list"));
  yield takeEvery(COMPANY_TYPE_ADD_CALL, companySettingAddCallGenerator("type", "company_type", "createdCompanyType"));
  yield takeEvery(
    COMPANY_TYPE_EDIT_CALL,
    companySettingEditCallGenerator("type", "company_type_id", "company_type", "updatedCompanyType")
  );
  yield takeEvery(
    COMPANY_TYPE_DELETE_CALL,
    companySettingDeleteCallGenerator("type", "company_type_id", "company_type")
  );

  yield takeEvery(
    COMPANY_INDUSTRY_CLASSIFICATIONS_CALL,
    companySettingsCallGenerator("industryClassification", "company_industry_classification_list")
  );
  yield takeEvery(
    COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL,
    companySettingAddCallGenerator(
      "industryClassification",
      "company_industry_classification",
      "createdCompanyIndustryClassification"
    )
  );
  yield takeEvery(callCompanyIndustryClassificationAddPromise, companyIndustryClassificationAddCallPromiseGenerator);
  yield takeEvery(
    COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL,
    companySettingEditCallGenerator(
      "industryClassification",
      "company_industry_classification_id",
      "company_industry_classification",
      "updatedClassification"
    )
  );
  yield takeEvery(
    COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL,
    companySettingDeleteCallGenerator(
      "industryClassification",
      "company_industry_classification_id",
      "company_industry_classification"
    )
  );


  yield takeEvery(
    COMPANY_ORG_REPS_CALL,
    companySettingsCallGenerator("tenant_users", "tenant_users_list")
  );
  yield takeEvery(
    COMPANY_ORG_REP_ADD_CALL,
    companySettingAddCallGenerator(
      "orgRep",
      "tenant_user_list",
      "createdCompanyOrgRep"
    )
  );
  yield takeEvery(
    COMPANY_ORG_REP_EDIT_CALL,
    companySettingEditCallGenerator(
      "org_rep",
      "tenant_user_id",
      "org_rep",
      "updatedOrgRep"
    )
  );
  yield takeEvery(
    COMPANY_ORG_REP_DELETE_CALL,
    companySettingDeleteCallGenerator(
      "org_rep",
      "tenant_user_id",
      "org_rep"
    )
  );

  yield takeEvery(COMPANY_GROUPS_CALL, companySettingsCallGenerator("group", "company_group_list"));
  yield takeEvery(
    COMPANY_GROUP_ADD_CALL,
    companySettingAddCallGenerator("group", "company_group", "createdCompanyGroup")
  );
  yield takeEvery(
    COMPANY_GROUP_EDIT_CALL,
    companySettingEditCallGenerator("group", "company_group_id", "company_group", "updatedCompanyGroup")
  );
  yield takeEvery(
    COMPANY_GROUP_DELETE_CALL,
    companySettingDeleteCallGenerator("group", "company_group_id", "company_group")
  );

  yield takeEvery(COMPANY_CUSTOM_FIELDS_CALL, companyCustomFieldsCallGenerator);
  yield takeEvery(COMPANY_CUSTOM_FIELD_CALL, companyCustomFieldCallGenerator);
  yield takeEvery(COMPANY_CUSTOM_FIELD_ADD_CALL, companyCustomFieldAddCallGenerator);
  yield takeEvery(COMPANY_CUSTOM_FIELD_EDIT_CALL, companyCustomFieldEditCallGenerator);
  yield takeEvery(COMPANY_CUSTOM_FIELD_DELETE_CALL, companyCustomFieldDeleteCallGenerator);
  yield takeEvery(COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE, updateCustomFieldRowsPositionGenerator);
}
