import { io } from "socket.io-client";
import { BASE_URL } from "constant/env-variables";

const socketInstance = io(BASE_URL, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionDelay: 5000,
});

export default socketInstance;
