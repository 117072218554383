import React, { memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import useStyles from "./style";

const Loader = memo(({ fixed }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background} />
      <div className={clsx(classes.loader, { fixed })}>
        <CircularProgress size={36} thickness={4} />
      </div>
    </>
  );
});

Loader.propTypes = {
  fixed: PropTypes.bool
};

Loader.defaultProps = {
  fixed: false
};

export default Loader;
