import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    notes: [],
    note: {
      isSingleFetched: false,
      data: undefined
    },
    isLoading: false,
    script: {
      isFetched: false,
      list: []
    },
    type: {
      isFetched: false,
      list: []
    },
    objection: {
      isFetched: false,
      list: []
    },
    selectedDefaultData: {
      type: null,
      id: null
    }
  },
  reducers: {
    onCallNoteTypes: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onSuccessNote: (state, { payload }) => {
      state.note = {
        data: {
          ...payload.note,

        },
        isSingleFetched: true
      };
    },
    onFailedNote: (state) => {
      state.note = {
        isSingleFetched: true,
        data: undefined
      };
    },
    resetNote: (state) => {
      state.note = {
        isSingleFetched: false,
        data: undefined
      };
    },
    switchNotesLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    onCallNoteScripts: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onCallNoteObjections: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
    },
    onSuccessNoteTypes: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData.map((item) => ({
        code: item.note_type_id,
        name: item.type
      }));
    },
    onSuccessNoteScripts: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData.map((item) => ({
        code: item.template_id,
        name: item.name,
        desc: item.body
      }));
    },
    onSuccessNoteObjections: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData.map((item) => ({
        code: item.template_id,
        name: item.name,
        desc: item.body,
        isSelected: false
      }));
    },
    onFailedNoteTypes: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },
    onFailedNoteScripts: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },
    onFailedNoteObjections: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
    },
    onToggleDefaultData: (state, { payload }) => {
      state.selectedDefaultData = {
        type: payload.type,
        id: payload.id
      };
    }
  }
});

export const {
  onSuccessNote,
  onFailedNote,
  resetNote,
  onCallNoteTypes,
  onSuccessNoteTypes,
  onFailedNoteTypes,
  onCallNoteScripts,
  onSuccessNoteScripts,
  onFailedNoteScripts,
  onCallNoteObjections,
  onSuccessNoteObjections,
  onFailedNoteObjections,
  switchNotesLoading,
  onSuccessNoteAdd,
  onToggleDefaultData
} = notesSlice.actions;

export default notesSlice.reducer;
