import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@mui/material";

function DialogWrapper({ isOpen, children, dialogProps, maxWidth }) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby={dialogProps?.["aria-labelledby"] || "dialog-title"}
      aria-describedby={dialogProps?.["aria-describedby"] || "dialog-description"}
      fullWidth
      maxWidth={maxWidth || "sm"}
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
}

export default DialogWrapper;

DialogWrapper.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
  dialogProps: PropTypes.shape({
    "aria-labelledby": PropTypes.string,
    "aria-describedby": PropTypes.string
  })
};

DialogWrapper.defaultProps = {
  isOpen: false,
  dialogProps: undefined
};
