import { createPromiseAction } from "@adobe/redux-saga-promise";

export const APP_CONTENTS_CALL = "APP_CONTENTS_CALL";
export const APP_CONTENTS_EDIT_CALL = "APP_CONTENTS_EDIT_CALL";
export const RESET_MFA_CALL = "RESET_MFA_CALL";
export const ADMIN_LOGS_CALL = "ADMIN_LOGS_CALL";
export const ADMIN_LOGS_CALL_PROMISE = "ADMIN_LOGS_CALL_PROMISE";
export const GET_APP_VERSION = "GET_APP_VERSION";
export const APP_TEMPLATES_CALL = "APP_TEMPLATES_CALL";
export const APP_TEMPLATES_CARD_CALL = "APP_TEMPLATES_CARD_CALL";
export const APP_SINGLE_TEMPLATE_CALL = "APP_SINGLE_TEMPLATE_CALL";
export const APP_TEMPLATES_ADD_CALL = "APP_TEMPLATES_ADD_CALL";
export const APP_TEMPLATES_EDIT_CALL = "APP_TEMPLATES_EDIT_CALL";
export const APP_TEMPLATES_DELETE_CALL = "APP_TEMPLATES_DELETE_CALL";
export const IMPORT_APP_TEMPLATE = "IMPORT_APP_TEMPLATE";

export const APP_TEMPLATES_CALL_PROMISE = "APP_TEMPLATES_CALL_PROMISE";

export const callAdminLogsPromise = createPromiseAction(ADMIN_LOGS_CALL_PROMISE);
export const callAppTemplatesPromise = createPromiseAction(APP_TEMPLATES_CALL_PROMISE);

export function callAppContents() {
  return { type: APP_CONTENTS_CALL };
}

export function getAppVersion() {
  return { type: GET_APP_VERSION };
}

export function callEditContent(contentId, contentData, messageFunction) {
  return { type: APP_CONTENTS_EDIT_CALL, payload: { contentId, contentData, messageFunction } };
}

export function callResetMfa(data, messageFunction) {
  return { type: RESET_MFA_CALL, payload: { mfaData: data, messageFunction } };
}

export function callAdminLogs(paginationData) {
  return { type: ADMIN_LOGS_CALL, payload: { paginationData } };
}

export function callAppTemplates(paginationData) {
  return { type: APP_TEMPLATES_CALL, payload: { paginationData } };
}

export function callSingleAppTemplate(appTemplateId) {
  return { type: APP_SINGLE_TEMPLATE_CALL, appTemplateId };
}

export function callAddAppTemplate({ templateData, newImage, messageFunction }) {
  return { type: APP_TEMPLATES_ADD_CALL, payload: { templateData, newImage, messageFunction } };
}

export function callEditAppTemplate({ templateId, templateData, newImage, messageFunction }) {
  return { type: APP_TEMPLATES_EDIT_CALL, payload: { templateId, templateData, newImage, messageFunction } };
}

export function callDeleteAppTemplate(templateId) {
  return { type: APP_TEMPLATES_DELETE_CALL, payload: { templateId } };
}

export function importAppTemplate(data, messageFunction) {
  return {
    type: IMPORT_APP_TEMPLATE,
    payload: { data, messageFunction },
  };
}

export function callAppTemplatesCard(paginationData) {
  return {
    type: APP_TEMPLATES_CARD_CALL,
    payload: { paginationData },
  };
}
