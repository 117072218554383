import { createPromiseAction } from "@adobe/redux-saga-promise";

export const PRODUCTS_SERVICES_CALL = "PRODUCTS_SERVICES_CALL";
export const PRODUCTS_SERVICES_CALL_PROMISE = "PRODUCTS_SERVICES_CALL_PROMISE";
export const PRODUCTS_SERVICE_CALL = "PRODUCTS_SERVICE_CALL";
export const PRODUCTS_SERVICE_ADD_CALL = "PRODUCTS_SERVICE_ADD_CALL";
export const PRODUCTS_SERVICE_EDIT_CALL = "PRODUCTS_SERVICE_EDIT_CALL";
export const PRODUCTS_SERVICES_DELETE_CALL = "PRODUCTS_SERVICES_DELETE_CALL";
export const PRODUCTS_SERVICES_RESTORE_CALL = "PRODUCTS_SERVICES_RESTORE_CALL";

export const PRODUCTS_SERVICES_CALL_STATUSES = "PRODUCTS_SERVICES_CALL_STATUSES";
export const PRODUCTS_SERVICES_CALL_TYPES = "PRODUCTS_SERVICES_CALL_TYPES";
export const PRODUCTS_SERVICES_CALL_CATEGORIES = "PRODUCTS_SERVICES_CALL_CATEGORIES";
export const PRODUCTS_SERVICES_CALL_SUB_CATEGORIES = "PRODUCTS_SERVICES_CALL_SUB_CATEGORIES";
export const PRODUCTS_SERVICES_CALL_MANUFACTURERS = "PRODUCTS_SERVICES_CALL_MANUFACTURERS";
export const PRODUCTS_SERVICES_CALL_UOMS = "PRODUCTS_SERVICES_CALL_UOMS";
export const PRODUCTS_SERVICES_CALL_RECURRENCES = "PRODUCTS_SERVICES_CALL_RECURRENCES";
export const PRODUCTS_SERVICES_CALL_PERIODS = "PRODUCTS_SERVICES_CALL_PERIODS";

// Right click
export const PRODUCTS_SERVICES_CHANGE_STATUSES = "PRODUCTS_SERVICES_CHANGE_STATUSES";
export const PRODUCTS_SERVICES_CHANGE_TYPES = "PRODUCTS_SERVICES_CHANGE_TYPES";
export const PRODUCTS_SERVICES_CHANGE_CATEGORIES = "PRODUCTS_SERVICES_CHANGE_CATEGORIES";
export const PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES = "PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES";
export const PRODUCTS_SERVICES_CHANGE_MANUFACTURERS = "PRODUCTS_SERVICES_CHANGE_MANUFACTURERS";
export const PRODUCTS_SERVICES_CHANGE_UOMS = "PRODUCTS_SERVICES_CHANGE_UOMS";
// For Settings
export const PRODUCTS_SERVICE_STATUSES_CALL = "PRODUCTS_SERVICE_STATUSES_CALL";
export const PRODUCTS_SERVICE_STATUS_ADD_CALL = "PRODUCTS_SERVICE_STATUS_ADD_CALL";
export const PRODUCTS_SERVICE_STATUS_EDIT_CALL = "PRODUCTS_SERVICE_STATUS_EDIT_CALL";
export const PRODUCTS_SERVICE_STATUS_DELETE_CALL = "PRODUCTS_SERVICE_STATUS_DELETE_CALL";

export const PRODUCTS_SERVICE_TYPES_CALL = "PRODUCTS_SERVICE_TYPES_CALL";
export const PRODUCTS_SERVICE_TYPE_ADD_CALL = "PRODUCTS_SERVICE_TYPE_ADD_CALL";
export const PRODUCTS_SERVICE_TYPE_EDIT_CALL = "PRODUCTS_SERVICE_TYPE_EDIT_CALL";
export const PRODUCTS_SERVICE_TYPE_DELETE_CALL = "PRODUCTS_SERVICE_TYPE_DELETE_CALL";

export const PRODUCTS_SERVICE_CATEGORIES_CALL = "PRODUCTS_SERVICE_CATEGORIES_CALL";
export const PRODUCTS_SERVICE_CATEGORY_ADD_CALL = "PRODUCTS_SERVICE_CATEGORY_ADD_CALL";
export const PRODUCTS_SERVICE_CATEGORY_EDIT_CALL = "PRODUCTS_SERVICE_CATEGORY_EDIT_CALL";
export const PRODUCTS_SERVICE_CATEGORY_DELETE_CALL = "PRODUCTS_SERVICE_CATEGORY_DELETE_CALL";

export const PRODUCTS_SERVICE_SUB_CATEGORIES_CALL = "PRODUCTS_SERVICE_SUB_CATEGORIES_CALL";
export const PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL = "PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL";
export const PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL = "PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL";
export const PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL = "PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL";

export const PRODUCTS_SERVICE_MANUFACTURERS_CALL = "PRODUCTS_SERVICE_MANUFACTURERS_CALL";
export const PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL = "PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL";
export const PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL = "PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL";
export const PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL = "PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL";

export const PRODUCTS_SERVICE_UOMS_CALL = "PRODUCTS_SERVICE_UOMS_CALL";
export const PRODUCTS_SERVICE_UOM_ADD_CALL = "PRODUCTS_SERVICE_UOM_ADD_CALL";
export const PRODUCTS_SERVICE_UOM_EDIT_CALL = "PRODUCTS_SERVICE_UOM_EDIT_CALL";
export const PRODUCTS_SERVICE_UOM_DELETE_CALL = "PRODUCTS_SERVICE_UOM_DELETE_CALL";

export const PRODUCTS_SERVICE_RECURRENCES_CALL = "PRODUCTS_SERVICE_RECURRENCES_CALL";
export const PRODUCTS_SERVICE_RECURRENCE_ADD_CALL = "PRODUCTS_SERVICE_RECURRENCE_ADD_CALL";
export const PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL = "PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL";
export const PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL = "PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL";

export const GET_PRODUCT_VENDOR = "GET_PRODUCT_VENDOR";

export const callProductsServicesPromise = createPromiseAction(PRODUCTS_SERVICES_CALL_PROMISE);
export const getProductVendorPromise = createPromiseAction(GET_PRODUCT_VENDOR);

export function callProductServices(paginationData, withoutLoading) {
  return { type: PRODUCTS_SERVICES_CALL, payload: { paginationData, withoutLoading } };
}

export function callProductsService(productsServiceId) {
  return { type: PRODUCTS_SERVICE_CALL, payload: { productsServiceId } };
}

export function addProductsService(productsServiceData, newPhotoFile, messageFunction) {
  return { type: PRODUCTS_SERVICE_ADD_CALL, payload: { productsServiceData, newPhotoFile, messageFunction } };
}

export function editProductsService(productsServiceId, productsServiceData, newPhotoFile, messageFunction) {
  return {
    type: PRODUCTS_SERVICE_EDIT_CALL,
    payload: { productsServiceId, productsServiceData, newPhotoFile, messageFunction }
  };
}

export function deleteProductsServices(productsServiceIds) {
  return { type: PRODUCTS_SERVICES_DELETE_CALL, payload: { productsServiceIds } };
}

// SETTINGS

export const callProductsServiceSettings = (actionName) => () => ({ type: actionName });

export function callAddProductsServiceSetting(actionName, itemData) {
  return { type: actionName, payload: { itemData } };
}

export function callEditProductsServiceSetting(actionName, itemId, itemData) {
  return { type: actionName, payload: { itemId, itemData } };
}

export function callDeleteProductsServiceSetting(actionName, itemId) {
  return { type: actionName, payload: { itemId } };
}

// ATTRIBUTES

export function callProductsServiceAttributes(type, category_id) {
  return { type, payload: { id: category_id } };
}

export function callProductsServicesChangeAttributes(type, data) {
  return { type, payload: { data } };
}
