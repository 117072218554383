export const ORGANIZATION_DATA_CALL = "ORGANIZATION_DATA_CALL";
export const ORGANIZATION_DATA_EDIT_CALL = "ORGANIZATION_DATA_EDIT_CALL";
export const SUBUSER_EMAIL_REPUTATION = "SUBUSER_EMAIL_REPUTATION";

export function callGetOrganizationData() {
  return {
    type: ORGANIZATION_DATA_CALL
  };
}

export function callEditOrganizationData(organizationData, newLogoFile, messageFunction) {
  return {
    type: ORGANIZATION_DATA_EDIT_CALL,
    payload: { organizationData, newLogoFile, messageFunction }
  };
}

export function callGetEmailReputation() {
  return {
    type: SUBUSER_EMAIL_REPUTATION
  };
}
