import { useMemo } from "react";
import PropTypes from "prop-types";
import { useCurrency } from "hooks";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { currencyExchangeRatesDataSelector } from "store/general/selectors";

/**
 * This hook converts different currencies to given one
 * @param {Object} listOfItems - List of items to convert to given currency
 * @param {String} toCurrencyId - Default convert currency id
 */

// TODO -> get exchangeRates from api with toCurrencyId base
const useCurrencyExchange = (listOfItems, toCurrencyId) => {
  const { data: exchangeRates } = useSelector(currencyExchangeRatesDataSelector, isEqual);

  const { currency, getCurrencyData } = useCurrency(toCurrencyId);

  const convertedItems = useMemo(
    () =>
      listOfItems
        .filter((item) => item?.amount !== undefined && item?.currency_id !== undefined)
        .map((item) => {
          const itemCurrencyIso = getCurrencyData(item?.currency_id)?.iso;
          const rate = exchangeRates[itemCurrencyIso || "USD"];

          return {
            ...item,
            amount: parseFloat((item.amount / rate).toFixed(currency.minorUnit)),
            code: currency.iso
          };
        }),
    [exchangeRates, listOfItems, toCurrencyId]
  );

  return {
    // convert,
    convertedItems
  };
};

useCurrencyExchange.propTypes = {
  exchangeRates: PropTypes.arrayOf().isRequired,
  toCurrencyId: PropTypes.number
};

useCurrencyExchange.defaultProps = {
  defaultToCurrency: null
};

export default useCurrencyExchange;
