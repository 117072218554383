export const authRouteNames = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password"
};

export const thirdPartAuthRouteNames = {
  AUTHENTICATION: "/authentication",
  UNSUBSCRIPTION: "/subscription_management"
};

export const routeNames = {
  APP: "/app",
  APP_DASHBOARD: "/app/dashboard",
  APP_TASKS: "/app/tasks",
  APP_DASHBOARD_FULLSCREEN: "/app/dashboard/fullscreen",
  APP_ACCOUNT: "/app/account",
  APP_OPPORTUNITIES: "/app/opportunities",
  APP_DOCUMENTS: "/app/documents",
  APP_TEMPLATES_DOCUMENTS: "/app/templates/documents",
  APP_COMPANIES: "/app/companies",
  APP_CONTACTS: "/app/contacts",
  APP_ORGANIZATION: "/app/organization",
  APP_USERS: "/app/users",
  APP_TEMPLATES: "/app/templates",
  APP_AUDIT_LOG: "/app/app_log",
  APP_PRODUCTS_SERVICES: "/app/products-services",
  APP_ADMINISTRATION: "/app/administration",
  APP_IMPERSONATION: "/app/impersonation",
  APP_WORKFLOW: "/app/workflows",
  APP_SEQUENCE: "/app/sequences",
  APP_INTEGRATIONS: "/app/integrations",
  APP_DOCUMENT_WIZARD: "/app/document-wizard",
  APP_DELETED_ITEMS: "/app/deleted-items"
};

export const organizationRouteNames = {
  GENERAL: "/general",
  TASK: "/task",
  SALES_TAX: "/sales-tax",
  USER: "/user",
  SECURITY: "/security",
  COMPANY: "/company",
  CONTACT: "/contact",
  OPPORTUNITY: "/opportunity",
  DOCUMENT: "/document",
  PRODUCTS_SERVICES: "/products-services"
};

export const integrationsRouteNames = {
  PAYMENT_METHODS: "/payment-methods",
  DISTRIBUTORS: "/distributors",
  ACCOUNTINGS: "/accounting",
  MISCELLANEOUS: "/miscellaneous",
  PSA: "/psa"
};

export const routesByIdProperty = {
  contact_id: routeNames.APP_CONTACTS,
  company_id: routeNames.APP_COMPANIES,
  opportunity_id: routeNames.APP_OPPORTUNITIES
};
