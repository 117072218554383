import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "Admin",
  initialState: {
    isFetched: false,
    isLoading: false,
    contents: [],
    content: {
      isFetched: false,
      isLoading: false
    },
    admin_logs: {
      isFetched: false,
      columnNames: [],
      tableData: [],
      count: 0,
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      }
    },
    version: {
      isFetched: false,
      data: {},
      error: null
    },
    cardTemplates: {
      isFetched: false,
      isLoading: false,
      data: [],
      error: null
    },
    importAppTemplate: {
      isFetched: false,
      isLoading: false,
      data: {},
      error: null,
    },
    singleTemplate: {
      isLoading: false,
      isFetched: false,
      data: {},
      error: null
    },
    templates: {
      isFetched: false,
      isLoading: false,
      columnNames: [],
      tableData: [],
      count: 0,
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      }
    }
  },
  reducers: {
    onCallCardTemplates: (state) => {
      state.cardTemplates.isFetched = false;
      state.cardTemplates.isLoading = true;
    },
    onCallCardTemplatesSuccess: (state, { payload }) => {
      state.cardTemplates.isFetched = true;
      state.cardTemplates.isLoading = false;
      state.cardTemplates.data = payload;
      state.cardTemplates.error = null;
    },
    onCallCardTemplatesFailure: (state, { payload }) => {
      state.cardTemplates.isFetched = false;
      state.cardTemplates.isLoading = true;
      state.cardTemplates.error = payload;
    },
    onImportAppTemplate: (state) => {
      state.importAppTemplate.isFetched = false;
      state.importAppTemplate.isLoading = true;
    },
    onImportAppTemplateSuccess: (state, { payload }) => {
      state.importAppTemplate.isFetched = true;
      state.importAppTemplate.isLoading = false;
      state.importAppTemplate.data = payload;
    },
    onImportAppTemplateFailure: (state, { payload }) => {
      state.importAppTemplate.isFetched = true;
      state.importAppTemplate.isLoading = false;
      state.importAppTemplate.error = payload;
    },
    onCallSingleAppTemplate: (state) => {
      state.singleTemplate.isFetched = false;
      state.singleTemplate.isLoading = true;
    },
    onCallSingleAppTemplateSuccess: (state, { payload }) => {
      state.singleTemplate.isFetched = true;
      state.singleTemplate.isLoading = false;
      state.singleTemplate.data = payload;
    },
    onCallSingleAppTemplateFailure: (state, { payload }) => {
      state.singleTemplate.isFetched = true;
      state.singleTemplate.isLoading = false;
      state.singleTemplate.error = payload;
    },
    onCallAppVersion: (state) => {
      state.version.isFetched = false;
      state.version.data = {};
    },
    onSuccessCallAppVersion: (state, { payload }) => {
      state.version.isFetched = true;
      state.version.data = payload;
    },
    onFailedCallAppVersion: (state, { payload }) => {
      state.version.isFetched = true;
      state.version.error = payload;
      state.version.data = {};
    },
    onCallContents: (state) => {
      state.isFetched = false;
      state.contents = [];
    },
    onSuccessContents: (state, { payload }) => {
      state.isFetched = true;
      state.contents = payload;
    },
    onFailedContents: (state) => {
      state.contents = [];
      state.isFetched = true;
    },
    onSuccessContentEdit: (state, { payload }) => {
      if (payload) {
        state.contents = state.contents.map((content) =>
          content.app_content_id === Number(payload.app_content_id)
            ? {
              title: payload.title,
              app_content_id: Number(payload.app_content_id),
              body: payload.contentBody,
              app_tag_id: Number(payload.tagId)
            }
            : content
        );
      }
    },
    onCallAdminLogs: (state, { payload }) => {
      if (!payload) {
        state.admin_logs.isFetched = false;
        state.admin_logs.columnNames = [];
        state.admin_logs.count = 0;
      } else {
        state.admin_logs.pagination = payload;
      }
    },
    onSuccessAdminLogs: (state, { payload }) => {
      state.admin_logs.isFetched = true;
      state.admin_logs.columnNames = payload.columnNames;
      state.admin_logs.tableData = payload.tableData;
      state.admin_logs.count = parseInt(payload?.total_count, 10);
    },
    onFailedAdminLogs: (state) => {
      state.admin_logs.isFetched = true;
      state.admin_logs.columnNames = [];
      state.admin_logs.tableData = [];
      state.admin_logs.count = 0;
    },
    onCallAppTemplates: (state, { payload }) => {
      if (!payload) {
        state.templates.isFetched = false;
        state.templates.columnNames = [];
        state.templates.count = 0;
      } else {
        state.templates.pagination = payload;
      }
      state.templates.isLoading = true;
    },
    onSuccessAppTemplates: (state, { payload }) => {
      state.templates.isFetched = true;
      state.templates.columnNames = payload.columnNames;
      state.templates.tableData = payload.tableData;
      state.templates.count = parseInt(payload?.total_count, 10);
    },
    onFailedAppTemplates: (state) => {
      state.templates.isFetched = true;
      state.templates.columnNames = [];
      state.templates.tableData = [];
      state.templates.count = 0;
    },
    switchAppTemplateLoading: (state, { payload }) => {
      state.templates.isLoading = payload !== undefined ? payload : !state.templates.isLoading;
    },
    onSuccessAppTemplateAdd: (state, { payload }) => {
      if (payload?.templateData) {
        state.templates.tableData.push(payload.templateData);
        state.templates.count += 1;
      }
    },
    onSuccessAppTemplateEdit: (state, { payload }) => {
      if (payload) {
        state.templates.tableData = state.templates.tableData.map((template) =>
          template.app_template_id === payload.app_template_id ? payload : template
        );
      }
    },
    onSuccessAppTemplateDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.templates.tableData = state.templates.tableData.filter(
          (template) => template.app_template_id !== payload
        );
        state.templates.count -= 1;
      }
    }
  }
});

export const {
  onCallCardTemplates,
  onCallCardTemplatesSuccess,
  onCallCardTemplatesFailure,
  onImportAppTemplate,
  onImportAppTemplateSuccess,
  onImportAppTemplateFailure,
  onCallSingleAppTemplate,
  onCallSingleAppTemplateSuccess,
  onCallSingleAppTemplateFailure,
  onCallContents,
  onSuccessContents,
  onFailedContents,
  onSuccessContentEdit,
  onCallAdminLogs,
  onSuccessAdminLogs,
  onFailedAdminLogs,
  onCallAppVersion,
  onSuccessCallAppVersion,
  onFailedCallAppVersion,
  onCallAppTemplates,
  onSuccessAppTemplates,
  onFailedAppTemplates,
  switchAppTemplateLoading,
  onSuccessAppTemplateAdd,
  onSuccessAppTemplateEdit,
  onSuccessAppTemplateDelete
} = adminSlice.actions;

export default adminSlice.reducer;
