const appModules = [
  {
    code: 1,
    name: "Tenant",
    value: "tenant"
  },
  {
    code: 2,
    name: "Tenant User",
    value: "tenant_user"
  },
  {
    code: 3,
    name: "Company",
    value: "company"
  },
  {
    code: 4,
    name: "Contact",
    value: "contact"
  },
  {
    code: 5,
    name: "Dashboard",
    value: "dashboard"
  },
  {
    code: 6,
    name: "Document",
    value: "document"
  },
  {
    code: 7,
    name: "Proposal",
    value: "proposal"
  },
  {
    code: 8,
    name: "Email Template",
    value: "email_template"
  },
  {
    code: 9,
    name: "Opportunity",
    value: "opportunity"
  },
  {
    code: 10,
    name: "Products/Services",
    value: "product_service"
  },
  {
    code: 11,
    name: "Task",
    value: "task"
  },
  {
    code: 12,
    name: "Note",
    value: "note"
  },
  {
    code: 13,
    name: "Sequence",
    value: "Sequence"
  }
];

export default appModules;
