// import { useMemo } from 'react';
//
//
// const useSubdomainCheck = () => useMemo(() => {
//     const { origin } = window.location;
//
//     // TODO -> allow for local
//     if (origin.includes('.localhost:3000')) {
//         return true;
//     }
//
//     if (origin.includes('https://www.')) {
//         return false;
//     }
//
//     return origin.split('.').length >= 3;
// }, []);
//
// export default useSubdomainCheck;

import { useMemo } from "react";

const useSubdomain = () =>
  useMemo(() => {
    const { host } = window.location;
    return host.split(".")[1] ? host.split(".")[0] : false;
  }, []);

export default useSubdomain;
