import {
  INTEGRATIONS_CALL,
  TENANT_INTEGRATIONS_CALL,
  TENANT_INTEGRATION_ADD_CALL_PROMISE,
  TENANT_INTEGRATION_EDIT_CALL_PROMISE,
  TENANT_INTEGRATION_DELETE_CALL_PROMISE
} from "store/integrations/sagas-actions";
import { IMPORT_ADD_CALL } from "store/import/sagas-actions";
import {
  SUBUSER_DOMAIN_AUTH_CANCEL_CALL,
  SUBUSER_DOMAIN_CREATE_CALL,
  SUBUSER_DOMAIN_STATUS_CALL,
  SUBUSER_DOMAIN_STATUS_CALL_PROMISE,
  SUBUSER_DOMAIN_VALIDATION_CALL
} from "store/subuserDomain/sagas-actions";
import {
  TASKS_CALL,
  TASK_CALL_TYPES,
  TASK_CALL_TAGS,
  TASK_CALL_PRIORITIES,
  TASK_ADD_CALL,
  TASK_CALL,
  TASK_DELETE_CALL,
  TASK_EDIT_CALL,
  TASKS_EDIT_CALL
} from "store/task/sagas-actions";
import {
  TASK_PRIORITIES_CALL,
  TASK_PRIORITY_ADD_CALL,
  TASK_PRIORITY_DELETE_CALL,
  TASK_PRIORITY_EDIT_CALL,
  TASK_PRIORITY_ROWS_POSITION_UPDATE,
  TASK_TAGS_CALL,
  TASK_TAG_ADD_CALL,
  TASK_TAG_DELETE_CALL,
  TASK_TAG_EDIT_CALL
} from "store/settings/task/sagas-actions";
import {
  NOTE_ADD_CALL,
  NOTES_CALL_OBJECTIONS,
  NOTES_CALL_SCRIPTS,
  NOTES_CALL_TYPES,
  NOTE_ADD_CALL_PROMISE,
  NOTE_CALL,
  NOTE_EDIT_CALL_PROMISE
} from "store/notes/sagas-actions";
import {
  SEQUENCES_CALL,
  SEQUENCES_CALL_CONDITIONS,
  SEQUENCES_CHANGE_STATUSES,
  SEQUENCE_ADD_CALL,
  SEQUENCE_CALL,
  SEQUENCE_DELETE_CALL,
  SEQUENCE_EDIT_CALL
} from "store/sequences/sagas-actions";
import { ADMIN_LOGS_CALL, APP_CONTENTS_CALL, APP_CONTENTS_EDIT_CALL, RESET_MFA_CALL } from "store/admin/sagas-actions";
import { APP_INTERNAL_SEARCH_CALL, APP_INTERNAL_SEARCH_CALL_PROMISE } from "store/app-universal-search/sagas-actions";
import {
  USER_GET,
  FORGOT_PASSWORD_CALL,
  USER_REFRESH_TOKEN,
  USER_CHANGE_STATUS_CALL
} from "../store/user/sagas-actions";
import { TERMS_CALL } from "../store/terms-conditions/sagas-actions";
import { APP_MESSAGE_CALL } from "../store/app-message/sagas-actions";
import {
  TEMPLATE_ADD_CALL,
  TEMPLATE_CALL,
  TEMPLATES_DELETE_CALL,
  TEMPLATE_EDIT_CALL,
  TEMPLATE_TEST_CALL,
  TEMPLATES_CALL,
  TEMPLATE_MERGE_FIELDS_CALL,
  TEMPLATES_CONTACTS_CALL,
  EMAILS_SEND_TO_CONTACTS_CALL,
  TEMPLATE_ADD_CALL_PROMISE,
  SMS_SEND_TO_CONTACTS_CALL,
  DOCUMENT_SEND_EMAIL
} from "../store/templates/sagas-actions";
import {
  USERS_CALL,
  USER_CALL,
  USER_CHARGE_CALL,
  USER_SECURITY_ROLES_CALL,
  USER_UI_SETTINGS_EDIT_CALL,
  USER_ADD_CALL,
  USER_EDIT_CALL
} from "../store/users/sagas-actions";
import { INDUSTRIES_CALL } from "../store/industries/sagas-actions";
import { PLANS_CALL } from "../store/plans/sagas-actions";
import {
  ORGANIZATION_DATA_CALL,
  ORGANIZATION_DATA_EDIT_CALL,
  SUBUSER_EMAIL_REPUTATION
} from "../store/organization/sagas-actions";
import {
  SECURITY_ROLE_ADD_CALL,
  SECURITY_ROLE_DELETE_CALL,
  SECURITY_ROLES_CALL,
  SECURITY_ROLES_EDIT_CALL
} from "../store/security/sagas-actions";
import { CURRENCIES_CALL } from "../store/currencies/sagas-actions";
import { TIMEZONES_CALL } from "../store/timezones/sagas-actions";
import {
  SALE_TAX_ADD_CALL,
  SALE_TAX_CALL,
  SALE_TAX_DELETE_CALL,
  SALE_TAX_EDIT_CALL,
  SALES_TAX_CALL
} from "../store/sales-tax/sagas-actions";
import {
  COMPANY_CUSTOM_FIELDS_CALL,
  COMPANY_CUSTOM_FIELD_CALL,
  COMPANY_CUSTOM_FIELD_ADD_CALL,
  COMPANY_CUSTOM_FIELD_EDIT_CALL,
  COMPANY_CUSTOM_FIELD_DELETE_CALL,
  COMPANY_GROUPS_CALL,
  COMPANY_GROUP_ADD_CALL,
  COMPANY_GROUP_EDIT_CALL,
  COMPANY_GROUP_DELETE_CALL,
  COMPANY_INDUSTRY_CLASSIFICATIONS_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL,
  COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL,
  COMPANY_STATUSES_CALL,
  COMPANY_STATUS_ADD_CALL,
  COMPANY_STATUS_EDIT_CALL,
  COMPANY_STATUS_DELETE_CALL,
  COMPANY_TYPES_CALL,
  COMPANY_TYPE_ADD_CALL,
  COMPANY_TYPE_EDIT_CALL,
  COMPANY_TYPE_DELETE_CALL,
  COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE
} from "../store/settings/company/sagas-actions";
import {
  CONTACT_GROUPS_CALL,
  CONTACT_GROUP_ADD_CALL,
  CONTACT_GROUP_EDIT_CALL,
  CONTACT_GROUP_DELETE_CALL,
  CONTACT_STATUSES_CALL,
  CONTACT_STATUS_ADD_CALL,
  CONTACT_STATUS_EDIT_CALL,
  CONTACT_STATUS_DELETE_CALL,
  CONTACT_TYPES_CALL,
  CONTACT_TYPE_ADD_CALL,
  CONTACT_TYPE_EDIT_CALL,
  CONTACT_TYPE_DELETE_CALL,
  CONTACT_CUSTOM_FIELDS_CALL,
  CONTACT_CUSTOM_FIELD_ADD_CALL,
  CONTACT_CUSTOM_FIELD_EDIT_CALL,
  CONTACT_CUSTOM_FIELD_DELETE_CALL,
  CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE,
  CONTACT_CUSTOM_FIELD_CALL
} from "../store/settings/contact/sagas-actions";
import {
  COMPANIES_CALL,
  COMPANIES_CALL_ACTIVE_TENANT_USERS,
  COMPANIES_CALL_CLASSIFICATIONS,
  COMPANIES_CALL_CUSTOM_FIELDS,
  COMPANIES_CALL_GROUPS,
  COMPANIES_CALL_SOCIAL_MEDIAS,
  COMPANIES_CALL_STATUSES,
  COMPANIES_CALL_TYPES,
  COMPANIES_CHANGE_CLASSIFICATIONS,
  COMPANIES_CHANGE_GROUPS,
  COMPANIES_CHANGE_STATUSES,
  COMPANIES_CHANGE_TYPES,
  COMPANY_ADD_CALL,
  COMPANY_ADD_CALL_PROMISE,
  COMPANY_CALL,
  COMPANIES_DELETE_CALL,
  COMPANY_EDIT_CALL,
  COMPANY_NOTES_CALL,
  COMPANIES_CALL_PROMISE,
  COMPANY_EDIT_CALL_PROMISE,
  COMPANY_TASKS_CALL
} from "../store/companies/sagas-actions";
import {
  COMPANY_TILE,
  CONTACT_TILE,
  DOCUMENT_TILE,
  PRODUCT_SERVICE_TILE,
  TEMPLATE_TABLE_VIEW,
  USER_TABLE_VIEW,
  COMPANY_SETTING_TILE,
  CONTACT_SETTING_TILE
} from "./env-variables";
import {
  CUSTOM_TABLE_VIEW_ADD_CALL,
  CUSTOM_TABLE_VIEW_CALL,
  CUSTOM_TABLE_VIEW_EDIT_CALL,
  CUSTOM_TABLE_VIEW_EDIT_CALL_PROMISE
} from "../store/table-view/sagas-actions";
import {
  CONTACT_ADD_CALL,
  CONTACT_CALL,
  CONTACT_DELETE_CALL,
  CONTACT_EDIT_CALL,
  CONTACTS_CALL,
  CONTACTS_CALL_GROUPS,
  CONTACTS_CALL_CUSTOM_FIELDS,
  CONTACTS_CALL_SOCIAL_MEDIAS,
  CONTACTS_CALL_STATUSES,
  CONTACTS_CALL_TYPES,
  CONTACTS_CHANGE_STATUSES,
  CONTACTS_CHANGE_TYPES,
  CONTACTS_CHANGE_GROUPS,
  CONTACT_ADD_CALL_PROMISE,
  CONTACT_NOTES_CALL,
  CONTACTS_CHANGE_SUBSCRIPTION,
  CONTACTS_CALL_SEQUENCES,
  CONTACTS_CHANGE_SEQUENCES,
  CONTACT_CALL_SEQUENCES,
  CONTACTS_CALL_PROMISE,
  CONTACT_EDIT_CALL_PROMISE,
  CONTACT_TASKS_CALL
} from "../store/contacts/sagas-actions";
import {
  OPPORTUNITIES_CALL,
  OPPORTUNITY_ADD_CALL,
  OPPORTUNITY_CALL,
  OPPORTUNITIES_DELETE_CALL,
  OPPORTUNITY_EDIT_CALL,
  OPPORTUNITIES_POSITIONS_CHANGE_CALL,
  OPPORTUNITY_STAGE_ADD_CALL,
  OPPORTUNITY_STAGE_CHANGE_CALL,
  OPPORTUNITY_STAGE_DELETE_CALL,
  OPPORTUNITY_STAGE_EDIT_CALL,
  OPPORTUNITY_STAGES_CALL,
  OPPORTUNITY_STATUS_ADD_CALL,
  OPPORTUNITY_STATUS_DELETE_CALL,
  OPPORTUNITY_STATUS_EDIT_CALL,
  OPPORTUNITY_STATUSES_CALL,
  OPPORTUNITY_TYPE_ADD_CALL,
  OPPORTUNITY_TYPE_DELETE_CALL,
  OPPORTUNITY_TYPE_EDIT_CALL,
  OPPORTUNITY_TYPES_CALL,
  OPPORTUNITIES_CALL_STATUSES,
  OPPORTUNITIES_CALL_TYPES,
  OPPORTUNITY_STAGES_POSITION_UPDATE,
  OPPORTUNITY_BOARDS_CALL,
  OPPORTUNITY_BOARD_ADD_CALL,
  OPPORTUNITY_BOARD_EDIT_CALL,
  OPPORTUNITY_BOARD_DELETE_CALL,
  OPPORTUNITY_BOARD_CHANGE_CALL,
  OPPORTUNITY_EDIT_PROMISE_CALL,
  OPPORTUNITY_ADD_PROMISE_CALL
} from "../store/opportunities/sagas-actions";
import {
  NOTIFICATIONS_DELETE_CALL,
  NOTIFICATIONS_SEEN_CALL,
  NOTIFICATIONS_CALL,
  NOTIFICATIONS_SEEN_ALL_CALL,
  NOTIFICATIONS_DELETE_ALL_CALL
} from "../store/notifications/sagas-actions";
import {
  DOCUMENT_ADD_CALL,
  DOCUMENT_CALL_HEAD,
  DOCUMENT_EDIT_CALL,
  DOCUMENT_ITEM_ADD_CALL,
  DOCUMENT_ITEM_EDIT_CALL,
  DOCUMENT_PRODUCT_SERVICE_ADD_CALL,
  DOCUMENT_PRODUCT_SERVICE_EDIT_CALL,
  DOCUMENT_PRODUCT_SERVICES_DELETE_CALL,
  DOCUMENT_SEND_EMAIL_PROMISE,
  DOCUMENT_STATUS_ADD_CALL,
  DOCUMENT_STATUS_DELETE_CALL,
  DOCUMENT_STATUS_EDIT_CALL,
  DOCUMENT_STATUSES_CALL,
  DOCUMENT_TYPE_ADD_CALL,
  DOCUMENT_TYPE_DELETE_CALL,
  DOCUMENT_TYPE_EDIT_CALL,
  DOCUMENT_TYPES_CALL,
  DOCUMENTS_CALL,
  DOCUMENTS_CALL_PROMISE,
  DOCUMENTS_CALL_STATUSES,
  DOCUMENTS_CALL_TYPES,
  DOCUMENTS_CHANGE_STATUSES,
  DOCUMENTS_CHANGE_TYPES,
  DOCUMENTS_DELETE_CALL,
  DOCUMENTS_TASK_CALL,
  DOCUMENT_NOTES_CALL,
  DOCUMENT_TENANT_SETTINGS_CALL,
  DOCUMENT_TENANT_SETTINGS_EDIT_CALL,
  DOCUMENT_PAYMENT_TERM_CALL,
  DOCUMENT_PAYMENT_TERM_ADD_CALL,
  DOCUMENT_PAYMENT_TERM_EDIT_CALL,
  DOCUMENT_PAYMENT_TERM_DELETE_CALL,
  DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL,
  DOCUMENT_OUTSOURCE_PRODUCT_SERVICES_CALL_PROMISE,
  DOCUMENTS_CALL_ADJUSTMENTS,
  DOCUMENTS_CALL_DESTINATION,
  DOCUMENT_ADD_CALL_PROMISE,
  DOCUMENTS_CALL_TEMPLATES,
  DOCUMENT_REVISIONS_CALL,
  DOCUMENT_CALL_ITEM,
  DOCUMENT_ITEM_ADD_PROMISE_CALL,
  DOCUMENT_EDIT_CALL_PROMISE,
  DOCUMENT_TASKS_CALL,
  DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL_PROMISE,
  DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY,
  CHANGE_ACTIVE_STATUS,
  DOCUMENTS_DELETE_CALL_PROMISE,
  DOCUMENT_ADD_TEMPLATE_PROMISE,
  DOCUMENT_ALL_ATTRIBUTES_CALL,
  PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL
} from "../store/documents/sagas-actions";
import { AUDIT_LOGS_CALL } from "../store/audit-log/sagas-actions";
import { CURRENCY_EXCHANGE_RATES_CALL, UPLOAD_TEMP_IMAGE_CALL_PROMISE } from "../store/general/sagas-actions";
import {
  WORKFLOW_ADD_CALL,
  WORKFLOW_CALL,
  WORKFLOW_DELETE_CALL,
  WORKFLOW_EDIT_CALL,
  WORKFLOWS_CALL,
  WORKFLOWS_CALL_CONDITIONS,
  WORKFLOWS_CHANGE_STATUSES
} from "../store/workflows/sagas-actions";
import {
  PRODUCTS_SERVICE_ADD_CALL,
  PRODUCTS_SERVICE_CALL,
  PRODUCTS_SERVICE_CATEGORIES_CALL,
  PRODUCTS_SERVICE_CATEGORY_ADD_CALL,
  PRODUCTS_SERVICE_CATEGORY_DELETE_CALL,
  PRODUCTS_SERVICE_CATEGORY_EDIT_CALL,
  PRODUCTS_SERVICE_EDIT_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL,
  PRODUCTS_SERVICE_MANUFACTURERS_CALL,
  PRODUCTS_SERVICE_STATUS_ADD_CALL,
  PRODUCTS_SERVICE_STATUS_DELETE_CALL,
  PRODUCTS_SERVICE_STATUS_EDIT_CALL,
  PRODUCTS_SERVICE_STATUSES_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORIES_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL,
  PRODUCTS_SERVICE_TYPE_ADD_CALL,
  PRODUCTS_SERVICE_TYPE_DELETE_CALL,
  PRODUCTS_SERVICE_TYPE_EDIT_CALL,
  PRODUCTS_SERVICE_TYPES_CALL,
  PRODUCTS_SERVICE_UOM_ADD_CALL,
  PRODUCTS_SERVICE_UOM_DELETE_CALL,
  PRODUCTS_SERVICE_UOM_EDIT_CALL,
  PRODUCTS_SERVICE_UOMS_CALL,
  PRODUCTS_SERVICES_CALL,
  PRODUCTS_SERVICES_CALL_CATEGORIES,
  PRODUCTS_SERVICES_CALL_MANUFACTURERS,
  PRODUCTS_SERVICES_CALL_PROMISE,
  PRODUCTS_SERVICES_CALL_STATUSES,
  PRODUCTS_SERVICES_CALL_SUB_CATEGORIES,
  PRODUCTS_SERVICES_CALL_TYPES,
  PRODUCTS_SERVICES_CALL_UOMS,
  PRODUCTS_SERVICES_CHANGE_CATEGORIES,
  PRODUCTS_SERVICES_CHANGE_MANUFACTURERS,
  PRODUCTS_SERVICES_CHANGE_STATUSES,
  PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES,
  PRODUCTS_SERVICES_CHANGE_TYPES,
  PRODUCTS_SERVICES_CHANGE_UOMS,
  PRODUCTS_SERVICES_DELETE_CALL,
  PRODUCTS_SERVICE_RECURRENCES_CALL,
  PRODUCTS_SERVICE_RECURRENCE_ADD_CALL,
  PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL,
  PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL,
  PRODUCTS_SERVICES_CALL_RECURRENCES
} from "../store/products-services/sagas-actions";
import {
  DASHBOARDS_CALL,
  DASHBOARD_ADD_CALL,
  DASHBOARD_EDIT_CALL,
  DASHBOARD_DELETE_CALL,
  DASHBOARD_LAYOUT_EDIT_CALL,
  DASHBOARD_ITEM_ADD_CALL_PROMISE,
  DASHBOARD_ITEM_EDIT_CALL_PROMISE,
  DASHBOARD_ITEM_DELETE_CALL_PROMISE,
  DASHBOARD_ITEM_DEFINITIONS_CALL
} from "../store/dashboard/sagas-actions";

const errorsList = {
  SERVER_DOWN: {
    message: "System maintenance in progress. Please try again shortly.",
    btnText: "Refresh",
    isFatal: true
  },
  [APP_INTERNAL_SEARCH_CALL]: {
    message: "Failed to load App content",
    btnText: "Retry"
  },
  [`${APP_INTERNAL_SEARCH_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load App content",
    btnText: "Retry"
  },
  [APP_CONTENTS_CALL]: {
    message: "Failed to load App content",
    btnText: "Retry"
  },
  [APP_CONTENTS_EDIT_CALL]: {
    message: "Failed to load updated content",
    btnText: "Retry"
  },
  [RESET_MFA_CALL]: {
    message: "Failed to reset MFA",
    btnText: "Retry"
  },

  [ADMIN_LOGS_CALL]: {
    message: "Failed to load logs",
    btnText: "Retry"
  },

  [TASKS_CALL]: {
    message: "Failed to load tasks",
    btnText: "Retry"
  },

  [TASK_CALL]: {
    message: "Failed to load task",
    btnText: "Retry"
  },

  [TASK_ADD_CALL]: {
    message: "Failed to Add task",
    btnText: "Retry"
  },

  [TASK_EDIT_CALL]: {
    message: "Failed to Update task",
    btnText: "Retry"
  },

  [TASKS_EDIT_CALL]: {
    message: "Failed to Update tasks",
    btnText: "Retry"
  },

  [TASK_DELETE_CALL]: {
    message: "Failed to delete task(s)",
    btnText: "Retry"
  },

  // task priority

  [TASK_PRIORITIES_CALL]: {
    message: "Failed to load task priorities",
    btnText: "Retry"
  },

  [TASK_PRIORITY_ADD_CALL]: {
    message: "Failed to create task priority",
    btnText: "Retry"
  },

  [TASK_PRIORITY_EDIT_CALL]: {
    message: "Failed to update task priority",
    btnText: "Retry"
  },

  [TASK_PRIORITY_DELETE_CALL]: {
    message: "Failed to delete task priority",
    btnText: "Retry"
  },
  [TASK_PRIORITY_ROWS_POSITION_UPDATE]: {
    message: "Failed to reorder priority row positions",
    btnText: "Retry"
  },

  [TASK_CALL_PRIORITIES]: {
    message: "Failed to load priorities",
    btnText: "Retry"
  },

  // TASK TAGS

  [TASK_TAGS_CALL]: {
    message: "Failed to load task tags",
    btnText: "Retry"
  },

  [TASK_TAG_ADD_CALL]: {
    message: "Failed to add task tag",
    btnText: "Retry"
  },

  [TASK_TAG_EDIT_CALL]: {
    message: "Failed to update task tag",
    btnText: "Retry"
  },

  [TASK_TAG_DELETE_CALL]: {
    message: "Failed to delete task tag",
    btnText: "Retry"
  },

  [TASK_CALL_TAGS]: {
    message: "Failed to load tags",
    btnText: "Retry"
  },

  // task_type

  [TASK_CALL_TYPES]: {
    message: "Failed to load task types",
    btnText: "Retry"
  },

  // notes

  [NOTE_ADD_CALL]: {
    message: "Failed to add note",
    btnText: "Retry"
  },

  [NOTE_CALL]: {
    message: "Failed to load note",
    btnText: "Retry"
  },

  [`${NOTE_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add note",
    btnText: "Retry"
  },

  [`${NOTE_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add note",
    btnText: "Retry"
  },

  [NOTES_CALL_TYPES]: {
    message: "Failed to load note types",
    btnText: "Retry"
  },

  [NOTES_CALL_OBJECTIONS]: {
    message: "Failed to load objections",
    btnText: "Retry"
  },

  [NOTES_CALL_SCRIPTS]: {
    message: "Failed to load scripts",
    btnText: "Retry"
  },

  // subdomain
  [SUBUSER_DOMAIN_CREATE_CALL]: {
    message: "Failed to create subdomain",
    btnText: "Retry"
  },

  [SUBUSER_DOMAIN_STATUS_CALL]: {
    message: "Failed to check domain verification status",
    btnText: "Retry"
  },

  [SUBUSER_DOMAIN_AUTH_CANCEL_CALL]: {
    message: "Failed to cancel domain verification",
    btnText: "Retry"
  },

  [SUBUSER_DOMAIN_AUTH_CANCEL_CALL]: {
    message: "Failed to cancel domain verification",
    btnText: "Retry"
  },

  [SUBUSER_DOMAIN_VALIDATION_CALL]: {
    message: "Domain validation failed",
    btnText: "Retry"
  },

  [`${SUBUSER_DOMAIN_STATUS_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to check domain verification status",
    btnText: "Retry"
  },

  // USER
  // [USER_SIGN_IN_CALL]: {
  //     message: 'Failed to sing in.',
  //     btnText: 'Refresh',
  //     isFatal: true
  // },
  // [USER_MFA_CALLBACK]: {
  //     message: 'Failed to sing in.',
  //     btnText: 'Refresh',
  //     isFatal: true
  // },
  [USER_GET]: {
    message: "Failed to get user data.",
    btnText: "Refresh",
    isFatal: true
  },
  [USER_REFRESH_TOKEN]: {
    message: "Failed to prolong user session.",
    btnText: "Refresh",
    isFatal: true
  },
  [TERMS_CALL]: {
    message: "Failed to get terms & conditions.",
    btnText: "Refresh",
    isFatal: true
  },
  [FORGOT_PASSWORD_CALL]: {
    message: "Failed to send reset password email.",
    btnText: "Retry"
  },
  [APP_MESSAGE_CALL]: {
    message: "Failed to get app message.",
    btnText: "Refresh",
    isFatal: true
  },
  [PLANS_CALL]: {
    message: "Failed to get plans.",
    btnText: "Refresh",
    isFatal: true
  },
  [INDUSTRIES_CALL]: {
    message: "Failed to get industries.",
    btnText: "Refresh",
    isFatal: true
  },
  [CURRENCIES_CALL]: {
    message: "Failed to get currencies.",
    btnText: "Refresh",
    isFatal: true
  },
  [TIMEZONES_CALL]: {
    message: "Failed to get timezones.",
    btnText: "Refresh",
    isFatal: true
  },

  // GENERAL
  [`${UPLOAD_TEMP_IMAGE_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to upload image.",
    btnText: "Retry"
  },
  [CURRENCY_EXCHANGE_RATES_CALL]: {
    message: "Failed to load exchange rates.",
    btnText: "Refresh",
    isFatal: true
  },

  [NOTIFICATIONS_CALL]: {
    message: "Failed to load notifications.",
    btnText: "Refresh",
    isFatal: true
  },
  [NOTIFICATIONS_SEEN_CALL]: {
    message: "Failed to mark notification as read.",
    btnText: "Retry"
  },
  [NOTIFICATIONS_DELETE_CALL]: {
    message: "Failed to delete notification.",
    btnText: "Retry"
  },
  [NOTIFICATIONS_SEEN_ALL_CALL]: {
    message: "Failed to mark notifications as read.",
    btnText: "Retry"
  },
  [NOTIFICATIONS_DELETE_ALL_CALL]: {
    message: "Failed to delete notifications.",
    btnText: "Retry"
  },

  // EMAIL TEMPLATES
  [TEMPLATE_CALL]: {
    // 404: {
    //     message: 'The username already exists',
    //     btnText: 'Close'
    // },
    message: "Failed to load template.",
    btnText: "Refresh",
    isFatal: true
  },
  [TEMPLATE_ADD_CALL]: {
    message: "Failed to create template.",
    btnText: "Retry"
  },
  [`${TEMPLATE_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to create template.",
    btnText: "Retry"
  },
  [TEMPLATE_EDIT_CALL]: {
    message: "Failed to edit template.",
    btnText: "Retry"
  },
  [TEMPLATES_DELETE_CALL]: {
    message: "Failed to delete template(s).",
    btnText: "Retry"
  },
  [TEMPLATE_TEST_CALL]: {
    message: "Failed to send test template.",
    btnText: "Retry"
  },
  [TEMPLATES_CALL]: {
    message: "Failed to load templates.",
    btnText: "Refresh",
    isFatal: true
  },
  [TEMPLATE_MERGE_FIELDS_CALL]: {
    message: "Failed to load merge fields.",
    btnText: "Refresh",
    isFatal: true
  },
  [TEMPLATES_CONTACTS_CALL]: {
    message: "Failed to load email for contacts.",
    btnText: "Refresh",
    isFatal: true
  },
  [EMAILS_SEND_TO_CONTACTS_CALL]: {
    message: "Failed to send email.",
    btnText: "Retry"
  },

  [DOCUMENT_SEND_EMAIL]: {
    message: "Failed to send email.",
    btnText: "Retry"
  },

  [SMS_SEND_TO_CONTACTS_CALL]: {
    message: "Failed to send SMS.",
    btnText: "Retry"
  },

  [`${CHANGE_ACTIVE_STATUS}.TRIGGER`]: {
    message: "Failed to make Document active.",
    btnText: "Retry"
  },

  // USERS
  [USERS_CALL]: {
    message: "Failed to load users.",
    btnText: "Refresh",
    isFatal: true
  },
  [USER_CALL]: {
    message: "Failed to load a user.",
    btnText: "Refresh",
    isFatal: true
  },
  [USER_ADD_CALL]: {
    message: "Failed to create a user.",
    btnText: "Retry"
  },
  [USER_EDIT_CALL]: {
    message: "Failed to edit a user.",
    btnText: "Retry"
  },
  [USER_UI_SETTINGS_EDIT_CALL]: {
    message: "Failed to save user UI settings.",
    btnText: "Retry"
  },
  [USER_SECURITY_ROLES_CALL]: {
    message: "Failed to load security roles.",
    btnText: "Refresh",
    isFatal: true
  },
  [USER_CHARGE_CALL]: {
    message: "Failed to load necessary modules.",
    btnText: "Refresh",
    isFatal: true
  },
  // [USER_DELETE_CALL]: {
  //     message: 'Failed to delete user',
  //     btnText: 'Retry'
  // }

  // ORGANIZATION
  [ORGANIZATION_DATA_CALL]: {
    message: "Failed to get organization data.",
    btnText: "Refresh",
    isFatal: true
  },
  [ORGANIZATION_DATA_EDIT_CALL]: {
    message: "Failed to save organization data.",
    btnText: "Retry"
  },
  [SECURITY_ROLES_CALL]: {
    message: "Failed to get security roles.",
    btnText: "Refresh",
    isFatal: true
  },
  [SECURITY_ROLES_EDIT_CALL]: {
    message: "Failed to edit security roles.",
    btnText: "Retry"
  },
  [SECURITY_ROLE_ADD_CALL]: {
    message: "Failed to add security role.",
    btnText: "Retry"
  },
  [SECURITY_ROLE_DELETE_CALL]: {
    message: "Failed to delete security role.",
    btnText: "Retry"
  },
  [SUBUSER_EMAIL_REPUTATION]: {
    message: "Failed to get domain reputation data.",
    btnText: "Refresh",
    isFatal: true
  },

  // SALES TAX
  [SALES_TAX_CALL]: {
    message: "Failed to get sales tax.",
    btnText: "Refresh",
    isFatal: true
  },
  [SALE_TAX_CALL]: {
    message: "Failed to get sales tax.",
    btnText: "Refresh",
    isFatal: true
  },
  [SALE_TAX_ADD_CALL]: {
    message: "Failed to add sales tax.",
    btnText: "Retry"
  },
  [SALE_TAX_EDIT_CALL]: {
    message: "Failed to edit sales tax.",
    btnText: "Retry"
  },
  [SALE_TAX_DELETE_CALL]: {
    message: "Failed to delete sales tax.",
    btnText: "Retry"
  },

  // COMPANIES
  [COMPANIES_CALL]: {
    message: "Failed to load Companies.",
    btnText: "Refresh",
    isFatal: true
  },
  [`${COMPANIES_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load Companies.",
    btnText: "Retry"
  },
  [COMPANY_CALL]: {
    message: "Failed to load Company.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_NOTES_CALL]: {
    message: "Failed to load notes for Company.",
    btnText: "Retry",
    isFatal: true
  },
  [COMPANY_TASKS_CALL]: {
    message: "Failed to load tasks for Company.",
    btnText: "Retry",
    isFatal: true
  },
  [COMPANY_ADD_CALL]: {
    message: "Failed to add Company.",
    btnText: "Retry"
  },
  [`${COMPANY_ADD_CALL}.TRIGGER`]: {
    message: "Failed to add Company.",
    btnText: "Retry"
  },
  [`${COMPANY_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add Company.",
    btnText: "Retry"
  },
  [COMPANY_EDIT_CALL]: {
    message: "Failed to edit Company.",
    btnText: "Retry"
  },
  [`${COMPANY_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit Company.",
    btnText: "Retry"
  },
  [COMPANIES_DELETE_CALL]: {
    message: "Failed to delete Company.",
    btnText: "Retry"
  },

  // COMPANIES RIGHT CLICK
  [COMPANIES_CHANGE_STATUSES]: {
    message: "Failed to change statues.",
    btnText: "Retry"
  },
  [COMPANIES_CHANGE_TYPES]: {
    message: "Failed to change types.",
    btnText: "Retry"
  },
  [COMPANIES_CHANGE_CLASSIFICATIONS]: {
    message: "Failed to change industry classifications.",
    btnText: "Retry"
  },
  [COMPANIES_CHANGE_GROUPS]: {
    message: "Failed to change groups.",
    btnText: "Retry"
  },

  // COMPANIES ATTRIBUTES
  [COMPANIES_CALL_STATUSES]: {
    message: "Failed to get Company statuses.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANIES_CALL_TYPES]: {
    message: "Failed to get Company types.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANIES_CALL_CLASSIFICATIONS]: {
    message: "Failed to get Company classifications.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANIES_CALL_GROUPS]: {
    message: "Failed to get Company groups.",
    btnText: "Refresh",
    isFatal: true
  },

  [COMPANIES_CALL_CUSTOM_FIELDS]: {
    message: "Failed to get Company custom fields.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANIES_CALL_SOCIAL_MEDIAS]: {
    message: "Failed to get Company social medias.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANIES_CALL_ACTIVE_TENANT_USERS]: {
    message: "Failed to get active tenant users.",
    btnText: "Refresh",
    isFatal: true
  },

  // COMPANY STATUS
  [COMPANY_STATUSES_CALL]: {
    message: "Failed to load Company statuses.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_STATUS_ADD_CALL]: {
    message: "Failed to add status.",
    btnText: "Retry"
  },
  [COMPANY_STATUS_EDIT_CALL]: {
    message: "Failed to edit status.",
    btnText: "Retry"
  },
  [COMPANY_STATUS_DELETE_CALL]: {
    message: "Failed to delete status.",
    btnText: "Retry"
  },

  // COMPANY TYPE
  [COMPANY_TYPES_CALL]: {
    message: "Failed to load Company types.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_TYPE_ADD_CALL]: {
    message: "Failed to add type.",
    btnText: "Retry"
  },
  [COMPANY_TYPE_EDIT_CALL]: {
    message: "Failed to edit type.",
    btnText: "Retry"
  },
  [COMPANY_TYPE_DELETE_CALL]: {
    message: "Failed to delete type.",
    btnText: "Retry"
  },

  // COMPANY INDUSTRY CLASSIFICATION
  [COMPANY_INDUSTRY_CLASSIFICATIONS_CALL]: {
    message: "Failed to load Company groups.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_INDUSTRY_CLASSIFICATION_ADD_CALL]: {
    message: "Failed to add group.",
    btnText: "Retry"
  },
  [COMPANY_INDUSTRY_CLASSIFICATION_EDIT_CALL]: {
    message: "Failed to edit group.",
    btnText: "Retry"
  },
  [COMPANY_INDUSTRY_CLASSIFICATION_DELETE_CALL]: {
    message: "Failed to delete group.",
    btnText: "Retry"
  },

  // COMPANY GROUP
  [COMPANY_GROUPS_CALL]: {
    message: "Failed to load Company groups.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_GROUP_ADD_CALL]: {
    message: "Failed to add group.",
    btnText: "Retry"
  },
  [COMPANY_GROUP_EDIT_CALL]: {
    message: "Failed to edit group.",
    btnText: "Retry"
  },
  [COMPANY_GROUP_DELETE_CALL]: {
    message: "Failed to delete group.",
    btnText: "Retry"
  },

  // COMPANY CUSTOM FIELDS
  [COMPANY_CUSTOM_FIELDS_CALL]: {
    message: "Failed to get Company custom fields.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_CUSTOM_FIELD_CALL]: {
    message: "Failed to get Company custom field.",
    btnText: "Refresh",
    isFatal: true
  },
  [COMPANY_CUSTOM_FIELD_ADD_CALL]: {
    message: "Failed to add Company custom field.",
    btnText: "Retry"
  },
  [COMPANY_CUSTOM_FIELD_EDIT_CALL]: {
    message: "Failed to edit Company custom field.",
    btnText: "Retry"
  },
  [COMPANY_CUSTOM_FIELD_DELETE_CALL]: {
    message: "Failed to delete Company custom field.",
    btnText: "Retry"
  },
  [COMPANY_CUSTOM_FIELD_ROWS_POSITION_UPDATE]: {
    message: "Failed to update Company custom field row positions.",
    btnText: "Retry"
  },

  // IMPORT
  [IMPORT_ADD_CALL]: {
    message: "Failed to import data.",
    btnText: "Retry"
  },

  // CONTACTS
  [CONTACTS_CALL]: {
    message: "Failed to load Contacts.",
    btnText: "Refresh",
    isFatal: true
  },
  [`${CONTACTS_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load Contacts.",
    btnText: "Retry"
  },
  [CONTACT_CALL]: {
    message: "Failed to load Contact.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_NOTES_CALL]: {
    message: "Failed to load notes for Contact.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_TASKS_CALL]: {
    message: "Failed to load tasks for Contact.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_ADD_CALL]: {
    message: "Failed to add Contact.",
    btnText: "Retry"
  },
  [`${CONTACT_ADD_CALL}.TRIGGER`]: {
    message: "Failed to add Contact.",
    btnText: "Retry"
  },
  [`${CONTACT_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add Contact.",
    btnText: "Retry"
  },
  [`${CONTACT_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit Contact.",
    btnText: "Retry"
  },
  [CONTACT_EDIT_CALL]: {
    message: "Failed to edit Contact.",
    btnText: "Retry"
  },
  [CONTACT_DELETE_CALL]: {
    message: "Failed to delete Contact.",
    btnText: "Retry"
  },
  [CONTACTS_CALL_CUSTOM_FIELDS]: {
    message: "Failed to get Contact custom fields.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACTS_CALL_SOCIAL_MEDIAS]: {
    message: "Failed to get Contact social medias.",
    btnText: "Refresh",
    isFatal: true
  },

  // CONTACTS RIGHT CLICK
  [CONTACTS_CHANGE_STATUSES]: {
    message: "Failed to change statues.",
    btnText: "Retry"
  },
  [CONTACTS_CHANGE_TYPES]: {
    message: "Failed to change types.",
    btnText: "Retry"
  },
  [CONTACTS_CHANGE_GROUPS]: {
    message: "Failed to change groups.",
    btnText: "Retry"
  },

  [CONTACTS_CHANGE_SUBSCRIPTION]: {
    message: "Failed to change subscription.",
    btnText: "Retry"
  },

  [CONTACTS_CALL_SEQUENCES]: {
    message: "Failed to load sequences.",
    btnText: "Retry"
  },

  [CONTACTS_CHANGE_SEQUENCES]: {
    message: "Failed to change sequences.",
    btnText: "Retry"
  },
  [CONTACT_CALL_SEQUENCES]: {
    message: "Failed to load sequences.",
    btnText: "Retry"
  },

  // CONTACTS ATTRIBUTES
  [CONTACTS_CALL_STATUSES]: {
    message: "Failed to get Company statuses.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACTS_CALL_TYPES]: {
    message: "Failed to get Company types.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACTS_CALL_GROUPS]: {
    message: "Failed to get Company groups.",
    btnText: "Refresh",
    isFatal: true
  },

  // CONTACT STATUS
  [CONTACT_STATUSES_CALL]: {
    message: "Failed to load Contact statuses data.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_STATUS_ADD_CALL]: {
    message: "Failed to add status.",
    btnText: "Retry"
  },
  [CONTACT_STATUS_EDIT_CALL]: {
    message: "Failed to edit status.",
    btnText: "Retry"
  },
  [CONTACT_STATUS_DELETE_CALL]: {
    message: "Failed to delete status.",
    btnText: "Retry"
  },

  // CONTACT TYPE
  [CONTACT_TYPES_CALL]: {
    message: "Failed to load Contact types data.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_TYPE_ADD_CALL]: {
    message: "Failed to add type.",
    btnText: "Retry"
  },
  [CONTACT_TYPE_EDIT_CALL]: {
    message: "Failed to edit type.",
    btnText: "Retry"
  },
  [CONTACT_TYPE_DELETE_CALL]: {
    message: "Failed to delete type.",
    btnText: "Retry"
  },

  // CONTACT GROUP
  [CONTACT_GROUPS_CALL]: {
    message: "Failed to load Contact groups data.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_GROUP_ADD_CALL]: {
    message: "Failed to add Contact group.",
    btnText: "Retry"
  },
  [CONTACT_GROUP_EDIT_CALL]: {
    message: "Failed to edit group.",
    btnText: "Retry"
  },
  [CONTACT_GROUP_DELETE_CALL]: {
    message: "Failed to delete group.",
    btnText: "Retry"
  },

  // CONTACT CUSTOM FIELDS
  [CONTACT_CUSTOM_FIELDS_CALL]: {
    message: "Failed to get Contact custom fields.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_CUSTOM_FIELD_CALL]: {
    message: "Failed to get Contact custom field.",
    btnText: "Refresh",
    isFatal: true
  },
  [CONTACT_CUSTOM_FIELD_ADD_CALL]: {
    message: "Failed to add Contact custom field.",
    btnText: "Retry"
  },
  [CONTACT_CUSTOM_FIELD_EDIT_CALL]: {
    message: "Failed to edit Contact custom field.",
    btnText: "Retry"
  },
  [CONTACT_CUSTOM_FIELD_DELETE_CALL]: {
    message: "Failed to delete Contact custom field.",
    btnText: "Retry"
  },
  [CONTACT_CUSTOM_FIELD_ROWS_POSITION_UPDATE]: {
    message: "Failed to delete Contact custom field row position.",
    btnText: "Retry"
  },

  // OPPORTUNITIES
  [OPPORTUNITIES_CALL]: {
    message: "Failed to get Opportunities.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_CALL]: {
    message: "Failed to get Opportunity.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_ADD_CALL]: {
    message: "Failed to add Opportunity.",
    btnText: "Retry"
  },
  [`${OPPORTUNITY_ADD_PROMISE_CALL}.TRIGGER`]: {
    message: "Failed to add Opportunity.",
    btnText: "Retry"
  },
  [OPPORTUNITY_EDIT_CALL]: {
    message: "Failed to edit Opportunity.",
    btnText: "Retry"
  },
  [`${OPPORTUNITY_EDIT_PROMISE_CALL}.TRIGGER`]: {
    message: "Failed to edit Opportunity.",
    btnText: "Retry"
  },
  [OPPORTUNITIES_DELETE_CALL]: {
    message: "Failed to delete Opportunit(ies).",
    btnText: "Retry"
  },
  [OPPORTUNITIES_POSITIONS_CHANGE_CALL]: {
    message: "Failed to change Opportunity positions.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STAGES_POSITION_UPDATE]: {
    message: "Failed to change stage positions.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STAGE_CHANGE_CALL]: {
    message: "Failed to change opportunity stage.",
    btnText: "Retry"
  },

  // OPPORTUNITY STATUS
  [OPPORTUNITY_STATUSES_CALL]: {
    message: "Failed to load Opportunity status data.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_STATUS_ADD_CALL]: {
    message: "Failed to add Opportunity status.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STATUS_EDIT_CALL]: {
    message: "Failed to edit Opportunity status.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STATUS_DELETE_CALL]: {
    message: "Failed to delete Opportunity status.",
    btnText: "Retry"
  },

  // OPPORTUNITY TYPE
  [OPPORTUNITY_TYPES_CALL]: {
    message: "Failed to load Opportunity type data.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_TYPE_ADD_CALL]: {
    message: "Failed to add Opportunity type.",
    btnText: "Retry"
  },
  [OPPORTUNITY_TYPE_EDIT_CALL]: {
    message: "Failed to edit Opportunity type.",
    btnText: "Retry"
  },
  [OPPORTUNITY_TYPE_DELETE_CALL]: {
    message: "Failed to delete Opportunity type.",
    btnText: "Retry"
  },

  // OPPORTUNITY STAGE
  [OPPORTUNITY_STAGES_CALL]: {
    message: "Failed to load Opportunity stage data.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_STAGE_ADD_CALL]: {
    message: "Failed to add Opportunity stage.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STAGE_EDIT_CALL]: {
    message: "Failed to edit Opportunity stage.",
    btnText: "Retry"
  },
  [OPPORTUNITY_STAGE_DELETE_CALL]: {
    message: "Failed to delete Opportunity stage.",
    btnText: "Retry"
  },

  // OPPORTUNITY BOARD

  [OPPORTUNITY_BOARDS_CALL]: {
    message: "Failed to load Opportunity board data.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITY_BOARD_ADD_CALL]: {
    message: "Failed to add Opportunity board.",
    btnText: "Retry"
  },
  [OPPORTUNITY_BOARD_EDIT_CALL]: {
    message: "Failed to edit Opportunity board.",
    btnText: "Retry"
  },
  [OPPORTUNITY_BOARD_DELETE_CALL]: {
    message: "Failed to delete Opportunity board.",
    btnText: "Retry"
  },
  [OPPORTUNITY_BOARD_CHANGE_CALL]: {
    message: "Failed to change Opportunity board.",
    btnText: "Retry"
  },

  // OPPORTUNITY ATTRIBUTES STATUSES
  [OPPORTUNITIES_CALL_STATUSES]: {
    message: "Failed to load Opportunity statues.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITIES_CALL_TYPES]: {
    message: "Failed to load Opportunity types.",
    btnText: "Refresh",
    isFatal: true
  },
  [OPPORTUNITIES_CALL_STATUSES]: {
    message: "Failed to load Opportunity stages.",
    btnText: "Refresh",
    isFatal: true
  },

  // DOCUMENTS
  [DOCUMENTS_CALL]: {
    message: "Failed to load Documents.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENTS_TASK_CALL]: {
    message: "Failed to load Documents.",
    btnText: "Refresh",
    isFatal: true
  },

  [DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL]: {
    message: "Failed to load Products/Services.",
    btnText: "Refresh",
    isFatal: true
  },

  // TODO -> fix this
  [`${DOCUMENTS_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load Documents.",
    btnText: "Retry"
    // isFatal: true
  },
  [`${DOCUMENT_OUTSOURCE_PRODUCT_SERVICES_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to get Product/Service.",
    btnText: "Retry"
    // isFatal: true
  },
  [DOCUMENT_CALL_HEAD]: {
    message: "Failed to load Document.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_NOTES_CALL]: {
    message: "Failed to load notes for Document.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_TASKS_CALL]: {
    message: "Failed to load tasks for Document.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_REVISIONS_CALL]: {
    message: "Failed to load revisions for Document.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_ADD_CALL]: {
    message: "Failed to add Document.",
    btnText: "Retry"
  },
  [`${DOCUMENT_ADD_TEMPLATE_PROMISE}.TRIGGER`]: {
    message: "Failed to create Template.",
    btnText: "Retry"
  },
  [DOCUMENT_CALL_ITEM]: {
    message: "Failed to load Document.",
    btnText: "Retry"
  },

  [`${DOCUMENT_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add Document.",
    btnText: "Retry"
    // isFatal: true
  },
  [`${DOCUMENT_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit Document.",
    btnText: "Retry"
    // isFatal: true
  },
  [`${DOCUMENTS_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to delete Document(s).",
    btnText: "Retry"
    // isFatal: true
  },

  // [`${DOCUMENT_ADD_CALL_PROMISE}.TRIGGER`]: {
  //     message: 'Failed to add company.',
  //     btnText: 'Retry'
  // },
  [DOCUMENT_EDIT_CALL]: {
    message: "Failed to edit Document.",
    btnText: "Retry"
  },
  [DOCUMENTS_DELETE_CALL]: {
    message: "Failed to delete Document.",
    btnText: "Retry"
  },
  [`${DOCUMENT_SEND_EMAIL_PROMISE}.TRIGGER`]: {
    message: "Failed to send email.",
    btnText: "Retry"
  },

  // DOCUMENT ITEM
  [DOCUMENT_ITEM_ADD_CALL]: {
    message: "Failed to add Document item.",
    btnText: "Retry"
  },
  [`${DOCUMENT_ITEM_ADD_PROMISE_CALL}.TRIGGER`]: {
    message: "Failed to add Document item.",
    btnText: "Retry"
  },
  [`${DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load products/services.",
    btnText: "Retry"
  },
  [`${DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY}`]: {
    message: "Failed to check products/services.",
    btnText: "Retry"
  },
  [DOCUMENT_ITEM_EDIT_CALL]: {
    message: "Failed to edit Document item.",
    btnText: "Retry"
  },
  // [DOCUMENT_PRODUCT_SERVICES_CALL]: {
  //     message: 'Failed to load document product services.',
  //     btnText: 'Refresh',
  //     isFatal: true
  // },
  [DOCUMENT_PRODUCT_SERVICE_ADD_CALL]: {
    message: "Failed to add Document product/service.",
    btnText: "Retry"
  },
  [DOCUMENT_PRODUCT_SERVICE_EDIT_CALL]: {
    message: "Failed to edit Document product/service.",
    btnText: "Retry"
  },
  [DOCUMENT_PRODUCT_SERVICES_DELETE_CALL]: {
    message: "Failed to delete Document product/service(s).",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL]: {
    message: "Failed to add Product/Service to Bizzity",
    btnText: "Retry"
  },

  // DOCUMENTS RIGHT CLICK
  [DOCUMENTS_CHANGE_STATUSES]: {
    message: "Failed to change statues.",
    btnText: "Retry"
  },
  [DOCUMENTS_CHANGE_TYPES]: {
    message: "Failed to change types.",
    btnText: "Retry"
  },

  // DOCUMENTS ATTRIBUTES STATUSES
  [DOCUMENTS_CALL_STATUSES]: {
    message: "Failed to get Document statuses.",
    btnText: "Refresh",
    isFatal: true
  },

  // DOCUMENTS ATTRIBUTES TYPES
  [DOCUMENTS_CALL_TYPES]: {
    message: "Failed to get Document types.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENTS_CALL_ADJUSTMENTS]: {
    message: "Failed to get Document adjustments.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_ALL_ATTRIBUTES_CALL]: {
    message: "Failed to get Document attributes.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENTS_CALL_DESTINATION]: {
    message: "Failed to get Document destinations.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENTS_CALL_TEMPLATES]: {
    message: "Failed to get Document templates.",
    btnText: "Refresh",
    isFatal: true
  },

  // DOCUMENT STATUS
  [DOCUMENT_STATUSES_CALL]: {
    message: "Failed to load Document statuses data.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_STATUS_ADD_CALL]: {
    message: "Failed to add Document status.",
    btnText: "Retry"
  },
  [DOCUMENT_STATUS_EDIT_CALL]: {
    message: "Failed to edit Document status.",
    btnText: "Retry"
  },
  [DOCUMENT_STATUS_DELETE_CALL]: {
    message: "Failed to delete Document status.",
    btnText: "Retry"
  },

  // DOCUMENT TYPE
  [DOCUMENT_TYPES_CALL]: {
    message: "Failed to load Document types data.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_TYPE_ADD_CALL]: {
    message: "Failed to add Document type.",
    btnText: "Retry"
  },
  [DOCUMENT_TYPE_EDIT_CALL]: {
    message: "Failed to edit Document type.",
    btnText: "Retry"
  },
  [DOCUMENT_TYPE_DELETE_CALL]: {
    message: "Failed to delete Document type.",
    btnText: "Retry"
  },

  // DOCUMENT PAYMENT TERM
  [DOCUMENT_PAYMENT_TERM_CALL]: {
    message: "Failed to load Document Payment Terms.",
    btnText: "Refresh",
    isFatal: true
  },
  [DOCUMENT_PAYMENT_TERM_ADD_CALL]: {
    message: "Failed to add Document Payment Term.",
    btnText: "Retry"
  },
  [DOCUMENT_PAYMENT_TERM_EDIT_CALL]: {
    message: "Failed to edit Document Payment Term.",
    btnText: "Retry"
  },
  [DOCUMENT_PAYMENT_TERM_DELETE_CALL]: {
    message: "Failed to delete Document Payment Term.",
    btnText: "Retry"
  },

  // Settings
  [DOCUMENT_TENANT_SETTINGS_CALL]: {
    message: "Failed to load Document tenant settings.",
    btnText: "Retry"
  },

  [DOCUMENT_TENANT_SETTINGS_EDIT_CALL]: {
    message: "Failed to edit Document tenant settings.",
    btnText: "Retry"
  },

  [CUSTOM_TABLE_VIEW_CALL]: {
    message: "Failed to load table views.",
    btnText: "Refresh",
    isFatal: false
  },
  [CUSTOM_TABLE_VIEW_ADD_CALL]: {
    message: "Failed to add view.",
    btnText: "Retry"
  },
  [CUSTOM_TABLE_VIEW_EDIT_CALL]: {
    message: "Failed to edit view.",
    btnText: "Retry"
  },
  [`${CUSTOM_TABLE_VIEW_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit view.",
    btnText: "Retry"
  },
  [TEMPLATE_TABLE_VIEW]: {
    message: "Failed to load Templates table view.",
    btnText: "Refresh",
    isFatal: false
  },
  [USER_TABLE_VIEW]: {
    message: "Failed to load Users table view.",
    btnText: "Refresh",
    isFatal: false
  },
  [COMPANY_TILE]: {
    message: "Failed to load Company tile view.",
    btnText: "Refresh",
    isFatal: false
  },
  [CONTACT_TILE]: {
    message: "Failed to load Contact tile view.",
    btnText: "Refresh",
    isFatal: false
  },
  [DOCUMENT_TILE]: {
    message: "Failed to load Document tile view.",
    btnText: "Refresh",
    isFatal: false
  },
  [PRODUCT_SERVICE_TILE]: {
    message: "Failed to load Product/Service tile view.",
    btnText: "Refresh",
    isFatal: false
  },
  [COMPANY_SETTING_TILE]: {
    message: "Failed to load Company setting tile view.",
    btnText: "Refresh",
    isFatal: false
  },
  [CONTACT_SETTING_TILE]: {
    message: "Failed to load Contact setting tile view.",
    btnText: "Refresh",
    isFatal: false
  },

  // AUDIT LOG
  [AUDIT_LOGS_CALL]: {
    message: "Failed to load log data.",
    btnText: "Refresh",
    isFatal: true
  },

  // WORKFLOWS
  [WORKFLOWS_CALL]: {
    message: "Failed to load Workflows.",
    btnText: "Refresh",
    isFatal: true
  },
  [WORKFLOW_CALL]: {
    message: "Failed to load Workflow.",
    btnText: "Refresh",
    isFatal: true
  },
  [`${WORKFLOW_ADD_CALL}.TRIGGER`]: {
    message: "Failed to add Workflow.",
    btnText: "Retry"
  },
  [WORKFLOW_EDIT_CALL]: {
    message: "Failed to edit Workflow.",
    btnText: "Retry"
  },
  [WORKFLOW_DELETE_CALL]: {
    message: "Failed to delete Workflow.",
    btnText: "Retry"
  },
  [`${WORKFLOWS_CHANGE_STATUSES}.TRIGGER`]: {
    message: "Failed to change Workflow status.",
    btnText: "Retry"
  },
  [WORKFLOWS_CALL_CONDITIONS]: {
    message: "Failed to load Workflow conditions.",
    btnText: "Refresh",
    isFatal: true
  },

  // SEQUENCES
  [SEQUENCES_CALL]: {
    message: "Failed to load Sequences.",
    btnText: "Refresh",
    isFatal: true
  },
  [SEQUENCE_CALL]: {
    message: "Failed to load Sequence.",
    btnText: "Refresh",
    isFatal: true
  },
  [`${SEQUENCE_ADD_CALL}.TRIGGER`]: {
    message: "Failed to add Sequence.",
    btnText: "Retry"
  },
  [SEQUENCE_EDIT_CALL]: {
    message: "Failed to edit Sequence.",
    btnText: "Retry"
  },
  [SEQUENCE_DELETE_CALL]: {
    message: "Failed to delete Sequence.",
    btnText: "Retry"
  },
  [`${SEQUENCES_CHANGE_STATUSES}.TRIGGER`]: {
    message: "Failed to change Sequence status.",
    btnText: "Retry"
  },
  [SEQUENCES_CALL_CONDITIONS]: {
    message: "Failed to load Sequence conditions.",
    btnText: "Refresh",
    isFatal: true
  },

  // PRODUCTS SERVICE
  [PRODUCTS_SERVICES_CALL]: {
    message: "Failed to load Products/Services.",
    btnText: "Refresh",
    isFatal: true
  },
  // TODO -> fix this
  [`${PRODUCTS_SERVICES_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to load Products/Services.",
    btnText: "Retry"
    // isFatal: true
  },
  [PRODUCTS_SERVICE_CALL]: {
    message: "Failed to load Product/Service.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_ADD_CALL]: {
    message: "Failed to add Product/Service.",
    btnText: "Retry"
  },
  // [`${PRODUCTS_SERVICE_ADD_CALL_PROMISE}.TRIGGER`]: {
  //     message: 'Failed to add contact.',
  //     btnText: 'Retry'
  // },
  [PRODUCTS_SERVICE_EDIT_CALL]: {
    message: "Failed to edit Product/Service.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_DELETE_CALL]: {
    message: "Failed to delete Product(s)/Service(s).",
    btnText: "Retry"
  },
  // [CONTACTS_CALL_CUSTOM_FIELDS]: {
  //     message: 'Failed to get contact custom fields.',
  //     btnText: 'Refresh',
  //     isFatal: true
  // },
  // [CONTACTS_CALL_SOCIAL_MEDIAS]: {
  //     message: 'Failed to get contact social medias.',
  //     btnText: 'Refresh',
  //     isFatal: true
  // },

  // PRODUCTS SERVICE RIGHT CLICK
  [PRODUCTS_SERVICES_CHANGE_STATUSES]: {
    message: "Failed to change statues.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_CHANGE_TYPES]: {
    message: "Failed to change types.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_CHANGE_CATEGORIES]: {
    message: "Failed to change categories.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES]: {
    message: "Failed to change sub-categories.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_CHANGE_MANUFACTURERS]: {
    message: "Failed to change manufacturers.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICES_CHANGE_UOMS]: {
    message: "Failed to change UOMs.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE ATTRIBUTES
  [PRODUCTS_SERVICES_CALL_STATUSES]: {
    message: "Failed to load Product/Service statues.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICES_CALL_TYPES]: {
    message: "Failed to load Product/Service types.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICES_CALL_CATEGORIES]: {
    message: "Failed to load Product/Service categories.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICES_CALL_SUB_CATEGORIES]: {
    message: "Failed to load Product/Service sub categories.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICES_CALL_MANUFACTURERS]: {
    message: "Failed to load Product/Service manufacturers.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICES_CALL_UOMS]: {
    message: "Failed to load Product/Service UOMs.",
    btnText: "Refresh",
    isFatal: true
  },

  [PRODUCTS_SERVICES_CALL_RECURRENCES]: {
    message: "Failed to load Product/Service Recurrences.",
    btnText: "Refresh",
    isFatal: true
  },

  // PRODUCTS SERVICE STATUS
  [PRODUCTS_SERVICE_STATUSES_CALL]: {
    message: "Failed to load Product/Service statuses.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_STATUS_ADD_CALL]: {
    message: "Failed to add Product/Service status.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_STATUS_EDIT_CALL]: {
    message: "Failed to edit Product/Service status.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_STATUS_DELETE_CALL]: {
    message: "Failed to delete Product/Service status.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE TYPE
  [PRODUCTS_SERVICE_TYPES_CALL]: {
    message: "Failed to load Product/Service types.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_TYPE_ADD_CALL]: {
    message: "Failed to add Product/Service type.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_TYPE_EDIT_CALL]: {
    message: "Failed to edit Product/Service type.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_TYPE_DELETE_CALL]: {
    message: "Failed to delete Product/Service type.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE CATEGORY
  [PRODUCTS_SERVICE_CATEGORIES_CALL]: {
    message: "Failed to load Product/Service categories data.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_CATEGORY_ADD_CALL]: {
    message: "Failed to add Product/Service category.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_CATEGORY_EDIT_CALL]: {
    message: "Failed to edit Product/Service category.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_CATEGORY_DELETE_CALL]: {
    message: "Failed to delete Product/Service category.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE SUB CATEGORY
  [PRODUCTS_SERVICE_SUB_CATEGORIES_CALL]: {
    message: "Failed to load Sub-categories.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL]: {
    message: "Failed to add Sub-category.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL]: {
    message: "Failed to edit Sub-category.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL]: {
    message: "Failed to delete Sub-category.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE MANUFACTURER
  [PRODUCTS_SERVICE_MANUFACTURERS_CALL]: {
    message: "Failed to load Product/Service manufacturer data.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL]: {
    message: "Failed to add Product/Service manufacturer.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL]: {
    message: "Failed to edit Product/Service manufacturer.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL]: {
    message: "Failed to delete Product/Service manufacturer.",
    btnText: "Retry"
  },

  // PRODUCTS SERVICE UOM
  [PRODUCTS_SERVICE_UOMS_CALL]: {
    message: "Failed to load Unit of Measure data.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_UOM_ADD_CALL]: {
    message: "Failed to add Unit of Measure.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_UOM_EDIT_CALL]: {
    message: "Failed to edit Unit of Measure.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_UOM_DELETE_CALL]: {
    message: "Failed to delete Unit of Measure.",
    btnText: "Retry"
  },

  [PRODUCTS_SERVICE_RECURRENCES_CALL]: {
    message: "Failed to load Recurrence data.",
    btnText: "Refresh",
    isFatal: true
  },
  [PRODUCTS_SERVICE_RECURRENCE_ADD_CALL]: {
    message: "Failed to add Recurrence.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL]: {
    message: "Failed to edit Recurrence.",
    btnText: "Retry"
  },
  [PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL]: {
    message: "Failed to delete Recurrence.",
    btnText: "Retry"
  },

  [DASHBOARDS_CALL]: {
    message: "Failed to load Dashboard.",
    btnText: "Refresh",
    isFatal: true
  },
  [DASHBOARD_ADD_CALL]: {
    message: "Failed to add Dashboard.",
    btnText: "Retry"
  },
  [DASHBOARD_EDIT_CALL]: {
    message: "Failed to edit Dashboard.",
    btnText: "Retry"
  },
  [DASHBOARD_DELETE_CALL]: {
    message: "Failed to delete Dashboard.",
    btnText: "Retry"
  },
  [DASHBOARD_LAYOUT_EDIT_CALL]: {
    message: "Failed to update Dashboard layout.",
    btnText: "Retry"
  },
  [`${DASHBOARD_ITEM_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add Dashboard item.",
    btnText: "Retry"
  },
  [`${DASHBOARD_ITEM_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit Dashboard item.",
    btnText: "Retry"
  },
  [`${DASHBOARD_ITEM_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to delete Dashboard item.",
    btnText: "Retry"
  },
  [DASHBOARD_ITEM_DEFINITIONS_CALL]: {
    message: "Failed to load Dashboard item definitions.",
    btnText: "Refresh",
    isFatal: true
  },

  [INTEGRATIONS_CALL]: {
    message: "Failed to load Integrations.",
    btnText: "Refresh",
    isFatal: true
  },
  [TENANT_INTEGRATIONS_CALL]: {
    message: "Failed to load tenant Integrations.",
    btnText: "Refresh",
    isFatal: true
  },
  [`${TENANT_INTEGRATION_ADD_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to add Integration.",
    btnText: "Retry"
  },
  [`${TENANT_INTEGRATION_EDIT_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to edit Integration.",
    btnText: "Retry"
  },
  [`${TENANT_INTEGRATION_DELETE_CALL_PROMISE}.TRIGGER`]: {
    message: "Failed to delete Integration.",
    btnText: "Retry"
  },
  [USER_CHANGE_STATUS_CALL]: {
    message: "Failed to change status",
    btnText: "Ok"
  }
};

export const WORKFLOW_ERROR_LIST = {
  CONDITION_IS_INCOMPLETE: "There is a Condition that is incomplete. Please correct and try again.",
  ACTION_IS_INCOMPLETE: "There is an Action that is incomplete. Please correct and try again.",
  MISSING_LINK_CONNECTION: "There are missing connection(s) between items. Please correct and try again.",
  ACTION_REQUIRED_AFTER_CONDITION:
    "There is a Condition without a Delay or Action connected. Please correct and try again.",
  ACTION_REQUIRED_AFTER_DELAY: "There is a Delay without an Action connected. Please correct and try again",
  EMPTY_WORKFLOW: "There are no Conditions or Actions defined. Please correct and try again.",
  ACTION_NOTIFICATION_REQUIREMENT: "Notification Action is missing Template. Please correct and try again.",
  ACTION_TASK_REQUIREMENT: "Task Action is missing Priority and Type. Please correct and try again.",
  ACTION_EMAIL_REQUIREMENT: "Email Action is missing Recipient and Template. Please correct and try again.",
  ACTION_SMS_REQUIREMENT: "SMS Action is missing Template. Please correct and try again.",
  ACTION_CHANGE_REQUIREMENT: "Action is incomplete. Please correct and try again."
};

export const SEQUENCE_ERROR_LIST = {
  CONDITION_IS_INCOMPLETE: "Condition is incomplete. Please correct and try again.",
  ACTION_IS_INCOMPLETE: "Action is incomplete. Please correct and try again.",
  MISSING_LINK_CONNECTION: "There are missing connection(s) between items. Please correct and try again.",
  ACTION_REQUIRED_AFTER_CONDITION:
    "There is a Condition without a Delay or Action connected. Please correct and try again.",
  ACTION_REQUIRED_AFTER_DELAY: "There is a Delay without an Action connected. Please correct and try again.",
  EMPTY_WORKFLOW: "There are no Conditions or Actions defined. Please correct and try again."
};

export const SETTINGS_ERROR_LIST = {
  CHANGE_DEFAULT_VALUE: "Default item required. Please select a new item as the default."
};

export default errorsList;
