import { createPromiseAction } from "@adobe/redux-saga-promise";

export const AUDIT_LOGS_CALL = "AUDIT_LOGS_CALL";
export const AUDIT_LOGS_CALL_PROMISE = "AUDIT_LOGS_CALL_PROMISE";

export const callAuditLogsPromise = createPromiseAction(AUDIT_LOGS_CALL_PROMISE);

export function callAuditLogs(paginationData) {
  return { type: AUDIT_LOGS_CALL, payload: { paginationData } };
}
