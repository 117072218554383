import { createPromiseAction } from "@adobe/redux-saga-promise";

export const WORKFLOWS_CALL = "WORKFLOWS_CALL";
export const WORKFLOW_CALL = "WORKFLOW_CALL";
export const WORKFLOW_ADD_CALL = "WORKFLOW_ADD_CALL";
export const WORKFLOW_EDIT_CALL = "WORKFLOW_EDIT_CALL";
export const WORKFLOW_DELETE_CALL = "WORKFLOW_DELETE_CALL";
export const WORKFLOW_RESTORE_CALL = "WORKFLOW_RESTORE_CALL";

export const WORKFLOWS_CHANGE_STATUSES = "WORKFLOWS_CHANGE_STATUSES";

export const WORKFLOWS_CALL_CONDITIONS = "WORKFLOWS_CALL_CONDITIONS";

export const callAddWorkflowPromise = createPromiseAction(WORKFLOW_ADD_CALL);
export const callEditWorkflowPromise = createPromiseAction(WORKFLOW_EDIT_CALL);

export function callWorkflows() {
  return { type: WORKFLOWS_CALL };
}

export function callWorkflow(workflowId) {
  return { type: WORKFLOW_CALL, payload: { workflowId } };
}

export function callDeleteWorkflow(workflowIds) {
  return { type: WORKFLOW_DELETE_CALL, payload: { workflowIds } };
}

export function callWorkflowConditions() {
  return { type: WORKFLOWS_CALL_CONDITIONS };
}
