import { createSlice } from "@reduxjs/toolkit";

export const errorsSlice = createSlice({
  name: "errors",
  initialState: {
    errorList: {}
  },
  reducers: {
    setError: (state, { payload }) => {
      if (payload?.error && payload?.action) {
        const error = {
          error: payload.error,
          action: payload.action,
          isVisible: true
        };

        state.errorList[payload.action.type] = error;
      }
    },
    removeError: (state, { payload }) => {
      if (payload) {
        delete state.errorList[payload];
      }
    },
    toggleError: (state, { payload }) => {
      if (payload) {
        const newError = { ...state.errorList[payload] };
        newError.isVisible = !newError.isVisible;
        state.errorList[payload] = newError;
      }
    }
  }
});

export const { setError, removeError, toggleError } = errorsSlice.actions;

export default errorsSlice.reducer;
