import { Typography } from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const DragItem = ({ itemObject, index }) => (
  <Draggable draggableId={`${itemObject.columnName}${index}`} index={index}>
    {(provided) => (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <Typography noWrap pl={1}>
          {itemObject.token}
        </Typography>
      </div>
    )}
  </Draggable>
);

export default DragItem;
