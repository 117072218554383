import { useRef, useLayoutEffect } from "react";

/**
 * Hook is for using EventListener. (Automatically removes Eventlistener on unmount)
 * @param {String} eventName - Event name
 * @param {Function} handler - Function to handle event
 * @param {Ref, Element} element - Element/Ref to assign event (document by default)
 * @param {Object} options - Event Options
 */

const useEventListener = (eventName, handler, element = global, options = {}) => {
  const savedHandler = useRef();
  const { capture, passive, once } = options;

  useLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useLayoutEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event) => savedHandler.current(event);
    const opts = {
      capture,
      passive,
      once
    };
    element.addEventListener(eventName, eventListener, opts);
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(eventName, eventListener, opts);
    };
  }, [eventName, element, capture, passive, once]);
};

export default useEventListener;
