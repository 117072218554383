import { createSlice } from "@reduxjs/toolkit";

export const contactSettingsSlice = createSlice({
  name: "contact-settings",
  initialState: {
    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    // industryClassification: {
    //     isFetched: false,
    //     isLoading: false,
    //     list: [],
    //     columnNames: []
    // },
    group: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    customField: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      isSingleFetched: false,
      singleCustomField: undefined
    }
  },
  reducers: {
    onCallContactSettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].isLoading = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessContactSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;
      state[payload.key].columnNames = payload.data.columnNames;
    },
    onFailedContactSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },

    switchContactSettingLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },

    onSuccessContactSettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default && payload.key !== 'group') {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessContactSettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          if (payload.key === 'group') {
            state[payload.key].list = state[payload.key].list.map((item) =>
              item[payload.idKey] === payload.data[payload.idKey]
                ? payload.data
                : item
            );
          } else {
            state[payload.key].list = state[payload.key].list.map((item) =>
              item[payload.idKey] === payload.data[payload.idKey]
                ? payload.data
                : {
                  ...item,
                  Default: false
                }
            );
          }

        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessContactSettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
      }
    },

    onCallContactCustomFields: (state) => {
      state.customField.isFetched = false;
      state.customField.isLoading = false;
      state.customField.list = [];
      state.customField.columnNames = [];
    },
    onSuccessContactCustomFields: (state, { payload }) => {
      state.customField.isFetched = true;
      state.customField.list = payload.tableData;
      state.customField.columnNames = payload.columnNames;
    },
    onFailedContactCustomFields: (state) => {
      state.customField.isFetched = true;
      state.customField.list = [];
      state.customField.columnNames = [];
    },

    switchContactCustomFieldLoading: (state, { payload }) => {
      state.customField.isLoading = payload !== undefined ? payload : !state.customField.isLoading;
    },

    onSuccessContactCustomField: (state, { payload }) => {
      state.customField.isSingleFetched = true;
      state.customField.singleCustomField = payload;
    },
    onFailedContactCustomField: (state) => {
      state.customField.isSingleFetched = true;
      state.customField.singleCustomField = undefined;
    },
    resetContactCustomField: (state) => {
      state.customField.isSingleFetched = false;
      state.customField.singleCustomField = undefined;
    },

    onSuccessContactCustomFieldAdd: (state, { payload }) => {
      if (payload) {
        state.customField.list = [...state.customField.list, payload];
      }
    },
    onSuccessContactCustomFieldEdit: (state, { payload }) => {
      if (payload) {
        state.customField.list = state.customField.list.map((cF) =>
          cF.custom_field_definition_id === payload.custom_field_definition_id ? payload : cF
        );
      }
    },
    onSuccessContactCustomFieldDelete: (state, { payload }) => {
      if (payload !== undefined) {
        const deletedItemIndex = state.customField.list.findIndex((cf) => cf.custom_field_definition_id === payload);
        state.customField.list = state.customField.list.reduce((acc, item, index) => {
          if (index >= deletedItemIndex) {
            item.Position -= 1;
          }
          if (item.custom_field_definition_id !== payload) {
            acc.push(item);
          }
          return acc;
        }, []);
        // TO DO => DELETE AFTER SUCCESSFULL TESTING // state.customField.list = state.customField.list.filter(cF => cF.custom_field_definition_id !== payload);
      }
    },
    onReorderContactSettings: (state, { payload }) => {
      state[payload.key].list = payload.data;
    },
    onFailedReorderCustomFieldRowsSetting: (state, { payload }) => {
      state[payload.key].list = payload.data;
    }
  }
});

export const {
  onCallContactSettings,
  onSuccessContactSettings,
  onFailedContactSettings,
  switchContactSettingLoading,
  onSuccessContactSettingAdd,
  onSuccessContactSettingEdit,
  onSuccessContactSettingDelete,
  onCallContactCustomFields,
  onSuccessContactCustomFields,
  onFailedContactCustomFields,
  switchContactCustomFieldLoading,
  onSuccessContactCustomField,
  onFailedContactCustomField,
  resetContactCustomField,
  onSuccessContactCustomFieldAdd,
  onSuccessContactCustomFieldEdit,
  onSuccessContactCustomFieldDelete,
  onReorderContactSettings,
  onFailedReorderCustomFieldRowsSetting
} = contactSettingsSlice.actions;

export default contactSettingsSlice.reducer;
