import React from "react";
import { Droppable } from "react-beautiful-dnd";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import DragItem from "../drag-item";

const BackEndColumn = ({ column }) => (
  <div
    style={{
      backgroundColor: "gray",
      padding: 20,
      color: "white",
      flexGrow: 1,
      width: "40%"
    }}
  >
    <Typography fontSize="19px!important" variant="h5">
      {column.name}
    </Typography>
    <Droppable droppableId={column.id}>
      {(provided) => (
        <List style={{ width: "100%", height: "100%" }} ref={provided.innerRef} {...provided.droppableProps}>
          {column.list.map((itemObject, index) => (
            <DragItem key={itemObject.columnName} itemObject={itemObject} index={index} />
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  </div>
);

export default BackEndColumn;
