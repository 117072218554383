import React, { memo } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: theme.shadows[2],
    fontSize: 11,
    borderRadius: "6px",
    color: "white",
    "&.uppercase": {
      textTransform: "uppercase"
    }
  },
  arrow: {
    color: theme.palette.primary.light
  }
}));

const CustomizedTooltip = memo(({ title, tooltipClassName, arrowClassName, uppercase, children, hidden, ...rest }) => {
  const classes = useStyles();
  return hidden ? (
    children
  ) : (
    <Tooltip
      title={title}
      classes={{
        tooltip: `${classes.tooltip} ${tooltipClassName} ${uppercase ? "uppercase" : ""}`,
        arrow: `${classes.arrow} ${arrowClassName}`
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
});

CustomizedTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]).isRequired,
  tooltipClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  uppercase: PropTypes.bool,
  children: PropTypes.element.isRequired,
  hidden: PropTypes.bool
};

CustomizedTooltip.defaultProps = {
  tooltipClassName: "",
  arrowClassName: "",
  hidden: false,
  uppercase: false
};

export default CustomizedTooltip;
