export const IMPORT_ADD_CALL = "IMPORT_ADD_CALL";

export const IMPORT_TYPES = {
  INSERT: "Add",
  UPDATE: "Update",
  INSERT_AND_UPDATE: "Insert and Update"
};

export function callAddImport(importData, importedFile, moduleName, messageFunction) {
  return { type: IMPORT_ADD_CALL, payload: { importData, importedFile, moduleName, messageFunction } };
}
