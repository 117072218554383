const joiDefaultErrorMessages = {
  "string.empty": "Is a required field",
  "any.required": "Is a required field",
  "string.base": "Should be a type of 'text'",
  "string.max": "Can not be longer than {#limit} characters",
  "string.min": "Can not be shorter than {#limit} characters",
  "string.email": "Invalid email address",
  "string.pattern.base": "Invalid value",
  "string.length": "Must be {#limit} characters",
  "number.base": "Must be a number",
  "number.unsafe": "Number out of range",
  "number.infinity": "Number out of range",
  "number.max": "Can not be shorter than {#limit} characters",
  "array.min": "Is a required field"
};

export const INCORRECT_URL = "Incorrect image url";

export default joiDefaultErrorMessages;
