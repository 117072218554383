import { takeLatest, put, call } from "redux-saga/effects";
import { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import {  VIDEO_TEMPLATE_LIST_CALL } from "./sagas-actions";
import { onCallVideoTemplate, onFailedVideoTemplate, onSuccessVideoTemplate } from "./slice";

function* videoCallGenerator(action) {
  yield put(onCallVideoTemplate());
  try {
    const { payload : { tenantId } } = action;
    const { data } = yield call(axiosSagaRequest, "post", "/base/video_template_list", {
      tenant_id: tenantId
    });
    yield put(onSuccessVideoTemplate(data[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedVideoTemplate());
  }
}

export default function* currenciesWatcherSaga() {
  yield takeLatest(VIDEO_TEMPLATE_LIST_CALL, videoCallGenerator);
}
