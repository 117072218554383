import { call, put, takeEvery } from "redux-saga/effects";
import { DELETED_ITEMS_CALL, callDeletedItemsPromise } from "./sagas-actions";
import { onCallDeletedItems, onFailedDeletedItems, onSuccessDeletedItems } from "./slice";
import { setError } from "store/error/slice";
import axiosInstance from "clients/api";
import { paramParserForPagination } from "utils/helpers";
import qs from "qs";
import { implementPromiseAction } from "@adobe/redux-saga-promise";


export const prefix = "/tenantUser/app_deleted_item";

// Initial call
function* deletedItemsCallGenerator(action) {
  yield put(onCallDeletedItems());
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);
    yield put(onSuccessDeletedItems(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDeletedItems());
  }
}


function* deletedItemsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* auditLogsWorker() {
    yield put(onCallDeletedItems(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      
      const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

      return yield put(onSuccessDeletedItems(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

export default function* deletedItemsSaga() {
  yield takeEvery(DELETED_ITEMS_CALL, deletedItemsCallGenerator);
  yield takeEvery(callDeletedItemsPromise, deletedItemsCallPromiseGenerator);
}