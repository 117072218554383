import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const ImportErrorDialog = ({ isOpen, title, onClose, message }) => (
  <Dialog fullWidth maxWidth="sm" open={isOpen}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Alert severity="error" color="error">
        {message}
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default ImportErrorDialog;
