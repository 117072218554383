import { createSlice, current } from "@reduxjs/toolkit";
import { arrayMove } from "utils/helpers";

export const opportunitySettingsSlice = createSlice({
  name: "opportunity",
  initialState: {
    isFetched: false,
    opportunities: {
      items: {},
      columns: {},
      columnsOrder: []
    },
    filters: {
      byTenantUserId: "no_filter"
    },

    isSingleFetched: false,
    isLoading: false,
    opportunity: undefined,

    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },
    stage: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    },

    board: {
      isFetched: false,
      isLoading: false,
      board_id_in_use: null,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        list: []
      }
    }
  },
  reducers: {
    onCallOpportunities: (state, { payload }) => {
      // payload -> without loading

      if (!payload) {
        state.isFetched = false;
        state.opportunities = {
          items: {},
          columns: {},
          columnsOrder: []
        };
      }
    },
    onSuccessOpportunities: (state, { payload }) => {
      const items = {};
      const columns = {};
      const opportunity_board_id = state.board.attributeData.list.find((board) => board.isDefault === true)?.code;
      const columnsOrder = state.stage.attributeData.list
        .filter((stage) => stage?.board_id === (payload.boardId || opportunity_board_id))
        .map((stage) => ({
          id: stage.code,
          name: stage.name
        }));
      state.board.board_id_in_use = payload.boardId || opportunity_board_id;

      payload.tableData.forEach((item) => {
        items[item.opportunity_id] = {
          ...item,
          cost: parseFloat(item.cost) || 0,
          revenue: parseFloat(item.revenue) || 0,
          probability: parseFloat(item.probability) || 0,
          id: item.opportunity_id,
          hidden:
            state.filters.byTenantUserId === "no_filter" ? false : state.filters.byTenantUserId !== item.tenant_user_id
        };
      });

      columnsOrder.forEach((column) => {
        columns[column.id] = {
          id: column.id,
          title: column.name,
          color: state.stage.attributeData.list.find((stage) => stage.code === column.id).color,
          itemIds: payload.tableData.filter((item) => item?.stage_id === column.id).map((item) => item.opportunity_id)
        };
      });

      state.isFetched = true;
      state.opportunities = {
        items,
        columns,
        columnsOrder: columnsOrder.map((c) => c.id)
      };
    },
    onFailedOpportunities: (state) => {
      state.isFetched = true;
      state.opportunities = {
        items: {},
        columns: {},
        columnsOrder: []
      };
    },

    switchOpportunitiesLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },

    onSuccessOpportunity: (state, { payload }) => {
      state.isSingleFetched = true;
      state.opportunity = payload;
    },
    onFailedOpportunity: (state) => {
      state.isSingleFetched = true;
      state.opportunity = undefined;
    },
    resetOpportunity: (state) => {
      state.isSingleFetched = false;
      state.opportunity = undefined;
    },

    onSuccessOpportunityAdd: (state, { payload }) => {
      if (payload) {
        state.opportunities.items[payload.opportunity_id] = {
          ...payload,
          cost: parseFloat(payload.cost) || 0,
          revenue: parseFloat(payload.revenue) || 0,
          probability: parseFloat(payload.probability) || 0,
          id: payload.opportunity_id,
          hidden:
            state.filters.byTenantUserId === "no_filter"
              ? false
              : state.filters.byTenantUserId !== payload.tenant_user_id
        };

        const columnId = payload?.stage_id;
        // const columnName = payload?.stage;
        // if (state.opportunities.columns[columnId]) {
        state.opportunities.columns[columnId].itemIds.push(payload.opportunity_id);
        // } else {
        //     state.opportunities.columnsOrder.push(columnId);
        //     state.opportunities.columns[columnId] = {
        //         id: columnId,
        //         title: columnName,
        //         itemIds: [payload.opportunity_id]
        //     };
        // }
      }
    },
    onSuccessOpportunityEdit: (state, { payload }) => {
      if (payload) {
        const newOpportunity = {
          ...payload,
          cost: parseFloat(payload.cost) || 0,
          revenue: parseFloat(payload.revenue) || 0,
          probability: parseFloat(payload.probability) || 0,
          id: payload.opportunity_id,
          hidden:
            state.filters.byTenantUserId === "no_filter"
              ? false
              : state.filters.byTenantUserId !== payload.tenant_user_id
        };

        const oldOpportunity = state.opportunities.items[payload.opportunity_id];
        // state.opportunities.items[payload.opportunity_id] = {
        //     ...payload,
        //     // stage: opp.stage,
        //     // stage_id: opp.stage_id,
        //     // position: opp.position,
        //     cost: parseFloat(payload.cost),
        //     revenue: parseFloat(payload.revenue)
        //     // id: payload.opportunity_id
        // };

        const newStageId = newOpportunity.stage_id;
        const oldStageId = oldOpportunity.stage_id;

        const newPosition = newOpportunity.position;
        const oldPosition = oldOpportunity.position;

        if (!(newStageId in state.opportunities.columns)) {
          state.opportunities.columns[oldStageId].itemIds = state.opportunities.columns[oldStageId].itemIds.filter(
            (item) => item !== newOpportunity.opportunity_id
          );
        } else if (newStageId !== oldStageId) {
          // let positionIsChanged = false;
          // if (newPosition !== oldPosition) {
          //     positionIsChanged = true;
          // }

          // state.opportunities.columns[oldStageId] = {
          //     ...state.opportunities.columns[oldStageId],
          //     itemIds: state.opportunities.columns[oldStageId].itemIds.filter(i => i !== payload.opportunity_id)
          // };
          // state.opportunities.columns[oldStageId].itemIds.filter(itemId => itemId !== payload.opportunity_id);
          state.opportunities.columns[oldStageId].itemIds.splice(oldPosition, 1);

          // const newItems = [...state.opportunities.columns[newStageId].itemIds];
          // state.opportunities.columns[newStageId] = {
          //     ...state.opportunities.columns[newStageId],
          //     itemIds: [...newItems.splice(newPosition, 0, payload.opportunity_id)]
          // };

          state.opportunities.columns[newStageId].itemIds.splice(newPosition, 0, payload.opportunity_id);
        } else if (newPosition !== oldPosition) {
          // state.opportunities.columns[oldStageId].itemIds.splice(oldPosition, 1);
          // state.opportunities.columns[oldStageId].itemIds.splice(newPosition, 0, payload.opportunity_id);
          state.opportunities.columns[oldStageId].itemIds = arrayMove(
            state.opportunities.columns[oldStageId].itemIds,
            oldPosition,
            newPosition
          );
        }

        state.opportunities.items[payload.opportunity_id] = newOpportunity;
      }
    },
    onSuccessOpportunitiesDelete: (state, { payload: { opportunityIds, newStageColumns, opportunityPositions } }) => {
      if (opportunityIds !== undefined) {
        state.opportunities.columns = newStageColumns;

        // Object.keys(state.opportunities.columns)
        //     .forEach(columnKey => {
        //         state.opportunities.columns[columnKey].itemIds = state.opportunities.columns[columnKey].itemIds.filter(i => !opportunityIds.includes(i));
        //     });

        Object.keys(state.opportunities.items).forEach((itemKey) => {
          if (opportunityIds.includes(itemKey)) {
            delete state.opportunities.items[itemKey];
          }
        });

        opportunityPositions.forEach((item) => {
          state.opportunities.items[item.opportunity_id].position = item.position;
        });
      }
    },
    onSuccessOpportunitiesPositionsChange: (state, { payload: { opportunityPositions, stageId } }) => {
      if (stageId !== undefined) {
        state.opportunities.columns[stageId].itemIds = opportunityPositions.map((item) => item.opportunity_id);

        opportunityPositions.forEach((item) => {
          state.opportunities.items[item.opportunity_id].position = item.position;
        });
      }
    },
    onSuccessOpportunityStageChange: (state, { payload }) => {
      if (payload !== undefined) {
        const { fromStageId, toStageId, fromStagePositions, toStagePositions, opportunityId, probability } = payload;

        state.opportunities.columns[fromStageId].itemIds = fromStagePositions.map((item) => item.opportunity_id);
        state.opportunities.columns[toStageId].itemIds = toStagePositions.map((item) => item.opportunity_id);
        state.opportunities.items[opportunityId].stage_id = toStageId;

        if (probability > 0) {
          state.opportunities.items[opportunityId].probability = probability;
        }

        fromStagePositions.forEach((item) => {
          state.opportunities.items[item.opportunity_id].position = item.position;
        });
        toStagePositions.forEach((item) => {
          state.opportunities.items[item.opportunity_id].position = item.position;
        });
      }
    },

    onCallOpportunitySettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessOpportunitySettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;
      state[payload.key].columnNames = payload.data.columnNames;
    },
    onFailedOpportunitySettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },

    switchOpportunitySettingLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },

    onSuccessOpportunitySettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessOpportunitySettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey]
              ? payload.data
              : {
                  ...item,
                  Default: false
                }
          );
        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessOpportunitySettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        if (payload.key === "stage") {
          const deletedItem = state[payload.key].list.reduce((acc, item) => {
            if (item[payload.idKey] === payload.id) {
              acc = { position: item["List Position"], boardName: item.Board };
            }
            return acc;
          }, {});
          state[payload.key].list = state[payload.key].list.reduce((acc, item) => {
            if (item["List Position"] > deletedItem.position && item.Board === deletedItem.boardName) {
              item["List Position"] -= 1;
            }
            if (item[payload.idKey] !== payload.id) {
              acc.push(item);
            }
            return acc;
          }, []);
        } else {
          state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
        }
      }
    },
    onReorderOpportunitySetting: (state, { payload: { key, idKey, data } }) => {
      // state[payload.key].list = payload.data;
      if (key === "stage") {
        state[key].list = state[key].list.map((stage) => data.find((item) => item[idKey] === stage[idKey]) || stage);
      } else {
        state[key].list = data;
      }
    },
    onFailedReorderOpportunitySetting: (state, { payload }) => {
      state[payload.key].list = payload.data;
    },

    // ATTRIBUTES
    onCallOpportunityAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = false;
      state[payload.key].attributeData.isLoading = false;
      state[payload.key].attributeData.list = [];
    },
    onSuccessOpportunityAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;

      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );
        state[payload.key].attributeData.list = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.default_value,
          ...(item.color !== undefined && { color: item.color }),
          ...(item.board_id !== undefined && { board_id: item.board_id }),
          ...(item.position !== undefined && { position: item.position }),
          ...(item.probability !== undefined && { probability: item.probability })
        }));
      }
    },
    onFailedOpportunityAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;
      state[payload.key].attributeData.list = [];
    },
    onSuccessOpportunityAttributeAdd: (state, { payload }) => {
      if (payload.data) {
        const codeFieldName = Object.keys(payload.data).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));
        state[payload.key].attributeData.list = [
          ...state[payload.key].attributeData.list,
          {
            code: payload.data[codeFieldName],
            name: payload.key === "stage" ? payload.data.Stage : payload.data[nameFieldName],
            isDefault: payload.data.Default,
            ...(payload.data.Board && {
              board_id: state.board.attributeData.list.find((item) => item.name === payload.data.Board)?.code
            })
          }
        ];

        // In case if adding stage from dropdown update stage list in columns (real time update needed)
        if (payload.key === "stage") {
          const stageId = payload.data.opportunity_stage_id;
          const stagePosition = payload.data["List Position"];
          const stageTitle = payload.data.Stage;
          const stageColor = payload.data.Color;
          state.opportunities.columnsOrder.splice(stagePosition, 0, stageId);
          state.opportunities.columns[stageId] = {
            id: stageId,
            title: stageTitle,
            color: stageColor,
            itemIds: []
          };
        }
      }
    },
    onSuccessOpportunityAttributeEdit: (state, { payload }) => {
      if (payload.data) {
        const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));
        if (payload.data.Default) {
          state[payload.key].attributeData.list = state[payload.key].attributeData.list.map((item) =>
            item.code === payload.data[payload.idKey]
              ? {
                  code: payload.data[payload.idKey],
                  name: payload.key === "stage" ? payload.data.Stage : payload.data[nameFieldName],
                  isDefault: payload.data.Default,
                  ...(payload.data["List Position"] && { position: payload.data["List Position"] }),
                  ...(payload.data.Color && { color: payload.data.Color }),
                  ...(payload.data.Probability && { probability: payload.data.Probability }),
                  ...(payload.data.Board && {
                    board_id: state.board.attributeData.list.find((item) => item.name === payload.data.Board)?.code
                  })
                }
              : {
                  ...item,
                  isDefault: false
                }
          );
        } else {
          state[payload.key].attributeData.list = state[payload.key].attributeData.list.map((item) =>
            item.code === payload.data[payload.idKey]
              ? {
                  code: payload.data[payload.idKey],
                  name: payload.key === "stage" ? payload.data.Stage : payload.data[nameFieldName],
                  isDefault: payload.data.Default,
                  ...(payload.data["List Position"] && { position: payload.data["List Position"] }),
                  ...(payload.data.Color && { color: payload.data.Color }),
                  ...(payload.data.Probability && { probability: payload.data.Probability }),
                  ...(payload.data.Board && {
                    board_id: state.board.attributeData.list.find((item) => item.name === payload.data.Board)?.code
                  })
                }
              : item
          );
        }
      }
    },
    switchOpportunitiesAttributeChangeLoading: (state, { payload }) => {
      state[payload.key].attributeData.isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].attributeData.isLoading;
    },

    // FILTERS
    filterOpportunities: (state, { payload }) => {
      // payload -> (no_filter || user ID)
      state.filters.byTenantUserId = payload;
      Object.values(current(state.opportunities.items)).forEach((opp) => {
        state.opportunities.items[opp.opportunity_id].hidden =
          payload === "no_filter" ? false : opp.tenant_user_id !== payload;
      });
    },
    filterOpportunitiesByCurrency: (state, { payload }) => {
      Object.values(current(state.opportunities.items)).forEach((opp) => {
        state.opportunities.items[opp.opportunity_id].hidden = opp.currency_id !== payload;
      });
    },
    changeActiveBoard: (state, { payload }) => {
      state.board.board_id_in_use = payload;
    }
  }
});

export const {
  onCallOpportunities,
  onSuccessOpportunities,
  onFailedOpportunities,
  switchOpportunitiesLoading,
  onSuccessOpportunity,
  onFailedOpportunity,
  resetOpportunity,

  onSuccessOpportunityAdd,
  onSuccessOpportunityEdit,
  onSuccessOpportunitiesDelete,
  onSuccessOpportunitiesPositionsChange,
  onSuccessOpportunityStageChange,

  onCallOpportunitySettings,
  onSuccessOpportunitySettings,
  onFailedOpportunitySettings,
  switchOpportunitySettingLoading,
  onSuccessOpportunitySettingAdd,
  onSuccessOpportunitySettingEdit,
  onSuccessOpportunitySettingDelete,

  onCallOpportunityAttributes,
  onSuccessOpportunityAttributes,
  onFailedOpportunityAttributes,
  onSuccessOpportunityAttributeAdd,
  onSuccessOpportunityAttributeEdit,
  switchOpportunitiesAttributeChangeLoading,

  filterOpportunities,
  filterOpportunitiesByBoard,
  filterOpportunitiesByCurrency,

  onReorderOpportunitySetting,
  onFailedReorderOpportunitySetting,

  onSocketOpportunitiesPositionsChange,

  changeActiveBoard
} = opportunitySettingsSlice.actions;

export default opportunitySettingsSlice.reducer;
