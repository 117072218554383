export const outcomeTypes = [
  {
    name: "No Change",
    code: '0',
  },
  {
    name: "Won",
    code: '1'
  },
  {
    name: "Lost",
    code: '2',
  },
];

export const outcomeTaskTypes = [
  {
    name: "No Change",
    code: '0',
  },
  {
    name: "Completed",
    code: '3',
  },
];
