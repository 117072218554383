import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import currenciesDataSelector from "store/currencies/selectors";

/**
 * This hook returns currency data based on currency id
 * @param {Number} defaultCurrencyId
 */

const useCurrency = (defaultCurrencyId) => {
  const { currencies } = useSelector(currenciesDataSelector, isEqual);
  const currency = useMemo(() => currencies.find((c) => c.code === defaultCurrencyId), [defaultCurrencyId, currencies]);
  const getCurrencyData = useCallback((currencyId) => currencies.find((c) => c.code === currencyId), [currencies]);

  return {
    currency,
    getCurrencyData,
    currencies
  };
};

useCurrency.propTypes = {
  defaultCurrencyId: PropTypes.number.isRequired
};

useCurrency.defaultProps = {};

export default useCurrency;
