import { createSlice } from "@reduxjs/toolkit";

export const taskSettingsSlice = createSlice({
  name: "task-settings",
  initialState: {
    priority: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    tag: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    },
    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: []
    }
  },
  reducers: {
    onCallTaskSettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].isLoading = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessTaskSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;
      state[payload.key].columnNames = payload.data.columnNames;
    },
    onFailedTaskSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },

    switchTaskSettingLoading: (state, { payload }) => {
      state[payload.key].isLoading = payload.status !== undefined ? payload.status : !state[payload.key].isLoading;
    },

    onSuccessTaskSettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessTaskSettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey]
              ? payload.data
              : {
                  ...item,
                  Default: false
                }
          );
        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessTaskSettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        if (payload.key === "priority") {
          const deletedItemIndex = state[payload.key].list.findIndex((item) => item[payload.idKey] === payload.id);
          state[payload.key].list = state[payload.key].list.reduce((acc, item, index) => {
            if (index >= deletedItemIndex) {
              item["List Position"] -= 1;
            }
            if (item[payload.idKey] !== payload.id) {
              acc.push(item);
            }
            return acc;
          }, []);
        } else {
          state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
        }
      }
    },
    onReorderTaskSettings: (state, { payload }) => {
      state[payload.key].list = payload.data;
    },
    onFailedReorderStageRowsSetting: (state, { payload }) => {
      state[payload.key].list = payload.data;
    }
  }
});

export const {
  onCallTaskSettings,
  onSuccessTaskSettingDelete,
  onSuccessTaskSettingEdit,
  onSuccessTaskSettingAdd,
  switchTaskSettingLoading,
  onFailedTaskSettings,
  onSuccessTaskSettings,
  onReorderTaskSettings,
  onFailedReorderStageRowsSetting
} = taskSettingsSlice.actions;

export default taskSettingsSlice.reducer;
