import { createSlice } from "@reduxjs/toolkit";

export const auditLogsSlice = createSlice({
  name: "auditLogs",
  initialState: {
    isFetched: false,
    auditLogs: [],
    columnNames: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
    // isSingleFetched: false,
    // isLoading: false,
    // auditLog: undefined,
  },
  reducers: {
    onCallAuditLogs: (state, { payload }) => {
      if (!payload) {
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        state.pagination = payload;
      }
      state.auditLogs = [];
    },
    onSuccessAuditLogs: (state, { payload }) => {
      state.isFetched = true;
      state.count = parseInt(payload?.total_count, 10);
      state.auditLogs = payload.tableData;
      state.columnNames = payload.columnNames;
    },
    onFailedAuditLogs: (state) => {
      state.isFetched = true;
      state.auditLogs = [];
      state.columnNames = [];
      state.count = 0;
    }
  }
});

export const { onCallAuditLogs, onSuccessAuditLogs, onFailedAuditLogs } = auditLogsSlice.actions;

export default auditLogsSlice.reducer;
