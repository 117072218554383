import { createSlice } from "@reduxjs/toolkit";
import { extractMergeFields } from "../../utils/helpers";

export const templatesSlice = createSlice({
  name: "templates",
  initialState: {
    // All template

    isFetched: false,
    templates: [],
    columnNames: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },

    templateGroups: {
      isFetched: false,
      data: [],
      error: null
    },

    filteredTemplates: {
      isFetched: false,
      data: [],
      error: null
    },

    templateByType: {
      Notification: {
        isFetched: false,
        data: [],
        error: null
      },
      Email: {
        isFetched: false,
        data: [],
        error: null
      },
      SMS: {
        isFetched: false,
        data: [],
        error: null
      },
      Script: {
        isFetched: false,
        data: [],
        error: null
      },
      Objection: {
        isFetched: false,
        data: [],
        error: null
      },
      Document: {
        isFetched: false,
        data: [],
        error: null
      }
    },

    templatesCount: {
      isFetched: false,
      list: [],
      error: null
    },

    mergeFields: {
      isFetched: false,
      list: [],
      grouped: undefined
    },
    mergeFieldItems: {
      isFetched: false,
      data: {}
    },
    ccBcc: {
      contactList: [],
      tenant_user_list: []
    },
    // single template
    isLoading: false,
    isLoadingTest: false,
    // isLoadingAttachment: false,
    isSingleFetched: false,
    template: {
      props: undefined,
      attachments: []
    },

    templateForContact: {
      isFetched: false,
      isLoading: false,
      list: []
    },

    selectedTemplateType: undefined
  },
  reducers: {
    onCallFilteredTemplates: (state) => {
      state.filteredTemplates.isFetched = false;
    },
    onCallFilteredTemplatesSuccess: (state, { payload }) => {
      state.filteredTemplates.isFetched = false;
      state.filteredTemplates.data = payload;
    },
    onCallFilteredTemplatesFailed: (state, { payload }) => {
      state.filteredTemplates.isFetched = false;
      state.filteredTemplates.error = payload;
    },
    onCallTemplatesGroups: (state) => {
      state.templateGroups.isFetched = false;
    },
    onCallTemplatesGroupsSuccess: (state, { payload }) => {
      state.templateGroups.isFetched = true;
      state.templateGroups.data = payload;
    },
    onCallTemplatesGroupsFailed: (state, { payload }) => {
      state.templateGroups.isFetched = true;
      state.templateGroups.error = payload;
    },
    onCallTemplateByType: (state, { payload }) => {
      state.templateByType[payload.typeName].isFetched = false;
    },
    onCallTemplateByTypeSuccess: (state, { payload }) => {
      state.templateByType[payload.typeName].isFetched = true;
      state.templateByType[payload.typeName].data = payload.data;
    },
    onCallTemplateByTypeFailed: (state, { payload }) => {
      state.templateByType[payload.typeName].isFetched = true;
      state.templateByType[payload.typeName].error = payload.data;
    },

    onCallTemplatesCount: (state) => {
      state.templatesCount.isFetched = false;
    },
    onCallTemplatesCountSuccess: (state, { payload }) => {
      state.templatesCount.isFetched = true;
      state.templatesCount.list = payload;
    },
    onCallTemplatesCountFailed: (state, { payload }) => {
      state.templatesCount.isFetched = true;
      state.templatesCount.error = payload;
    },
    // all templates
    onCallTemplates: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.templates = [];
    },
    onSuccessTemplates: (state, { payload }) => {
      state.isFetched = true;
      state.count = parseInt(payload?.total_count, 10) || 0;
      state.templates = payload.tableData;
      state.columnNames = payload.columnNames;
    },
    onFailedTemplates: (state) => {
      state.isFetched = true;
      state.templates = [];
      state.columnNames = [];
      state.count = 0;
    },
    onCallCcBccTemplate: (state) => {
      state.isFetched = false;
    },

    onSelectTemplateType: (state, { payload }) => {
      if (payload) {
        state.selectedTemplateType = payload;
      }
    },
    resetSelectedTemplateType: (state) => {
      state.selectedTemplateType = undefined;
    },

    onSuccessCcBccTemplate: (state, { payload }) => {
      if (payload) {
        const tenant_users = payload?.tenantUsers.map((item) => ({
          code: item.tenant_user_id,
          name: `${item.first_name} ${item.last_name}`
        }));
        const contacts = payload?.contacts.map((item) => ({
          code: item.contact_id,
          name: `${item.first_name} ${item.last_name}`
        }));
        state.isFetched = true;
        state.ccBcc = {
          contactList: contacts,
          tenant_user_list: tenant_users
        };
      }
    },
    onFailedCcBccTemplate: (state) => {
      state.isFetched = true;
      state.ccBcc = {
        contactList: [],
        tenant_user_list: []
      };
    },
    // merge fields
    onCallMergeFields: (state) => {
      state.mergeFields.isFetched = false;
      state.mergeFields.list = [];
    },
    onSuccessMergeFields: (state, { payload }) => {
      state.mergeFields.isFetched = true;
      state.mergeFields.list = payload;

      const groups = payload.reduce((r, a) => {
        r[a.group_name] = [...(r[a.group_name] || []), a]; // .toLowerCase()
        return r;
      }, {});

      Object.keys(groups).forEach((mFK) => {
        if (mFK !== "null") {
          groups[mFK] = groups[mFK]
            .filter((item) => item.token)
            // .filter(item => +item?.template_type_id === +templateTypeId)
            .map((item) => ({
              text: item.token,
              value: `[${item.group_name}.${item.token}]`,
              column_name: item.column_name
            }));
        } else {
          delete groups[mFK];
        }
      });

      state.mergeFields.grouped = groups;
    },
    onFailedMergeFields: (state) => {
      state.mergeFields.isFetched = true;
      state.mergeFields.list = [];
    },

    // single template
    switchTemplatesLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    switchTemplateTestLoading: (state, { payload }) => {
      state.isLoadingTest = payload !== undefined ? payload : !state.isLoadingTest;
    },
    // switchTemplatesAttachmentLoading: (state, { payload }) => {
    //     state.isLoadingAttachment = payload !== undefined ? payload : !state.isLoadingAttachment;
    // },

    onSuccessTemplate: (state, { payload }) => {
      state.isSingleFetched = true;
      const subjectMergeFields = extractMergeFields(payload?.template?.[0].subject);
      const bodyMergeFields = extractMergeFields(payload?.template?.[0].body);

      state.template = {
        props: {
          ...payload?.template?.[0],
          mergeFields: [...subjectMergeFields, ...bodyMergeFields]
        },
        attachments: payload?.attachments || []
      };
    },
    onFailedTemplate: (state) => {
      state.isSingleFetched = true;
      state.template = {
        props: undefined,
        attachments: []
      };
    },
    resetTemplate: (state) => {
      state.isSingleFetched = false;
      state.template = {
        props: undefined,
        attachments: []
      };
    },

    onSuccessTemplateAdd: (state, { payload }) => {
      if (payload) {
        state.templates = [...state.templates, payload];
      }
    },
    onSuccessTemplateAddForFiltered: (state, { payload }) => {
      if (payload) {
        state.filteredTemplates.data = [...state.filteredTemplates.data, payload];
        state.count += 1;

        state.templatesCount.list = state.templatesCount.list.map((item) => {
          if (item.template_type_id === payload.template_type_id) {
            item.type_count = (+item.type_count + 1).toString();
          }
          return item;
        });

        state.templateByType[payload.Type].data = [...state.templateByType[payload.Type].data, payload];
      }
    },
    onSuccessTemplateEdit: (state, { payload }) => {
      if (payload) {
        state.templates = state.templates.map((eT) => (eT.template_id === payload.template_id ? payload : eT));
        state.template.props = {
          ...state.template.props,
          ...payload
        };
      }
    },
    onSuccessTemplatesDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.templates = state.templates.filter((eT) => !payload.includes(eT.template_id));
      }
    },

    // Email templates to send for contacts
    onCallContactTemplates: (state) => {
      state.templateForContact.isFetched = false;
      state.templateForContact.list = [];
    },
    onSuccessContactTemplates: (state, { payload }) => {
      state.templateForContact.isFetched = true;
      state.templateForContact.list = payload?.templates || [];
    },
    onFailedContactTemplates: (state) => {
      state.templateForContact.isFetched = true;
      state.templateForContact.list = [];
    },
    switchContactEmailTemplateSendLoading: (state, { payload }) => {
      state.templateForContact.isLoading = payload !== undefined ? payload : !state.templateForContact.isLoading;
    },

    onCallContactTemplateMergeFieldsData: (state) => {
      state.mergeFieldItems.isFetched = false;
      state.mergeFieldItems.data = [];
    },
    onSuccessContactTemplateMergeFieldsData: (state, { payload }) => {
      state.mergeFieldItems.isFetched = true;
      state.mergeFieldItems.data = payload || [];
    },
    onFailedContactTemplateMergeFieldsData: (state) => {
      state.mergeFieldItems.isFetched = true;
      state.mergeFieldItems.data = [];
    },
    resetTemplateMergeFields: (state) => {
      state.mergeFieldItems.isFetched = false;
      state.mergeFieldItems.data = null;
    }
  }
});

export const {
  onCallFilteredTemplates,
  onCallFilteredTemplatesSuccess,
  onCallFilteredTemplatesFailed,

  onCallTemplatesGroups,
  onCallTemplatesGroupsSuccess,
  onCallTemplatesGroupsFailed,

  onCallTemplateByType,
  onCallTemplateByTypeSuccess,
  onCallTemplateByTypeFailed,

  onCallTemplatesCount,
  onCallTemplatesCountSuccess,
  onCallTemplatesCountFailed,

  onCallTemplates,
  onSuccessTemplates,
  onFailedTemplates,
  onCallCcBccTemplate,
  onSuccessCcBccTemplate,
  onFailedCcBccTemplate,
  resetSelectedTemplateType,

  switchTemplatesLoading,
  switchTemplateTestLoading,
  // switchTemplatesAttachmentLoading,
  onSuccessTemplate,
  onFailedTemplate,
  resetTemplate,

  onCallMergeFields,
  onSuccessMergeFields,
  onFailedMergeFields,

  onSuccessTemplateAdd,
  onSuccessTemplateEdit,
  onSuccessTemplatesDelete,
  onSuccessTemplateAddForFiltered,

  onCallContactTemplates,
  onSuccessContactTemplates,
  onFailedContactTemplates,
  switchContactEmailTemplateSendLoading,

  onCallContactTemplateMergeFieldsData,
  onSuccessContactTemplateMergeFieldsData,
  onFailedContactTemplateMergeFieldsData,
  resetTemplateMergeFields
} = templatesSlice.actions;

export default templatesSlice.reducer;
