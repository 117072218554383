import { createPromiseAction } from "@adobe/redux-saga-promise";

export const DOCUMENT_WIZARD_DEFINITIONS_CALL = "DOCUMENT_WIZARD_DEFINITIONS_CALL";
export const DOCUMENT_WIZARD_DEFINITION_CALL = "DOCUMENT_WIZARD_DEFINITION_CALL";
export const DOCUMENT_WIZARD_DEFINITION_ADD_CALL = "DOCUMENT_WIZARD_DEFINITION_ADD_CALL";
export const DOCUMENT_WIZARD_DEFINITION_EDIT_CALL = "DOCUMENT_WIZARD_DEFINITION_EDIT_CALL";
export const DOCUMENT_WIZARD_DEFINITION_DELETE_CALL = "DOCUMENT_WIZARD_DEFINITION_DELETE_CALL";
export const DOCUMENT_WIZARD_DEFINITION_RESTORE_CALL = "DOCUMENT_WIZARD_DEFINITION_RESTORE_CALL";

export const DOCUMENT_WIZARDS_CALL = "DOCUMENT_WIZARDS_CALL";
export const DOCUMENT_WIZARD_CALL = "DOCUMENT_WIZARD_CALL";
export const DOCUMENT_WIZARD_ADD_CALL = "DOCUMENT_WIZARD_ADD_CALL";
export const DOCUMENT_WIZARD_EDIT_CALL = "DOCUMENT_WIZARD_EDIT_CALL";
export const DOCUMENT_WIZARD_DELETE_CALL = "DOCUMENT_WIZARD_DELETE_CALL";

export const DOCUMENT_WIZARD_DEFINITIONS_CHANGE_STATUSES = "DOCUMENT_WIZARD_DEFINITIONS_CHANGE_STATUSES";

export const DOCUMENT_WIZARD_DEFINITIONS_CALL_CONDITIONS = "DOCUMENT_WIZARD_DEFINITIONS_CALL_CONDITIONS";


export const CREATE_DOCUMENT_FROM_WIZARD = "CREATE_DOCUMENT_FROM_WIZARD";
export const CHANGE_ITEM_FROM_WIZARD = "CHANGE_ITEM_FROM_WIZARD";
export const ADD_ITEM_FROM_WIZARD = "ADD_ITEM_FROM_WIZARD";


export function createDocumentFromWizard(data, resolve, reject) {
  return { type: CREATE_DOCUMENT_FROM_WIZARD, payload: { data, resolve ,reject } };
}
export function changeItemFromWizard(data, resolve, reject) {
  return { type: CHANGE_ITEM_FROM_WIZARD, payload: { data, resolve ,reject } };
}
export function addItemFromWizard(data, resolve, reject) {
  return { type: ADD_ITEM_FROM_WIZARD, payload: { data, resolve ,reject } };
}

export function callDocumentWizardDefinitions() {
  return { type: DOCUMENT_WIZARD_DEFINITIONS_CALL };
}

export function callDocumentWizardDefinition(documentWizardDefinitionId) {
  return { type: DOCUMENT_WIZARD_DEFINITION_CALL, payload: { documentWizardDefinitionId } };
}

export const callAddDocumentWizardDefinitionPromise = createPromiseAction(DOCUMENT_WIZARD_DEFINITION_ADD_CALL);

export function callEditDocumentWizardDefinition(documentWizardDefinitionData, messageFunction) {
  return { type: DOCUMENT_WIZARD_DEFINITION_EDIT_CALL, payload: { documentWizardDefinitionData, messageFunction } };
}

export function callDeleteDocumentWizardDefinition(documentWizardDefinitionId) {
  return { type: DOCUMENT_WIZARD_DEFINITION_DELETE_CALL, payload: { documentWizardDefinitionId } };
}

export function callDocumentWizardDefinitionConditions() {
  return { type: DOCUMENT_WIZARD_DEFINITIONS_CALL_CONDITIONS };
}

export function callDocumentWizards() {
  return { type: DOCUMENT_WIZARDS_CALL };
}

export function callDocumentWizard(documentWizardId) {
  return { type: DOCUMENT_WIZARD_CALL, payload: { documentWizardId } };
}

export function callAddDocumentWizard(wizardData, messageFunction) {
  return { type: DOCUMENT_WIZARD_ADD_CALL, payload: { wizardData, messageFunction } };
}

export function callEditDocumentWizard(documentWizardId, documentWizardData, messageFunction) {
  return { type: DOCUMENT_WIZARD_EDIT_CALL, payload: { documentWizardId, documentWizardData, messageFunction } };
}

export function callDeleteDocumentWizard(documentWizardId) {
  return { type: DOCUMENT_WIZARD_DELETE_CALL, payload: { documentWizardId } };
}
