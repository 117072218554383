import { Typography } from "@mui/material";
import React from "react";
import { numberWithCommas } from "utils/helpers";

const Confirmation = ({ fileDataAmount, importType, moduleName }) => {
  const TOKEN_NAME = {
    company: ["company", "companies"],
    contact: ["contact", "contacts"],
    product_service: ["product/service", "products/services"]
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography>
        You are about to {importType?.toLowerCase()} {numberWithCommas(fileDataAmount)} new{" "}
        {fileDataAmount === 1 ? TOKEN_NAME[moduleName][0] : TOKEN_NAME[moduleName][1]}.
      </Typography>
    </div>
  );
};
export default Confirmation;
