import { createSlice } from "@reduxjs/toolkit";

export const salesTaxSlice = createSlice({
  name: "sales_tax",
  initialState: {
    isFetched: false,
    salesTax: [],
    columnNames: [],

    isSingleFetched: false,
    isLoading: false,
    saleTax: undefined
  },
  reducers: {
    onCallSalesTax: (state) => {
      state.isFetched = false;
      state.isLoading = false;
      state.salesTax = [];
      state.columnNames = [];
    },
    onSuccessSalesTax: (state, { payload }) => {
      state.isFetched = true;
      state.salesTax = payload.salesTaxes;
      state.columnNames = payload.columnNames;
    },
    onFailedSalesTax: (state) => {
      state.isFetched = true;
      state.salesTax = [];
      state.columnNames = [];
    },

    switchSalesTaxLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },

    onSuccessSaleTax: (state, { payload }) => {
      state.isSingleFetched = true;
      state.saleTax = payload;
    },
    onFailedSaleTax: (state) => {
      state.isSingleFetched = true;
      state.saleTax = undefined;
    },
    resetSaleTax: (state) => {
      state.isSingleFetched = false;
      state.saleTax = undefined;
    },

    onSuccessSaleTaxAdd: (state, { payload }) => {
      if (payload) {
        if (payload.Default) {
          state.salesTax = [
            ...state.salesTax.map((sT) => ({
              ...sT,
              Default: false
            })),
            payload
          ];
        } else {
          state.salesTax.push(payload);
        }
      }
    },
    onSuccessSaleTaxEdit: (state, { payload }) => {
      if (payload) {
        if (payload.Default) {
          state.salesTax = state.salesTax.map((sT) =>
            sT.sales_tax_id === payload.sales_tax_id
              ? payload
              : {
                  ...sT,
                  Default: false
                }
          );
        } else {
          state.salesTax = state.salesTax.map((sT) => (sT.sales_tax_id === payload.sales_tax_id ? payload : sT));
        }
      }
    },
    onSuccessSalesTaxDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.salesTax = state.salesTax.filter((sT) => sT.sales_tax_id !== payload);
      }
    }
  }
});

export const {
  onCallSalesTax,
  onSuccessSalesTax,
  onFailedSalesTax,
  switchSalesTaxLoading,
  onSuccessSaleTax,
  onFailedSaleTax,
  resetSaleTax,
  onSuccessSaleTaxAdd,
  onSuccessSaleTaxEdit,
  onSuccessSalesTaxDelete
} = salesTaxSlice.actions;

export default salesTaxSlice.reducer;
