const months = [
  {
    name: "January",
    code: 1
  },
  {
    name: "February",
    code: 2
  },
  {
    name: "March",
    code: 3
  },
  {
    name: "April",
    code: 4
  },
  {
    name: "May",
    code: 5
  },
  {
    name: "June",
    code: 6
  },
  {
    name: "July",
    code: 7
  },
  {
    name: "August",
    code: 8
  },
  {
    name: "September",
    code: 9
  },
  {
    name: "October",
    code: 10
  },
  {
    name: "November",
    code: 11
  },
  {
    name: "December",
    code: 12
  }
];

export default months;
