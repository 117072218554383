import { createSelector } from "@reduxjs/toolkit";

export const tableViewLoaderSelector = createSelector(
  (state, tableName) => state.tableViewData?.[tableName],
  (a) => a
);

export const tableViewsSelector = createSelector(
  (state, tableName) => state.tableViewData?.[tableName],
  (a) => a
);

export const tableViewDataSelector = createSelector(
  (state) => state.tableViewData,
  (a) => a
);

export const customTableViewDataSelector = createSelector(
  (state, moduleValue) => state.tableViewData?.[moduleValue],
  (a) => a
);
