import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "Admin",
  initialState: {
    isFetched: false,
    isLoading: false,
    contents: [],
    content: {
      isFetched: false,
      isLoading: false
    },
    admin_logs: {
      isFetched: false,
      columnNames: [],
      tableData: [],
      count: 0,
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      },
    },
    version: {
      isFetched: false,
      data: {},
      error: null
    }
  },
  reducers: {
    onCallAppVersion: (state) => {
      state.version.isFetched = false;
      state.version.data = {};
    },
    onSuccessCallAppVersion: (state, { payload }) => {
      state.version.isFetched = true;
      state.version.data = payload;
    },
    onFailedCallAppVersion: (state, { payload }) => {
      state.version.isFetched = true;
      state.version.error = payload;
      state.version.data = {};
    },
    onCallContents: (state) => {
      state.isFetched = false;
      state.contents = [];
    },
    onSuccessContents: (state, { payload }) => {
      state.isFetched = true;
      state.contents = payload;
    },
    onFailedContents: (state) => {
      state.contents = [];
      state.isFetched = true;
    },
    onSuccessContentEdit: (state, { payload }) => {
      if (payload) {
        state.contents = state.contents.map((content) =>
          content.app_content_id === Number(payload.app_content_id)
            ? {
              title: payload.title,
              app_content_id: Number(payload.app_content_id),
              body: payload.contentBody,
              app_tag_id: Number(payload.tagId)
            }
            : content
        );
      }
    },
    onCallAdminLogs: (state, { payload }) => {
      if (!payload) {
        state.admin_logs.isFetched = false;
        state.admin_logs.columnNames = [];
        state.admin_logs.count = 0;
      } else {
        state.admin_logs.pagination = payload;
      }
    },
    onSuccessAdminLogs: (state, { payload }) => {
      state.admin_logs.isFetched = true;
      state.admin_logs.columnNames = payload.columnNames;
      state.admin_logs.tableData = payload.tableData;
      state.admin_logs.count = parseInt(payload?.total_count, 10);
    },
    onFailedAdminLogs: (state) => {
      state.admin_logs.isFetched = true;
      state.admin_logs.columnNames = [];
      state.admin_logs.tableData = [];
      state.admin_logs.count = 0;
    }
  }
});

export const {
  onCallContents,
  onSuccessContents,
  onFailedContents,
  onSuccessContentEdit,
  onCallAdminLogs,
  onSuccessAdminLogs,
  onFailedAdminLogs,
  onCallAppVersion,
  onSuccessCallAppVersion,
  onFailedCallAppVersion
} = adminSlice.actions;

export default adminSlice.reducer;
