import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { axiosSagaRequest } from "clients/api";
import { call, put, takeEvery } from "redux-saga/effects";
import { setError } from "store/error/slice";
import {
  callSubuserDomainStatusPromise,
  SUBUSER_DOMAIN_AUTH_CANCEL_CALL,
  SUBUSER_DOMAIN_CREATE_CALL,
  SUBUSER_DOMAIN_STATUS_CALL,
  SUBUSER_DOMAIN_VALIDATION_CALL
} from "./sagas-actions";
import {
  onSuccessDeleteSubuserDomain,
  onSuccessSubuserDomain,
  onSuccessValidateDomain,
  switchSubuserDomainLoading
} from "./slice";

function* createSubuserDomainCallGenerator(action) {
  const {
    payload: { domain }
  } = action;
  yield put(switchSubuserDomainLoading(true));
  try {
    const { data } = yield call(axiosSagaRequest, "post", "/integration/sendgrid/authenticate", {
      domain
    });
    yield put(onSuccessSubuserDomain(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSubuserDomainLoading(false));
}

function* subuserDomainStatusCallGenerator() {
  try {
    const { data } = yield call(axiosSagaRequest, "get", "/integration/sendgrid/authenticate");
    if (data.id) {
      yield put(onSuccessSubuserDomain(data));
    }
  } catch (error) {
    // Need to decide using this error message or not
    // yield put(setError({
    //     error,
    //     action
    // }));
  }
}

function* subuserDomainStatusCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* f() {
    try {
      const { data } = yield call(axiosSagaRequest, "get", "/integration/sendgrid/authenticate");

      if (data.id) {
        yield put(onSuccessSubuserDomain(data));
      }

      return data;
    } catch (error) {
      // Need to decide using this error message or not
      // yield put(setError({
      //     error,
      //     action
      // }));
    }
  });
}

function* subuserDomainAuthCancelGenerator(action) {
  try {
    yield put(switchSubuserDomainLoading(true));
    yield call(axiosSagaRequest, "delete", "/integration/sendgrid/subuser");
    yield put(onSuccessDeleteSubuserDomain());
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(switchSubuserDomainLoading(false));
  }
}

function* subuserDomainValidateGenerator(action) {
  yield put(switchSubuserDomainLoading(true));
  try {
    const { data } = yield call(axiosSagaRequest, "post", "/integration/sendgrid/authenticate/validate");
    yield put(onSuccessValidateDomain(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchSubuserDomainLoading(false));
}

export default function* SubuserDomainSaga() {
  yield takeEvery(SUBUSER_DOMAIN_CREATE_CALL, createSubuserDomainCallGenerator);
  yield takeEvery(SUBUSER_DOMAIN_STATUS_CALL, subuserDomainStatusCallGenerator);
  yield takeEvery(callSubuserDomainStatusPromise, subuserDomainStatusCallPromiseGenerator);
  yield takeEvery(SUBUSER_DOMAIN_AUTH_CANCEL_CALL, subuserDomainAuthCancelGenerator);
  yield takeEvery(SUBUSER_DOMAIN_VALIDATION_CALL, subuserDomainValidateGenerator);
}
