import { implementPromiseAction, rejectPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import appModules from "constant/data/app-modules";
import { paramParserForPagination } from "utils/helpers";
import { setError } from "../error/slice";
import qs from "qs";
import {
  COMPANIES_CALL,
  COMPANIES_CALL_ACTIVE_TENANT_USERS,
  COMPANIES_CALL_CLASSIFICATIONS,
  COMPANIES_CALL_CUSTOM_FIELDS,
  COMPANIES_CALL_GROUPS,
  COMPANIES_CALL_SOCIAL_MEDIAS,
  COMPANIES_CALL_STATUSES,
  COMPANIES_CALL_TYPES,
  COMPANIES_CHANGE_CLASSIFICATIONS,
  COMPANIES_CHANGE_ORG_REP,
  COMPANIES_CHANGE_GROUPS,
  COMPANIES_CHANGE_STATUSES,
  COMPANIES_CHANGE_TYPES,
  COMPANIES_DELETE_CALL,
  COMPANIES_RESTORE_CALL,
  COMPANY_CALL,
  COMPANY_CONTACTS_CALL,
  COMPANY_NOTES_CALL,
  COMPANY_TASKS_CALL,
  callCompaniesPromise,
  callCompanyAddPromise,
  callCompanyEditPromise,
  callSingleCompanyAddPromise,
  COMPANIES_CALL_ORGREPS,
  COMPANY_DOCUMENTS_CALL
} from "./sagas-actions";
import {
  onCallActiveTenantUsers,
  onCallCompanies,
  onCallCompanyAttributes,
  onCallCompanyContacts,
  onCallCompanyCustomFields,
  onCallCompanyDocuments,
  onCallCompanyNotes,
  onCallCompanySocialMedias,
  onCallCompanyTasks,
  onFailedActiveTenantUsers,
  onFailedCompanies,
  onFailedCompany,
  onFailedCompanyAttributes,
  onFailedCompanyContacts,
  onFailedCompanyCustomFields,
  onFailedCompanyDocuments,
  onFailedCompanyNotes,
  onFailedCompanySocialMedias,
  onFailedCompanyTasks,
  onSetCompanyDuplicates,
  onSuccessActiveTenantUsers,
  onSuccessCompanies,
  onSuccessCompaniesDelete,
  onSuccessCompany,
  onSuccessCompanyAdd,
  onSuccessCompanyAttributeChange,
  onSuccessCompanyAttributes,
  onSuccessCompanyContacts,
  onSuccessCompanyCustomFields,
  onSuccessCompanyDocuments,
  onSuccessCompanyEdit,
  onSuccessCompanyNotes,
  onSuccessCompanySocialMedias,
  onSuccessCompanyTasks,
  switchCompaniesAttributeChangeLoading,
  switchCompanyLoading
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { listViewTypes } from "../../constant/data/list-view-types";
import { DUPLICATE_DATA_ERROR_LIST } from "../../constant/errors";
import lodash from "dagre/lib/lodash";

export const prefix = "/tenantUser/companies";
export const addressPrefix = "/tenantUser/companies/company_address";
const appModuleId = appModules.find((t) => t.name === "Company").code;

// Initial call
function* companiesCallGenerator(action) {
  yield put(onCallCompanies());
  try {
    const {
      payload: { paginationData, tenantUserId }
    } = action;
    const params = paramParserForPagination(paginationData);
    if (tenantUserId) {
      params.filter_fields.push({
        name: "tenant_user_id",
        operator: "eq",
        value: tenantUserId
      });
    }
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);
    data.uiViewType = listViewTypes[paginationData.uiViewTypeId];
    data.shouldClear = params.current_page === 1;

    yield put(onSuccessCompanies({ ...data, paginationData }));
  } catch (error) {
    console.log(error, "this is error");
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanies());
  }
}

// Pagination call
function* companiesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* companiesWorker() {
    yield put(onCallCompanies(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

      return yield put(onSuccessCompanies(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* companyCallGenerator(action) {
  try {
    const {
      payload: { companyId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${companyId}`);

    yield put(onSuccessCompany(data?.company?.[0]));
    action?.payload?.resolve && action.payload.resolve(data?.company?.[0]);
  } catch (error) {
    action?.payload?.reject && action?.payload?.reject(error);
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompany());
  }
}

function* uploadCompanyPhotoGenerator(file, fileName, companyId) {
  const formData = new FormData();
  formData.append("logo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, `${prefix}/logo/${companyId}`, formData);

  return data?.url;
}

function* companyContactsCallGenerator(action) {
  yield put(onCallCompanyContacts());
  try {
    const {
      payload: { companyId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/companies/list/contacts/${companyId}`);
    yield put(onSuccessCompanyContacts(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyContacts());
  }
}

function* companyNotesCallGenerator(action) {
  try {
    const {
      payload: { entityId, module, isSocketUpdate }
    } = action;
    yield put(onCallCompanyNotes(isSocketUpdate));
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/note/generic/${module}/?entity_id=${entityId}`);
    yield put(onSuccessCompanyNotes(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyNotes());
  }
}

function* companyTasksCallGenerator(action) {
  const {
    payload: { companyId, isSocketUpdate }
  } = action;
  yield put(onCallCompanyTasks(isSocketUpdate));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/task/table_data?company_id=${companyId}`);
    yield put(onSuccessCompanyTasks(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyTasks());
  }
}

// Single Company
function* companyAddCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  yield call(implementPromiseAction, action, function* SingleCompanyAddWorker() {
    try {
      const {
        payload: { companyData, newPhotoFile, messageFunction }
      } = action;

      // Company add
      const { data } = yield call(axiosInstance.post, prefix, companyData);

      // // Company logo add
      let imageNewUrl;
      if (newPhotoFile?.file) {
        imageNewUrl = yield uploadCompanyPhotoGenerator(
          newPhotoFile?.file,
          newPhotoFile?.name,
          data.createdCompany.company_id
        );
      }

      yield put(
        onSuccessCompanyAdd({
          companyData: {
            ...data.createdCompany,
            ...(newPhotoFile?.file && { logo: imageNewUrl })
          }
        })
      );
      yield data && messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      if (error.response.data.msg === DUPLICATE_DATA_ERROR_LIST[action.type]) {
        const duplications = error.response.data.body.duplications;
        const companyData = lodash.cloneDeep(error.response.data.body);
        delete companyData.duplications;

        yield put(onSetCompanyDuplicates({ duplications, companyData }));
      } else {
        yield put(
          setError({
            error,
            action
          })
        );
        yield call(rejectPromiseAction, action, error);
      }
    }
  });
  yield put(switchCompanyLoading(false));
}

function* companyAddCallPromiseGenerator(action) {
  yield put(switchCompanyLoading(true));

  yield call(implementPromiseAction, action, function* companyAddWorker() {
    try {
      const {
        payload: { companyData, messageFunction }
      } = action;

      // Company add
      const { data } = yield call(axiosInstance.post, prefix, companyData);

      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      if (error.response.data.msg === DUPLICATE_DATA_ERROR_LIST[action.type]) {
        const duplications = error.response.data.body.duplications;
        const companyData = lodash.cloneDeep(error.response.data.body);
        delete companyData.duplications;

        yield put(onSetCompanyDuplicates({ duplications, companyData }));
      } else {
        yield put(
          setError({
            error,
            action
          })
        );
        return Promise.reject(error);
      }
    }
  });

  yield put(switchCompanyLoading(false));
}

function* companyEditPromiseCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  yield call(implementPromiseAction, action, function* companyEditWorker() {
    try {
      const {
        payload: { companyData, companyId, newPhotoFile, messageFunction }
      } = action;
      // change logo
      if (newPhotoFile?.file) {
        yield uploadCompanyPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, companyId);
      }

      // update company data
      const { data } = yield call(axiosInstance.put, `${prefix}/${companyId}`, companyData);

      yield put(onSuccessCompanyEdit({ ...data?.updatedCompany }));
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      if (error.response.data.msg === DUPLICATE_DATA_ERROR_LIST[action.type]) {
        const duplications = error.response.data.body.duplications;
        const companyData = lodash.cloneDeep(error.response.data.body);
        delete companyData.duplications;

        yield put(onSetCompanyDuplicates({ duplications, companyData }));
      } else {
        yield put(
          setError({
            error,
            action
          })
        );
      }
    }
  });
  yield put(switchCompanyLoading(false));
}

function* companyDeleteCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  try {
    const {
      payload: { companyIds }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      company_ids: companyIds
    });
    yield put(onSuccessCompaniesDelete(companyIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyLoading(false));
}

function* companyRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      company_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

const companyAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallCompanyAttributes({ key }));
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/company-settings/list/${url}`);
      yield put(
        onSuccessCompanyAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedCompanyAttributes({ key }));
    }
  };

function* companySocialMediasCallGenerator(action) {
  yield put(onCallCompanySocialMedias());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/social_media");
    yield put(onSuccessCompanySocialMedias(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanySocialMedias());
  }
}

function* companyCustomFieldsCallGenerator(action) {
  yield put(onCallCompanyCustomFields());
  try {
    const { data } = yield call(axiosInstance.get, `/tenantUser/custom-fields/list/${appModuleId}`);

    yield put(onSuccessCompanyCustomFields(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyCustomFields());
  }
}

function* companyActiveTenantUsersCallGenerator(action) {
  yield put(onCallActiveTenantUsers());
  try {
    const {
      payload: { tenant_user_id }
    } = action;
    const user_id = tenant_user_id ? `?tenant_user_id=${tenant_user_id}` : "";
    const { data } = yield call(axiosInstance.get, `/tenantUser/get_active_tenant_users${user_id}`);
    yield put(onSuccessActiveTenantUsers(data));
  } catch (error) {
    console.log("error", error, "we got error");
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedActiveTenantUsers());
  }
}

function* getNewChanges(data, fieldToChange, companies) {
  let fieldName;
  switch (fieldToChange) {
    case "type_id":
      fieldName = {
        key: "type",
        name: "Type"
      };
      break;
    case "status_id":
      fieldName = {
        key: "status",
        name: "Status"
      };
      break;
    case "classification_id":
      fieldName = {
        key: "industryClassification",
        name: "Industry"
      };
      break;
    case "tenant_user_id":
      fieldName = {
        key: "orgRep",
        name: "Org Rep"
      };
      break;
    default:
      fieldName = {
        key: "type",
        name: "Type"
      };
      break;
  }
  const fieldData = yield select((state) => state.companiesData[fieldName.key].list);
  console.log(fieldData, 'fieldData');
  const newChanges = data.ids.reduce((acc, current) => {
    const currentCompany = companies.find((company) => company.company_id === current);
    const currentFieldId = fieldData.find((field) => field.name === currentCompany?.[fieldName.name])?.code;

    const newObject = {};
    if (data.value !== currentFieldId) {
      newObject.id = current;
      newObject.value = data.value;
      acc.push(newObject);
    }
    return acc;
  }, []);

  return newChanges;
}

const companiesAttributeChangeCallGenerator = (key, fieldToChange) =>
  function* gen(action) {
    yield put(
      switchCompaniesAttributeChangeLoading({
        key,
        status: true
      })
    );

    try {
      const {
        payload: { data }
      } = action;
      const companies = yield select((state) => state.companiesData.allCompanies);

      if (key === "group") {
        const groups = yield select((state) => state.companiesData.group.list);

        console.log(data, 'this is data coming in');

        const newGroups = data.ids.reduce((acc, current) => {
          const currentCompany = companies.find((company) => company.company_id === current);
          const currentGroupIds =
            currentCompany.Groups?.split(", ")?.map((currentGroup) => {
              return groups.find((group) => group.name === currentGroup)?.code;
            }) || [];
          console.log(currentGroupIds, 'current group ids');
          const newObject = {};
          newObject.id = current;
          newObject.new_groups = data.groups_to_add.filter((gId) => {
            if (!currentGroupIds.includes(gId)) {
              return gId;
            }
          });
          acc.push(newObject);
          return acc;
        }, []);

        data.new_groups = newGroups;
        yield call(axiosInstance.patch, "/tenantUser/company/change_groups", data);
      } else {
        const newChanges = yield getNewChanges(data, fieldToChange, companies);
        data.new_changes = newChanges;
        const { data: resData } = yield call(
          axiosInstance.patch,
          `/tenantUser/company/change_setting/${fieldToChange}`,
          data
        );
        yield put(
          onSuccessCompanyAttributeChange({
            data: resData?.updatedRows
          })
        );
      }
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }

    yield put(
      switchCompaniesAttributeChangeLoading({
        key,
        status: false
      })
    );
  };

function* companyDocumentsCallGenerator(action) {
  yield put(onCallCompanyDocuments());
  try {
    const {
      payload: { companyId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/documents/list/company/${companyId}`);
    yield put(onSuccessCompanyDocuments(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyDocuments());
  }
}

export default function* companiesSaga() {
  yield takeEvery(COMPANIES_CALL, companiesCallGenerator);
  yield takeEvery(callCompaniesPromise, companiesCallPromiseGenerator);
  yield takeLatest(COMPANY_CALL, companyCallGenerator);
  yield takeEvery(callSingleCompanyAddPromise, companyAddCallGenerator);
  yield takeEvery(callCompanyAddPromise, companyAddCallPromiseGenerator);
  yield takeEvery(callCompanyEditPromise, companyEditPromiseCallGenerator);

  yield takeEvery(COMPANIES_DELETE_CALL, companyDeleteCallGenerator);
  yield takeEvery(COMPANIES_RESTORE_CALL, companyRestoreCallGenerator);

  yield takeLatest(COMPANY_CONTACTS_CALL, companyContactsCallGenerator);

  yield takeLatest(COMPANY_DOCUMENTS_CALL, companyDocumentsCallGenerator);

  yield takeLatest(COMPANY_NOTES_CALL, companyNotesCallGenerator);

  yield takeLatest(COMPANY_TASKS_CALL, companyTasksCallGenerator);

  yield takeEvery(COMPANIES_CALL_STATUSES, companyAttributesCallGenerator("status", "company_status"));
  yield takeEvery(COMPANIES_CALL_TYPES, companyAttributesCallGenerator("type", "company_type"));
  yield takeEvery(
    COMPANIES_CALL_CLASSIFICATIONS,
    companyAttributesCallGenerator("industryClassification", "company_industry_classification")
  );
  yield takeEvery(COMPANIES_CALL_ORGREPS, companyAttributesCallGenerator("orgRep", "tenant_user_list"));
  yield takeEvery(COMPANIES_CALL_GROUPS, companyAttributesCallGenerator("group", "company_group"));

  yield takeEvery(COMPANIES_CALL_CUSTOM_FIELDS, companyCustomFieldsCallGenerator);
  yield takeEvery(COMPANIES_CALL_SOCIAL_MEDIAS, companySocialMediasCallGenerator);
  yield takeEvery(COMPANIES_CALL_ACTIVE_TENANT_USERS, companyActiveTenantUsersCallGenerator);

  yield takeEvery(COMPANIES_CHANGE_STATUSES, companiesAttributeChangeCallGenerator("status", "status_id"));
  yield takeEvery(COMPANIES_CHANGE_TYPES, companiesAttributeChangeCallGenerator("type", "type_id"));
  yield takeEvery(
    COMPANIES_CHANGE_CLASSIFICATIONS,
    companiesAttributeChangeCallGenerator("industryClassification", "classification_id")
  );
  yield takeEvery(COMPANIES_CHANGE_ORG_REP, companiesAttributeChangeCallGenerator("orgRep", "tenant_user_id"));
  yield takeEvery(COMPANIES_CHANGE_GROUPS, companiesAttributeChangeCallGenerator("group"));
}
