import { createSlice } from "@reduxjs/toolkit";

export const industriesSlice = createSlice({
  name: "industries",
  initialState: {
    isFetched: false,
    industries: []
  },
  reducers: {
    onCallIndustries: (state) => {
      state.isFetched = false;
      state.industries = [];
    },
    onSuccessIndustries: (state, action) => {
      state.isFetched = true;
      state.industries = action.payload.industries;
    },
    onFailedIndustries: (state) => {
      state.isFetched = true;
      state.industries = [];
    }
  }
});

export const { onCallIndustries, onSuccessIndustries, onFailedIndustries } = industriesSlice.actions;

export default industriesSlice.reducer;
