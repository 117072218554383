import { createSlice } from "@reduxjs/toolkit";

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: {
    isFetched: false,
    currencies: []
  },
  reducers: {
    onCallCurrencies: (state) => {
      state.isFetched = false;
      state.currencies = [];
    },
    onSuccessCurrencies: (state, { payload }) => {
      state.isFetched = true;
      if (payload?.currencies && Array.isArray(payload.currencies)) {
        // code: "USD"
        // country: "United States"
        // currency: "US Dollar"
        // currency_id: 840
        // minor_unit: 2
        // symbol: "$"
        state.currencies = payload?.currencies.map((c) => ({
          code: c.currency_id,
          name: `${c.currency} (${c.code})`,
          minorUnit: c.minor_unit,
          symbol: c.symbol,
          iso: c.code
        }));
      }
    },
    onFailedCurrencies: (state) => {
      state.isFetched = true;
      state.currencies = [];
    }
  }
});

export const { onCallCurrencies, onSuccessCurrencies, onFailedCurrencies } = currenciesSlice.actions;

export default currenciesSlice.reducer;
