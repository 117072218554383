import { createSlice } from "@reduxjs/toolkit";

export const appMessageSlice = createSlice({
  name: "app-message",
  initialState: {
    isFetched: false,
    appMessage: {}
  },
  reducers: {
    onCallAppMessage: (state) => {
      state.isFetched = false;
      state.appMessage = {};
    },
    onSuccessAppMessage: (state, action) => {
      state.isFetched = true;
      state.appMessage = action.payload;
    },
    onFailedAppMessage: (state) => {
      state.isFetched = true;
      state.appMessage = {};
    }
  }
});

export const { onCallAppMessage, onSuccessAppMessage, onFailedAppMessage } = appMessageSlice.actions;

export default appMessageSlice.reducer;
