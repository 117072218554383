import { createSelector } from "@reduxjs/toolkit";

export const opportunitiesDataSelector = createSelector(
  (state) => state.opportunityData,
  (a) => a
);

export const opportunityDataSelector = createSelector(
  (state) => state.opportunityData?.opportunity,
  (a) => a
);

// SETTINGS

export const opportunityStatusDataSelector = createSelector(
  (state) => state.opportunityData.status,
  (a) => a
);

export const opportunityTypeDataSelector = createSelector(
  (state) => state.opportunityData.type,
  (a) => a
);

export const opportunityStageDataSelector = createSelector(
  (state) => state.opportunityData.stage,
  (a) => a
);

export const opportunityBoardDataSelector = createSelector(
  (state) => state.opportunityData.board,
  (a) => a
);

// ATTRIBUTES

export const opportunitiesStatusDataSelector = createSelector(
  (state) => state.opportunityData.status.attributeData,
  (a) => a
);

export const opportunitiesTypesDataSelector = createSelector(
  (state) => state.opportunityData.type.attributeData,
  (a) => a
);

export const opportunitiesStagesDataSelector = createSelector(
  (state) => state.opportunityData.stage.attributeData,
  (a) => a
);

export const opportunitiesBoardsDataSelector = createSelector(
  (state) => state.opportunityData.board.attributeData,
  (a) => a
);

// FILTERS

export const opportunitiesFiltersDataSelector = createSelector(
  (state) => state.opportunityData.filters,
  (a) => a
);
