import { call, put, takeEvery } from "redux-saga/effects";
import { setError } from "store/error/slice";
import axiosInstance from "clients/api";
import { IMPORT_ADD_CALL } from "./sagas-actions";
import { onImportLoading, onSuccessImportAdd } from "./slice";

function* importAddCallGenerator(action) {
  try {
    yield put(onImportLoading(true));
    const {
      payload: { importData, importedFile, moduleName, messageFunction }
    } = action;
    const formData = new FormData();
    formData.append("file", importedFile, importedFile.name);
    formData.append("importData", JSON.stringify(importData));
    yield call(axiosInstance.post, `/tenantUser/${moduleName}/sheet/import`, formData);
    yield put(
      onSuccessImportAdd({
        importData: []
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(onImportLoading(false));
}

export default function* importSaga() {
  yield takeEvery(IMPORT_ADD_CALL, importAddCallGenerator);
}
