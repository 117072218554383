import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  APP_CONTENTS_CALL,
  APP_CONTENTS_EDIT_CALL,
  RESET_MFA_CALL,
  ADMIN_LOGS_CALL,
  callAdminLogsPromise, GET_APP_VERSION
} from "./sagas-actions";
import {
  onFailedContents,
  onCallContents,
  onSuccessContentEdit,
  onSuccessContents,
  onCallAdminLogs,
  onFailedAdminLogs,
  onSuccessAdminLogs, onCallAppVersion, onFailedCallAppVersion, onSuccessCallAppVersion
} from "./slice";
import { paramParserForPagination } from "../../utils/helpers";
import qs from "qs";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* contentsCallGenerator(action) {
  try {
    yield put(onCallContents());
    const { data } = yield call(axiosInstance.get, "/super-user/dynamic-content");
    yield put(onSuccessContents(data.content));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContents());
  }
}

function* contentEditCallGenerator(action) {
  try {
    const {
      payload: { contentId, contentData, messageFunction }
    } = action;
    // update content data
    const { data } = yield call(axiosInstance.put, `/super-user/dynamic-content/${contentId}`, contentData);

    yield put(onSuccessContentEdit(data));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* resetMfaCallGenerator(action) {
  try {
    const {
      payload: { mfaData, messageFunction }
    } = action;
    yield call(axiosInstance.post, "/tenantUser/deleteFactor", mfaData);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* adminLogsCallGenerator(action) {
  yield put(onCallAdminLogs(true));
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `/tenantUser/app-log/app_admin/admin_app_log_list?${queryString}`);
    yield put(onSuccessAdminLogs(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAdminLogs());
  }
}

function* adminLogsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* adminLogsWorker() {
    yield put(onCallAdminLogs(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `/tenantUser/app-log/app_admin/admin_app_log_list?${queryString}`);

      return yield put(onSuccessAdminLogs(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* getAppVersionGenerator(action) {
  yield put(onCallAppVersion());
  try {
    const {data} = yield call(axiosInstance.get, '/base/version');
    const [FEcommitId, ...FEdate] = data.frontend.split(' ');
    const [BEcommitId, ...BEdate] = data.backend.split(' ');
    const finalData = {
      frontEndCommitId: FEcommitId,
      frontEndDate: FEdate.join(' '),
      backEndCommitId: BEcommitId,
      backEndDate: BEdate.join(' ')
    };

    yield put(onSuccessCallAppVersion(finalData));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCallAppVersion(error));
  }
}


export default function* adminSaga() {
  yield takeEvery(APP_CONTENTS_CALL, contentsCallGenerator);
  yield takeEvery(APP_CONTENTS_EDIT_CALL, contentEditCallGenerator);
  yield takeEvery(RESET_MFA_CALL, resetMfaCallGenerator);
  yield takeEvery(ADMIN_LOGS_CALL, adminLogsCallGenerator);
  yield takeEvery(callAdminLogsPromise, adminLogsCallPromiseGenerator);
  yield takeEvery(GET_APP_VERSION, getAppVersionGenerator);
}
