import { createSlice } from "@reduxjs/toolkit";

export const multiTenantAdminSlice = createSlice({
  name: "MultiTenant",
  initialState: {
    tenants: {
      isFetched: false,
      columnNames: [],
      tableData: [],
      count: 0,
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      }
    },
    templates: {
      isFetched: false,
      isLoading: false,
      columnNames: [],
      tableData: [],
      count: 0,
      pagination: {
        skip: 0,
        limit: 10,
        sortInfo: [],
        groupBy: [],
        filterValue: []
      }
    }
  },
  reducers: {
    onCallMultiTenantAdminTenants: (state, { payload }) => {
      if (!payload) {
        state.tenants.isFetched = false;
        state.tenants.columnNames = [];
        state.tenants.count = 0;
      } else {
        state.tenants.pagination = payload;
      }
    },
    onSuccessMultiTenantAdminTenants: (state, { payload }) => {
      state.tenants.isFetched = true;
      state.tenants.columnNames = payload.columnNames;
      state.tenants.tableData = payload.tableData;
      state.tenants.count = parseInt(payload?.total_count, 10);
    },
    onFailedMultiTenantAdminTenants: (state) => {
      state.tenants.isFetched = true;
      state.tenants.columnNames = [];
      state.tenants.tableData = [];
      state.tenants.count = 0;
    },
    onCallMultiTenantAdminAppTemplates: (state, { payload }) => {
      if (!payload) {
        state.templates.isFetched = false;
        state.templates.columnNames = [];
        state.templates.count = 0;
      } else {
        state.templates.pagination = payload;
      }
      state.templates.isLoading = true;
    },
    onSuccessMultiTenantAdminAppTemplates: (state, { payload }) => {
      state.templates.isFetched = true;
      state.templates.columnNames = payload.columnNames;
      state.templates.tableData = payload.tableData;
      state.templates.count = parseInt(payload?.total_count, 10);
    },
    onFailedMultiTenantAdminAppTemplates: (state) => {
      state.templates.isFetched = true;
      state.templates.columnNames = [];
      state.templates.tableData = [];
      state.templates.count = 0;
    },
    switchMultiTenantAdminAppTemplateLoading: (state, { payload }) => {
      state.templates.isLoading = payload !== undefined ? payload : !state.templates.isLoading;
    },
    onSuccessMultiTenantAdminAppTemplateAdd: (state, { payload }) => {
      if (payload?.templateData) {
        state.templates.tableData.push(payload.templateData);
        state.templates.count += 1;
      }
    },
    onSuccessMultiTenantAdminAppTemplateEdit: (state, { payload }) => {
      if (payload) {
        state.templates.tableData = state.templates.tableData.map((template) =>
          template.app_template_id === payload.app_template_id ? payload : template
        );
      }
    },
    onSuccessMultiTenantAdminAppTemplateDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.templates.tableData = state.templates.tableData.filter(
          (template) => template.app_template_id !== payload
        );
        state.templates.count -= 1;
      }
    }
  }
});

export const {
  onCallMultiTenantAdminTenants,
  onSuccessMultiTenantAdminTenants,
  onFailedMultiTenantAdminTenants,
  onCallMultiTenantAdminAppTemplates,
  onSuccessMultiTenantAdminAppTemplates,
  onFailedMultiTenantAdminAppTemplates,
  switchMultiTenantAdminAppTemplateLoading,
  onSuccessMultiTenantAdminAppTemplateAdd,
  onSuccessMultiTenantAdminAppTemplateEdit,
  onSuccessMultiTenantAdminAppTemplateDelete
} = multiTenantAdminSlice.actions;

export default multiTenantAdminSlice.reducer;
