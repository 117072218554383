import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";

export const userSlice = createSlice({
  name: "organization",
  initialState: {
    isLoading: false,
    isFetched: false,
    data: {},
    reputation: {
      isFetched: false,
      data: null
    }
  },
  reducers: {
    onCallOrganization: (state) => {
      state.isFetched = false;
      state.data = {};
    },
    onSuccessOrganizationGet: (state, { payload }) => {
      state.isFetched = true;
      state.data = {
        ...payload,
        ...(payload?.logo && {
          logo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/companyLogo/${
            payload.logo
          }`
        })
      };
    },
    onFailedOrganization: (state) => {
      state.isFetched = true;
      state.data = {};
    },
    onSuccessOrganizationEdit: (state, { payload }) => {
      state.data = {
        ...payload,
        ...(payload?.logo && {
          logo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/companyLogo/${
            payload.logo
          }`
        })
      };
    },
    organizationLoadingSwitch: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    onCallEmailReputation: (state) => {
      state.reputation.isFetched = false;
    },
    onSuccessEmailReputation: (state, { payload }) => {
      state.reputation.isFetched = true;
      state.reputation.data = payload;
    },
    onFailedEmailReputation: (state) => {
      state.reputation.isFetched = true;
      state.reputation.data = null;
    }
  }
});

export const {
  onCallOrganization,
  onSuccessOrganizationGet,
  onFailedOrganization,
  onSuccessOrganizationEdit,
  organizationLoadingSwitch,
  onCallEmailReputation,
  onSuccessEmailReputation,
  onFailedEmailReputation
} = userSlice.actions;

export default userSlice.reducer;
