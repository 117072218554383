import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshToken } from "../../store/user/sagas-actions";

const useRefreshUserToken = () => {
  const dispatch = useDispatch();
  const refreshTokenInterval = useRef(null);

  // Add interval to refresh token
  useEffect(() => {
    dispatch(refreshToken());

    if (!refreshTokenInterval.current) {
      refreshTokenInterval.current = setInterval(
        () => {
          dispatch(refreshToken());
        },
        1000 * 60 * 15
      ); // 15 min
    }
    return () => clearInterval(refreshTokenInterval.current);
  }, []);
};

export default useRefreshUserToken;
