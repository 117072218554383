import { takeEvery, put, call } from "redux-saga/effects";
import { setError } from "../error/slice";
import axiosInstance from "../../clients/api";
import { TERMS_CALL } from "./sagas-actions";
import { onCallTerms, onSuccessTerms, onFailedTerms } from "./slice";

function* termsCallGenerator(action) {
  yield put(onCallTerms());
  try {
    const { data } = yield call(axiosInstance.get, "/base/dynamicContent/1");
    yield put(onSuccessTerms(data.content[0]));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTerms());
  }
}

export default function* termsSaga() {
  yield takeEvery(TERMS_CALL, termsCallGenerator);
}
