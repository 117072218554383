import { createPromiseAction } from "@adobe/redux-saga-promise";

export const CUSTOM_TABLE_VIEW_CALL = 'CUSTOM_TABLE_VIEW_CALL';
export const CUSTOM_TABLE_VIEW_ADD_CALL = 'CUSTOM_TABLE_VIEW_ADD_CALL';
export const CUSTOM_TABLE_VIEW_EDIT_CALL = 'CUSTOM_TABLE_VIEW_EDIT_CALL';
export const CUSTOM_TABLE_VIEW_EDIT_CALL_PROMISE = 'CUSTOM_TABLE_VIEW_EDIT_CALL_PROMISE';
export const CUSTOM_TABLE_VIEW_DELETE_CALL = 'CUSTOM_TABLE_VIEW_DELETE_CALL';
export const CUSTOM_TABLE_VIEW_SELECT_CALL = 'CUSTOM_TABLE_VIEW_SELECT_CALL';

export const callEditCustomTableViewpromise = createPromiseAction(CUSTOM_TABLE_VIEW_EDIT_CALL_PROMISE);

export function callTableViewEdit(actionName, tableData) {
  return { type: actionName, payload: { tableData } };
}

export function callCustomTableView(moduleValue) {
  return { type: CUSTOM_TABLE_VIEW_CALL, payload: { moduleValue } };
}

export function callAddCustomTableView(moduleValue, viewData, messageFunction) {
  return { type: CUSTOM_TABLE_VIEW_ADD_CALL, payload: { moduleValue, viewData, messageFunction } };
}

export function callEditCustomTableView(moduleValue, viewId, viewData, withoutLoading, messageFunction) {
  return { type: CUSTOM_TABLE_VIEW_EDIT_CALL, payload: { moduleValue, viewId, viewData, withoutLoading, messageFunction } };
}

export function callDeleteCustomTableView(moduleValue, viewId) {
  return { type: CUSTOM_TABLE_VIEW_DELETE_CALL, payload: { moduleValue, viewId } };
}

export function callSelectCustomTableView(tenantUserId, listSettings) {
    return { type: CUSTOM_TABLE_VIEW_SELECT_CALL, payload: { tenantUserId, listSettings } };
}
