import { createSlice } from "@reduxjs/toolkit";

export const noteMessagesSlice = createSlice({
  name: "noteMessages",
  initialState: {
    internalTableData: {
      loading: false,
      loaded: false,
      data: [],
      error: null
    },
    tableData: {
      loading: false,
      loaded: false,
      data: [],
      error: null
    },
    delete: {
      loading: false,
      loaded: false,
      data: {},
      error: null
    }
  },
  reducers: {
    onCallTableData: (state) => {
      state.tableData.loading = true;
    },
    onCallTableDataSuccess: (state, action) => {
      state.tableData.loading = false;
      state.tableData.loaded = true;
      state.tableData.data = action.payload;
    },
    onCallTableDataFailure: (state, action) => {
      state.tableData.loading = false;
      state.tableData.loaded = true;
      state.tableData.error = action.payload.error;
    },
    onUpdateSocketMessage: (state, action) => {
      state.internalTableData.data = [action.payload, ...state.internalTableData.data];
      state.tableData.data = [action.payload, ...state.tableData.data];
    },
    onCallTableDataInternal: (state) => {
      state.internalTableData.loading = true;
    },
    onCallTableDataInternalSuccess: (state, action) => {
      state.internalTableData.loading = false;
      state.internalTableData.loaded = true;
      state.internalTableData.data = action.payload;
    },
    onCallTableDataInternalFailure: (state, action) => {
      state.internalTableData.loading = false;
      state.internalTableData.loaded = true;
      state.internalTableData.error = action.payload.error;
    }
  }
});

export const {
  onCallTableData,
  onCallTableDataSuccess,
  onCallTableDataFailure,
  onDeleteNoteMessage,
  onDeleteNoteMessageSuccess,
  onDeleteNoteMessageFailure,
  onCallTableDataInternalSuccess,
  onCallTableDataInternal,
  onCallTableDataInternalFailure,
  onUpdateSocketMessage
} = noteMessagesSlice.actions;

export default noteMessagesSlice.reducer;
