import { createSlice } from "@reduxjs/toolkit";

export const appUniversalSearchSlice = createSlice({
  name: "app-universal-search",
  initialState: {
    isFetched: false,
    internalData: {
      pagination: {
        skip: 0,
        limit: 10
      },
      isLoading: false,
      columnNames: [],
      list: [],
      totalCount: 0
    }
  },
  reducers: {
    onCallAppInternalSearch: (state) => {
      state.internalData.isLoading = true;
      state.internalData.columnNames = [];
      state.internalData.list = [];
      state.internalData.totalCount = 0;
    },
    onSuccessInternalSearch: (state, { payload }) => {
      state.internalData.list = payload.tableData;
      state.internalData.columnNames = payload.columnNames;
      state.internalData.isLoading = false;
      state.internalData.totalCount = parseInt(payload.total_count, 10) || 0;
    },
    onFailedInternalSearch: (state) => {
      state.internalData.isLoading = false;
      state.internalData.columnNames = [];
      state.internalData.list = [];
      state.internalData.totalCount = 0;
    },
    onCallAppInternalSearchPromise: (state, { payload }) => {
      state.internalData.pagination = {
        skip: payload?.skip || 0,
        limit: payload?.limit || 10
      };
    },
    onSuccessAppInternalSearchPromise: (state, { payload }) => {
      state.internalData.list = payload.tableData;
      state.internalData.totalCount = parseInt(payload.total_count, 10) || 0;
      state.internalData.columnNames = payload.columnNames;
    }
  }
});

export const {
  onCallAppInternalSearch,
  onSuccessInternalSearch,
  onFailedInternalSearch,

  onSuccessAppInternalSearchPromise,
  onCallAppInternalSearchPromise
} = appUniversalSearchSlice.actions;

export default appUniversalSearchSlice.reducer;
