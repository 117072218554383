import { takeEvery, put, call } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import axiosInstance from "clients/api";
import { paramParserForPagination } from "utils/helpers";
import { setError } from "../error/slice";
import { AUDIT_LOGS_CALL, callAuditLogsPromise } from "./sagas-actions";
import { onCallAuditLogs, onSuccessAuditLogs, onFailedAuditLogs } from "./slice";
import qs from "qs";

const prefix = "/tenantUser/app-log";

function* auditLogsCallGenerator(action) {
  yield put(onCallAuditLogs());
  try {// TODO: Replace with new note api
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

    yield put(onSuccessAuditLogs(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAuditLogs());
  }
}

function* auditLogsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* auditLogsWorker() {
    yield put(onCallAuditLogs(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      
      const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

      return yield put(onSuccessAuditLogs(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

export default function* auditLogsSaga() {
  yield takeEvery(AUDIT_LOGS_CALL, auditLogsCallGenerator);
  yield takeEvery(callAuditLogsPromise, auditLogsCallPromiseGenerator);
}
