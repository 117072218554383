import errorsList from "constant/errors";

export default class NetworkError {
  constructor(err) {
    const customMessageObject = errorsList[err.action.type];
    this.message = "";
    this.name = "Failed";
    this.buttonName = customMessageObject?.btnText || "Retry";
    this.closeOnFail = customMessageObject?.closeOnFail || false;
    this.isFatal = customMessageObject?.isFatal || false;

    if (err.error && err.error.response) {
      if (err.error.response.data) {
        const { status, msg, errors } = err.error.response.data;

        if (errors) {
          if (Array.isArray(errors)) {
            this.message += errors.map((e) => `${e.msg || e.message || e.status}`).join("\n");
          } else if (typeof errors === "string") {
            this.message += errors;
          }
        } else if (msg) {
          this.message += msg;
        } else {
          this.message += `There is a server issue. Please contact support. ${status ? `(Code ${status})` : ""}`;
        }
        /** In case of custom errors with error code **/
        // this.message += `\n ${message || message} ${status ? `(Code ${status})` : ''}`;
        //
        // const { status } = err.error.response.data;
        //
        // if (customMessageObject[status]) {
        //
        //     if (typeof customMessageObject[status].message === 'string') {
        //         this.message += `\n ${customMessageObject[status].message}`;
        //     } else {
        //         const errorForCase = customMessageObject[status].message(err.error);
        //         if (errorForCase) {
        //             this.message += `\n ${errorForCase}`;
        //         } else {
        //             this.message += `\n ${customMessageObject.message} (Code ${status})`;
        //         }
        //     }
        //
        //     // Error button text
        //     if (customMessageObject[status].btnText) {
        //         this.buttonName = customMessageObject[status].btnText;
        //     }
        //
        //     // Action in case of error
        //     if (customMessageObject[status].closeOnFail) {
        //         this.closeOnFail = customMessageObject[status].closeOnFail;
        //     }
        //
        //     // Error is fatal (get actions)
        //     if (customMessageObject[status].isFatal) {
        //         this.isFatal = customMessageObject[status].isFatal;
        //     }
        // } else {
        //     this.message += `\n ${customMessageObject.message} (Code ${status})`;
        // }
      } else {
        if (err.error.response.statusText) {
          this.message += `\n ${err.error.response.statusText}`;
        }
        if (err.error.response.status) {
          this.message += `\n (Code ${err.error.response.status})`;
        }
      }
    } else {
      // If no response
      this.message = customMessageObject?.message
        ? `\n ${customMessageObject?.message}`
        : `\n Unknown network error ${this.message}`;
    }
  }
}
