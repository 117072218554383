export const SALES_TAX_CALL = "SALES_TAX_CALL";
export const SALE_TAX_CALL = "SALE_TAX_CALL";
export const SALE_TAX_ADD_CALL = "SALE_TAX_ADD_CALL";
export const SALE_TAX_EDIT_CALL = "SALE_TAX_EDIT_CALL";
export const SALE_TAX_DELETE_CALL = "SALE_TAX_DELETE_CALL";

export function callSalesTax() {
  return { type: SALES_TAX_CALL };
}

export function callSaleTax(saleTaxId) {
  return { type: SALE_TAX_CALL, payload: { saleTaxId } };
}

export function addSaleTax(saleTaxData, messageFunction) {
  return { type: SALE_TAX_ADD_CALL, payload: { saleTaxData, messageFunction } };
}

export function editSaleTax(saleTaxId, saleTaxData, messageFunction) {
  return { type: SALE_TAX_EDIT_CALL, payload: { saleTaxId, saleTaxData, messageFunction } };
}

export function deleteSaleTax(saleTaxId) {
  return { type: SALE_TAX_DELETE_CALL, payload: { saleTaxId } };
}
