import { createPromiseAction } from "@adobe/redux-saga-promise";

export const SEQUENCES_CALL = "SEQUENCES_CALL";
export const SEQUENCE_CALL = "SEQUENCE_CALL";
export const SEQUENCE_ADD_CALL = "SEQUENCE_ADD_CALL";
export const SEQUENCE_EDIT_CALL = "SEQUENCE_EDIT_CALL";
export const SEQUENCE_DELETE_CALL = "SEQUENCE_DELETE_CALL";
export const SEQUENCE_RESTORE_CALL = "SEQUENCE_RESTORE_CALL";

export const SEQUENCES_CHANGE_STATUSES = "SEQUENCES_CHANGE_STATUSES";

export const SEQUENCES_CALL_CONDITIONS = "SEQUENCES_CALL_CONDITIONS";
export const SEQUENCES_CALL_ANALYTICS = "SEQUENCES_CALL_ANALYTICS";
export const GET_HOLIDAYS = "GET_HOLIDAYS";

export const callAddSequencePromise = createPromiseAction(SEQUENCE_ADD_CALL);
export const callEditSequencePromise = createPromiseAction(SEQUENCE_EDIT_CALL);


export function callSequences() {
  return { type: SEQUENCES_CALL };
}

export function getHolidays({ countryCode, year }) {
  return {
    type: GET_HOLIDAYS,
    payload: { countryCode, year }
  };
}

export function callSequence(sequenceId) {
  return { type: SEQUENCE_CALL, payload: { sequenceId } };
}

export function callDeleteSequence(sequenceIds) {
  return { type: SEQUENCE_DELETE_CALL, payload: { sequenceIds } };
}

export function callSequenceConditions() {
  return { type: SEQUENCES_CALL_CONDITIONS };
}

export function callSequenceAnalytics(sequenceId) {
  return { type: SEQUENCES_CALL_ANALYTICS, payload: { sequenceId } };
}
