import { useState, useCallback, useEffect, useRef } from "react";

const useStateComplexCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);

  const setStateCallback = useCallback((newState, callback) => {
    callbackRef.current = callback;
    setState((prevState) => ({
      ...prevState,
      ...newState
    }));
  }, []);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

export default useStateComplexCallback;
