import { call, put, takeEvery } from "redux-saga/effects";
import {
  onCallTableData,
  onCallTableDataFailure,
  onCallTableDataInternal,
  onCallTableDataInternalFailure,
  onCallTableDataInternalSuccess,
  onCallTableDataSuccess,
  onDeleteNoteMessage,
  onDeleteNoteMessageFailure,
  onDeleteNoteMessageSuccess
} from "./slice";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  CREATE_NEW_NOTE_MESSAGE,
  CREATE_NEW_NOTE_MESSAGE_INTERNAL,
  DELETE_NOTE_MESSAGE,
  GET_INTERNAL_NOTE_MESSAGES_TABLE_DATA,
  GET_NOTE_MESSAGES_TABLE_DATA,
  getNoteMessagesTableData,
  UPLOAD_PDF_TO_BACKEND
} from "./saga-actions";

function* getNoteMessagesTableDataGenerator({ encryptedDocumentNumber, encryptedTenantId }) {
  yield put(onCallTableData());
  try {
    const { data } = yield call(
      axiosInstance.get,
      `/tenantUser/note/view/document/messages?document_number=${encryptedDocumentNumber}&tenant_id=${encryptedTenantId}page=1&pageSize=1000`
    );
    yield put(onCallTableDataSuccess(data.tableData));
  } catch (error) {
    yield put(
      setError({
        error
      })
    );
    yield put(onCallTableDataFailure(error));
  }
}

function* deleteNoteMessages({ noteId }) {
  yield put(onDeleteNoteMessage());
  try {
    const { data } = yield call(axiosInstance.delete, `/tenantUser/note/${noteId}`);
    yield put(onDeleteNoteMessageSuccess(data));
  } catch (error) {
    yield put(
      setError({
        error
      })
    );
    yield put(onDeleteNoteMessageFailure(error));
  }
}

function* onCreateNewMessage({ payload }) {
  // @TODO use pagination correctly
  const { key, message, encryptedTenantId, encryptedDocumentNumber } = payload;
  try {
    const formData = new FormData();
    formData.append("type_id", "102");
    formData.append("message", message);
    formData.append("key", key);

    yield call(axiosInstance.post, "/tenantUser/note/document", formData);
    yield put(getNoteMessagesTableData({ encryptedTenantId, encryptedDocumentNumber, page: 1, pageSize: 10000 }));
  } catch (error) {
    yield put(
      setError({
        error
      })
    );
  }
}

function* sendPdfToBackendGenerator(action) {
  try {
    const { key, attachment } = action.payload;
    const formData = new FormData();
    formData.append("key", key);
    formData.append("attachment", attachment);
    formData.append("message", "Attachment(s)");
    const { data } = yield call(axiosInstance.post, "/tenantUser/note/document", formData);
    console.log(data, "this is data");
  } catch (e) {
    console.log(e, "this is e");
  }
}

function* onCreateNewMessageInternal({ payload }) {
  const { message, documentId } = payload;
  const formData = new FormData();
  formData.append("type_id", "102");
  formData.append("message", message);
  formData.append("document_id", documentId);
  try {
    yield call(axiosInstance.post, "/tenantUser/note/new/document", formData);

    // yield put(getInternalNoteMessagesTableData({ documentNumber }));
  } catch (error) {
    yield put(
      setError({
        error
      })
    );
  }
}

function* getNoteMessagesTableDataInternalGenerator({ documentNumber }) {
  yield put(onCallTableDataInternal());
  try {
    const { data } = yield call(axiosInstance.get, `/tenantUser/note/view/document?document_number=${documentNumber}`);
    yield put(onCallTableDataInternalSuccess(data.tableData));
  } catch (error) {
    yield put(
      setError({
        error
      })
    );
    yield put(onCallTableDataInternalFailure(error));
  }
}

export default function* noteMessagesSaga() {
  yield takeEvery(CREATE_NEW_NOTE_MESSAGE, onCreateNewMessage);
  yield takeEvery(UPLOAD_PDF_TO_BACKEND, sendPdfToBackendGenerator);
  yield takeEvery(DELETE_NOTE_MESSAGE, deleteNoteMessages);
  yield takeEvery(GET_NOTE_MESSAGES_TABLE_DATA, getNoteMessagesTableDataGenerator);
  yield takeEvery(GET_INTERNAL_NOTE_MESSAGES_TABLE_DATA, getNoteMessagesTableDataInternalGenerator);
  yield takeEvery(CREATE_NEW_NOTE_MESSAGE_INTERNAL, onCreateNewMessageInternal);
}
