import Joi from "joi";
import joiDefaultErrorMessages from "constant/validation/messages";

const validationSchema = Joi.object({
  headers: Joi.array().required().messages(joiDefaultErrorMessages),
  fileName: Joi.string().required().messages(joiDefaultErrorMessages),
  fileType: Joi.string().required().messages(joiDefaultErrorMessages)
});

export default validationSchema;
