import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { userDataSelector } from "store/user/selectors";
import { CRISP_WEBSITE_ID } from "constant/env-variables";
import useDidUpdate from "hooks/common/useDidUpdate";
import useGetNearestColorName from "./useGetNearestColorName";

/**
 * This hook connects crisp chat app
 */

const crispColors = {
  // default: '#1972F5',
  amber: "#E1AE00",
  black: "#2A2A2A",
  blue: "#1972F5",
  blue_grey: "#457991",
  light_blue: "#009FDE",
  brown: "#924B32",
  cyan: "#00A3B4",
  green: "#28A52D",
  light_green: "#6EB700",
  grey: "#7A7A7A",
  indigo: "#2943D1",
  orange: "#EC7500",
  deep_orange: "#E45300",
  pink: "#F01D65",
  purple: "#B521CF",
  deep_purple: "#682AD5",
  red: "#E61D0F",
  teal: "#00947C"
};

export const useCrispChat = () => {
  const { user, themeProps, tempUiSettings } = useSelector(userDataSelector, isEqual);

  const [initialized, setInitialized] = useState(false);
  const getColorName = useGetNearestColorName(crispColors);
  const crispColor = useMemo(() => {
    const userMainColor = tempUiSettings?.themeProps?.primary?.main ?? themeProps?.primary?.main;
    if (userMainColor) {
      const c = getColorName(userMainColor);
      // if (c.distance > 35) {
      //     return 'grey';
      // }
      return c.name;
    }
    return "grey";
  }, [themeProps?.primary?.main, tempUiSettings?.themeProps?.primary?.main]);

  const initCrisp = () => {
    window.$crisp = [];
    // $crisp.push(["config", "container:index", [1]]) // change container z-index
    window.$crisp.push(["config", "color:theme", [crispColor]]);
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;
    const scriptEl = document.createElement("script");
    scriptEl.src = "https://client.crisp.chat/l.js";
    scriptEl.async = true;
    document.head.appendChild(scriptEl);
  };

  const handleLoaded = () => {
    // const getEmail = window.$crisp.get('user:email');
    setInitialized(true);
    // setEmail(getEmail);
  };

  const onChatClose = () => {
    setTimeout(() => {
      window.$crisp.push(["do", "chat:hide"]);
    }, [200]);
  };

  // Start crisp
  useEffect(() => {
    initCrisp();

    window.$crisp.push(["set", "user:email", [user.email]]);
    window.$crisp.push(["set", "user:nickname", [`${user.first_name} ${user.last_name}`]]);
    window.$crisp.push(["set", "user:phone", [user.phone_cell]]);
    user?.photo && window.$crisp.push(["set", "user:avatar", [user.photo]]);
    user?.name && window.$crisp.push(["set", "user:company", [user.name]]);
    window.$crisp.push(["on", "session:loaded", handleLoaded]);
    // window.$crisp.push(['on', 'user:email:changed', handleEmailChanged]);
    window.$crisp.push(["on", "chat:closed", onChatClose]);
    window.$crisp.push(["do", "chat:hide"]);
    window.$crisp.push(["do", "chat:open"]);

    return () => {
      if (initialized) {
        window.$crisp.push(["off", "session:loaded"]);
        window.$crisp.push(["off", "user:email:changed"]);
      }
    };
  }, []);

  // Update color of crisp
  useDidUpdate(() => {
    window.$crisp.push(["config", "color:theme", [crispColor]]);
  }, [crispColor]);

  // Update email
  useDidUpdate(() => {
    window.$crisp.push(["set", "user:email", [user.email]]);
  }, [user.email]);

  // Update nickname
  useDidUpdate(() => {
    window.$crisp.push(["set", "user:nickname", [`${user.first_name} ${user.last_name}`]]);
  }, [user.first_name, user.last_name]);

  // Update phone
  useDidUpdate(() => {
    window.$crisp.push(["set", "user:phone", [user.phone_cell]]);
  }, [user.phone]);

  // Update avatar
  useDidUpdate(() => {
    user?.photo && window.$crisp.push(["set", "user:avatar", [user.photo]]);
  }, [user.photo]);

  // Update company name
  useDidUpdate(() => {
    user?.name && window.$crisp.push(["set", "user:company", [user.name]]);
  }, [user.name]);
};

export default useCrispChat;
