import { createSlice } from "@reduxjs/toolkit";

export const impersonateSlice = createSlice({
  name: "Impersonate",
  initialState: {
    isFetched: false,
    isLoading: false,
    person: {
      token: "",
      subdomain: '',
    }
  },
  reducers: {
    onCallPerson: (state) => {
      state.isFetched = false;
      state.person = {};
    },
    onSuccessPerson: (state, { payload }) => {
      state.isFetched = true;
      state.person = payload;
    },
    onFailedPerson: (state) => {
      state.person = {};
      state.isFetched = true;
    },
  }
});

export const {
  onCallPerson,
  onSuccessPerson,
  onFailedPerson,
} = impersonateSlice.actions;

export default impersonateSlice.reducer;
