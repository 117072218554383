import React, { memo } from "react";
import { LinearProgress } from "@mui/material";

const LoaderLinear = memo(() => (
  <LinearProgress
    style={{
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%"
    }}
  />
));

export default LoaderLinear;
