import React, { useMemo, memo, forwardRef } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const FieldFormatted = memo(
  forwardRef(({ maskedType, customProps, onChange, prefix, decimalScale, ...rest }, ref) => {
    const defaultProps = useMemo(() => {
      switch (maskedType) {
        case "currency": {
          return {
            thousandSeparator: true,
            // isNumericString: true, // check it
            decimalScale: decimalScale || 2,
            prefix: prefix || "$",
            ...(customProps && { ...customProps })
          };
        }
        case "percent": {
          return {
            // isNumericString: true,
            fixedDecimalScale: true,
            decimalScale: 0,
            suffix: "%",
            ...(customProps && { ...customProps })
          };
        }
        case "percentWithDecimals": {
          return {
            isNumericString: true,
            decimalScale: 2,
            suffix: "%",
            ...(customProps && { ...customProps })
          };
        }
        // case 'subdomain': {
        //     return {
        //         // isNumericString: true,
        //         allowEmptyFormatting: true,
        //         suffix: '.atavit.app',
        //         // mask
        //         // format
        //         // displayType
        //     };
        // }
        default: {
          return customProps || {};
        }
      }
    }, [maskedType, customProps, prefix, decimalScale]);

    return (
      <NumberFormat
        {...rest}
        {...defaultProps}
        prefix={prefix}
        decimalScale={decimalScale}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange(values.value);
        }}
      />
    );
  })
);

FieldFormatted.propTypes = {
  maskedType: PropTypes.string,
  customProps: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

FieldFormatted.defaultProps = {
  maskedType: undefined,
  customProps: undefined
};

export default FieldFormatted;
