import { Step, StepLabel, Box, Stepper } from "@mui/material";
import { PropTypes } from "prop-types";
import React from "react";

const ExportStepper = ({ steps, activeStep }) => (
  <Box sx={{ padding: "10px" }}>
    <Stepper activeStep={activeStep}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
export default ExportStepper;

ExportStepper.propTypes = {
    activeStep: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string)
};