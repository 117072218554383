import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  background: {
    position: "absolute",
    zIndex: "100",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    cursor: "progress"
  },
  loader: {
    position: "absolute",
    zIndex: "101",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    padding: "6px",
    boxShadow: "0 0 10px -4px #00000069",
    // boxShadow: `0 0 10px -4px ${theme.palette.background.paper}`
    backgroundColor: theme.palette.background.paper,
    cursor: "progress",
    "&.fixed": {
      position: "fixed"
    }
  }
}));
