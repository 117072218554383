import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import { CURRENCIES_CALL } from "./sagas-actions";
import { onCallCurrencies, onSuccessCurrencies, onFailedCurrencies } from "./slice";

function* currenciesCallGenerator(action) {
  yield put(onCallCurrencies());
  try {
    const { data } = yield call(axiosInstance.get, "/base/getCurrencyTypes");
    yield put(onSuccessCurrencies(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCurrencies());
  }
}

export default function* currenciesWatcherSaga() {
  yield takeEvery(CURRENCIES_CALL, currenciesCallGenerator);
}
