import { createSlice } from "@reduxjs/toolkit";

export const exportSlice = createSlice({
  name: "export",
  initialState: {
    isLoading: false,
    isDownloaded: false
  },
  reducers: {
    onLoadingExport: (state) => {
      state.isLoading = !state.isLoading;
    },
    onDownloadedExport: (state, { payload }) => {
      state.isDownloaded = payload;
    }
  }
});

export const { onLoadingExport, onDownloadedExport } = exportSlice.actions;

export default exportSlice.reducer;
