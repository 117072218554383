import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "store/error/slice";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  OPPORTUNITIES_CALL,
  OPPORTUNITY_CALL,
  OPPORTUNITY_ADD_CALL,
  OPPORTUNITY_EDIT_CALL,
  OPPORTUNITIES_DELETE_CALL,
  OPPORTUNITIES_RESTORE_CALL,
  OPPORTUNITY_STATUSES_CALL,
  OPPORTUNITY_STATUS_ADD_CALL,
  OPPORTUNITY_STATUS_EDIT_CALL,
  OPPORTUNITY_STATUS_DELETE_CALL,
  OPPORTUNITY_TYPES_CALL,
  OPPORTUNITY_TYPE_ADD_CALL,
  OPPORTUNITY_TYPE_EDIT_CALL,
  OPPORTUNITY_TYPE_DELETE_CALL,
  OPPORTUNITY_STAGES_CALL,
  OPPORTUNITY_STAGE_ADD_CALL,
  OPPORTUNITY_STAGE_EDIT_CALL,
  OPPORTUNITY_STAGE_DELETE_CALL,
  OPPORTUNITIES_CALL_STAGES,
  OPPORTUNITIES_CALL_STATUSES,
  OPPORTUNITIES_CALL_TYPES,
  OPPORTUNITIES_POSITIONS_CHANGE_CALL,
  OPPORTUNITY_STAGE_CHANGE_CALL,
  OPPORTUNITY_STAGES_POSITION_UPDATE,
  OPPORTUNITY_BOARDS_CALL,
  OPPORTUNITY_BOARD_ADD_CALL,
  OPPORTUNITY_BOARD_EDIT_CALL,
  OPPORTUNITY_BOARD_DELETE_CALL,
  OPPORTUNITIES_CALL_BOARDS,
  OPPORTUNITY_BOARD_CHANGE_CALL,
  opportunityEditPromise,
  opportunityAddPromise,
} from "./sagas-actions";
import {
  onCallOpportunities,
  onSuccessOpportunities,
  onFailedOpportunities,
  switchOpportunitiesLoading,
  onSuccessOpportunity,
  onFailedOpportunity,
  resetOpportunity,
  onSuccessOpportunityAdd,
  onSuccessOpportunityEdit,
  onSuccessOpportunitiesDelete,
  onSuccessOpportunitiesPositionsChange,
  onSuccessOpportunityStageChange,
  onCallOpportunitySettings,
  onSuccessOpportunitySettings,
  onFailedOpportunitySettings,
  switchOpportunitySettingLoading,
  onSuccessOpportunitySettingAdd,
  onSuccessOpportunitySettingEdit,
  onSuccessOpportunitySettingDelete,
  onCallOpportunityAttributes,
  onSuccessOpportunityAttributes,
  onFailedOpportunityAttributes,
  onSuccessOpportunityAttributeAdd,
  onSuccessOpportunityAttributeEdit,
  onFailedReorderOpportunitySetting
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

export const prefix = "/tenantUser/opportunities";

function* opportunitiesCallGenerator(action) {
  const {
    payload: { withoutLoading }
  } = action;

  yield put(onCallOpportunities(withoutLoading));
  try {
    const { data } = yield call(axiosInstance.get, `${prefix}/table_data`);

    yield put(onSuccessOpportunities(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedOpportunities());
  }
}

function* opportunityCallGenerator(action) {
  yield put(resetOpportunity());
  try {
    const {
      payload: { opportunityId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${opportunityId}`);

    yield put(onSuccessOpportunity(data?.opportunity));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedOpportunity());
  }
}

function* opportunitiesPositionsChangeCallGenerator(action) {
  // yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { stageId, opportunityPositions }
    } = action;

    yield call(axiosInstance.patch, `${prefix}/stage_and_position/update_multiple`, {
      stages: opportunityPositions
    });

    yield put(
      onSuccessOpportunitiesPositionsChange({
        stageId,
        opportunityPositions
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  // yield put(switchOpportunitiesLoading(false));
}

function* opportunityStageChangeCallGenerator(action) {
  // yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { fromStageId, toStageId, fromStagePositions, toStagePositions, opportunityId, probability }
    } = action;
    yield call(axiosInstance.patch, `${prefix}/stage_and_position/update_multiple`, {
      stages: [
        ...fromStagePositions,
        ...toStagePositions.map((i) =>
          i.opportunity_id === opportunityId
            ? {
              ...i,
              stage_id: toStageId,
              ...(probability > 0 && { probability })
            }
            : i
        )
      ]
    });

    yield put(
      onSuccessOpportunityStageChange({
        fromStageId,
        toStageId,
        fromStagePositions,
        toStagePositions,
        opportunityId,
        probability
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  // yield put(switchOpportunitiesLoading(false));
}

function* opportunityAddCallGenerator(action) {
  yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { opportunityData, isExternal, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, prefix, opportunityData);

    if (!isExternal) {
      yield put(onSuccessOpportunityAdd(data.createdOpportunity));
    }
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchOpportunitiesLoading(false));
}

function* opportunityAddPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* opportunityAddWorker() {
    yield put(switchOpportunitiesLoading(true));
    try {
      const {
        payload: { opportunityData, messageFunction }
      } = action;
      const { data } = yield call(axiosInstance.post, prefix, opportunityData);

      yield put(switchOpportunitiesLoading(false));
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(switchOpportunitiesLoading(false));
    }
  });
}

function* opportunityEditCallGenerator(action) {
  yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { opportunityId, opportunityData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.put, `${prefix}/${opportunityId}`, opportunityData);
    const editedOpportunities = data.updatedOpportunity;

    yield put(onSuccessOpportunityEdit(editedOpportunities));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchOpportunitiesLoading(false));
}

function* opportunityEditPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* opportunityEditWorker() {
    yield put(switchOpportunitiesLoading(true));
    try {
      const {
        payload: { opportunityId, opportunityData, messageFunction }
      } = action;
      const { data } = yield call(axiosInstance.put, `${prefix}/${opportunityId}`, opportunityData);
      const editedOpportunities = data.updatedOpportunity;

      yield put(switchOpportunitiesLoading(false));
      yield data && messageFunction && messageFunction('Opportunity updated.')
      return editedOpportunities;
      // yield put(onSuccessOpportunityEdit(editedOpportunities));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(switchOpportunitiesLoading(false));
    }
  });
}

function* opportunitiesDeleteCallGenerator(action) {
  yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { opportunityIds, stageColumns }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      opportunity_ids: opportunityIds
    });

    const newStageColumns = JSON.parse(JSON.stringify(stageColumns));
    const opportunityPositions = [];

    Object.keys(stageColumns).forEach((columnKey) => {
      const newItemsList = stageColumns[columnKey].itemIds.filter((i) => !opportunityIds.includes(i));

      // If there opp removed from stage column then reorder positions
      if (newItemsList.length !== stageColumns[columnKey].itemIds.length) {
        newStageColumns[columnKey].itemIds = newItemsList;

        if (newItemsList.length > 0) {
          opportunityPositions.push(
            ...newItemsList.map((oppId, index) => ({
              position: index,
              opportunity_id: oppId
            }))
          );
        }
      }
    });

    if (opportunityPositions.length > 0) {
      yield call(axiosInstance.patch, `${prefix}/position`, {
        positions: opportunityPositions
      });
    }

    yield put(
      onSuccessOpportunitiesDelete({
        opportunityIds,
        newStageColumns,
        opportunityPositions
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchOpportunitiesLoading(false));
}

function* opportunitiesRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      opportunity_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

const opportunitySettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallOpportunitySettings({
        key
      })
    );
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/opportunity-setting/${url}`);

      yield put(
        onSuccessOpportunitySettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedOpportunitySettings({
          key
        })
      );
    }
  };

function* opportunityStagesUpdatePositionsGenerator(action) {
  const { positions, tableName, oldPositions } = action.payload;
  try {
    yield call(axiosInstance.put, `/tenantUser/changePosition/${tableName}`, positions);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedReorderOpportunitySetting({ key: "stage", data: oldPositions }));
  }
}

const opportunitySettingAddCallGenerator = (key, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemData }
      } = action;

      const { data } = yield call(axiosInstance.post, `/tenantUser/opportunity-setting/${url}`, itemData);
      yield put(
        onSuccessOpportunitySettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessOpportunityAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put({ type: OPPORTUNITIES_CALL_STAGES, withoutLoading: true });
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: false
      })
    );
  };

const opportunitySettingEditCallGenerator = (key, idKey, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `/tenantUser/opportunity-setting/${url}/${itemId}`, itemData);
      yield put(
        onSuccessOpportunitySettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessOpportunityAttributeEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: false
      })
    );
  };

const opportunitySettingDeleteCallGenerator = (key, idKey, url) =>
  function* gen(action) {
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `/tenantUser/opportunity-setting/generic_delete/${itemId}/${url}`);
      yield put(
        onSuccessOpportunitySettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchOpportunitySettingLoading({
        key,
        status: false
      })
    );
  };

const opportunityAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    if (!action.withoutLoading) {
      yield put(onCallOpportunityAttributes({ key }));
    }
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/opportunity-setting/list/${url}`);
      yield put(
        onSuccessOpportunityAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedOpportunityAttributes({ key }));
    }
  };

function* opportunityBoardChangeGenerator(action) {
  yield put(switchOpportunitiesLoading(true));
  try {
    const {
      payload: { boardId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/opportunities/board/${boardId}`);
    yield put(onSuccessOpportunities({ boardId, ...data }));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedOpportunities());
  }
  yield put(switchOpportunitiesLoading(false));
}

export default function* opportunitySettingsSaga() {
  yield takeEvery(OPPORTUNITIES_CALL, opportunitiesCallGenerator);
  yield takeEvery(OPPORTUNITIES_DELETE_CALL, opportunitiesDeleteCallGenerator);
  yield takeEvery(OPPORTUNITIES_RESTORE_CALL, opportunitiesRestoreCallGenerator);
  yield takeEvery(OPPORTUNITIES_POSITIONS_CHANGE_CALL, opportunitiesPositionsChangeCallGenerator);
  yield takeEvery(OPPORTUNITY_CALL, opportunityCallGenerator);
  yield takeEvery(OPPORTUNITY_ADD_CALL, opportunityAddCallGenerator);
  yield takeEvery(opportunityAddPromise, opportunityAddPromiseCallGenerator);
  yield takeEvery(OPPORTUNITY_EDIT_CALL, opportunityEditCallGenerator);
  yield takeEvery(opportunityEditPromise, opportunityEditPromiseCallGenerator);
  yield takeEvery(OPPORTUNITY_STAGE_CHANGE_CALL, opportunityStageChangeCallGenerator);

  // SETTINGS

  yield takeEvery(OPPORTUNITY_STATUSES_CALL, opportunitySettingsCallGenerator("status", "opportunity_status_list"));
  yield takeEvery(
    OPPORTUNITY_STATUS_ADD_CALL,
    opportunitySettingAddCallGenerator("status", "status", "createdOpportunityStatus")
  );
  yield takeEvery(
    OPPORTUNITY_STATUS_EDIT_CALL,
    opportunitySettingEditCallGenerator("status", "opportunity_status_id", "status", "updatedOpportunityStatus")
  );
  yield takeEvery(
    OPPORTUNITY_STATUS_DELETE_CALL,
    opportunitySettingDeleteCallGenerator("status", "opportunity_status_id", "opportunity_status")
  );

  yield takeEvery(OPPORTUNITY_TYPES_CALL, opportunitySettingsCallGenerator("type", "opportunity_type_list"));
  yield takeEvery(
    OPPORTUNITY_TYPE_ADD_CALL,
    opportunitySettingAddCallGenerator("type", "type", "createdOpportunityType")
  );
  yield takeEvery(
    OPPORTUNITY_TYPE_EDIT_CALL,
    opportunitySettingEditCallGenerator("type", "opportunity_type_id", "type", "updatedOpportunityType")
  );
  yield takeEvery(
    OPPORTUNITY_TYPE_DELETE_CALL,
    opportunitySettingDeleteCallGenerator("type", "opportunity_type_id", "opportunity_type")
  );

  yield takeEvery(OPPORTUNITY_STAGES_CALL, opportunitySettingsCallGenerator("stage", "opportunity_stage_list"));
  yield takeEvery(OPPORTUNITY_STAGES_POSITION_UPDATE, opportunityStagesUpdatePositionsGenerator);
  yield takeEvery(
    OPPORTUNITY_STAGE_ADD_CALL,
    opportunitySettingAddCallGenerator("stage", "stage", "createdOpportunityStage")
  );
  yield takeEvery(
    OPPORTUNITY_STAGE_EDIT_CALL,
    opportunitySettingEditCallGenerator("stage", "opportunity_stage_id", "stage", "updatedOpportunityStage")
  );
  yield takeEvery(
    OPPORTUNITY_STAGE_DELETE_CALL,
    opportunitySettingDeleteCallGenerator("stage", "opportunity_stage_id", "opportunity_stage")
  );

  yield takeEvery(OPPORTUNITY_BOARDS_CALL, opportunitySettingsCallGenerator("board", "opportunity_board_list"));
  yield takeEvery(
    OPPORTUNITY_BOARD_ADD_CALL,
    opportunitySettingAddCallGenerator("board", "board", "createdOpportunityBoard")
  );
  yield takeEvery(
    OPPORTUNITY_BOARD_EDIT_CALL,
    opportunitySettingEditCallGenerator("board", "opportunity_board_id", "board", "updateOpportunityBoard")
  );
  yield takeEvery(
    OPPORTUNITY_BOARD_DELETE_CALL,
    opportunitySettingDeleteCallGenerator("board", "opportunity_board_id", "opportunity_board")
  );

  // ATTRIBUTES

  yield takeEvery(OPPORTUNITIES_CALL_STATUSES, opportunityAttributesCallGenerator("status", "opportunity_status"));
  yield takeEvery(OPPORTUNITIES_CALL_TYPES, opportunityAttributesCallGenerator("type", "opportunity_type"));
  yield takeEvery(OPPORTUNITIES_CALL_STAGES, opportunityAttributesCallGenerator("stage", "opportunity_stage"));
  yield takeEvery(OPPORTUNITIES_CALL_BOARDS, opportunityAttributesCallGenerator("board", "opportunity_board"));

  yield takeEvery(OPPORTUNITY_BOARD_CHANGE_CALL, opportunityBoardChangeGenerator);
}
