import { useRef, useLayoutEffect } from "react";

const useDidUpdateLayout = (callback, deps) => {
  const hasMount = useRef(false);

  useLayoutEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, deps);
};

export default useDidUpdateLayout;
