import { createSelector } from "@reduxjs/toolkit";

export const productsServicesDataSelector = createSelector(
  (state) => state.productsServicesData,
  (a) => a
);

export const productsServiceDataSelector = createSelector(
  (state) => state.productsServicesData?.productsService,
  (a) => a
);

// SETTINGS

export const productsServiceStatusDataSelector = createSelector(
  (state) => state.productsServicesData.status,
  (a) => a
);

export const productsServiceTypeDataSelector = createSelector(
  (state) => state.productsServicesData.type,
  (a) => a
);

export const productsServiceCategoryDataSelector = createSelector(
  (state) => state.productsServicesData.category,
  (a) => a
);

export const productsServiceSubCategoryDataSelector = createSelector(
  (state) => state.productsServicesData.sub_category,
  (a) => a
);

export const productsServiceManufacturerDataSelector = createSelector(
  (state) => state.productsServicesData.manufacturer,
  (a) => a
);

export const productsServiceUOMDataSelector = createSelector(
  (state) => state.productsServicesData.uom,
  (a) => a
);

export const productsServiceRecurrenceDataSelector = createSelector(
  (state) => state.productsServicesData.recurrence,
  (a) => a
);

// ATTRIBUTES

export const productsServicesStatusDataSelector = createSelector(
  (state) => state.productsServicesData.status.attributeData,
  (a) => a
);

export const productsServicesTypesDataSelector = createSelector(
  (state) => state.productsServicesData.type.attributeData,
  (a) => a
);

export const productsServicesCategoriesDataSelector = createSelector(
  (state) => state.productsServicesData.category.attributeData,
  (a) => a
);

export const productsServicesSubCategoriesDataSelector = createSelector(
  (state) => state.productsServicesData.sub_category.attributeData,
  (a) => a
);

export const productsServicesManufacturersDataSelector = createSelector(
  (state) => state.productsServicesData.manufacturer.attributeData,
  (a) => a
);

export const productsServicesUOMsDataSelector = createSelector(
  (state) => state.productsServicesData.uom.attributeData,
  (a) => a
);

export const productsServicesRecurrencesDataSelector = createSelector(
  (state) => state.productsServicesData.recurrence.attributeData,
  (a) => a
);
