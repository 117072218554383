import Joi from "joi";
import { name } from "constant/validation/schemas";
import joiDefaultErrorMessages from "constant/validation/messages";

const validationSchema = (fields) =>
  Joi.object({
    name,
    isDefault: Joi.boolean(),
    ...(fields.dueDays && {
      dueDays: Joi.number().integer().min(0).required().messages(joiDefaultErrorMessages)
    }),
    ...(fields.period && {
      period_id: Joi.number().integer().min(0).required().messages(joiDefaultErrorMessages)
    }),
    ...(fields.position && {
      position: Joi.number().integer().min(0).required().messages(joiDefaultErrorMessages)
    }),
    ...(fields.probability && {
      probability: Joi.number()
        .min(0)
        .max(100)
        .empty("")
        .messages({
          ...joiDefaultErrorMessages,
          "number.min": "Must be greater than 0",
          "number.max": "Must be less than 100"
        })
    }),
    ...(fields.category_id && {
      category_id: Joi.string().required()
      // category_id: Joi.object({
      //     code: Joi.string()
      //         .messages({
      //             ...joiDefaultErrorMessages,
      //             'number.base': 'Is a required field'
      //         }),
      //     name: Joi.string()
      // })
      //     .required()
      //     .messages({
      //         'object.base': 'Is a required field',
      //         ...joiDefaultErrorMessages
      //     })
    }),
    ...(fields.board && {
      board_id: Joi.string().allow(null).required().messages(joiDefaultErrorMessages)
    }),
    ...(fields.outcome_id && {
      outcome_id: Joi.string().allow(null).required().messages(joiDefaultErrorMessages)
    })
  });

export default validationSchema;
