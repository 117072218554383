import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    message: {
      padding: "20px",
      maxWidth: "500px",
      width: "500px",
      borderRadius: "5px",
      backgroundColor: theme.palette.grey[300]
    }
  })
);

const Message = ({ firstName }) => {
  const classes = useStyles();
  return (
    <div className={classes.message}>{firstName}, your subscription preferences have been updated. Thank you!</div>
  );
};

Message.propTypes = {
  firstName: PropTypes.string
};

export default Message;
