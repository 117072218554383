import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  NOTIFICATIONS_CALL,
  NOTIFICATIONS_SEEN_CALL,
  NOTIFICATIONS_DELETE_CALL,
  NOTIFICATIONS_SEEN_ALL_CALL,
  NOTIFICATIONS_DELETE_ALL_CALL
} from "./sagas-actions";
import {
  onCallNotifications,
  onSuccessNotifications,
  onFailedNotifications,
  switchNotificationsLoading,
  onSuccessNotificationSeen,
  onSuccessNotificationDelete,
  onSuccessNotificationsAllSeen,
  onSuccessNotificationsAllDelete
} from "./slice";

const prefix = "/tenantUser/notifications";

function* notificationsCallGenerator(action) {
  yield put(onCallNotifications());
  try {
    const {
      payload: { page }
    } = action;

    const { data } = yield call(axiosInstance.post, `${prefix}/list`, {
      current_page: page,
      page_size: 10,
      sorter_fields: {
        name: 'created_date',
        dir: 2,
      }
    });
    yield put(onSuccessNotifications(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedNotifications());
  }
}

function* notificationSeenCallGenerator(action) {
  yield put(switchNotificationsLoading(true));
  try {
    const {
      payload: { notificationId, notificationReadStatus }
    } = action;
    // yield put(
    //   onSuccessNotificationSeen({
    //     notificationId,
    //     notificationReadStatus
    //   })
    // );
    yield call(axiosInstance.patch, `${prefix}/status/${notificationId}`, {
      status: !notificationReadStatus
    });
    // yield put(onSuccessNotificationSeen({
    //     notificationId,
    //     notificationReadStatus
    // }));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchNotificationsLoading(false));
}

function* notificationDeleteCallGenerator(action) {
  yield put(switchNotificationsLoading(true));
  try {
    const {
      payload: { notificationId, notificationReadStatus }
    } = action;
    yield put(
      onSuccessNotificationDelete({
        notificationId,
        notificationReadStatus
      })
    );
    yield call(axiosInstance.post, `${prefix}/multiple_delete`, {
      notification_ids: [notificationId]
    });
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchNotificationsLoading(false));
}

function* notificationsAllSeenCallGenerator(action) {
  yield put(switchNotificationsLoading(true));
  try {
    yield call(axiosInstance.put, `${prefix}/mark_all_as_read`);
    yield put(onSuccessNotificationsAllSeen());
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchNotificationsLoading(false));
}

function* notificationsAllDeleteCallGenerator(action) {
  yield put(switchNotificationsLoading(true));
  try {
    yield call(axiosInstance.delete, `${prefix}/delete_all`);
    yield put(onSuccessNotificationsAllDelete());
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchNotificationsLoading(false));
}

export default function* notificationsSaga() {
  yield takeEvery(NOTIFICATIONS_CALL, notificationsCallGenerator);
  yield takeEvery(NOTIFICATIONS_SEEN_CALL, notificationSeenCallGenerator);
  yield takeEvery(NOTIFICATIONS_DELETE_CALL, notificationDeleteCallGenerator);
  yield takeLatest(NOTIFICATIONS_SEEN_ALL_CALL, notificationsAllSeenCallGenerator);
  yield takeLatest(NOTIFICATIONS_DELETE_ALL_CALL, notificationsAllDeleteCallGenerator);
}
