import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { generateValidUrl, isValidUrl } from "utils/helpers";
import CustomizedTooltip from "components/common/customized-tooltip";
import { makeStyles } from "@mui/styles";
import { Box, Icon } from "@mui/material";
import { Link } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  socialInputWrapper: {
    display: "flex",
    "& > .icon": {
      height: "calc(1rem + 2px)",
      padding: theme.spacing(1.5, 0),
      marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(1.5),
      boxSizing: "content-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}));

const FieldUrl = ({ name, label, icon, FormField, watch, ...props }) => {
  const classes = useStyles();
  const value = watch(name);

  const isValid = useMemo(() => isValidUrl(value), [value]);

  const generate = useMemo(() => generateValidUrl(value), [value]);

  return (
    <Box className={classes.socialInputWrapper}>
      <CustomizedTooltip title="Open in new tab" placement="top" arrow hidden={!isValid}>
        <Icon
          className="icon"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          color={isValid ? "primary" : "disabled"}
          {...(isValid && { href: generate })}
        >
          {icon || <Link />}
        </Icon>
      </CustomizedTooltip>
      {FormField({
        name,
        ...(label && { label }),
        ...props
      })}
    </Box>
  );
};

export default FieldUrl;

FieldUrl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  FormField: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired
};

FieldUrl.defaultProps = {
  icon: undefined
};
