import { useState, useLayoutEffect } from "react";

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  function getDeviceType() {
    const width = window.innerWidth;

    if (width < 768) {
      return "mobile";
    }
    if (width >= 768 && width < 1200) {
      return "tablet";
    }
    return "desktop";
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { deviceType, isMobile: deviceType === "mobile" };
};

export default useDeviceType;
