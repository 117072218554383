import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isFetched: false,
    users: [],
    columnNames: [],

    // single user
    isSingleFetched: false,
    isLoading: false,
    user: undefined,

    charge: {
      isFetched: false,
      amount: undefined
    },
    securityRoles: {
      isFetched: false,
      list: []
    }
  },
  reducers: {
    // all templates
    onCallUsers: (state) => {
      state.isFetched = false;
      state.users = [];
      state.columnNames = [];
    },
    onSuccessUsers: (state, { payload }) => {
      state.isFetched = true;
      state.users = payload.users;
      state.columnNames = payload.columnNames;
    },
    onFailedUsers: (state) => {
      state.isFetched = true;
      state.users = [];
      state.columnNames = [];
    },

    // single user
    switchUsersLoading: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },

    onSuccessUser: (state, { payload }) => {
      state.isSingleFetched = true;
      state.user = {
        ...payload,
        ...(payload?.photo && {
          photo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/${
            payload.tenant_user_id
          }/${payload.photo}`
        })
      };
    },
    onFailedUser: (state) => {
      state.isSingleFetched = true;
      state.user = undefined;
    },
    resetUser: (state) => {
      state.isSingleFetched = false;
      state.user = undefined;
    },

    onSuccessUserAdd: (state, { payload }) => {
      if (payload?.userData) {
        state.users = [...state.users, payload?.userData];
      }
    },
    onSuccessUserCharge: (state, { payload }) => {
      if (payload?.amountToCharge) {
        state.charge.amount = payload.amountToCharge / 100;
      }
      state.charge.isFetched = true;
    },
    onFailedUserCharge: (state) => {
      state.charge.isFetched = true;
      state.charge.amount = undefined;
    },
    onSuccessUserEdit: (state, { payload }) => {
      if (payload) {
        state.users = state.users.map((user) => (user.tenant_user_id === payload.tenant_user_id ? payload : user));
      }
    },
    cleanChargedAmount: (state) => {
      state.charge.amount = undefined;
    },

    // security roles
    onCallUserSecurityRoles: (state) => {
      state.securityRoles.isFetched = false;
      state.securityRoles.list = [];
    },
    onSuccessUserSecurityRoles: (state, { payload }) => {
      state.securityRoles.isFetched = true;
      state.securityRoles.list = payload;
    },
    onFailedUserSecurityRoles: (state) => {
      state.securityRoles.isFetched = true;
      state.securityRoles.list = [];
    }
  }
});

export const {
  onCallUsers,
  onSuccessUsers,
  onFailedUsers,
  switchUsersLoading,
  onSuccessUser,
  onFailedUser,
  resetUser,
  onSuccessUserAdd,
  onSuccessUserEdit,
  onSuccessUserCharge,
  onFailedUserCharge,
  cleanChargedAmount,
  onCallUserSecurityRoles,
  onSuccessUserSecurityRoles,
  onFailedUserSecurityRoles
} = usersSlice.actions;

export default usersSlice.reducer;
