import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  APP_CONTENTS_CALL,
  APP_CONTENTS_EDIT_CALL,
  RESET_MFA_CALL,
  ADMIN_LOGS_CALL,
  callAdminLogsPromise,
  GET_APP_VERSION,
  APP_TEMPLATES_CALL,
  APP_TEMPLATES_ADD_CALL,
  APP_TEMPLATES_EDIT_CALL,
  APP_TEMPLATES_DELETE_CALL,
  callAppTemplatesPromise,
  APP_SINGLE_TEMPLATE_CALL,
  IMPORT_APP_TEMPLATE, APP_TEMPLATES_CARD_CALL
} from "./sagas-actions";
import {
  onFailedContents,
  onCallContents,
  onSuccessContentEdit,
  onSuccessContents,
  onCallAdminLogs,
  onFailedAdminLogs,
  onSuccessAdminLogs,
  onCallAppVersion,
  onFailedCallAppVersion,
  onSuccessCallAppVersion,
  onCallAppTemplates,
  onSuccessAppTemplates,
  onFailedAppTemplates,
  onSuccessAppTemplateAdd,
  onSuccessAppTemplateEdit,
  onSuccessAppTemplateDelete,
  switchAppTemplateLoading,
  onCallSingleAppTemplate,
  onCallSingleAppTemplateSuccess,
  onCallSingleAppTemplateFailure,
  onImportAppTemplate,
  onImportAppTemplateSuccess,
  onImportAppTemplateFailure,
  onCallCardTemplates,
  onCallCardTemplatesSuccess,
  onCallCardTemplatesFailure,
} from "./slice";
import { paramParserForPagination } from "../../utils/helpers";
import qs from "qs";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* contentsCallGenerator(action) {
  try {
    yield put(onCallContents());
    const { data } = yield call(axiosInstance.get, "/super-user/dynamic-content");
    yield put(onSuccessContents(data.content));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedContents());
  }
}

function* contentEditCallGenerator(action) {
  try {
    const {
      payload: { contentId, contentData, messageFunction }
    } = action;
    // update content data
    const { data } = yield call(axiosInstance.put, `/super-user/dynamic-content/${contentId}`, contentData);

    yield put(onSuccessContentEdit(data));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* resetMfaCallGenerator(action) {
  try {
    const {
      payload: { mfaData, messageFunction }
    } = action;
    yield call(axiosInstance.post, "/tenantUser/deleteFactor", mfaData);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* adminLogsCallGenerator(action) {
  yield put(onCallAdminLogs(true));
  try {
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `/tenantUser/app-log/app_admin/admin_app_log_list?${queryString}`);
    yield put(onSuccessAdminLogs(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAdminLogs());
  }
}

function* adminLogsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* adminLogsWorker() {
    yield put(onCallAdminLogs(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `/tenantUser/app-log/app_admin/admin_app_log_list?${queryString}`);

      return yield put(onSuccessAdminLogs(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* getAppVersionGenerator(action) {
  yield put(onCallAppVersion());
  try {
    const { data } = yield call(axiosInstance.get, "/base/version");
    const [FEcommitId, ...FEdate] = data.frontend.split(" ");
    const [BEcommitId, ...BEdate] = data.backend.split(" ");
    const finalData = {
      frontEndCommitId: FEcommitId,
      frontEndDate: FEdate.join(" "),
      backEndCommitId: BEcommitId,
      backEndDate: BEdate.join(" ")
    };

    yield put(onSuccessCallAppVersion(finalData));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCallAppVersion(error));
  }
}

function* appTemplatesCallGenerator(action) {
  yield put(onCallAppTemplates());
  try {
    const {
      payload: { paginationData }
    } = action;

    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);

    const { data } = yield call(axiosInstance.get, `/tenantUser/app_template?${queryString}`);
    yield put(onSuccessAppTemplates(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAppTemplates());
  }
  yield put(switchAppTemplateLoading(false));
}

function* appTemplatesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* adminLogsWorker() {
    yield put(onCallAppTemplates(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);

      const { data } = yield call(axiosInstance.get, `/tenantUser/app_template?${queryString}`);

      yield put(onSuccessAppTemplates(data));
      return yield put(switchAppTemplateLoading(false));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* uploadAppTemplateImageGenerator(file, fileName, templateId) {
  const formData = new FormData();
  formData.append("image", file, file.name || fileName);
  const { data } = yield call(axiosInstance.post, `/tenantUser/app_template/image/${templateId}`, formData);

  return data?.url;
}

function* appTemplatesAddCallGenerator(action) {
  yield put(switchAppTemplateLoading(true));
  try {
    const {
      payload: { templateData, newImage, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.post, "/tenantUser/app_template", templateData);

    let imageNewUrl;
    if (newImage?.file && data) {
      imageNewUrl = yield uploadAppTemplateImageGenerator(newImage?.file, newImage?.name, data.app_template_id);
    }

    yield put(
      onSuccessAppTemplateAdd({
        templateData: {
          ...data,
          ...(newImage?.file && { Image: imageNewUrl })
        }
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchAppTemplateLoading(false));
}

function* appTemplatesEditCallGenerator(action) {
  yield put(switchAppTemplateLoading(true));
  try {
    const {
      payload: { templateId, templateData, newImage, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.put, `/tenantUser/app_template/${templateId}`, templateData);

    let imageNewUrl;
    if (newImage?.file && data) {
      imageNewUrl = yield uploadAppTemplateImageGenerator(newImage?.file, newImage?.name, data?.app_template_id);
    }
    yield put(
      onSuccessAppTemplateEdit({
        ...data,
        ...(newImage?.file && { image: imageNewUrl })
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchAppTemplateLoading(false));
}

function* appTemplatesDeleteCallGenerator(action) {
  yield put(switchAppTemplateLoading(true));
  try {
    const {
      payload: { templateId }
    } = action;

    yield call(axiosInstance.delete, `/tenantUser/app_template/${templateId}`);

    yield put(onSuccessAppTemplateDelete(templateId));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchAppTemplateLoading(false));
}

function* callSingleAppTemplateGenerator(action) {
  yield put(onCallSingleAppTemplate());
  try {
    const response = yield call(axiosInstance.get, `/tenantUser/app_template/${action.appTemplateId}`);
    yield put(onCallSingleAppTemplateSuccess(response.data));
  } catch (error) {
    yield put(onCallSingleAppTemplateFailure(error));
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

const getImportModule = (module) => {
  switch (module) {
    case "Tenant":
      return "tenant";
    case "Template":
      return "templates";
    case "Workflow":
      return "workflows";
    case "Sequence":
      return "sequences";
    default:
      return "templates";
  }
};

const getImportModuleId = (module) => {
  switch (module) {
    case "Tenant":
      return "tenant_id";
    case "Template":
      return "template_id";
    case "Workflow":
      return "workflow_id";
    case "Sequence":
      return "sequence_id";
    default:
      return "template_id";
  }
};

function* importAppTemplateGenerator(action) {
  yield put(onImportAppTemplate());
  try {
    const module = getImportModule(action.payload.data.Module);
    const data = {
      [getImportModuleId(action.payload.data.Module)]: action.payload.data.entity_id,
      destination_tenant_id: action.payload.data.tenantId,
      destination_tenant_user_id: action.payload.data.tenantUserId
    };
    const response = yield call(axiosInstance.post, `/tenantUser/${module}/import`, data);
    yield put(onImportAppTemplateSuccess(response.data));
    action.payload.messageFunction && action.payload.messageFunction(action.payload.data.Module);
  } catch (error) {
    yield put(onImportAppTemplateFailure(error));
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* appTemplatesCardGenerator(action) {
  try {
    yield put(onCallCardTemplates());
    const {
      payload: { paginationData }
    } = action;
    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);
    const response = yield call(axiosInstance.get, `/tenantUser/app_template?${queryString}`);
    yield put(onCallCardTemplatesSuccess(response.data));
  } catch (error) {
    yield put(onCallCardTemplatesFailure(error));
  }
}

export default function* adminSaga() {
  yield takeEvery(APP_CONTENTS_CALL, contentsCallGenerator);
  yield takeEvery(APP_CONTENTS_EDIT_CALL, contentEditCallGenerator);
  yield takeEvery(RESET_MFA_CALL, resetMfaCallGenerator);
  yield takeEvery(ADMIN_LOGS_CALL, adminLogsCallGenerator);
  yield takeEvery(callAdminLogsPromise, adminLogsCallPromiseGenerator);
  yield takeEvery(GET_APP_VERSION, getAppVersionGenerator);
  yield takeEvery(APP_TEMPLATES_CALL, appTemplatesCallGenerator);
  yield takeEvery(APP_TEMPLATES_ADD_CALL, appTemplatesAddCallGenerator);
  yield takeEvery(APP_TEMPLATES_EDIT_CALL, appTemplatesEditCallGenerator);
  yield takeEvery(APP_TEMPLATES_DELETE_CALL, appTemplatesDeleteCallGenerator);
  yield takeEvery(callAppTemplatesPromise, appTemplatesCallPromiseGenerator);
  yield takeEvery(APP_SINGLE_TEMPLATE_CALL, callSingleAppTemplateGenerator);
  yield takeEvery(IMPORT_APP_TEMPLATE, importAppTemplateGenerator);
  yield takeEvery(APP_TEMPLATES_CARD_CALL, appTemplatesCardGenerator);
}
