export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_TEST_URL = process.env.REACT_APP_TEST_BASE_URL || "http://frontend.localhost:3000";
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const TINYEMC_KEY = process.env.REACT_APP_TINYEMC_KEY;
export const REACTDATAGRID_KEY = process.env.REACT_APP_REACTDATAGRID_KEY;
export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
export const CRISP_HELP_URL = process.env.REACT_APP_CRISP_HELP_URL;
export const DIGITALOCEAN_SPACES_STORAGE_URL = process.env.REACT_APP_DIGITALOCEAN_SPACES_STORAGE_URL;
export const AZURE_STORAGE_PREFIX = process.env.REACT_APP_AZURE_STORAGE_PREFIX;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

export const TEMPLATE_TABLE_VIEW = process.env.REACT_APP_TEMPLATE_TABLE_VIEW;
export const USER_TABLE_VIEW = process.env.REACT_APP_USER_TABLE_VIEW;
export const COMPANY_SETTING_TILE = process.env.REACT_APP_COMPANY_SETTING_TILE;
export const CONTACT_SETTING_TILE = process.env.REACT_APP_CONTACT_SETTING_TILE;
export const COMPANY_TILE = process.env.REACT_APP_COMPANY_TILE;
export const CONTACT_TILE = process.env.REACT_APP_CONTACT_TILE;
export const DOCUMENT_TILE = process.env.REACT_APP_DOCUMENT_TILE;
export const PRODUCT_SERVICE_TILE = process.env.REACT_APP_PRODUCT_SERVICE_TILE;
export const isDevelopment = process.env.REACT_APP_ENV === "development";
export const isProduction = process.env.REACT_APP_ENV === "production";
export const DOCUMENT_ENCRYPTION_KEY = process.env.REACT_APP_DOCUMENT_ENCRYPTION_KEY;
export const MESSAGE_ENCRYPTION_KEY = process.env.REACT_APP_MESSAGE_ENCRYPTION_KEY;
export const AZURE_FEED_PREFIX = process.env.REACT_APP_AZURE_FEED_PREFIX;
export const TENANT_USER_KEY = process.env.REACT_APP_TENANT_USER_KEY;
export const CONTACT_KEY = process.env.REACT_APP_CONTACT_KEY;

export const D_AND_H_CLIENT_ID = process.env.REACT_APP_D_AND_H_CLIENT_ID;
export const D_AND_H_CLIENT_SECRET = process.env.REACT_APP_D_AND_H_CLIENT_SECRET;
export const D_AND_H_ACCOUNT_NUMBER = process.env.REACT_APP_D_AND_H_ACCOUNT_NUMBER;

export const AUTOTASK_USERNAME = process.env.REACT_APP_AUTOTASK_USERNAME;
export const AUTOTASK_PASSWORD = process.env.REACT_APP_AUTOTASK_PASSWORD;

export const TEST_USER_BEARER_TOKEN = process.env.REACT_TEST_USER_BEARER_TOKEN;
export const TEST_SECOND_USER_BEARER_TOKEN = process.env.REACT_TEST_SECOND_USER_BEARER_TOKEN;
