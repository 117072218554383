import { createPromiseAction } from "@adobe/redux-saga-promise";

export const SUBUSER_DOMAIN_CREATE_CALL = "SUBUSER_DOMAIN_CREATE_CALL";
export const SUBUSER_DOMAIN_STATUS_CALL = "SUBUSER_DOMAIN_STATUS_CALL";
export const SUBUSER_DOMAIN_STATUS_CALL_PROMISE = "SUBUSER_DOMAIN_STATUS_CALL_PROMISE";
export const SUBUSER_DOMAIN_AUTH_CANCEL_CALL = "SUBUSER_DOMAIN_AUTH_CANCEL_CALL";
export const SUBUSER_DOMAIN_VALIDATION_CALL = "SUBUSER_DOMAIN_VALIDATION_CALL";

export const callSubuserDomainStatusPromise = createPromiseAction(SUBUSER_DOMAIN_STATUS_CALL_PROMISE);

export function callCreateSubuser(domain) {
  return { type: SUBUSER_DOMAIN_CREATE_CALL, payload: { domain } };
}

export function callSubuserDomainStatus() {
  return { type: SUBUSER_DOMAIN_STATUS_CALL };
}

export function callCancelSubuserDomainAuthentication() {
  return { type: SUBUSER_DOMAIN_AUTH_CANCEL_CALL };
}

export function callValidateSubuserDomain() {
  return { type: SUBUSER_DOMAIN_VALIDATION_CALL };
}
