import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "store/error/slice";
import appModules from "constant/data/app-modules";
import {
  COMPANY_SETTING_TILE,
  COMPANY_TILE,
  CONTACT_SETTING_TILE,
  CONTACT_TILE,
  DOCUMENT_TILE,
  PRODUCT_SERVICE_TILE,
  TEMPLATE_TABLE_VIEW,
  USER_TABLE_VIEW
} from "constant/env-variables";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  onCallCustomTableView,
  onFailedCustomTableView,
  onSuccessCustomTableView,
  onSuccessCustomTableViewAdd,
  onSuccessCustomTableViewDelete,
  onSuccessCustomTableViewEdit,
  onSuccessTableView,
  switchTableViewLoading,
} from "./slice";
import {onTableViewChange} from '../user/slice';
import {
  callEditCustomTableViewpromise,
  CUSTOM_TABLE_VIEW_ADD_CALL,
  CUSTOM_TABLE_VIEW_CALL,
  CUSTOM_TABLE_VIEW_DELETE_CALL,
  CUSTOM_TABLE_VIEW_EDIT_CALL,
  CUSTOM_TABLE_VIEW_SELECT_CALL
} from './sagas-actions';

function getModuleCode(moduleValue) {
  return appModules.find((t) => t.value === moduleValue).code;
}

function* tableViewEditGenerator(action) {
  const tableName = action.type;

  yield put(
    switchTableViewLoading({
      key: tableName,
      status: true
    })
  );
  try {
    const {
      payload: { tableData }
    } = action;
    yield call(axiosInstance.put, `/tenantUser/saveTableView/${tableName}`, {
      table_view_properties: JSON.stringify(tableData)
    });
    yield put(
      onSuccessTableView({
        key: tableName,
        data: tableData
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(
    switchTableViewLoading({
      key: tableName,
      status: false
    })
  );
}

function* customTableViewCallGenerator(action) {
  const {
    payload: { moduleValue }
  } = action;

  yield put(
    onCallCustomTableView({
      key: moduleValue
    })
  );
  try {
    const moduleId = getModuleCode(moduleValue);
    const { data } = yield call(axiosInstance.get, `/tenantUser/tableView/shared/${moduleId}`);

    yield put(
      onSuccessCustomTableView({
        key: moduleValue,
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(
      onFailedCustomTableView({
        key: moduleValue
      })
    );
  }
}

function* customTableViewAddCallGenerator(action) {
  const {
    payload: { moduleValue, viewData, messageFunction }
  } = action;

  yield put(
    switchTableViewLoading({
      key: moduleValue,
      status: true
    })
  );
  try {
    const moduleId = getModuleCode(moduleValue);
    const { data } = yield call(axiosInstance.post, `/tenantUser/tableView/shared/${moduleId}`, {
      ...viewData,
      data: JSON.stringify(viewData.data)
    });

    yield put(
      onSuccessCustomTableViewAdd({
        key: moduleValue,
        data
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(
    switchTableViewLoading({
      key: moduleValue,
      status: false
    })
  );
}

function* customTableViewEditCallGenerator(action) {
  const {
    payload: { moduleValue, viewId, viewData, withoutLoading, messageFunction }
  } = action;

  if (!withoutLoading) {
    yield put(
      switchTableViewLoading({
        key: moduleValue,
        status: true
      })
    );
  }

  try {
    const { data } = yield call(axiosInstance.put, `/tenantUser/tableView/shared/${viewId}`, {
      ...viewData,
      data: JSON.stringify(viewData.data)
    });

    yield put(
      onSuccessCustomTableViewEdit({
        key: moduleValue,
        data,
        withoutLoading
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }

  if (!withoutLoading) {
    yield put(
      switchTableViewLoading({
        key: moduleValue,
        status: false
      })
    );
  }
}

function* customTableViewEditCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* customTableViewEditWorker() {
    const {
      payload: { moduleValue, viewId, viewData, withoutLoading }
    } = action;

    if (!withoutLoading) {
      yield put(
        switchTableViewLoading({
          key: moduleValue,
          status: true
        })
      );
    }

    try {
      const { data } = yield call(axiosInstance.put, `/tenantUser/tableView/shared/${viewId}`, {
        ...viewData,
        data: JSON.stringify(viewData.data)
      });
      yield put(
        onSuccessCustomTableViewEdit({
          key: moduleValue,
          data,
          withoutLoading
        })
      );
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }

    if (!withoutLoading) {
      yield put(
        switchTableViewLoading({
          key: moduleValue,
          status: false
        })
      );
    }
  });
}

function* customTableViewDeleteCallGenerator(action) {
  const {
    payload: { moduleValue, viewId }
  } = action;

  yield put(
    switchTableViewLoading({
      key: moduleValue,
      status: true
    })
  );
  try {
    yield call(axiosInstance.delete, `/tenantUser/tableView/shared/${viewId}`);

    yield put(
      onSuccessCustomTableViewDelete({
        key: moduleValue,
        viewId
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(
    switchTableViewLoading({
      key: moduleValue,
      status: false
    })
  );
}

function* customTableViewSelectCallGenerator(action) {
  const {
    payload: { tenantUserId, listSettings }
  } = action;
  try {
    yield call(axiosInstance.put, `/tenantUser/changeUiSettings/${tenantUserId}`, listSettings);
    yield put(onTableViewChange(listSettings.list_settings));
  } catch (error) {
    console.log(error);
  }
}

export default function* tableViewSaga() {
  yield takeLatest(TEMPLATE_TABLE_VIEW, tableViewEditGenerator);
  yield takeLatest(USER_TABLE_VIEW, tableViewEditGenerator);
  yield takeLatest(COMPANY_SETTING_TILE, tableViewEditGenerator);
  yield takeLatest(CONTACT_SETTING_TILE, tableViewEditGenerator);
  yield takeLatest(COMPANY_TILE, tableViewEditGenerator);
  yield takeLatest(CONTACT_TILE, tableViewEditGenerator);
  yield takeLatest(DOCUMENT_TILE, tableViewEditGenerator);
  yield takeLatest(PRODUCT_SERVICE_TILE, tableViewEditGenerator);

  yield takeEvery(CUSTOM_TABLE_VIEW_CALL, customTableViewCallGenerator);
  yield takeEvery(CUSTOM_TABLE_VIEW_ADD_CALL, customTableViewAddCallGenerator);
  yield takeEvery(CUSTOM_TABLE_VIEW_EDIT_CALL, customTableViewEditCallGenerator);
  yield takeEvery(callEditCustomTableViewpromise, customTableViewEditCallPromiseGenerator);
  yield takeEvery(CUSTOM_TABLE_VIEW_DELETE_CALL, customTableViewDeleteCallGenerator);
  yield takeEvery(CUSTOM_TABLE_VIEW_SELECT_CALL, customTableViewSelectCallGenerator);
}
