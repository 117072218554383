import { createSlice } from "@reduxjs/toolkit";

export const plansSlice = createSlice({
  name: "plans",
  initialState: {
    isFetched: false,
    plans: []
  },
  reducers: {
    onCallPlans: (state) => {
      state.isFetched = false;
      state.plans = [];
    },
    onSuccessPlans: (state, action) => {
      state.isFetched = true;
      state.plans = action.payload.plans;
    },
    onFailedPlans: (state) => {
      state.isFetched = true;
      state.plans = [];
    }
  }
});

export const { onCallPlans, onSuccessPlans, onFailedPlans } = plansSlice.actions;

export default plansSlice.reducer;
