import { all, call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { getMessages } from "store/messages/sagas-actions";
import { setError } from "../error/slice";
import {
  callNoteAddPromise,
  callNoteEditPromise, GET_SINGLE_NOTE,
  NOTE_ADD_CALL,
  NOTE_CALL,
  NOTES_CALL_OBJECTIONS,
  NOTES_CALL_SCRIPTS,
  NOTES_CALL_TYPES,
  NOTES_TOGGLE_DEFAULT_DATA
} from "./sagas-actions";
import {
  onCallNoteObjections,
  onCallNoteScripts,
  onCallNoteTypes,
  onFailedNote,
  onFailedNoteObjections,
  onFailedNoteScripts,
  onFailedNoteTypes,
  onSuccessNote,
  onSuccessNoteObjections,
  onSuccessNoteScripts,
  onSuccessNoteTypes,
  onToggleDefaultData,
  resetNote,
  switchNotesLoading,
} from "./slice";
import { onAddNewNote as onAddNewNoteCompany } from "../companies/slice";
import { onAddNewNote as onAddNewNoteContact } from "../contacts/slice";
import { onAddNewNote as onAddNewNoteDocument } from "../documents/slice";

function* noteAddCallGenerator(action) {
  yield put(switchNotesLoading(true));
  try {
    const {
      payload: { noteData, key }
    } = action;
    yield call(axiosInstance.post, "/tenantUser/app-log", noteData);
    yield put(getMessages({ key }));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchNotesLoading(false));
}

function* noteCallGenerator(action) {
  yield put(resetNote());
  try {
    const {
      payload: { noteId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/app-log/${noteId}`);
    yield put(onSuccessNote(data));
  } catch (error) {
    yield put(onFailedNote());
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* getSingleNoteGenerator(action) {
  yield put(resetNote());
  try {
    const {
      payload: { noteId }
    } = action;
    const { data } = yield call(axiosInstance.get, `/tenantUser/note/${noteId}`);
    yield put(onSuccessNote(data));
  } catch (error) {
    yield put(onFailedNote());
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* noteAddCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* noteAddWorker() {
    yield put(switchNotesLoading(true));
    try {
      const { payload: { fData, success, moduleType } } = action;
      const {
        contact_id,
        company_id,
        opportunity_id,
        document_id,
        message,
        objection_ids,
        script_id,
        task_id,
        tenant_user_id,
        attachmentsToUpload,
        type_id,
        audio
      } = fData;
      const formData = new FormData();

      contact_id && formData.append("contact_id", contact_id);
      company_id && formData.append("company_id", company_id);
      opportunity_id && formData.append("opportunity_id", opportunity_id);
      document_id && formData.append("document_id", document_id);
      message && formData.append("message", message);
      objection_ids && formData.append("objection_ids", objection_ids);
      script_id && formData.append("script_id", script_id);
      task_id && formData.append("task_id", task_id);
      tenant_user_id && formData.append("tenant_user_id", tenant_user_id);
      type_id && formData.append("type_id", type_id);

      yield all(attachmentsToUpload.map(async (attachment) => {
        const blob = await fetch(attachment.url).then(r => r.blob());
        const fileWithNewName = await new File([blob], attachment.file.name, { type: blob.type });
        formData.append('attachments', fileWithNewName);
      }));

      if (audio) {
        const fileWithNewName = new File([audio], `record-${Date.now()}.mp3`, { type: audio.type });
        formData.append('attachments', fileWithNewName);
      }

      const { data } = yield call(axiosInstance.post, "/tenantUser/note/new", formData);
      yield put(switchNotesLoading(false));

      if (moduleType === 'company') {
        yield put(onAddNewNoteCompany(data?.response));
      }

      if (moduleType === 'contact') {
        yield put(onAddNewNoteContact(data?.response));
      }

      if (moduleType === 'document') {
        yield put(onAddNewNoteDocument(data?.response));
      }
      yield success && success();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(switchNotesLoading(false));
  });
}

function* noteEditCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* noteEditWorker() {
    yield put(switchNotesLoading(true));
    try {
      const { payload: { fData, success, moduleType } } = action;
      console.log(action, 'action');
      const {
        contact_id,
        company_id,
        opportunity_id,
        document_id,
        message,
        objection_ids,
        script_id,
        task_id,
        tenant_user_id,
        attachmentsToUpload,
        type_id,
        audio
      } = fData;
      const formData = new FormData();

      contact_id && formData.append("contact_id", contact_id);
      company_id && formData.append("company_id", company_id);
      opportunity_id && formData.append("opportunity_id", opportunity_id);
      document_id && formData.append("document_id", document_id);
      message && formData.append("message", message);
      objection_ids && formData.append("objection_ids", objection_ids);
      script_id && formData.append("script_id", script_id);
      task_id && formData.append("task_id", task_id);
      tenant_user_id && formData.append("tenant_user_id", tenant_user_id);
      type_id && formData.append("type_id", type_id);

      yield all(attachmentsToUpload.map(async (attachment) => {
        const blob = await fetch(attachment.url).then(r => r.blob());
        const fileWithNewName = await new File([blob], attachment.file.name, { type: blob.type });
        formData.append('attachments', fileWithNewName);
      }));

      if (audio) {
        const fileWithNewName = new File([audio], `record-${Date.now()}.mp3`, { type: audio.type });
        formData.append('attachments', fileWithNewName);
      }

      const { data } = yield call(axiosInstance.put, "/tenantUser/note/update", formData);
      yield put(switchNotesLoading(false));
      yield success && success();
      return data;
    } catch (error) {
      yield put(switchNotesLoading(false));
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });
}

function* noteTypesCallGenerator(action) {
  yield put(onCallNoteTypes({ key: "type" }));
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/note/type");
    yield put(
      onSuccessNoteTypes({
        key: "type",
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedNoteTypes());
  }
}

function* noteScriptsCallGenerator(action) {
  yield put(onCallNoteScripts({ key: "script" }));
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/app-log/template?template_type_id=4");
    yield put(
      onSuccessNoteScripts({
        key: "script",
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedNoteScripts({ key: "script" }));
  }
}

function* noteObjectionsCallGenerator(action) {
  yield put(onCallNoteObjections({ key: "objection" }));
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/app-log/template?template_type_id=5");
    yield put(
      onSuccessNoteObjections({
        key: "objection",
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedNoteObjections({ key: "objection" }));
  }
}

function* noteDefaultDataCallGenerator(action) {
  const {
    payload: { type, id }
  } = action;
  yield put(onToggleDefaultData({ type, id }));
}

export default function* notesSaga() {
  yield takeEvery(NOTES_CALL_TYPES, noteTypesCallGenerator);
  yield takeEvery(NOTES_CALL_OBJECTIONS, noteObjectionsCallGenerator);
  yield takeEvery(NOTES_CALL_SCRIPTS, noteScriptsCallGenerator);
  yield takeEvery(NOTE_ADD_CALL, noteAddCallGenerator);
  yield takeEvery(NOTE_CALL, noteCallGenerator);
  yield takeEvery(GET_SINGLE_NOTE, getSingleNoteGenerator);
  yield takeEvery(callNoteAddPromise, noteAddCallPromiseGenerator);
  yield takeEvery(callNoteEditPromise, noteEditCallPromiseGenerator);
  yield takeEvery(NOTES_TOGGLE_DEFAULT_DATA, noteDefaultDataCallGenerator);
  // yield takeEvery(NOTES_RESTORE_CALL, noteRestoreCallGenerator);
}
