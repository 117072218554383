export const listViewTypeOptions = [
  {
    code: 0,
    name: "Table View"
  },
  {
    code: 1,
    name: "Card View"
  }
];

export const listViewTypes = {
  0: "table",
  1: "card"
};
