import { createSlice } from "@reduxjs/toolkit";

export const videoTemplateSlice = createSlice({
  name: "videoTemplate",
  initialState: {
    isFetched: false,
    data: []
  },
  reducers: {
    onCallVideoTemplate: (state) => {
      state.isFetched = false;
      state.data = [];
    },
    onSuccessVideoTemplate: (state, { payload }) => {
      state.isFetched = true;
      if (payload) {
        state.data = payload;
      }
    },
    onFailedVideoTemplate: (state) => {
      state.isFetched = true;
      state.data = [];
    }
  }
});

export const { onCallVideoTemplate, onSuccessVideoTemplate, onFailedVideoTemplate } = videoTemplateSlice.actions;

export default videoTemplateSlice.reducer;
