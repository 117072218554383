import { createPromiseAction } from "@adobe/redux-saga-promise";

export const NOTES_CALL_TYPES = "NOTES_CALL_TYPES";
export const NOTES_CALL_SCRIPTS = "NOTES_CALL_SCRIPTS";
export const NOTES_CALL_OBJECTIONS = "NOTES_CALL_OBJECTIONS";
export const NOTE_ADD_CALL = "NOTE_ADD_CALL";
export const NOTE_EDIT_CALL_PROMISE = "NOTE_EDIT_CALL_PROMISE";
export const NOTE_CALL = "NOTE_CALL";
export const GET_SINGLE_NOTE = "GET_SINGLE_NOTE";
export const NOTE_ADD_CALL_PROMISE = "NOTE_ADD_CALL_PROMISE";
export const NOTES_TOGGLE_DEFAULT_DATA = "NOTES_TOGGLE_DEFAULT_DATA";
export const NOTES_RESTORE_CALL = "NOTES_RESTORE_CALL";


export const callNoteAddPromise = createPromiseAction(NOTE_ADD_CALL_PROMISE);
export const callNoteEditPromise = createPromiseAction(NOTE_EDIT_CALL_PROMISE);

export function addNote(noteData, key) {
  return { type: NOTE_ADD_CALL, payload: { noteData, key } };
}

export function callNote(noteId) {
  return { type: NOTE_CALL, payload: { noteId } };
}

export function getSingleNote(noteId) {
  return { type: GET_SINGLE_NOTE, payload: { noteId } };
}

export function callNotesTypes() {
  return { type: NOTES_CALL_TYPES };
}

export function callNotesScripts() {
  return { type: NOTES_CALL_SCRIPTS };
}

export function callNotesObjections() {
  return { type: NOTES_CALL_OBJECTIONS };
}

export function toogleNotesDefaultData(type, id) {
  return { type: NOTES_TOGGLE_DEFAULT_DATA, payload: { type, id } };
}
