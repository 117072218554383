import { useCallback, useState } from "react";
import { debounce } from "lodash";
import useSocketLiveSyncSystem from "./useSocketLiveSyncSystem";

/**
 * This hook listens for event update (using debounce)
 * @param {String} socketModuleName - Module name
 * @param {String} socketEventName - Socket event name
 */

const useSocketEventListener = ({
  socketModuleName,
  socketEventName,
  listenOnly = true,
  customHandler,
  withoutTabId
}) => {
  const [update, setUpdate] = useState(false);

  const debouncedUpdate = useCallback(
    debounce(() => {
      setUpdate((prevValue) => !prevValue);
    }, 500),
    []
  );

  useSocketLiveSyncSystem({
    listenOnly,
    socketModuleName,
    socketEvents: [
      {
        event: socketEventName,
        handler: customHandler || debouncedUpdate
      }
    ],
    withoutTabId
  });

  return {
    update
  };
};

export default useSocketEventListener;
