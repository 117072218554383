import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import { setError } from "store/error/slice";
import { implementPromiseAction, rejectPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import { prefix } from "store/products-services/sagas";
import { paramParserForPagination } from "utils/helpers";
import qs from "qs";
import {
  ADD_PRODUCT_FROM_PDF,
  CALL_REMOVE_SELECTED_SIGNATURE,
  CALL_RESET_SIGNATURES,
  CALL_SELECTED_SIGNATURES,
  callDocumentsPromise,
  COMPANIES_SELECT_SEARCH,
  CONTACTS_SELECT_SEARCH,
  CREATE_TEMPLATE_FROM_DOCUMENT,
  DOCUMENT_ADD_CALL,
  DOCUMENT_ALL_ATTRIBUTES_CALL,
  DOCUMENT_APPROVE_CALL,
  DOCUMENT_CALL_HEAD,
  DOCUMENT_CALL_HEAD_WITH_CHANGED_FIELDS,
  DOCUMENT_CALL_ITEM,
  DOCUMENT_EDIT_CALL,
  DOCUMENT_EXTERNAL_PRODUCT_SERVICE_ITEMS_EDIT_CALL,
  DOCUMENT_FROM_LINK_ADD_CALL,
  DOCUMENT_ITEM_ADD_CALL,
  DOCUMENT_ITEM_EDIT_CALL,
  DOCUMENT_NOTES_CALL,
  DOCUMENT_PAYMENT_TERM_ADD_CALL,
  DOCUMENT_PAYMENT_TERM_CALL,
  DOCUMENT_PAYMENT_TERM_DELETE_CALL,
  DOCUMENT_PAYMENT_TERM_EDIT_CALL,
  DOCUMENT_PRODUCT_SERVICE_ADD_CALL,
  DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY,
  DOCUMENT_PRODUCT_SERVICE_EDIT_CALL,
  DOCUMENT_PRODUCT_SERVICES_DELETE_CALL,
  DOCUMENT_PRODUCT_SERVICES_EDIT_TAXABLE_CALL,
  DOCUMENT_PRODUCT_SERVICES_ITEM_IDS_EDIT,
  DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL,
  DOCUMENT_REQUEST_APPROVAL_CALL,
  DOCUMENT_REVISIONS_CALL,
  DOCUMENT_SHIPPING_EDIT_CALL,
  DOCUMENT_STATUS_ADD_CALL,
  DOCUMENT_STATUS_DELETE_CALL,
  DOCUMENT_STATUS_EDIT_CALL,
  DOCUMENT_STATUSES_CALL,
  DOCUMENT_TASKS_CALL,
  DOCUMENT_TENANT_SETTINGS_CALL,
  DOCUMENT_TENANT_SETTINGS_EDIT_CALL,
  DOCUMENT_TYPE_ADD_CALL,
  DOCUMENT_TYPE_DELETE_CALL,
  DOCUMENT_TYPE_EDIT_CALL,
  DOCUMENT_TYPES_CALL,
  documentAddPromise,
  documentAddTemplatePromise,
  documentChangeActiveStatusPromise,
  documentDeletePromise,
  documentEditPromise,
  documentItemAddPromise,
  documentOutsourceProductServicePromise,
  documentProductServiceSearchPromise,
  DOCUMENTS_CALL,
  DOCUMENTS_CALL_ADJUSTMENTS,
  DOCUMENTS_CALL_DESTINATION,
  DOCUMENTS_CALL_STATUSES,
  DOCUMENTS_CALL_TEMPLATES,
  DOCUMENTS_CALL_TYPES,
  DOCUMENTS_CHANGE_STATUSES,
  DOCUMENTS_CHANGE_TYPES,
  DOCUMENTS_DELETE_CALL,
  DOCUMENTS_RESTORE_CALL,
  DOCUMENTS_TASK_CALL,
  documentSendEmailPromise,
  GET_MERGE_FIELDS,
  LAYOUT_CHANGED,
  PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL,
  PRODUCTS_SERVICE_LINK_EDIT_CALL,
  PRODUCTS_SERVICE_LOCATION_ADD_CALL,
  UPDATE_DOCUMENT_ITEM_FROM_TEMPLATE
} from "./sagas-actions";
import {
  _onSuccessDocumentItemAdd,
  onCallAllDocumentAttributes,
  onCallDocumentAttributes,
  onCallDocumentNotes,
  onCallDocumentProductServiceCheckInventory,
  onCallDocumentProductServicesSearch,
  onCallDocumentProductServicesSearchPromise,
  onCallDocumentRevisions,
  onCallDocuments,
  onCallDocumentSettings,
  onCallDocumentTasks,
  onCallDocumentTenantSettings,
  onCallOutsourceProductService,
  onCallSelectedSignatures,
  onFailedAllDocumentAttributes,
  onFailedDocument,
  onFailedDocumentAttributes,
  onFailedDocumentNotes,
  onFailedDocumentProductServiceCheckInventory,
  onFailedDocumentProductServicesSearch,
  onFailedDocumentRevisions,
  onFailedDocuments,
  onFailedDocumentSettings,
  onFailedDocumentsTask,
  onFailedDocumentTasks,
  onFailedDocumentTenantSettings,
  onFailedOutsourceProductService,
  onLayoutChange,
  onPdfImport,
  onShippingEditCall,
  onShippingEditFailure,
  onShippingEditSuccess,
  onSuccessAllDocumentAttributes,
  onSuccessDocumentAdd,
  onSuccessDocumentApprove,
  onSuccessDocumentAttributeAdd,
  onSuccessDocumentAttributeChange,
  onSuccessDocumentAttributes,
  onSuccessDocumentEdit,
  onSuccessDocumentFromLink,
  onSuccessDocumentHead,
  onSuccessDocumentHeadWithChangedFields,
  onSuccessDocumentItem,
  onSuccessDocumentItemAdd,
  onSuccessDocumentItemEdit,
  onSuccessDocumentNotes,
  onSuccessDocumentProductServiceAdd,
  onSuccessDocumentProductServiceCheckInventory,
  onSuccessDocumentProductServiceEdit,
  onSuccessDocumentProductServicesDelete,
  onSuccessDocumentProductServicesSearch,
  onSuccessDocumentProductServicesSearchPromise,
  onSuccessDocumentRequestApproval,
  onSuccessDocumentRevisions,
  onSuccessDocuments,
  onSuccessDocumentsDelete,
  onSuccessDocumentSettingAdd,
  onSuccessDocumentSettingDelete,
  onSuccessDocumentSettingEdit,
  onSuccessDocumentSettings,
  onSuccessDocumentsTask,
  onSuccessDocumentTasks,
  onSuccessDocumentTenantSettings,
  onSuccessEditProductServiceInLink,
  onSuccessEditProductServiceItems,
  onSuccessLocation,
  onSuccessOutsourceProductService,
  onSuccessProductServiceTaxableEdit,
  onUpdateDocumentItemFromTemplate,
  removeSignature,
  resetDocumentHead,
  resetDocumentItem,
  resetSignatures,
  switchDocumentAttributeChangeLoading,
  switchDocumentProductServiceLoading,
  switchDocumentSettingLoading,
  switchDocumentsLoading,
  switchDocumentTenantSettingLoading,
  onFailedMergeFields,
  onSuccessMergeFields,
  onLoadMergeFields,
  onGetContactsSelectSearch,
  onGetContactsSelectSearchSuccess,
  onGetContactsSelectSearchFailure,
  onGetCompaniesSelectSearch,
  onGetCompaniesSelectSearchSuccess,
  onGetCompaniesSelectSearchFailure
} from "./slice";
import { v4 as uuidv4 } from "uuid";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { listViewTypes } from "../../constant/data/list-view-types";

export const documentsPrefix = "/tenantUser/documents";
const documentsSettingPrefix = "/tenantUser/documents-settings";
const documentProductServicePrefix = "/tenantUser/documents_product_service";

// Initial call
function* documentsCallGenerator(action) {
  try {
    const {
      payload: { paginationData, tenantUserId, withoutLoading }
    } = action;
    if (!withoutLoading) {
      yield put(onCallDocuments());
    }
    const params = paramParserForPagination(paginationData);
    if (tenantUserId) {
      params.filter_fields.push({
        name: "tenant_user_id",
        operator: "eq",
        value: tenantUserId
      });
    }
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${documentsPrefix}/table_data?${queryString}`);

    data.uiViewType = listViewTypes[paginationData.uiViewTypeId];
    data.shouldClear = params.current_page === 1;

    yield put(onSuccessDocuments({...data, paginationData}));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocuments());
  }
}

function* documentApproveCallGenerator(action) {
  try {
    const {
      payload: { documentId, data, messageFunction }
    } = action;
    yield call(axiosInstance.patch, `${documentsPrefix}/${documentId}/approve`, data);
    yield put(onSuccessDocumentApprove(data));
    yield messageFunction && messageFunction("Document approved.");
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* documentRequestApprovalCallGenerator(action) {
  try {
    const {
      payload: { documentId, messageFunction }
    } = action;
    yield call(axiosInstance.head, `${documentsPrefix}/${documentId}/request-approval`);
    yield put(onSuccessDocumentRequestApproval(true));
    yield messageFunction && messageFunction("Approval request has been sent.");
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* documentsTaskCallGenerator(action) {
  yield put(onCallDocuments());
  try {
    const { data } = yield call(axiosInstance.get, `${documentsPrefix}`);
    yield put(onSuccessDocumentsTask(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentsTask());
  }
}

// Pagination call
function* documentsCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentsWorker() {
    yield put(onCallDocuments(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${documentsPrefix}/table_data?${queryString}`);

      return yield put(onSuccessDocuments(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );

      yield call(rejectPromiseAction, action, error);

      // yield call(resolvePromiseAction, action, value)
      // yield put(onFailedDocuments());
    }
  });
}

// Document
function* documentHeadCallGenerator(action) {
  try {
    const {
      payload: { documentId, withoutLoading, reset }
    } = action;
    if (!withoutLoading) {
      yield put(resetDocumentHead());
      yield put(switchDocumentsLoading(true));
    }
    const { data: headData } = yield call(axiosInstance.get, `${documentsPrefix}/${documentId}`);
    yield put(
      onSuccessDocumentHead({
        headData
        // itemData
        // documentProductServices
      })
    );
    if (reset) reset();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocument());
  }
}

// Document
function* documentHeadWithChangedFieldsCallGenerator(action) {
  // yield put(resetDocumentHead());
  try {
    const {
      payload: { documentId, fields }
    } = action;
    yield put(switchDocumentsLoading(true));
    const { data: headData } = yield call(axiosInstance.get, `${documentsPrefix}/${documentId}`);
    yield put(
      onSuccessDocumentHeadWithChangedFields({
        headData,
        fields
        // itemData
        // documentProductServices
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocument());
  }
}

function* documentItemCallGenerator(action) {
  // console.log('documentItemCallGenerator');
  yield put(resetDocumentItem());
  try {
    const {
      payload: { documentId }
    } = action;
    const { data: itemData } = yield call(axiosInstance.get, `${documentsPrefix}/items/${documentId}`);
    yield put(
      onSuccessDocumentItem({
        itemData
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocument());
  }
}

function* documentNotesCallGenerator(action) {
  const {
    payload: { entityId, module, isSocketUpdate, documentNumber }
  } = action;
  yield put(onCallDocumentNotes(isSocketUpdate));
  try {
    const { data } = yield call(
      axiosSagaRequest,
      "get",
      `/tenantUser/note/generic/${module}/?entity_id=${entityId}&document_number=${documentNumber}`
    );
    yield put(onSuccessDocumentNotes(data));
  } catch (error) {
    // yield put(
    // setError({
    //   error,
    //   action
    // })
    // );
    yield put(onFailedDocumentNotes());
  }
}

function* documentTasksCallGenerator(action) {
  const {
    payload: { documentId, isSocketUpdate }
  } = action;
  yield put(onCallDocumentTasks(isSocketUpdate));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/task/table_data?document_id=${documentId}`);
    yield put(onSuccessDocumentTasks(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentTasks());
  }
}

function* documentRevisionsCallGenerator(action) {
  try {
    const {
      payload: { documentId, isSocketUpdate }
    } = action;
    yield put(onCallDocumentRevisions(isSocketUpdate));
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/documents/${documentId}/revision`);
    yield put(onSuccessDocumentRevisions(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentRevisions());
  }
}

function* changeDocumentActiveStatusPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* changeDocumentActiveStatusWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const {
        payload: { selected, messageFunction }
      } = action;
      const { data } = yield call(axiosInstance.get, `tenantUser/documents/active?document_id=${selected}`);
      yield put(switchDocumentsLoading(false));
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(switchDocumentsLoading(false));
  });
}

function* documentAddCallGenerator(action) {
  yield put(switchDocumentsLoading(true));
  try {
    const {
      payload: { documentData }
    } = action;
    const { data } = yield call(axiosInstance.post, documentsPrefix, documentData);
    yield put(onSuccessDocumentAdd(data.document));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentsLoading(false));
}

function* documentsAddPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentsAddWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const { payload } = action;
      const { data } = yield call(axiosInstance.post, documentsPrefix, payload);
      yield put(
        onSuccessDocumentAdd({
          ...data.document,
          "Doc Name": data?.document["Document Name"],
          "Doc Num": data?.document["Document Number"]
        })
      );
      yield put(
        onSuccessDocumentAttributeAdd({
          key: "template",
          data: { document_id: data?.document.document_id, name: data?.document["Document Name"] }
        })
      );
      yield put(switchDocumentsLoading(false));
      return data.document;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(switchDocumentsLoading(false));
  });
}

function* documentAddTemplatePromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentAddTemplateWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const {
        payload: { documentId, template_name }
      } = action;
      const { data } = yield call(axiosInstance.put, `/tenantUser/documents/${documentId}/revision?type=template`, {
        name: template_name
      });
      yield put(switchDocumentsLoading(false));
      return data;
      // yield put(switchDocumentsLoading(false));
    } catch (error) {
      yield put(switchDocumentsLoading(false));
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });
}

function* documentEditCallGenerator(action) {
  yield put(switchDocumentsLoading(true));
  try {
    const {
      payload: { documentId, documentData, oppName }
    } = action;

    const { data } = yield call(axiosInstance.put, `${documentsPrefix}/${documentId}`, documentData);
    if (oppName) documentData.opportunity_name = oppName;

    yield put(onSuccessDocumentEdit({ data, documentData }));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentsLoading(false));
}

function* documentEditPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* documentEditWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const {
        payload: { documentId, documentData, messageFunction }
      } = action;
      if (documentData?.productServiceData?.length) {
        documentData.productServiceData = documentData?.productServiceData.map((ps) => {
          const newPs = {
            ...ps
          };
          delete newPs.vendor_name;
          return newPs;
        });
      }
      const { data } = yield call(axiosInstance.put, `${documentsPrefix}/${documentId}`, documentData);
      yield put(onSuccessDocumentEdit({ data, documentData }));
      yield put(switchDocumentsLoading(false));
      yield data && messageFunction && messageFunction();
      return data.document;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(switchDocumentsLoading(false));
    }
  });
}

function* documentsDeleteCallGenerator(action) {
  yield put(switchDocumentsLoading(true));
  try {
    const {
      payload: { documentIds, messageFunction }
    } = action;
    yield call(axiosInstance.post, `${documentsPrefix}/delete_multiple`, {
      document_ids: documentIds
    });

    yield messageFunction && messageFunction();
    yield put(onSuccessDocumentsDelete(documentIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentsLoading(false));
}

function* documentsRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${documentsPrefix}/undo_deleted`, {
      document_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function* documentsDeleteCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentsDeleteWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const { payload } = action;
      yield call(axiosInstance.post, `${documentsPrefix}/delete_multiple`, {
        document_ids: payload
      });
      yield put(switchDocumentsLoading(false));
      return yield put(onSuccessDocumentsDelete(payload));
    } catch (error) {
      yield put(switchDocumentsLoading(false));
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });
}

// Document item
function* documentItemAddCallGenerator(action) {
  yield put(switchDocumentsLoading(true));
  try {
    const {
      payload: { documentItemData }
    } = action;
    const { data } = yield call(axiosInstance.post, `${documentsPrefix}/items`, documentItemData);
    yield put(_onSuccessDocumentItemAdd(data));
    const templateId = localStorage.getItem("selected_template");
    if (templateId) {
      localStorage.removeItem("selected_template");
    }
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentsLoading(false));
}

function* documentItemAddPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* documentItemAddWorker() {
    yield put(switchDocumentsLoading(true));
    try {
      const {
        payload: { documentItemData }
      } = action;
      // Document layout save
      const { data } = yield call(axiosInstance.post, `${documentsPrefix}/items`, documentItemData);
      yield put(_onSuccessDocumentItemAdd(data));
      yield put(switchDocumentsLoading(false));
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(switchDocumentsLoading(false));
    }
  });
}

function* documentItemEditCallGenerator(action) {
  yield put(switchDocumentsLoading(true));
  try {
    const {
      payload: { documentItemId, documentItemData }
    } = action;
    const { data } = yield call(axiosInstance.put, `${documentsPrefix}/items/${documentItemId}`, documentItemData);
    yield put(onSuccessDocumentItemEdit(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentsLoading(false));
}

function* documentProductServiceAddCallGenerator(action) {
  yield put(switchDocumentProductServiceLoading(true));
  try {
    const {
      payload: { productServiceData, newPhotoFile }
    } = action;
    const { data } = yield call(axiosInstance.post, documentProductServicePrefix, productServiceData);
    let imageNewUrl;
    if (newPhotoFile?.file) {
      imageNewUrl = yield uploadProductServicesPhotoGenerator(
        newPhotoFile?.file,
        newPhotoFile?.name,
        data.document_item_product_service.document_item_product_service_id
      );
      data.document_item_product_service.photoUrl = imageNewUrl;
    }
    yield put(onSuccessDocumentProductServiceAdd(data.document_item_product_service));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentProductServiceLoading(false));
}

function* documentProductServiceEditCallGenerator(action) {
  const {
    payload: { productServiceData, location, itemToDelete, messageFunction }
  } = action;
  yield messageFunction && messageFunction();
  yield put(onSuccessDocumentProductServiceEdit({ productServiceData, location, itemToDelete }));
}

function* documentProductServicesDeleteCallGenerator(action) {
  yield put(switchDocumentProductServiceLoading(true));
  try {
    const {
      payload: { productServiceIds }
    } = action;
    yield call(axiosInstance.post, `${documentProductServicePrefix}/delete_multiple`, {
      document_item_product_service_ids: productServiceIds
    });
    yield put(onSuccessDocumentProductServicesDelete(productServiceIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentProductServiceLoading(false));
}

function* documentProductServicesEditFromLayoutCallGenerator(action) {
  yield put(switchDocumentProductServiceLoading(true));
  const {
    payload: { psPayload, messageFunction }
  } = action;
  try {
    const {
      status_id,
      type_id,
      uom_id,
      category_id,
      sub_category_id,
      manufacturer_id,
      currency_id,
      manufacturer_part_number,
      tenant_part_number,
      short_description,
      long_description,
      cost,
      sell,
      map,
      specification_sheet,
      manual,
      msrp,
      taxable,
      product_service_parent_id,
      recurrence_id,
      photo,
      document_item_product_service_id,
      url
    } = psPayload;

    yield call(axiosInstance.post, `${prefix}`, {
      status_id,
      type_id,
      uom_id,
      category_id,
      sub_category_id,
      manufacturer_id,
      currency_id,
      manufacturer_part_number,
      tenant_part_number,
      short_description,
      long_description,
      cost,
      sell,
      map,
      specification_sheet,
      manual,
      msrp,
      taxable,
      product_service_parent_id,
      recurrence_id,
      photo,
      url,
      document_item_product_service_id
    });
    messageFunction && messageFunction("success");
  } catch (error) {
    messageFunction && messageFunction("error");
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentProductServiceLoading(false));
}

function* uploadProductServicesPhotoGenerator(file, fileName, productServiceIds) {
  const formData = new FormData();
  formData.append("photo", file, file.name || fileName);
  const { data } = yield call(
    axiosInstance.put,
    `${documentProductServicePrefix}/photo/${productServiceIds}`,
    formData
  );

  return data?.url;
}

// Document settings
const documentSettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallDocumentSettings({
        key
      })
    );
    try {
      const { data } = yield call(axiosInstance.get, `${documentsSettingPrefix}/${url}`);
      yield put(
        onSuccessDocumentSettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedDocumentSettings({
          key
        })
      );
    }
  };

function* documentTenantSettingsCallGenerator(action) {
  yield put(onCallDocumentTenantSettings());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/tenant-settings");
    yield put(onSuccessDocumentTenantSettings(data.tenant_settings));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDocumentTenantSettings());
  }
}

function* documentTenantSettingEditCallGenerator(action) {
  yield put(switchDocumentTenantSettingLoading());
  try {
    const {
      payload: { tenantData, messageFunction }
    } = action;
    yield call(axiosInstance.patch, "/tenantUser/tenant-settings", tenantData);
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDocumentTenantSettingLoading());
}

const documentSettingAddCallGenerator = (key, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchDocumentSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemData }
      } = action;

      const { data } = yield call(axiosInstance.post, `${documentsSettingPrefix}/${url}`, itemData);
      yield put(
        onSuccessDocumentSettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessDocumentAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchDocumentSettingLoading({
        key,
        status: false
      })
    );
  };

const documentSettingEditCallGenerator = (key, idKey, url, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchDocumentSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `${documentsSettingPrefix}/${url}/${itemId}`, itemData);
      yield put(
        onSuccessDocumentSettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchDocumentSettingLoading({
        key,
        status: false
      })
    );
  };

const documentSettingDeleteCallGenerator = (key, idKey, url) =>
  function* gen(action) {
    yield put(
      switchDocumentSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `${documentsSettingPrefix}/generic_delete/${itemId}/${url}`);
      yield put(
        onSuccessDocumentSettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchDocumentSettingLoading({
        key,
        status: false
      })
    );
  };

const documentAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallDocumentAttributes({ key }));
    try {
      const { data } = yield call(axiosInstance.get, `${documentsSettingPrefix}/${url}`);

      yield put(
        onSuccessDocumentAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedDocumentAttributes({ key }));
    }
  };

function* documentAllAttributesCallGenerator(action) {
  yield put(onCallAllDocumentAttributes());
  try {
    const { data } = yield call(axiosInstance.get, `${documentsSettingPrefix}/all-settings`);
    yield put(onSuccessAllDocumentAttributes(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedAllDocumentAttributes());
  }
}

const documentsAttributeChangeCallGenerator = (key, fieldToChange) =>
  function* gen(action) {
    yield put(
      switchDocumentAttributeChangeLoading({
        key,
        status: true
      })
    );

    try {
      const {
        payload: { data }
      } = action;

      if (key === "group") {
        yield call(axiosInstance.patch, "/tenantUser/document/change_groups", data);
      } else {
        const { data: resData } = yield call(
          axiosInstance.patch,
          `/tenantUser/document/change_setting/${fieldToChange}`,
          data
        );
        yield put(
          onSuccessDocumentAttributeChange({
            data: resData?.updatedRows
          })
        );
      }
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }

    yield put(
      switchDocumentAttributeChangeLoading({
        key,
        status: false
      })
    );
  };

function* documentSendEmailCallGenerator(action) {
  yield call(implementPromiseAction, action, function* documentSendEmailWorker() {
    try {
      const {
        payload: { documentId, email, messageFunction }
      } = action;

      const { data } = yield call(axiosInstance.post, `${documentsPrefix}/${documentId}/send_document_link`, {
        email
      });
      yield messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// Document
function* documentProductServicesSearchCallGenerator(action) {
  yield put(onCallDocumentProductServicesSearch());
  try {
    const {
      payload: { search_value, lookupPlace, isShowInStockItemsOnly }
    } = action;
    const { data } = yield call(
      axiosInstance.get,
      `tenantUser/search?offset=0&limit=10&search=${search_value}&schema=${lookupPlace}&in_stock=${isShowInStockItemsOnly}`
    );
    yield put(onSuccessDocumentProductServicesSearch(data));
  } catch (error) {
    yield put(onFailedDocumentProductServicesSearch());
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* documentProductServiceCheckInventoryCallGenerator(action) {
  yield put(onCallDocumentProductServiceCheckInventory());
  try {
    const {
      payload: { part_number, upc }
    } = action;
    const { data } = yield call(
      axiosInstance.post,
      `tenantUser/outsource_products/distPriceAvail?partNumber=${part_number}&upc=${upc}`,
      {
        authentication: true
      }
    );
    yield put(onSuccessDocumentProductServiceCheckInventory(data));
  } catch (error) {
    // yield put(
    //   // setError({
    //   //   error,
    //   //   action
    //   // })
    // );
  }
  yield put(onFailedDocumentProductServiceCheckInventory());
}

function* documentProductServicesSearchCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentProductServicesSearchWorker() {
    yield put(onCallDocumentProductServicesSearchPromise(action.payload));
    try {
      const {
        payload: { in_stock, search_value, skip, limit, lookupPlace }
      } = action;
      const { data } = yield call(
        axiosInstance.get,
        `/tenantUser/search?offset=${skip}&limit=${limit}&search=${search_value}&schema=${lookupPlace}&in_stock=${in_stock}`
      );
      return yield put(onSuccessDocumentProductServicesSearchPromise({ data, in_stock }));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* documentAddLocationGenerator(action) {
  const {
    payload: { locationName }
  } = action;
  yield put(onSuccessLocation(locationName.location_id));
}

function* documentOutsourceProductServiceCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* documentOutsourceProductServiceWorker() {
    yield put(onCallOutsourceProductService());
    try {
      const {
        payload: { id }
      } = action;
      const { data } = yield call(axiosInstance.get, `/tenantUser/outsource_products/etilize/${id}`);
      yield put(onSuccessOutsourceProductService(data));
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedOutsourceProductService());
    }
  });
}

function* documentLinkCallGenerator(action) {
  const {
    payload: { headData, productServices, itemData, documentItems, tenantData }
  } = action;
  yield put(onSuccessDocumentFromLink({ headData, productServices, itemData, documentItems, tenantData }));
}

function* documentProductServicesEditCallGenerator(action) {
  const {
    payload: { taxableInfo }
  } = action;
  yield put(onSuccessProductServiceTaxableEdit(taxableInfo));
}

function* documentProductServicesItemIdsCallGenerator(action) {
  const { payload } = action;
  yield put(onSuccessEditProductServiceItems(payload));
}

function* documentExternalProductServiceItemsEditCallGenerator(action) {
  const {
    payload: { documentItemId, key, documentItemData }
  } = action;
  yield call(axiosInstance.patch, `${documentsPrefix}/external/${documentItemId}/${key}`, documentItemData);
}

function* productServiceLinkEditCallGenerator(action) {
  const { payload } = action;
  yield put(onSuccessEditProductServiceInLink(payload));
}

function* storeSelectedSignaturesSaga(action) {
  const { payload } = action;
  yield put(onCallSelectedSignatures(payload));
}

function* removeSelectedSignatureSaga(action) {
  const { payload } = action;
  yield put(removeSignature(payload));
}

function* resetSelectedSignatureSaga() {
  yield put(resetSignatures());
}

function* onLyoutChangeSaga(payload) {
  yield put(onLayoutChange(payload));
}

function* documentEditShippingGenerator({ payload }) {
  try {
    const { documentId, shippingRate, shippingTax } = payload;
    yield put(onShippingEditCall());
    const { data } = yield call(axiosInstance.patch, `${documentsPrefix}/shipping/${documentId}`, {
      shipping_tax: shippingTax,
      shipping: shippingRate
    });
    yield put(onShippingEditSuccess(data.document));
  } catch (error) {
    console.log(error, "this is error");
    yield put(onShippingEditFailure(error));
  }
}

function* updateDocumentItemFromTemplateGenerator({ payload }) {
  try {
    const { data } = yield call(axiosInstance.get, `${documentsPrefix}/items/${payload.templateId}`);
    const ps = [];

    const newLayout = data.layoutData.layout.map((item) => {
      const newItemId = uuidv4();
      if (item.typeId === "product_service") {
        data.productServices.map((productService) => {
          if (productService.document_item_id === item.i) {
            const newProductServiceItemId = uuidv4();
            // referenceProductServiceId[productService.document_item_product_service_id] = newProductServiceItemId;
            const newPs = {
              ...productService,
              document_item_id: newItemId,
              document_item_product_service_id: newProductServiceItemId
            };
            delete newPs.vendor_name;
            ps.push(newPs);
          }
        });
      }
      data.layoutData.items[newItemId] = data.layoutData.items[item.i];
      delete data.layoutData.items[item.i];
      return {
        ...item,
        i: newItemId
      };
    });

    yield put(
      onUpdateDocumentItemFromTemplate({
        ...data,
        layoutData: { ...data.layoutData, layout: newLayout },
        productServices: ps
      })
    );
  } catch (e) {
    console.log(e, "this is error in somewher eelse");
  }
}

function* createTemplateFromDocumentGenerator(action) {
  const { payload } = action;
  try {
    const { documentHead, documentItem } = yield all({
      documentHead: call(axiosInstance.get, `${documentsPrefix}/${payload.documentId}`),
      documentItem: call(axiosInstance.get, `${documentsPrefix}/items/${payload.documentId}`)
    });
    const createDocumentData = {
      template: true,
      name: payload.templateName,
      opportunity_id: null,
      adjustments: documentHead.data.document.adjustments,
      bill_to_company_address_id: documentHead.data.document.bill_to_company_address_id || null,
      bill_to_company_id: documentHead.data.document.bill_to_company_id || null,
      bill_to_contact_id: documentHead.data.document.bill_to_contact_id || null,
      cost: Number(documentHead.data.document.cost) || 0,
      currency_id: documentHead.data.document.currency_id || 840,
      document_date: documentHead.data.document.document_date,
      expire_date: documentHead.data.document.expire_date,
      opportunity_add_edit: documentHead.data.document.opportunity_add_edit,
      payment_term_id: documentHead.data.document.payment_term_id || null,
      sales_tax_id: documentHead.data.document.sales_tax_id || null,
      sales_tax_rate: documentHead.data.document.sales_tax_rate || null,
      ship_to_company_address_id: documentHead.data.document.ship_to_company_address_id || null,
      ship_to_company_id: documentHead.data.document.ship_to_company_id || null,
      ship_to_contact_id: documentHead.data.document.ship_to_contact_id || null,
      sold_to_company_address_id: documentHead.data.document.sold_to_company_address_id || null,
      sold_to_company_id: documentHead.data.document.sold_to_company_id || null,
      sold_to_contact_id: documentHead.data.document.sold_to_contact_id || "",
      status_id: documentHead.data.document.status_id,
      sub_total: documentHead.data.document.sub_total,
      tenant_user_id: documentHead.data.document.tenant_user_id,
      type_id: documentHead.data.document.type_id
    };

    const res = yield call(axiosInstance.post, documentsPrefix, createDocumentData);

    const ps = [];

    const newLayout = documentItem.data.layoutData.layout.map((item) => {
      const newItemId = uuidv4();
      if (item.typeId === "product_service") {
        documentItem.data.productServices.map((productService) => {
          if (productService.document_item_id === item.i) {
            const newProductServiceItemId = uuidv4();
            // referenceProductServiceId[productService.document_item_product_service_id] = newProductServiceItemId;
            const newPs = {
              ...productService,
              document_item_id: newItemId,
              document_item_product_service_id: newProductServiceItemId
            };
            ps.push(newPs);
          }
        });
      }
      documentItem.data.layoutData.items[newItemId] = documentItem.data.layoutData.items[item.i];
      delete documentItem.data.layoutData.items[item.i];
      if (item.typeId === "signature") {
        const signatureValue = JSON.parse(documentItem.data.layoutData.items[newItemId]);
        delete signatureValue.user_info;
        delete signatureValue.value;
        documentItem.data.layoutData.items[newItemId] = JSON.stringify(signatureValue);
      }
      return {
        ...item,
        i: newItemId
      };
    });

    const dataToCreateItems = {
      docData: {
        sales_tax_rate: documentHead.data.document.sales_tax_rate,
        cost: documentHead.data.document.cost,
        sub_total: documentHead.data.document.sub_total
      },

      documentId: res.data.document.document_id,
      productServiceData: JSON.stringify({ delete: [], upsert: ps }),
      layoutData: JSON.stringify({
        ...documentItem.data.layoutData,
        layout: newLayout,
        deletedItemIds: [],
        itemUrls: []
      })
    };

    yield call(axiosInstance.post, `${documentsPrefix}/items`, dataToCreateItems);
    // tenantUser/documents
    yield payload && payload.messageFunction && payload.messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* addProductFromPdfGenerator(action) {
  const { payload } = action;

  try {
    // /api/tenantUser/product_service/90ad5711-9946-4b75-90b9-e07f9a11136f/products-from-pdf
    const formData = new FormData();
    formData.append("file", payload.file[0]);
    const { data } = yield call(
      axiosInstance.post,
      `/tenantUser/product_service/${payload.documentId}/products-from-pdf`,
      formData
    );
    yield put(onPdfImport({ data, payload }));
  } catch (e) {
    console.log(e, "error uploading file");
  }
}

function* getMergeFieldsGenerator({ payload }) {
  yield put(onLoadMergeFields());
  try {
    const { data } = yield call(axiosInstance.post, "tenantUser/templates/getMergeFields/fillTemplate", {
      ...payload.data
    });
    yield put(onSuccessMergeFields(data));
    payload.resolve && payload.resolve(data.items);
  } catch (e) {
    payload.reject && payload.reject(e);
    yield put(onFailedMergeFields(e));
  }
}

function* getContactsSelectSearch() {
  yield put(onGetContactsSelectSearch());
  try {
    const { data } = yield call(axiosInstance.get, "tenantUser/contacts/list/select_search");
    yield put(onGetContactsSelectSearchSuccess(data));
  } catch (e) {
    yield put(onGetContactsSelectSearchFailure(e));
  }
}

function* getCompaniesSelectSearch() {
  yield put(onGetCompaniesSelectSearch());
  try {
    const { data } = yield call(axiosInstance.get, "tenantUser/companies/list/select_search");
    yield put(onGetCompaniesSelectSearchSuccess(data));
  } catch (e) {
    yield put(onGetCompaniesSelectSearchFailure(e));
  }
}

export default function* documentsSaga() {
  yield takeEvery(DOCUMENTS_CALL, documentsCallGenerator);
  yield takeEvery(DOCUMENT_APPROVE_CALL, documentApproveCallGenerator);
  yield takeEvery(DOCUMENT_REQUEST_APPROVAL_CALL, documentRequestApprovalCallGenerator);
  yield takeEvery(callDocumentsPromise, documentsCallPromiseGenerator);
  yield takeEvery(DOCUMENTS_TASK_CALL, documentsTaskCallGenerator);
  yield takeEvery(DOCUMENTS_DELETE_CALL, documentsDeleteCallGenerator);
  yield takeEvery(DOCUMENTS_RESTORE_CALL, documentsRestoreCallGenerator);
  yield takeEvery(documentDeletePromise, documentsDeleteCallPromiseGenerator);
  yield takeEvery(documentChangeActiveStatusPromise, changeDocumentActiveStatusPromiseGenerator);

  // HEAD
  yield takeEvery(DOCUMENT_CALL_HEAD, documentHeadCallGenerator);
  yield takeEvery(DOCUMENT_CALL_HEAD_WITH_CHANGED_FIELDS, documentHeadWithChangedFieldsCallGenerator);
  yield takeEvery(DOCUMENT_ADD_CALL, documentAddCallGenerator);
  yield takeEvery(documentAddPromise, documentsAddPromiseGenerator);
  yield takeEvery(documentAddTemplatePromise, documentAddTemplatePromiseGenerator);
  yield takeEvery(DOCUMENT_EDIT_CALL, documentEditCallGenerator);
  yield takeEvery(documentEditPromise, documentEditPromiseCallGenerator);

  yield takeEvery(CONTACTS_SELECT_SEARCH, getContactsSelectSearch);
  yield takeEvery(COMPANIES_SELECT_SEARCH, getCompaniesSelectSearch);

  // ITEM
  yield takeEvery(DOCUMENT_ITEM_ADD_CALL, documentItemAddCallGenerator);
  yield takeEvery(documentItemAddPromise, documentItemAddPromiseCallGenerator);
  yield takeEvery(DOCUMENT_CALL_ITEM, documentItemCallGenerator);
  yield takeEvery(DOCUMENT_ITEM_EDIT_CALL, documentItemEditCallGenerator);
  // yield takeEvery(DOCUMENT_PRODUCT_SERVICES_CALL, documentProductServicesCallGenerator);
  yield takeEvery(DOCUMENT_PRODUCT_SERVICE_ADD_CALL, documentProductServiceAddCallGenerator);
  yield takeEvery(DOCUMENT_PRODUCT_SERVICE_EDIT_CALL, documentProductServiceEditCallGenerator);
  yield takeEvery(DOCUMENT_PRODUCT_SERVICES_DELETE_CALL, documentProductServicesDeleteCallGenerator);
  yield takeEvery(PRODUCTS_SERVICE_EDIT_FROM_DOCUMENT_CALL, documentProductServicesEditFromLayoutCallGenerator);
  yield takeEvery(DOCUMENT_PRODUCT_SERVICES_EDIT_TAXABLE_CALL, documentProductServicesEditCallGenerator);

  // SETTINGS
  yield takeEvery(DOCUMENT_TENANT_SETTINGS_CALL, documentTenantSettingsCallGenerator);
  yield takeEvery(DOCUMENT_TENANT_SETTINGS_EDIT_CALL, documentTenantSettingEditCallGenerator);

  yield takeEvery(DOCUMENT_STATUSES_CALL, documentSettingsCallGenerator("status", "document_status_list"));
  yield takeEvery(
    DOCUMENT_STATUS_ADD_CALL,
    documentSettingAddCallGenerator("status", "status", "createdDocumentStatus")
  );
  yield takeEvery(
    DOCUMENT_STATUS_EDIT_CALL,
    documentSettingEditCallGenerator("status", "document_status_id", "status", "updatedDocumentStatus")
  );
  yield takeEvery(
    DOCUMENT_STATUS_DELETE_CALL,
    documentSettingDeleteCallGenerator("status", "document_status_id", "document_status")
  );
  yield takeEvery(DOCUMENT_PRODUCT_SERVICES_ITEM_IDS_EDIT, documentProductServicesItemIdsCallGenerator);
  yield takeEvery(
    DOCUMENT_EXTERNAL_PRODUCT_SERVICE_ITEMS_EDIT_CALL,
    documentExternalProductServiceItemsEditCallGenerator
  );
  yield takeEvery(DOCUMENT_TYPES_CALL, documentSettingsCallGenerator("type", "document_type_list"));
  yield takeEvery(DOCUMENT_TYPE_ADD_CALL, documentSettingAddCallGenerator("type", "type", "createdDocumentType"));
  yield takeEvery(
    DOCUMENT_TYPE_EDIT_CALL,
    documentSettingEditCallGenerator("type", "document_type_id", "type", "updatedDocumentType")
  );
  yield takeEvery(
    DOCUMENT_TYPE_DELETE_CALL,
    documentSettingDeleteCallGenerator("type", "document_type_id", "document_type")
  );

  yield takeEvery(DOCUMENT_PAYMENT_TERM_CALL, documentSettingsCallGenerator("payment_term", "payment_term_list"));
  yield takeEvery(
    DOCUMENT_PAYMENT_TERM_ADD_CALL,
    documentSettingAddCallGenerator("payment_term", "payment_term", "createdPaymentTerm")
  );
  yield takeEvery(
    DOCUMENT_PAYMENT_TERM_EDIT_CALL,
    documentSettingEditCallGenerator("payment_term", "payment_term_id", "payment_term", "updatedDocumentPaymentTerm")
  );
  yield takeEvery(
    DOCUMENT_PAYMENT_TERM_DELETE_CALL,
    documentSettingDeleteCallGenerator("payment_term", "payment_term_id", "document_payment_term")
  );

  yield takeLatest(DOCUMENT_NOTES_CALL, documentNotesCallGenerator);

  yield takeLatest(DOCUMENT_TASKS_CALL, documentTasksCallGenerator);

  yield takeLatest(DOCUMENT_REVISIONS_CALL, documentRevisionsCallGenerator);

  // ATTRIBUTES
  yield takeEvery(DOCUMENTS_CALL_STATUSES, documentAttributesCallGenerator("status", "list/document_status"));
  yield takeEvery(DOCUMENTS_CALL_TYPES, documentAttributesCallGenerator("type", "list/document_type"));
  yield takeEvery(
    DOCUMENTS_CALL_ADJUSTMENTS,
    documentAttributesCallGenerator("adjustment_source", "document_adjustment_source")
  );
  yield takeEvery(
    DOCUMENTS_CALL_DESTINATION,
    documentAttributesCallGenerator("adjustment_destination", "document_adjustment_destination")
  );
  yield takeEvery(DOCUMENTS_CALL_TEMPLATES, documentAttributesCallGenerator("template", "templates"));

  yield takeEvery(DOCUMENT_ALL_ATTRIBUTES_CALL, documentAllAttributesCallGenerator);

  yield takeEvery(DOCUMENTS_CHANGE_STATUSES, documentsAttributeChangeCallGenerator("status", "status_id"));
  yield takeEvery(DOCUMENTS_CHANGE_TYPES, documentsAttributeChangeCallGenerator("type", "type_id"));
  yield takeEvery(documentSendEmailPromise, documentSendEmailCallGenerator);

  // SEND EMAIL
  yield takeEvery(PRODUCTS_SERVICE_LOCATION_ADD_CALL, documentAddLocationGenerator);
  yield takeEvery(DOCUMENT_SHIPPING_EDIT_CALL, documentEditShippingGenerator);

  yield takeEvery(DOCUMENT_PRODUCT_SERVICES_SEARCH_CALL, documentProductServicesSearchCallGenerator);

  yield takeEvery(DOCUMENT_PRODUCT_SERVICE_CHECK_INVENTORY, documentProductServiceCheckInventoryCallGenerator);

  yield takeEvery(documentProductServiceSearchPromise, documentProductServicesSearchCallPromiseGenerator);

  yield takeEvery(documentOutsourceProductServicePromise, documentOutsourceProductServiceCallPromiseGenerator);
  yield takeEvery(DOCUMENT_FROM_LINK_ADD_CALL, documentLinkCallGenerator);
  yield takeEvery(PRODUCTS_SERVICE_LINK_EDIT_CALL, productServiceLinkEditCallGenerator);
  yield takeEvery(CALL_SELECTED_SIGNATURES, storeSelectedSignaturesSaga);
  yield takeEvery(CALL_REMOVE_SELECTED_SIGNATURE, removeSelectedSignatureSaga);
  yield takeEvery(CALL_RESET_SIGNATURES, resetSelectedSignatureSaga);
  yield takeEvery(LAYOUT_CHANGED, onLyoutChangeSaga);
  yield takeEvery(UPDATE_DOCUMENT_ITEM_FROM_TEMPLATE, updateDocumentItemFromTemplateGenerator);
  yield takeEvery(CREATE_TEMPLATE_FROM_DOCUMENT, createTemplateFromDocumentGenerator);
  yield takeEvery(ADD_PRODUCT_FROM_PDF, addProductFromPdfGenerator);
  yield takeEvery(GET_MERGE_FIELDS, getMergeFieldsGenerator);
}
