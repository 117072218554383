import { createSlice } from "@reduxjs/toolkit";

export const dashboardsSlice = createSlice({
  name: "integrations",
  initialState: {
    integrations: {
      data: [],
      isFetched: false,
      isLoading: false
    },
    tenantIntegrations: {
      data: [],
      isFetched: false,
      isLoading: false
    },
    userTenantIntegrations: {
      data: [],
      isFetched: false,
      isLoading: false
    }
  },
  reducers: {
    onCallIntegrations: (state) => {
      state.integrations.isFetched = false;
      state.integrations.data = [];
    },
    onSuccessIntegrations: (state, { payload }) => {
      const { integrationList } = payload;
      if (integrationList) {
        state.integrations.isFetched = true;
        state.integrations.data = integrationList;
      }
    },
    onFailedIntegrations: (state) => {
      state.integrations.isFetched = true;
      state.integrations.data = [];
    },

    // Tenant Integrations
    onCallTenantIntegrations: (state) => {
      state.tenantIntegrations.isFetched = false;
      state.tenantIntegrations.data = [];
    },
    onSuccessTenantIntegrations: (state, { payload }) => {
      const { tenantIntegrationList } = payload;
      if (tenantIntegrationList) {
        state.tenantIntegrations.isFetched = true;
        state.tenantIntegrations.data = tenantIntegrationList;
      }
    },
    onFailedTenantIntegrations: (state) => {
      state.tenantIntegrations.isFetched = true;
      state.tenantIntegrations.data = [];
    },

    // User Tenant Integration
    onCallUserTenantIntegrations: (state) => {
      state.userTenantIntegrations.isFetched = false;
      state.userTenantIntegrations.data = [];
    },
    onSuccessUserTenantIntegrations: (state, { payload }) => {
      const { tenantUserIntegrationList } = payload;
      if (tenantUserIntegrationList) {
        state.userTenantIntegrations.isFetched = true;
        state.userTenantIntegrations.data = tenantUserIntegrationList;
      }
    },
    onFailedUserTenantIntegrations: (state) => {
      state.userTenantIntegrations.isFetched = true;
      state.userTenantIntegrations.data = [];
    },

    switchTenantIntegrationLoading: (state, { payload }) => {
      state.tenantIntegrations.isLoading = payload !== undefined ? payload : !state.tenantIntegrations.isLoading;
    },

    onSuccessTenantIntegrationAdd: (state, { payload }) => {
      if (payload.data) {
        const key = payload.userTenantIntegration ? "userTenantIntegrations" : "tenantIntegrations";
        state[key].data = [
          ...state[key].data,
          payload.userTenantIntegration
            ? payload.data.createdTenantUserIntegration
            : payload.data.createdTenantIntegration
        ];
      }
    },
    onSuccessTenantIntegrationEdit: (state, { payload }) => {
      if (payload?.data) {
        const { integration_id } = payload.userTenantIntegration
          ? payload.data.updatedTenantUserIntegration
          : payload.data.updatedTenantIntegration;
        const key = payload.userTenantIntegration ? "userTenantIntegrations" : "tenantIntegrations";

        state[key].data = state[key].data.map((tI) =>
          tI.integration_id === integration_id
            ? {
                ...tI,
                ...(payload.userTenantIntegration
                  ? payload.data.updatedTenantUserIntegration
                  : payload.data.updatedTenantIntegration)
              }
            : tI
        );
      }
    },
    onSuccessDisconnectIntegration: (state, { payload }) => {
      if (payload) {
        state.tenantIntegrations.data = state.tenantIntegrations.data.map((tI) =>
          tI.tenant_integration_id === payload
            ? {
                ...tI,
                connected: false,
                status_id: 2
              }
            : tI
        );
      }
    },
    onSuccessTenantIntegrationDelete: (state, { payload }) => {
      if (payload !== undefined) {
        const key = payload.userTenantIntegration ? "userTenantIntegrations" : "tenantIntegrations";
        const idKey = payload.userTenantIntegration ? "tenant_user_integration_id" : "tenant_integration_id";
        state[key].data = state[key].data.filter((tI) => !payload.tenantIntegrationIds.includes(tI[idKey]));
      }
    }
  }
});
export const {
  onCallIntegrations,
  onSuccessIntegrations,
  onFailedIntegrations,

  onCallTenantIntegrations,
  onSuccessTenantIntegrations,
  onFailedTenantIntegrations,

  onCallUserTenantIntegrations,
  onSuccessUserTenantIntegrations,
  onFailedUserTenantIntegrations,

  onSuccessTenantIntegrationAdd,
  onSuccessTenantIntegrationEdit,
  onSuccessTenantIntegrationDelete,

  onSuccessDisconnectIntegration,
  onSuccessTenantIntegrationsStartProcess,

  switchTenantIntegrationLoading
} = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
