import {
  Email,
  Cake,
  Phone,
  AssignmentTurnedIn,
  Business,
  PeopleAlt,
  MonetizationOn,
  Description
} from "@mui/icons-material";
import { routeNames } from "../routes";

const notificationTypes = {
  1: {
    name: "Task",
    icon: AssignmentTurnedIn
  },
  2: {
    name: "Birthday",
    icon: Cake,
    pathName: routeNames.APP_CONTACTS
  },
  3: {
    name: "Phone Call",
    icon: Phone,
    pathName: routeNames.APP_CONTACTS
  },
  4: {
    name: "Email",
    icon: Email,
    pathName: routeNames.APP_CONTACTS
  },
  5: {
    name: "Company",
    icon: Business,
    pathName: routeNames.APP_COMPANIES
  },
  6: {
    name: "Contact",
    icon: PeopleAlt,
    pathName: routeNames.APP_CONTACTS
  },
  7: {
    name: "Opportunities",
    icon: MonetizationOn,
    pathName: routeNames.APP_OPPORTUNITIES
  },
  8: {
    name: "Document",
    icon: Description,
    pathName: routeNames.APP_DOCUMENTS
  }
};

export default notificationTypes;
