import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";


const initialAnalyticsData = {
  delivered: "0",
  opened: "0",
  clicked: "0",
  dropped: "0",
  deferred: "0",
  bounced: "0",
  blocked: "0",
};

export const sequencesSlice = createSlice({
  name: "sequences",
  initialState: {
    isFetched: false,
    sequences: [],

    // single sequence
    sequence: {
      isFetched: false,
      isLoading: false,
      isLoadingDelete: false,
      data: undefined
    },
    holidays: {
      isFetched: false,
      isLoading: false,
      data: [],
      error: null
    },

    sequenceCondition: {
      isFetched: false,
      data: undefined
    },

    sequenceAnalytics: {
      isFetched: false,
      data: initialAnalyticsData
    },
    duplicateNode: null
  },
  reducers: {
    onAddDuplicateNode: (state, {payload}) => {
      state.duplicateNode = payload;
    },
    onRemoveDuplicateNode: (state) => {
      state.duplicateNode = null;
    },
    onCallHolidays: (state) => {
      state.holidays.isLoading = true;
    },
    onCallHolidaysSuccess: (state, { payload }) => {
      state.holidays.isLoading = false;
      state.holidays.isFetched = true;
      state.holidays.data = [...state.holidays.data, {
        id: payload.countryCode, label: payload.countryCode, children: payload.list.map(item => {
          return {
            ...item,
            id: `${payload.countryCode}-${item.name}`,
            label: item.name
          };
        })
      }];
    },
    onCallHolidaysFailure: (state, { payload }) => {
      state.holidays.isLoading = false;
      state.holidays.isFetched = true;
      state.holidays.error = payload;
    },
    // all sequences
    onCallSequences: (state) => {
      state.isFetched = false;
      state.sequences = [];
    },
    onSuccessSequences: (state, { payload }) => {
      state.isFetched = true;
      state.sequences = payload.sequences;
    },
    onFailedSequences: (state) => {
      state.isFetched = true;
      state.sequences = [];
    },

    // single sequence
    switchSequenceLoading: (state, { payload }) => {
      state.sequence.isLoading = payload !== undefined ? payload : !state.sequence.isLoading;
    },
    switchSequenceDeleteLoading: (state, { payload }) => {
      state.sequence.isLoadingDelete = payload !== undefined ? payload : !state.sequence.isLoadingDelete;
    },

    onSuccessSequence: (state, { payload }) => {
      state.sequence.isFetched = true;
      state.sequence.data = payload;
    },
    onFailedSequence: (state) => {
      state.sequence.isFetched = true;
      state.sequence.data = undefined;
    },
    resetSequence: (state) => {
      state.sequence.isFetched = false;
      state.sequence.data = undefined;
    },

    onSuccessSequenceAdd: (state, { payload }) => {
      if (payload) {
        const sequenceData = {
          ...payload,
          status_id: payload.status === 'Active' ? 1 : 2
        };
        state.sequences = [...state.sequences, sequenceData];
        state.sequence.data = sequenceData;
        state.sequence.isFetched = true;
      }
    },
    onSuccessSequenceEdit: (state, { payload }) => {
      if (payload) {
        const sequenceData = {
          ...payload,
          status_id: payload.status === 'Active' ? 1 : 2
        };
        state.sequences = state.sequences.map((sequence) =>
          sequence.sequence_id === payload.sequence_id ? sequenceData : sequence
        );
        state.sequence.data = sequenceData;
        state.sequence.isFetched = true;
      }
    },
    onSuccessSequenceDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.sequences = state.sequences.filter((sequence) => !payload.includes(sequence.sequence_id));
      }
    },

    // sequence conditions
    onCallSequenceConditions: (state) => {
      state.sequenceCondition.isFetched = false;
      state.sequenceCondition.data = [];
    },
    onSuccessSequenceConditions: (state, { payload }) => {
      const cleanList = payload.conditionTokens.filter((cT) => cT.data_type !== "unused");
      const parsedList = cleanList.map((item) => ({
        ...item
      }));

      const groups = parsedList.reduce((r, a) => {
        r[a.group_name.toLowerCase()] = [...(r[a.group_name.toLowerCase()] || []), a];
        return r;
      }, {});

      Object.keys(groups).forEach((group) => {
        groups[group] = groups[group] // .filter(item => item.token)
          .map((item) => ({
            code: item.token,
            name: item.token,
            type: item.data_type
            // ex.
            // column_name: "name"
            // data_length: "50"
            // data_type: "text"
            // group_name: "Company"
            // token: "Company Name"
            // token_id: 17
          }));
      });

      state.sequenceCondition.isFetched = true;
      state.sequenceCondition.data = groups;
    },
    onFailedSequenceConditions: (state) => {
      state.sequenceCondition.isFetched = true;
      state.sequenceCondition.data = [];
    },

    // sequence analytics
    onCallSequenceAnalytics: (state) => {
      state.sequenceAnalytics.isFetched = false;
      state.sequenceAnalytics.data = initialAnalyticsData;
    },
    onSuccessSequenceAnalytics: (state, { payload }) => {
      state.sequenceAnalytics.data = {
        delivered: payload.delivered || '0',
        opened: payload.opened || '0',
        clicked: payload.clicked || '0',
        dropped: payload.dropped || '0',
        deferred: payload.deferred || '0',
        bounced: payload.bounced || '0',
        blocked: payload.blocked || '0',
      };
      state.sequenceAnalytics.isFetched = true;
    },
    onFailedSequenceAnalytics: (state) => {
      state.sequenceAnalytics.isFetched = true;
      state.sequenceAnalytics.data = initialAnalyticsData;
    }
  }
});

export const {
  onAddDuplicateNode,
  onRemoveDuplicateNode,

  onCallHolidays,
  onCallHolidaysSuccess,
  onCallHolidaysFailure,

  onCallSequences,
  onSuccessSequences,
  onFailedSequences,

  switchSequenceLoading,
  switchSequenceDeleteLoading,

  onSuccessSequence,
  onFailedSequence,
  resetSequence,

  onSuccessSequenceAdd,
  onSuccessSequenceEdit,
  onSuccessSequenceDelete,

  onCallSequenceConditions,
  onSuccessSequenceConditions,
  onFailedSequenceConditions,

  onCallSequenceAnalytics,
  onFailedSequenceAnalytics,
  onSuccessSequenceAnalytics
} = sequencesSlice.actions;

export default sequencesSlice.reducer;
