import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { callAddImport, IMPORT_ADD_CALL, IMPORT_TYPES } from "store/import/sagas-actions";
import { templateMergeFieldsSelector } from "store/templates/selectors";
import { errorSelectorById } from "store/error/selector";
import { isEqual } from "lodash";
import { useDidUpdate } from "hooks";
import { useSnackbar } from "notistack";
import importDataSelector from "store/import/selectors";
import LoaderLinear from "components/common/loader-liner";
import Confirmation from "./confirmation";
import ImportErrorDialog from "./import-error-dialog";
import ImportStepper from "./import-stepper";
import ImportUploader from "./import-uploader";
import MappingList from "./mapping-list";

const REQUIRED_FIELDS_TO_MAP = {
  company: ["Company Name"],
  contact: ["First Name", "Last Name"],
  opportunity: ["Name"],
  product_service: ["Short Description", "Category"]
};

const TOKENS = {
  company: "Company",
  contact: "Contact",
  product_service: "Product/Service"
};

const Import = ({ isOpen, handleClose, moduleName }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [fileColumnNames, setFileColumnNames] = useState(null);
  const [isFirstRowColumnated, setIsFirstRowColumnated] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [importType, setImportType] = useState(IMPORT_TYPES.INSERT);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const steps = ["Select File", "Map Columns", "Confirmation"];
  const allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "application/vnd.ms-excel"
  ];
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileRowsAmount, setFileRowsAmount] = useState(0);
  const { grouped: modulesList } = useSelector(templateMergeFieldsSelector);
  const { isLoading } = useSelector(importDataSelector);
  const resError = useSelector((store) => errorSelectorById(store, IMPORT_ADD_CALL), isEqual);
  const [columnsToSend, setColumnsToSend] = useState({});
  const [file, setFile] = useState(null);
  const moduleFieldSList = useMemo(() => {
    if (modulesList && modulesList[TOKENS[moduleName]]) {
      return modulesList[TOKENS[moduleName]].map((item) => item.text);
    }
    return [];
  }, [modulesList]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const fileUploadHandler = async (acceptedFile) => {
    try {
      setIsFileSelected(true);
      setFile(acceptedFile);
      const importedFile = acceptedFile;
      setIsFileLoading(true);
      const data = await importedFile.arrayBuffer();
      setIsFileLoading(false);
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      jsonData.length > 0 && setFileRowsAmount(jsonData.length - 1);
      const columnNames = jsonData.length > 0 ? jsonData[0] : [];
      setColumnsToSend({});
      setIsFirstRowColumnated(true);
      setFileColumnNames(columnNames);
    } catch (error) {
      setFile(null);
      setIsFileSelected(false);
      setErrorMessage({
        title: "Error while reading file",
        content: "There was an error reading the file. Please check the file and try again"
      });
    }
  };

  const onDrop = useCallback(async (acceptedFile) => {
    fileUploadHandler(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, accept: allowedFileTypes });

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const onClose = () => {
    handleClose();
    setFile(null);
    setIsFileSelected(false);
    setActiveStep(0);
  };

  // On error
  useDidUpdate(() => {
    if (resError) {
      onClose();
    }
  }, [resError]);

  function success() {
    enqueueSnackbar("File has uploaded successfully. Import Started.", {
      variant: "success"
    });
  }

  const validateMappedList = () => {
    const missedFieldsToMap = [];
    REQUIRED_FIELDS_TO_MAP[moduleName].forEach((item) => {
      if (!columnsToSend?.FILE?.list.find((columnField) => columnField.token === item)) {
        missedFieldsToMap.push(item);
      }
    });
    return missedFieldsToMap;
  };

  const showStepContent = (step) => {
    if (step === 0) {
      return (
        <ImportUploader
          setImportType={setImportType}
          importType={importType}
          onFileUpload={fileUploadHandler}
          file={file}
          isFileLoading={isFileLoading}
          allowedFileTypes={allowedFileTypes}
        />
      );
    }
    if (step === 1) {
      return (
        <MappingList
          fileDataAmount={fileRowsAmount}
          setFileRowsAmount={setFileRowsAmount}
          columnsToSend={columnsToSend}
          setColumnsToSend={setColumnsToSend}
          isFirstRowColumnated={isFirstRowColumnated}
          setIsFirstRowColumnated={setIsFirstRowColumnated}
          backendColumnNames={moduleFieldSList}
          fileColumnNames={fileColumnNames}
        />
      );
    }
    if (step === 2) {
      const missedFields = validateMappedList(columnsToSend);
      if (missedFields.length < 1) {
        return <Confirmation moduleName={moduleName} importType={importType} fileDataAmount={fileRowsAmount} />;
      }
      setErrorMessage({
        title: "Required Column(s)",
        content: `Following column(s) are required for the import: ${missedFields.join(", ")}`
      });
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const onCloseDialog = () => {
    setErrorMessage(null);
  };

  const submitHandler = () => {
    // if (importType === IMPORT_TYPES.INSERT) {
    // if (!isFirstRowColumnated) {
    //     columnsToSend.FILE.list = columnsToSend.FILE.list.map((item, index) => ({ ...item, columnName: fileColumnNames[index] }));
    // }
    const importData = {
      isFirstRowColumnated,
      importType,
      list: columnsToSend.FILE.list
    };
    dispatch(callAddImport(importData, file, moduleName, success));
    onClose();
    // }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <DialogTitle>
          <ImportStepper steps={steps} activeStep={activeStep} />
        </DialogTitle>
        <DialogContent sx={{ maxHeight: "350px" }}>{showStepContent(activeStep)}</DialogContent>
        <DialogActions>
          {activeStep !== 0 && (
            <Button onClick={(e) => handleBack(e)} disabled={isLoading}>
              Back
            </Button>
          )}
          {activeStep !== steps.length - 1 && (
            <Button disabled={!isFileSelected} onClick={(e) => handleNext(e)}>
              Next
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button disabled={!isFileSelected || isLoading} onClick={submitHandler}>
              Finish
            </Button>
          )}
          <Button onClick={onClose} disabled={isLoading}>
            Close
          </Button>
        </DialogActions>
      </Box>
      <ImportErrorDialog
        title={errorMessage?.title}
        message={errorMessage?.content}
        onClose={onCloseDialog}
        isOpen={Boolean(errorMessage)}
      />
      {isLoading && <LoaderLinear />}
    </Dialog>
  );
};

export default Import;
