import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { setError } from "../error/slice";
import { appInternalSearchPromise, APP_INTERNAL_SEARCH_CALL } from "./sagas-actions";
import {
  onSuccessInternalSearch,
  onFailedInternalSearch,
  onCallAppInternalSearch,
  onCallAppInternalSearchPromise,
  onSuccessAppInternalSearchPromise
} from "./slice";

function* appInternalSearchCallGenerator(action) {
  yield put(onCallAppInternalSearch());
  try {
    const {
      payload: { search_value, limit = 10, offset = 0 }
    } = action;
    const { data } = yield call(
      axiosSagaRequest,
      "get",
      `tenantUser/search?offset=${offset}&limit=${limit}&search=${search_value}&full=true&schema=internal`
    );
    yield put(onSuccessInternalSearch(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedInternalSearch());
  }
}

function* appInternalSearchCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* appInternalSearchWorker() {
    yield put(onCallAppInternalSearchPromise());
    try {
      const {
        payload: { search_value, skip, limit }
      } = action;
      const { data } = yield call(
        axiosInstance.get,
        `tenantUser/search?offset=${skip}&limit=${limit}&search=${search_value}&full=true&schema=internal`
      );
      return yield put(onSuccessAppInternalSearchPromise(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

export default function* appUniversalSearchSaga() {
  yield takeLatest(APP_INTERNAL_SEARCH_CALL, appInternalSearchCallGenerator);
  yield takeLatest(appInternalSearchPromise, appInternalSearchCallPromiseGenerator);
}
