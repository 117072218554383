import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import { implementPromiseAction, rejectPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import qs from "qs";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import {
  DASHBOARDS_CALL,
  DASHBOARD_ADD_CALL,
  DASHBOARD_EDIT_CALL,
  DASHBOARD_DELETE_CALL,
  DASHBOARD_RESTORE_CALL,
  DASHBOARD_LAYOUT_EDIT_CALL,
  callDashboardItemAddPromise,
  callDashboardItemEditPromise,
  callDashboardItemDeletePromise,
  DASHBOARD_ITEM_DEFINITIONS_CALL,
  callDashboardItemDetailsPromise,
  DASHBOARD_ITEM_DETAILS_CALL
} from "./sagas-actions";
import {
  onCallDashboards,
  onSuccessDashboards,
  onFailedDashboards,
  switchDashboardLoading,
  switchDashboardLayoutLoading,
  onSuccessDashboardAdd,
  onSuccessDashboardEdit,
  onSuccessDashboardsDelete,
  onCallDashboardItemDefinitions,
  onSuccessDashboardItemDefinitions,
  onFailedDashboardItemDefinitions,
  onCallDashboardItemDetails,
  onSuccessDashboardItemDetails,
  onFailedDashboardItemDetails
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { paramParserForPagination } from "../../utils/helpers";

const prefix = "/tenantUser/dashboard";

function* dashboardsCallGenerator(action) {
  yield put(onCallDashboards());
  try {
    const { data } = yield call(axiosSagaRequest, "get", prefix);
    yield put(onSuccessDashboards(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDashboards());
  }
}

function* dashboardAddCallGenerator(action) {
  yield put(switchDashboardLoading(true));
  try {
    const {
      payload: { dashboardData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, prefix, dashboardData);
    yield put(onSuccessDashboardAdd(data));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDashboardLoading(false));
}

function* dashboardEditCallGenerator(action) {
  yield put(switchDashboardLoading(true));
  try {
    const {
      payload: { dashboardId, dashboardData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.put, `${prefix}/${dashboardId}`, dashboardData);
    yield put(onSuccessDashboardEdit(data));
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDashboardLoading(false));
}

function* dashboardDeleteCallGenerator(action) {
  yield put(switchDashboardLoading(true));
  try {
    const {
      payload: { dashboardIds }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      dashboard_ids: dashboardIds
    });
    yield put(onSuccessDashboardsDelete(dashboardIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDashboardLoading(false));
}

function* dashboardRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      dashboard_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

function* dashboardLayoutUpdateCallGenerator(action) {
  yield put(switchDashboardLayoutLoading(true));
  const {
    payload: { dashboardId, layout, messageFunction }
  } = action;
  try {
    yield call(axiosSagaRequest, "put", `${prefix}/${dashboardId}`, { layout });
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield messageFunction && messageFunction();
  }
  yield put(switchDashboardLayoutLoading(false));
}

// Dashboard items
function* dashboardItemGeneratorWrapper(gen) {
  // yield put(switchDashboardItemLoading(true));
  yield gen();
  // yield put(switchDashboardItemLoading(false));
}

function* dashboardItemAddPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* dashboardItemAddWorker() {
    const {
      payload: { dashboardId, body, messageFunction }
    } = action;
    yield dashboardItemGeneratorWrapper(function* dashboardItemGeneratorWrapperAdd() {
      try {
        const { data } = yield call(axiosSagaRequest, "post", `${prefix}/${dashboardId}/item`, body);
        // yield put(onSuccessDashboardItemAdd(data));
        yield messageFunction && messageFunction("Dashboard item saved.", "success");
        yield call(resolvePromiseAction, action, data);
      } catch (error) {
        yield put(setError(error, action));
        yield messageFunction && messageFunction("Dashboard item creation failed.", "error");
        yield call(rejectPromiseAction, action, error);
      }
    });
  });
}

function* dashboardItemEditPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* dashboardItemEditWorker() {
    const {
      payload: { dashboardId, body, messageFunction }
    } = action;
    yield dashboardItemGeneratorWrapper(function* dashboardItemGeneratorWrapperEdit() {
      try {
        const { data } = yield call(axiosSagaRequest, "put", `${prefix}/${dashboardId}/item`, body);
        yield messageFunction && messageFunction("Dashboard item updated.", "success");
        yield call(resolvePromiseAction, action, data);
      } catch (error) {
        yield put(setError(error, action));
        yield messageFunction && messageFunction("Dashboard item update failed.", "error");
        yield call(rejectPromiseAction, action, error);
      }
    });
  });
}

function* dashboardItemDeletePromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* dashboardItemDeleteWorker() {
    const {
      payload: { dashboardId, body, messageFunction }
    } = action;
    yield dashboardItemGeneratorWrapper(function* dashboardItemGeneratorWrapperDelete() {
      try {
        const { data } = yield call(axiosSagaRequest, "post", `${prefix}/${dashboardId}/item/delete_multiple`, body);
        yield messageFunction && messageFunction("Dashboard item deleted.", "success");
        yield call(resolvePromiseAction, action, data);
      } catch (error) {
        yield put(setError(error, action));
        yield messageFunction && messageFunction("Dashboard item deletion failed.", "error");
        yield call(rejectPromiseAction, action, error);
      }
    });
  });
}

// Dashboard item definitions
function* dashboardItemDefinitionsCallGenerator(action) {
  yield put(onCallDashboardItemDefinitions());
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${prefix}/item/definition`);
    yield put(onSuccessDashboardItemDefinitions(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDashboardItemDefinitions());
  }
}

function* dashboardItemDetailsCallGenerator(action) {
  yield put(onCallDashboardItemDetails());
  try {
    const {
      payload: { paginationData, itemId, definitionId }
    } = action;

    const params = paramParserForPagination(paginationData);

    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/item/${itemId}/details/${definitionId}?${queryString}`);
    yield put(onSuccessDashboardItemDetails(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedDashboardItemDetails());
  }
}

function* dashboardItemDetailsPromiseCallGenerator(action) {
  yield call(implementPromiseAction, action, function* contactsWorker() {
    const {
      payload: { itemId, definitionId, ...paginationData }
    } = action;
    yield put(onCallDashboardItemDetails(paginationData));
    try {
      const params = paramParserForPagination(paginationData);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/item/${itemId}/details/${definitionId}?${queryString}`);

      return yield put(onSuccessDashboardItemDetails(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedDashboardItemDetails());
    }
  });
}

export default function* dashboardsSaga() {
  yield takeLatest(DASHBOARDS_CALL, dashboardsCallGenerator);
  yield takeEvery(DASHBOARD_ADD_CALL, dashboardAddCallGenerator);
  yield takeEvery(DASHBOARD_EDIT_CALL, dashboardEditCallGenerator);
  yield takeEvery(DASHBOARD_DELETE_CALL, dashboardDeleteCallGenerator);
  yield takeEvery(DASHBOARD_RESTORE_CALL, dashboardRestoreCallGenerator);
  yield takeLatest(DASHBOARD_LAYOUT_EDIT_CALL, dashboardLayoutUpdateCallGenerator);
  yield takeEvery(callDashboardItemAddPromise, dashboardItemAddPromiseCallGenerator);
  yield takeEvery(callDashboardItemEditPromise, dashboardItemEditPromiseCallGenerator);
  yield takeEvery(callDashboardItemDeletePromise, dashboardItemDeletePromiseCallGenerator);
  yield takeLatest(DASHBOARD_ITEM_DEFINITIONS_CALL, dashboardItemDefinitionsCallGenerator);
  yield takeEvery(DASHBOARD_ITEM_DETAILS_CALL, dashboardItemDetailsCallGenerator);
  yield takeEvery(callDashboardItemDetailsPromise, dashboardItemDetailsPromiseCallGenerator);
}
