import { teal, orange, red } from "@mui/material/colors";

const baseThemeProps = {
  default: {
    primary: {
      light: "#8b54f9",
      main: "#7d4be1",
      dark: "#5427af",
      contrastText: "#fff"
    }
  },
  blue: {
    primary: {
      light: "#5186e2",
      main: "#4074cb",
      dark: "#325ca2",
      contrastText: "#fff"
    }
  },
  teal: {
    primary: {
      light: teal[300],
      main: teal[400],
      dark: teal[500],
      contrastText: "#fff"
    }
  },
  green: {
    primary: {
      light: "#16d16e",
      main: "#02b757",
      dark: "#059247",
      contrastText: "#fff"
    }
  },
  orange: {
    primary: {
      light: orange[400],
      main: orange[500],
      dark: orange[600],
      contrastText: "#fff"
    }
  },
  red: {
    primary: {
      light: red[600],
      main: red[700],
      dark: red[800],
      contrastText: "#fff"
    }
  }
};

export default baseThemeProps;
