import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import { INDUSTRIES_CALL } from "./sagas-actions";
import { onCallIndustries, onSuccessIndustries, onFailedIndustries } from "./slice";

function* industriesCallGenerator(action) {
  yield put(onCallIndustries());
  try {
    const { data } = yield call(axiosInstance.get, "/base/getIndustries");
    yield put(onSuccessIndustries(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedIndustries());
  }
}

export default function* industriesWatcherSaga() {
  yield takeEvery(INDUSTRIES_CALL, industriesCallGenerator);
}
