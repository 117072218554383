import { useEffect, useState } from "react";
import moment from "moment-timezone";

const useGetCityTime = (tz) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return new Date(moment(currentDate).tz(tz).format("YYYY-MM-DD HH:mm:ss"));
};

export default useGetCityTime;
