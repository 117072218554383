import { useCallback, useMemo } from "react";
import nearestColor from "nearest-color";

/**
 * This hook find the nearest color given a predefined list of colors.
 */

export const useGetNearestColorName = (colorList) => {
  const getNearestColor = useMemo(() => nearestColor.from(colorList), [colorList]);
  return useCallback((colorHexCode) => getNearestColor(colorHexCode), [getNearestColor]);
};

export default useGetNearestColorName;
