import { useMemo } from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { responsiveFontSizes } from "@mui/material";
import { adaptV4Theme, createTheme } from "@mui/material/styles";
import { userDataSelector } from "store/user/selectors";

const useUserTheme = () => {
  const { themeMode, themeProps, tempUiSettings } = useSelector(userDataSelector, isEqual);

  return useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          adaptV4Theme({
            palette: {
              mode:
                tempUiSettings?.themeMode !== undefined
                  ? tempUiSettings?.themeMode
                    ? "light"
                    : "dark"
                  : themeMode
                  ? "light"
                  : "dark",
              ...(themeMode && { 
                background: {
                  paper: '#f1f1f1',
                  default: '#f1f1f1'
                } 
              }),
              ...(tempUiSettings?.themeProps !== undefined ? tempUiSettings?.themeProps : themeProps)
            }
          })
        )
      ),
    [themeMode, themeProps, tempUiSettings]
  );
};

export default useUserTheme;
