export const MESSAGES_CALL = "MESSAGES_CALL";

export const MESSAGE_ADD_CALL = "MESSAGE_ADD_CALL";

export const MESSAGES_FETCH_CALL = "MESSAGES_FETCH_CALL";

export function getMessages(key) {
  return { type: MESSAGES_CALL, payload: key };
}
export function addMessage(key) {
  return { type: MESSAGE_ADD_CALL, payload: key };
}
export function fetchMessages(key) {
  return { type: MESSAGES_FETCH_CALL, payload: { key } };
}
